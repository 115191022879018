import React from 'react'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons'
import { formatNumber } from 'helpers/formatNumber'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    display: 'flex',
    alignItems: 'center'
  }
}))

interface DataTableBodyCellTrendProps {
  value?: string
}

export function DataTableBodyCellTrend ({ value }: DataTableBodyCellTrendProps) {
  const isValuePositive = Number(value) > 0
  const classes = useStyles()

  if (!isFinite(Number(value))) {
    return null
  }

  return (
    <div className={classes.root}>
      <div className={classes.icon} style={{ color: isValuePositive ? '#4caf50' : '#f44336' }}>
        {isValuePositive ? <ArrowDropUp /> : <ArrowDropDown />}
      </div>

      <Typography variant="caption" style={{ fontSize: 14 }} color={isValuePositive ? 'textSecondary' : 'error'}>
        {formatNumber(value, { maximumFractionDigits: 2 })}%
      </Typography>
    </div>
  )
}
