import React, { useEffect, useRef, useState } from 'react'
import { Grid } from '@material-ui/core'
import { DataTable } from 'components/DataTable'
import { useAuthContext } from 'components/AuthContext'
import { useLayoutContext } from 'components/LayoutContext'
import {
  marketAndCompanyApi,
  MonetaryCollection,
  MonetaryEndpoints,
  MonetaryTab
} from 'api/marketAndCompanyApi'
import { MarketVolumeAndCustomerSalesChart } from 'components/MarketVolumeAndCustomerSalesChart'
import { MarketAndCustomerSalesGrowthChart } from 'components/MarketAndCustomerSalesGrowthChart'
import { Marker } from 'components/Marker'
import { CommentForm } from 'components/CommentForm'
import { useQueryParams } from '../../hooks/queryParams'

export function ConsumptionInMonetaryTermsPage () {
  const { startContentLoading, finishContentLoading } = useLayoutContext()
  const { handleResponseFailure, handleResponseSuccess, currentUser } = useAuthContext()
  const [collection, setCollection] = useState<MonetaryCollection>()
  const { queryParams } = useQueryParams()
  const inKindTableRef = useRef<HTMLDivElement>(null)
  const kpiTableRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (currentUser && currentUser.selectedCountry) {
      startContentLoading()

      marketAndCompanyApi.getMonetaryCollection(currentUser.selectedCountry.id)
        .then(setCollection)
        .catch(handleResponseFailure)
        .finally(finishContentLoading)
    }
  }, [finishContentLoading, startContentLoading, handleResponseFailure, currentUser])

  if (!collection) {
    return null
  }

  if (queryParams.get('inKindTableSelectedRows')) {
    inKindTableRef.current?.scrollIntoView()
  }

  if (queryParams.get('kpiTableSelectedRowsIds')) {
    kpiTableRef.current?.scrollIntoView()
  }

  const updateCollection = () => {
    if (currentUser && currentUser.selectedCountry) {
      marketAndCompanyApi.getMonetaryCollection(currentUser.selectedCountry.id)
        .then((updatedCollection) => {
          setCollection(updatedCollection)
          handleResponseSuccess()
        })
        .catch(handleResponseFailure)
    }
  }
  const handleTab1Update = (grid: MonetaryTab['grid']) => {
    marketAndCompanyApi.updateMonetaryTab1(grid)
      .then(updateCollection)
      .catch(handleResponseFailure)
  }
  const handleTab2Update = (grid: MonetaryTab['grid']) => {
    marketAndCompanyApi.updateMonetaryTab2(grid)
      .then(updateCollection)
      .catch(handleResponseFailure)
  }
  const handleCommentChange = (comment: string) => {
    marketAndCompanyApi.updateMonetaryComment(comment)
      .then(handleResponseSuccess)
      .catch(handleResponseFailure)
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={3} sm={6}>
        <Marker {...collection[MonetaryEndpoints.MARKER_MARKET]} />
      </Grid>

      <Grid item xs={12} md={3} sm={6}>
        <Marker {...collection[MonetaryEndpoints.MARKER_COMPANY]} />
      </Grid>

      <Grid item xs={12} md={3} sm={6}>
        <Marker {...collection[MonetaryEndpoints.MARKER_MARKET_SHARE]} />
      </Grid>

      <Grid item xs={12} md={7}>
        <MarketVolumeAndCustomerSalesChart
          {...collection[MonetaryEndpoints.GRAPH_VOL]}
        />
      </Grid>

      <Grid item xs={12} md={5}>
        <MarketAndCustomerSalesGrowthChart
          {...collection[MonetaryEndpoints.GRAPH_GROWTH]}
        />
      </Grid>

      <Grid item xs={12} ref={inKindTableRef}>
        <DataTable
          title={collection[MonetaryEndpoints.TAB1].title}
          description={collection[MonetaryEndpoints.TAB1].description}
          data={collection[MonetaryEndpoints.TAB1].grid}
          onDataUpdate={handleTab1Update}
        />
      </Grid>

      <Grid item xs={12} md={8} ref={kpiTableRef}>
        <DataTable
          title={collection[MonetaryEndpoints.TAB2].title}
          description={collection[MonetaryEndpoints.TAB2].description}
          data={collection[MonetaryEndpoints.TAB2].grid}
          onDataUpdate={handleTab2Update}
          highlightedRows={queryParams.get('kpiTableSelectedRowsIds')}
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <CommentForm
          label={collection[MonetaryEndpoints.COMMENT].title}
          value={collection[MonetaryEndpoints.COMMENT].comment}
          onChange={handleCommentChange}
        />
      </Grid>
    </Grid>
  )
}
