import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { CompaniesPage } from './CompaniesPage'
import { CompanyUsersPage } from './CompanyUsersPage'

export function CompaniesManagementPage () {
  const match = useRouteMatch()

  return (
    <Switch>
      <Route path={match.url} exact component={CompaniesPage} />
      <Route path={`${match.url}/:companyId/users`} component={CompanyUsersPage} />
    </Switch>
  )
}
