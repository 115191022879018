import React, { useState, useEffect } from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { observer } from 'mobx-react-lite'
import { Box } from '@material-ui/core'
import { countriesStore, ICountriesStore } from '../stores/countriesStore'
import { useAuthContext } from './AuthContext'
import { useHistory } from 'react-router'
import { Country } from '../api/types'
import { profileApi } from '../api/profileApi'
import { countriesApi } from '../api/countriesApi'
import { useTranslationContext } from './TranslationContext'
import { UserRole } from '../api/usersApi'

interface CountrySelectProps {
  countriesStore: ICountriesStore
}

function CountrySelectWithStore ({ countriesStore } : CountrySelectProps) {
  const { handleResponseSuccess, handleResponseFailure, currentUser } = useAuthContext()
  const { translation } = useTranslationContext()
  const history = useHistory()
  const [countries, setCountries] = useState<Country[]>()

  useEffect(() => {
    if (!countries && currentUser) {
      countriesApi[currentUser.role === UserRole.APP_ADMIN ? 'getCountriesList' : 'getCompanyCountriesList']()
        .then(countriesList => {
          setCountries(countriesList)
        })
    }
  })

  if (countries === undefined || !currentUser) {
    return null
  }

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    return profileApi.changeSelectedCountry(currentUser?.id, event.target.value as number)
      .then(() => {
        handleResponseSuccess()
        history.go(0)
      })
      .catch(handleResponseFailure)
  }

  return (
    <Box px={5}>
      <FormControl>
        <Select
          value={currentUser.selectedCountry ? currentUser.selectedCountry.id : ''}
          onChange={handleChange}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
        >
          <MenuItem value="" disabled>
            {translation['common.select_country']}
          </MenuItem>
          {
            countries.map((el: Country) => <MenuItem key={el.id!.toString()} value={el.id}>
              { el.name }
            </MenuItem>)
          }
        </Select>
      </FormControl>
    </Box>
  )
}
const CountrySelectObserver = observer(CountrySelectWithStore)
export const CountrySelect = () => <CountrySelectObserver countriesStore={countriesStore} />
