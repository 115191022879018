import React from 'react'
import { useParams } from 'react-router-dom'
import { Page } from 'components/Page'
import { UsersList } from 'components/UsersList'
import { useTranslationContext } from 'components/TranslationContext'

export function CompanyUsersPage () {
  const { companyId } = useParams<{ companyId: string }>()
  const { translation } = useTranslationContext()

  return (
    <Page title={translation['menu.company_users']}>
      <UsersList companyId={Number(companyId)} />
    </Page>
  )
}
