import React, { useState } from 'react'
import { Formik, Form, Field, FormikConfig } from 'formik'
import { CheckboxWithLabel } from 'formik-material-ui'
import { Container, Card, CardContent, Typography, Box, FormHelperText } from '@material-ui/core'
import { Credentials, authApi } from 'api/authApi'
import { useAuthContext } from 'components/AuthContext'
import { TextField } from 'components/TextField'
import { LoadingButton } from 'components/LoadingButton'
import { useTranslationContext } from 'components/TranslationContext'
import { LayoutLanguage } from './LayoutLanguage'

interface Values extends Credentials {
  persistToken: boolean
}

const isValidEmail = (string: string) => {
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,5}$/i.test(string)
}

export function SignInForm () {
  const { signIn } = useAuthContext()
  const [signInError, setSignInError] = useState<string>()
  const { translation } = useTranslationContext()
  const initialValues: Values = {
    email: '',
    password: '',
    persistToken: true
  }
  const validate = ({ email, password }: Values) => {
    const errors: Partial<Credentials> = {}

    if (!email) {
      errors.email = translation['menu.login.validation.empty_email']
    } else if (!isValidEmail(email)) {
      errors.email = translation['menu.login.validation.wrong_email']
    }

    if (!password) {
      errors.password = translation['menu.login.validation.empty_password']
    }

    return errors
  }
  const handleSubmit: FormikConfig<Values>['onSubmit'] = ({ persistToken, ...values }, { setErrors }) => {
    return authApi.signIn(values)
      .then(({ token }) => {
        signIn(token, persistToken)
      })
      .catch((error) => {
        if (error.message) {
          setSignInError(error.message)
        }
        if (error.details) {
          setSignInError(error.details.join('\n'))
        }
      })
  }

  return (
    <Container maxWidth="xs">
      <Box display='flex' justifyContent='flex-end' flexDirection='row' flexBasis='100%'>
        <LayoutLanguage />
      </Box>
      <Box pb={6} pt={2}>
        <Card>
          <CardContent>
            <Typography variant="h5" align="center">
              {translation['menu.login.title']}
            </Typography>

            <Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit}>
              {({ isSubmitting, errors }) => (
                <Form>
                  <TextField
                    name="email"
                    label={translation['menu.profile.email']}
                    autoComplete="email"
                    autoFocus
                  />

                  <TextField
                    type="password"
                    name="password"
                    label={translation['menu.profile.password']}
                    autoComplete="current-password"
                  />

                  {signInError && (
                    <FormHelperText error>
                      {signInError}
                    </FormHelperText>
                  )}

                  <Field
                    component={CheckboxWithLabel}
                    name="persistToken"
                    Label={{ label: translation['menu.login.remember'] }}
                    type="checkbox"
                    color="primary"
                  />

                  <Box mt={2} clone>
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      color="primary"
                      size="large"
                      fullWidth
                      disableElevation
                      disabled={isSubmitting}
                      isLoading={isSubmitting}
                    >
                      {translation['menu.login.button']}
                    </LoadingButton>
                  </Box>
                </Form>
              )}
            </Formik>
          </CardContent>
        </Card>
      </Box>
    </Container>
  )
}
