import { request } from 'api/request'
import { Country, Region } from './types'

export interface Company {
  id: number
  name: string,
  shortName: string,
  market: string,
  subscriptionExpiresData: string,
  licenseUsersTotal: number,
  licenseUsersActive: number,
  clientHost: string,
  logo?: string,
  countries: Country[]
  divisionBySubject: boolean
  personalClassification: boolean
  customRegions: Region[]
  marketingBudgetEditable: boolean
}

function getCompanyLogo (companyId: Company['id']) {
  return request(`companies/${companyId}/logo`)
}

function updateCompanyLogo (companyId: Company['id'], logo: Blob | string | null | MediaSource) {
  const formData = new FormData()
  if (logo) {
    // @ts-ignore
    formData.append('logo', logo, 'companylogo')
  }
  return request(`companies/${companyId}/logo`, 'POST', formData)
}

function getCompany (companyId: Company['id']) {
  return request(`companies/${companyId}`)
}

function getCompanies () {
  return request('companies')
}

function updateCompany (company: Partial<Company>) {
  return request(`companies/${company.id}`, 'PATCH', company)
}

function createCompany (company: Partial<Company>) {
  return request('companies', 'POST', company)
}

async function getCurrentCompany (): Promise<Company> {
  return await request('companies/current') as Company
}

export const companiesApi = {
  getCompany,
  getCompanyLogo,
  updateCompanyLogo,
  getCompanies,
  updateCompany,
  createCompany,
  getCurrentCompany
}
