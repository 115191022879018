import React from 'react'
import { Formik, Form, FormikConfig } from 'formik'
import { Box, MenuItem } from '@material-ui/core'
import { User, UserRole } from 'api/usersApi'
import { useTranslationContext } from 'components/TranslationContext'
import { userRoles } from 'constants/userRoles'
import { TextField } from 'components/TextField'
import { LoadingButton } from 'components/LoadingButton'

type Values = Partial<User>

const defaultValues: Values = {
  username: '',
  role: UserRole.OPERATOR,
  email: '',
  position: ''
}

export interface UserFormProps extends Omit<FormikConfig<Values>, 'initialValues'> {
  initialValues?: Values
}

export function UserForm ({ initialValues, onSubmit }: UserFormProps) {
  const { translation } = useTranslationContext()

  return (
    <Formik initialValues={initialValues ?? defaultValues} onSubmit={onSubmit}>
      {({ isSubmitting }) => (
        <Form>
          <TextField
            name="username"
            label={translation['menu.profile.name']}
            autoFocus
          />

          <TextField
            name="email"
            label={translation['menu.profile.email']}
          />

          <TextField
            name="position"
            label={translation['menu.profile.position']}
          />

          <TextField
            name="role"
            label={translation['menu.profile.role']}
            select
          >
            {Object.entries(userRoles).map(([key, value]) => (
              <MenuItem key={key} value={key}>{translation[`roles.${value}`]}</MenuItem>
            ))}
          </TextField>

          {!initialValues?.id && (
            <TextField
              name="password"
              label={translation['menu.profile.password']}
              type="password"
            />
          )}

          <Box mt={2} textAlign="right">
            <LoadingButton
              type="submit"
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              isLoading={isSubmitting}
            >
              {translation['form.save']}
            </LoadingButton>
          </Box>
        </Form>
      )}
    </Formik>
  )
}
