import React, { ChangeEvent, Fragment } from 'react'
import { Box, Button, Typography } from '@material-ui/core'
import { StyledTypography } from '../StyledTypography'
import { useTranslationContext } from '../../components/TranslationContext'
import { useAuthContext } from '../../components/AuthContext'
import { getLocalStatLogo } from '../../pages/OutletSalesPage/LocalStatLogo'

interface DataSourcesProps {
  onSelectExcel?: (event: ChangeEvent<HTMLInputElement>) => void
  excelUploadDisabled?: boolean
  manualInputDisabled?: boolean
  manualInputActive?: boolean
  rosstatActive?: boolean
  excelInputActive?: boolean
  rosstatDisabled?: boolean
  excel?: boolean
  withExcelFileInputOpen?: boolean
  manual?: boolean
  rosstat?: boolean
  onManualInputClick?: () => void
  onExcelInputClick?: () => void
  onRosstatClick?: () => void
}

export function DataSources ({ onSelectExcel, withExcelFileInputOpen, excelInputActive, onManualInputClick, onExcelInputClick, onRosstatClick, excelUploadDisabled, manualInputDisabled, rosstatDisabled, excel, manual, rosstat, rosstatActive, manualInputActive }: DataSourcesProps) {
  const { translation } = useTranslationContext()
  const trans = (message: string) => translation[`pages.OutletSalesPage.${message}`]
  const { currentUser } = useAuthContext()
  const localStatLogo = getLocalStatLogo(currentUser?.selectedCountry)

  return (
    <Fragment>
      <Box>
        <Typography variant='h5'>{trans('source_title')}</Typography>
      </Box>
      <Box display='flex' py={3}>
        {manual && <Box flexDirection="column" display="flex" mr={3} mx={2}>
          <Button onClick={onManualInputClick} component="label" disabled={manualInputDisabled} style={{ width: 200, height: 200, border: manualInputActive ? '2px solid #c5c5c5' : 'none', cursor: 'pointer', backgroundColor: manualInputActive ? '#ffffff' : '#f5f5f5' }}>
            <img
              src="/hand-mode-icon.png"
              style={{ width: 120, height: 120 }}
              alt={trans('source_manual')}
            />
            <StyledTypography style={{ textAlign: 'center', marginLeft: 10 }}>{trans('source_manual')}</StyledTypography>
          </Button>
        </Box>}
        {excel && <Box flexDirection="column" display="flex" mx={2}>
          <Button onClick={onExcelInputClick} component="label" disabled={excelUploadDisabled} style={{ width: 220, height: 200, border: excelInputActive ? '2px solid #c5c5c5' : 'none', cursor: 'pointer', backgroundColor: excelInputActive ? '#ffffff' : '#f5f5f5' }}>
            <img
              src="/excel-icon.png"
              style={{ width: 120, height: 120 }}
              alt={trans('source_excel')}
            />
            { withExcelFileInputOpen && <input
              onChange={onSelectExcel}
              name="outletSalesTargetsXlsx"
              type="file"
              hidden
              accept="application/xlsx"
            />}
            <StyledTypography style={{ textAlign: 'center', marginLeft: 10 }}>{trans('source_excel')}</StyledTypography>
          </Button>
        </Box>}
        {rosstat && <Box flexDirection="column" display="flex" mx={2}>
          <Button onClick={onRosstatClick} component="label" disabled={rosstatDisabled} style={{ width: 220, height: 200, border: rosstatActive ? '2px solid #c5c5c5' : 'none', cursor: 'pointer', backgroundColor: rosstatActive ? '#ffffff' : '#f5f5f5' }}>
            <img
              src={localStatLogo.logo}
              style={{ width: 120, height: 120 }}
              alt={trans(localStatLogo.translation)}
            />
            <Box>
              <StyledTypography style={{ textAlign: 'center', marginLeft: 10 }}>{trans(localStatLogo.translation)}</StyledTypography>
            </Box>
          </Button>
        </Box>}
      </Box>
    </Fragment>
  )
}
