import { createStyles, withStyles } from '@material-ui/core/styles'
import { Table, TableProps } from '@material-ui/core'
import React from 'react'

export const StyledTable = withStyles(() => {
  return createStyles({
    root: {
      '& td, & th': {
        fontSize: 16
      }
    }
  })
})((props: TableProps) => <Table {...props} />)
