import React from 'react'
import { Page } from 'components/Page'
import { OwnProductionPage } from './OwnProductionPage'
import { ContractProductionPage } from './ContractProductionPage'
import { useTranslationContext } from 'components/TranslationContext'

export function NewProductsLaunchPage () {
  const { translation } = useTranslationContext()
  const tabs = [
    {
      label: translation['pages.NewProductsLaunchPage.tabs.own_production'],
      url: 'own-production',
      component: OwnProductionPage,
      hasInfoOnly: true
    },
    {
      label: translation['pages.NewProductsLaunchPage.tabs.contract_production'],
      url: 'contract-production',
      component: ContractProductionPage,
      hasInfoOnly: true
    }
  ]
  return (
    <Page title={translation['pages.NewProductsLaunchPage.title']} tabs={tabs} />
  )
}
