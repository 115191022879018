import React, { Fragment, useCallback, useEffect, useState } from 'react'
import {
  Box, Button,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select, SelectProps,
  Typography
} from '@material-ui/core'
import { CustomFormEditable } from '../../components/CustomFormEditable'
import { useTranslationContext } from '../../components/TranslationContext'
import { OutletPurposeItem, OutletsPurposes, Table } from '../../api/types'
import { useSnackbar } from 'notistack'
import { useAuthContext } from '../../components/AuthContext'
import { marketStandartApi } from '../../api/marketStandartApi'
import { TabInterface } from './TabInterface'
import { StyledTypography } from '../../components/StyledTypography'
import { useQueryParams } from '../../hooks/queryParams'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import { DataTable } from '../../components/DataTable'
import { CloudDownload } from '@material-ui/icons'
import { useLocalStorage } from '../../helpers/useLocalStorage'

interface PageQueryParams {
  categoryId: number | unknown
}

interface IManualInputTab extends TabInterface {
  excel?: boolean
}

const OUTLET_SALES_STANDARDS_USER_CATEGORY = 'OutletSalesStandards.category'
const OUTLET_SALES_STANDARDS_USER_REGION = 'OutletSalesStandards.region'
const OUTLET_SALES_STANDARDS_USER_PURPOSE = 'OutletSalesStandards.purpose'

export function ManualInputTab ({ productCategories, userRegions, personalClassification, standardRussiaRegions, excel }: IManualInputTab) {
  const { queryParams, setQueryParams } = useQueryParams()
  const [userCategory, setUserCategory] = useLocalStorage(OUTLET_SALES_STANDARDS_USER_CATEGORY)
  const [userRegion, setUserRegion] = useLocalStorage(OUTLET_SALES_STANDARDS_USER_REGION)
  const [userPurpose, setUserPurpose] = useLocalStorage(OUTLET_SALES_STANDARDS_USER_PURPOSE)
  const { translation } = useTranslationContext()
  const [firstAnswer, setFirstAnswer] = useState<string | null>(null)
  const [outletsPurposesRosstat, setOutletsPurposesRosstat] = useState<OutletsPurposes>()
  const [outletsPurposesCustom, setOutletsPurposesCustom] = useState<OutletsPurposes>()
  const { enqueueSnackbar } = useSnackbar()
  const { handleResponseFailure, handleResponseSuccess, selectedCountry, currentCompany } = useAuthContext()
  const [categoryId, setCategoryId] = useState<string | null>(
    queryParams.has('categoryId')
      ? queryParams.get('categoryId')
      : (userCategory as unknown) as string || ''
  )
  const [outletPurposeId, setOutletPurposeId] = useState<number | null>(Number(userPurpose) || null)
  const [regionId, setRegionId] = useState<string | null>(userRegion || null)
  const [outletsSalesStandardTab, setOutletsSalesStandardTab] = useState<Table | null>()
  const [excelFile, setExcelFile] = useState<File | null>()

  const trans = useCallback((message: string) => translation[`pages.OutletSalesPage.${message}`], [translation])

  const updateTab = useCallback(() => {
    if (categoryId && regionId && outletPurposeId) {
      marketStandartApi.getOutletSalesStandardsTab({
        categoryId: Number(categoryId),
        regionId: regionId === 'others' ? regionId : Number(regionId),
        purposeId: outletPurposeId
      })
        .then(setOutletsSalesStandardTab)
        .catch(handleResponseFailure)
    }
  }, [handleResponseFailure, categoryId, regionId, outletPurposeId])

  useEffect(() => {
    updateTab()
  }, [updateTab])

  useEffect(() => {
    if (excelFile && categoryId) {
      marketStandartApi.uploadOutletSalesStandardsXlsx({
        xlsx: excelFile,
        categoryId
      }).then((response) => {
        enqueueSnackbar(trans('file_uploaded'), { variant: 'success' })
      })
        .finally(() => {
          setExcelFile(null)
          updateTab()
        })
    }
  }, [excelFile, categoryId, enqueueSnackbar, updateTab, trans])

  useEffect(() => {
    if (!outletsPurposesRosstat) {
      marketStandartApi.getOutletsPurposesRosstat()
        .then((result: OutletsPurposes) => {
          setOutletsPurposesRosstat(result)
        })
        .catch(handleResponseFailure)
    }
  }, [outletsPurposesRosstat, handleResponseFailure])

  useEffect(() => {
    if (!outletsPurposesCustom) {
      marketStandartApi.getOutletsPurposes()
        .then((result: OutletsPurposes) => {
          setOutletsPurposesCustom(result)
          setFirstAnswer(result.names.length ? 'yes' : 'no')
        })
        .catch(handleResponseFailure)
    }
  }, [outletsPurposesCustom, handleResponseFailure])

  const handleSubmitCustomOutlets = async (outletsCustomNames : { names: OutletPurposeItem[] }) => {
    try {
      const newItems = await marketStandartApi.updateOutletsPurposes(outletsCustomNames)
      setOutletsPurposesCustom(newItems)
      handleResponseSuccess()
    } catch (err) {
      handleResponseFailure(err as any)
    }
  }

  const handleChangeFirstAnswer = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const answer = event.target.value as string
    setFirstAnswer(answer)
    if (answer === 'no') {
      const newItems = await marketStandartApi.deleteOutletsPurposes()
      setOutletsPurposesCustom(newItems)
    }
  }

  const handleCategoriesFilterChange: SelectProps['onChange'] = (e) => {
    const { value } = e.target
    setQueryParams<PageQueryParams>({ categoryId: value })
    setUserCategory(e.target.value as string)
    setCategoryId(value as string)
  }

  const handleOutletPurposesFilterChange: SelectProps['onChange'] = (e) => {
    const { value } = e.target
    setUserPurpose(value as string)
    setOutletPurposeId(value as number)
  }

  if (!outletsPurposesRosstat || !outletsPurposesCustom || !currentCompany) {
    return null
  }

  const purposes = (outletsPurposesCustom.names.length > 0) ? outletsPurposesCustom : outletsPurposesRosstat

  const handleTableUpdate = (grid: Table['grid']) => {
    if (categoryId && regionId && outletPurposeId) {
      marketStandartApi.updateOutletSalesStandardsTab({
        categoryId: Number(categoryId),
        regionId: regionId === 'others' ? regionId : Number(regionId),
        purposeId: outletPurposeId
      }, grid)
        .then(() => {
          updateTab()
          handleResponseSuccess()
        })
        .catch(handleResponseFailure)
    }
  }

  return (
    <Fragment>
      <Fragment>
        { <Grid item xs={12}>
          <FormControl component="fieldset">
            <Typography variant="h6" gutterBottom>
              1. {trans('is_manual_classification')}
            </Typography>
            <RadioGroup value={firstAnswer} onChange={handleChangeFirstAnswer}>
              <FormControlLabel value="yes" control={<Radio color="primary"/>} label={translation['components.GeographySalesPage.yes']} />
              <FormControlLabel value="no" control={<Radio color="primary"/>} label={translation['components.GeographySalesPage.no']} />
            </RadioGroup>
          </FormControl>
        </Grid>}

        { firstAnswer === 'yes' && <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            2. {trans('enter_outlets')}
          </Typography>
          <Box style={{ maxWidth: 500 }}>
            {<CustomFormEditable header={trans('outlet_names')} handleSubmit={handleSubmitCustomOutlets} items={outletsPurposesCustom} />}
          </Box>
        </Grid>}

        <Box py={2}>
          <Grid container spacing={3}>
            <Grid item md={4}>
              <FormControl variant="outlined" fullWidth>
                <StyledTypography variant="h5">{trans('choose_category')}</StyledTypography>
                <Select
                  value={categoryId}
                  onChange={handleCategoriesFilterChange}
                  MenuProps={{ MenuListProps: { disablePadding: true } }}
                  displayEmpty
                >
                  <MenuItem value="" disabled>{trans('choose_category')}</MenuItem>
                  {
                    productCategories.productCategoryArr.map(el => <MenuItem value={el.id}>{el.categoryName}</MenuItem>)
                  }
                </Select>
              </FormControl>
            </Grid>

          </Grid>
        </Box>

        <Box py={2}>
          <Grid container spacing={3}>
            <Grid item md={4} xs={12}>
              <FormControl variant="outlined" fullWidth>
                <StyledTypography variant="h5">{trans('purpose')}</StyledTypography>
                <Select
                  value={outletPurposeId || ''}
                  onChange={handleOutletPurposesFilterChange}
                  MenuProps={{ MenuListProps: { disablePadding: true } }}
                  displayEmpty
                >
                  <MenuItem value="" disabled>{trans('choose_purpose')}</MenuItem>
                  {
                    purposes.names.map((el) => <MenuItem value={el.id}>{el.name}</MenuItem>)
                  }
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Box>

        <Box py={2}>
          <StyledTypography
            variant="h5">{translation['components.GeographyConsumptionPage.regions']} {personalClassification ? currentCompany.name : selectedCountry!.shortName!}</StyledTypography>
          <ToggleButtonGroup
            exclusive
            value={regionId}
            onChange={(event: React.MouseEvent<HTMLElement>, value) => {
              if (value) {
                setUserRegion((value as string))
                setRegionId(value as string)
              }
            }}
          >
            {(personalClassification ? userRegions : standardRussiaRegions).items.map((el, idx) => <ToggleButton
              key={idx.toString()} value={el.id.toString()}>{el.name}</ToggleButton>)}
            <ToggleButton value={'others'}>{translation['components.GeographyConsumptionPage.others']}</ToggleButton>
          </ToggleButtonGroup>
        </Box>
        <Grid container >
          <Grid item xs={12} md={8}>
            { !excel && <Typography variant="h6" gutterBottom style={{ marginTop: '1px' }}>3. {translation['pages.OutletSalesPage.step_3.source_manual']}</Typography> }
            { excel && <Typography variant="h6" gutterBottom style={{ marginTop: '1px' }} >3. {translation['pages.OutletSalesPage.step_3.source_excel']}</Typography> }
          </Grid>
          <Grid item xs={12} md={4}>
            { excel &&
            <Box pb={3} display='flex' flexDirection='row' justifyContent='flex-end'>
              <Button
                component='label'
                variant='contained'
                color='primary'
                startIcon={<CloudDownload />}
              >
                <input
                  onChange={(event) => setExcelFile(event.currentTarget.files![0])}
                  name="outletSalesStandardsXlsx"
                  type="file"
                  hidden
                  accept="application/xlsx"
                />
                {trans('upload_template')}
              </Button>
              <Button
                style={{ marginLeft: 3 }}
                onClick={() => marketStandartApi.downloadXlsx(`market_standards/outlet_sales_standards/template/${categoryId}`)}
                variant='contained'
                color='primary'
                startIcon={<CloudDownload />}
              >
                {trans('download_template')}
              </Button>
            </Box>
            }
            {
              !excel && categoryId && regionId && outletPurposeId && outletsPurposesRosstat &&
              <Box pb={3} display='flex' flexDirection='row' justifyContent='flex-end'>
                <Button
                  style={{ marginLeft: 3 }}
                  onClick={() => {
                    if (categoryId && regionId && outletPurposeId) {
                      marketStandartApi.downloadOutletSalesTab({
                        categoryId: Number(categoryId),
                        regionId: regionId === 'others' ? regionId : Number(regionId),
                        purposeId: outletPurposeId
                      })
                    }
                  }}
                  variant='contained'
                  color='primary'
                  startIcon={<CloudDownload />}
                >
                  {trans('download')}
                </Button>
              </Box>
            }
          </Grid>
        </Grid>
        { outletsSalesStandardTab && <Grid item xs={12}>
          <DataTable
            title={outletsSalesStandardTab.title}
            data={outletsSalesStandardTab.grid}
            hint={outletsSalesStandardTab.description}
            onDataUpdate={handleTableUpdate}
            showYear
          />
        </Grid>}

      </Fragment>
    </Fragment>
  )
}
