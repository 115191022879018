import React from 'react'
import { Page } from '../components/Page'
import { useParams } from 'react-router-dom'
import { Typography } from '@material-ui/core'
import { useTranslationContext } from 'components/TranslationContext'

export function InfoPage () {
  const { id } = useParams<any>()
  const { translation } = useTranslationContext()

  const sectionsInProgress = [
    translation['menu.dashboards_general'],
    translation['menu.dashboards_general_director'],
    translation['menu.dashboards_director_of_development'],
    translation['menu.dashboards_director_of_sales'],
    translation['menu.dashboards_marketing_director'],
    translation['menu.assortment_development'],
    translation['menu.optimal_batch_of_new_products'],
    translation['menu.pilot_sales'],
    translation['menu.prices_by_sales_channel'],
    translation['menu.prices_for_foreign_markets'],
    translation['menu.quantitative_distribution'],
    translation['menu.quality_distribution'],
    translation['menu.liquidity_through_pos'],
    translation['menu.promotion_communication'],
    translation['menu.promotion_market_plan_budget'],
    translation['menu.market_standards_consumption'],
    translation['menu.price_monitoring'],
    translation['menu.optimal_price'],
    translation['menu.sales_management'],
    translation['menu.market_standards_distribution']
  ]

  return (
    <Page title={sectionsInProgress[id - 1]}>
      <Typography paragraph>
        {translation['pages.InfoPage.content']}
      </Typography>
    </Page>
  )
}
