import React from 'react'
import { Grid } from '@material-ui/core'

export function ConsumerAssessmentPage () {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <img
          src='/consumer-assessment.png'
          style={{ width: '100%' }}
          alt='consumer-assessment preview'
        />
      </Grid>
    </Grid>
  )
}
