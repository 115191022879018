import React, { createContext, useCallback, useContext, useState } from 'react'

interface Context {
  title?: string
  pageKey?: string
  editableTitle?: boolean
  setTitle: (title?: string) => void
  setPageKey: (pageKey?: string) => void
  setEditableTitle: (editableTitle?: boolean) => void
  isContentLoading: boolean
  startContentLoading: () => void
  finishContentLoading: () => void
}

const LayoutContext = createContext<Context | null>(null)

export function LayoutContextProvider ({ children }: React.PropsWithChildren<{}>) {
  const [title, setTitle] = useState<string>()
  const [pageKey, setPageKey] = useState<string>()
  const [editableTitle, setEditableTitle] = useState<boolean>()
  const [contentLoadingCount, setContentLoadingCount] = useState(0)
  const isContentLoading = contentLoadingCount > 0
  const startContentLoading = useCallback(() => (
    setContentLoadingCount((prevValue) => prevValue + 1)
  ), [])
  const finishContentLoading = useCallback(() => (
    setContentLoadingCount((prevValue) => prevValue - 1)
  ), [])
  const value = {
    title,
    setTitle,
    pageKey,
    setPageKey,
    editableTitle,
    setEditableTitle,
    isContentLoading,
    startContentLoading,
    finishContentLoading
  }

  return (
    <LayoutContext.Provider value={value}>
      {children}
    </LayoutContext.Provider>
  )
}

export function useLayoutContext () {
  const context = useContext(LayoutContext)

  if (!context) {
    throw new Error('Used outside of "LayoutContextProvider"')
  }

  return context
}
