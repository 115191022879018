import React from 'react'
import { Page } from 'components/Page'
import { StartPage } from './StartPage'
import { ExpertReviewPage } from './ExpertReviewPage'
import { ConsumerAssessmentPage } from './ConsumerAssessmentPage'
import { TotalPage } from './TotalPage'
import { useTranslationContext } from 'components/TranslationContext'

export function AestheticsAssessmentPage () {
  const { translation } = useTranslationContext()
  const tabs = [
    {
      label: translation['pages.AestheticsAssessmentPage.tabs.start'],
      url: 'start',
      component: StartPage,
      hasInfoOnly: true
    },
    {
      label: translation['pages.AestheticsAssessmentPage.tabs.expert_review'],
      url: 'expert-review',
      component: ExpertReviewPage,
      hasInfoOnly: true
    },
    {
      label: translation['pages.AestheticsAssessmentPage.tabs.consumer_assessment'],
      url: 'consumer-assessment',
      component: ConsumerAssessmentPage,
      hasInfoOnly: true
    },
    {
      label: translation['pages.AestheticsAssessmentPage.tabs.total'],
      url: 'total',
      component: TotalPage,
      hasInfoOnly: true
    }
  ]
  return (
    <Page title={translation['pages.AestheticsAssessmentPage.title']} tabs={tabs} />
  )
}
