import React from 'react'
import { InfoPage } from './infoPage'
import { Page } from 'components/Page'
import { useTranslationContext } from 'components/TranslationContext'

export function PromoCommunicationByTaskPage () {
  const { translation } = useTranslationContext()

  const tabs = [
    {
      label: translation['menu.promotion_communication_tab.leadership_retention'],
      url: 'leadership-retention',
      component: InfoPage
    },
    {
      label: translation['menu.promotion_communication_tab.new_product_or_brand'],
      url: 'new-product-or-brand',
      component: InfoPage
    },
    {
      label: translation['menu.promotion_communication_tab.awareness_growth'],
      url: 'awareness-growth',
      component: InfoPage
    },
    {
      label: translation['menu.promotion_communication_tab.growth_through_outlet'],
      url: 'growth-through-outlet',
      component: InfoPage
    },
    {
      label: translation['menu.promotion_communication_tab.loyalty_growth'],
      url: 'loyalty-growth',
      component: InfoPage
    },
    {
      label: translation['menu.promotion_communication_tab.lead_generation_for_the_task'],
      url: 'lead-generation-for-the-task',
      component: InfoPage
    }
  ]

  return (
    <Page title={translation['menu.promotion_communication']} tabs={tabs} />
  )
}
