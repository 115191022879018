import React, { useEffect, useState } from 'react'
import { Field, Form, Formik } from 'formik'
import { Checkbox } from 'formik-material-ui'
import {
  Box,
  Card,
  CardContent,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core'
import { useAuthContext } from 'components/AuthContext'
import { useLayoutContext } from 'components/LayoutContext'
import { useTranslationContext } from 'components/TranslationContext'
import { LoadingButton } from 'components/LoadingButton'
import { companyCharacteristicsApi, MarketingBudget } from 'api/companyCharacteristicsApi'
import { TableCellField } from 'components/TableCellField'

export function MarketingBudgetPage () {
  const { handleResponseSuccess, handleResponseFailure } = useAuthContext()
  const { startContentLoading, finishContentLoading } = useLayoutContext()
  const { translation } = useTranslationContext()
  const [marketingBudget, setMarketingBudget] = useState<MarketingBudget>()
  const handleSubmit = (marketingBudget: MarketingBudget) => {
    return companyCharacteristicsApi.updateMarketingBudget(marketingBudget)
      .then(() => handleResponseSuccess())
      .catch(handleResponseFailure)
  }

  useEffect(() => {
    startContentLoading()

    companyCharacteristicsApi.getMarketingBudget()
      .then(setMarketingBudget)
      .catch(handleResponseFailure)
      .finally(finishContentLoading)
  }, [finishContentLoading, startContentLoading, handleResponseFailure])

  if (!marketingBudget) {
    return null
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={8}>
        <Paper>
          <Formik
            initialValues={marketingBudget}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({ isSubmitting, setFieldValue, values }) => (
              <Form>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell width="20%">{marketingBudget.typeNameTitle}</TableCell>
                      <TableCell width="40%">{marketingBudget.typeDescTitle}</TableCell>
                      <TableCell width="110px">{marketingBudget.enabledTitle}</TableCell>
                      <TableCell>{marketingBudget.nameTitle}</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {marketingBudget.rows.map((row, idx) => (
                      <TableRow>
                        <TableCell>
                          {row.typeName}
                        </TableCell>

                        <TableCell>
                          {row.desc.split('\n').map(el => <div>{el}</div>)}
                        </TableCell>

                        <TableCell>
                          <Field
                            name={`rows[${idx}].enabled`}
                            component={Checkbox}
                            type="checkbox"
                            color="default"
                          />
                        </TableCell>

                        <TableCellField
                          name={`rows[${idx}].name`}
                          highlight={values.rows[idx].enabled}
                          multiline
                          disabled={!values.rows[idx].enabled}
                          inputProps={{ maxLength: 150 }}
                        />
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>

                <Box p={1} textAlign="right">
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    disableElevation
                    disabled={isSubmitting}
                    isLoading={isSubmitting}
                  >
                    {translation['form.save']}
                  </LoadingButton>
                </Box>
              </Form>
            )}
          </Formik>
        </Paper>
      </Grid>

      <Grid item xs={12} md={4}>
        <Card>
          <CardContent>
            <Typography>
              {translation['company.marketing_budget_description']}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}
