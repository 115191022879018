import React, { CSSProperties, PropsWithChildren, useCallback } from 'react'
import {
  Box,
  Grid, GridProps,
  Tooltip as MuiTooltip,
  useTheme
} from '@material-ui/core'
import {
  amber,
  blue,
  cyan,
  deepOrange,
  deepPurple,
  green,
  indigo,
  lightBlue,
  lightGreen,
  lime,
  orange,
  pink,
  purple,
  red,
  teal,
  yellow
} from '@material-ui/core/colors'
import {
  AveragePriceLineChartColors,
  CategoriesChartColors,
  DistributionChannelsChartColors,
  GrowthSourcesChartFactColors,
  GrowthSourcesChartForecastColors,
  IncreaseInSalesChartColors,
  MarketBudgetFactColors,
  MarketBudgetFactColorsMap,
  MarketBudgetForecastColors,
  MarketBudgetLegendColors,
  MarketVolumeChartForecastColors,
  MarketVolumeChartFactColorsMap,
  MarketVolumeChartFactColors,
  MarketVolumeChartLegendColors,
  SKULineChartColors,
  TargetsByCategoryChartColors,
  AdvertisingStandardGraphColors,
  AdvertisingPieColors,
  geoDistributionColors
} from '../constants/ChartColors'
import {
  Chart as DxChart,
  ChartProps as DxChartProps,
  LineSeries as DxLineSeries,
  LineSeriesProps as DxLineSeriesProps,
  PieSeriesProps,
  Tooltip as DxTooltip,
  TooltipProps as DxTooltipProps,
  ValueAxis as DxValueAxis,
  ValueAxisProps,
  ArgumentAxis as DxArgumentAxis,
  BarSeries,
  BarSeriesProps,
  ScatterSeries,
  PieSeries as DxPieSeries
} from '@devexpress/dx-react-chart-material-ui'
import { formatNumber } from 'helpers/formatNumber'
import { StyledTypography } from './StyledTypography'
import {
  StandardsResult,
  TargetsResult
} from 'api/advertisingApi'

export { Plugin } from '@devexpress/dx-react-core'
export type { ModifyDomainFn } from '@devexpress/dx-react-chart'
export { Stack, ValueScale, EventTracker } from '@devexpress/dx-react-chart'
export { Legend, BarSeries, AreaSeries } from '@devexpress/dx-react-chart-material-ui'

export enum ChartVariants {
  default,
  DistributionChannels,
  GrowthSources,
  MarketVolume,
  MarketVolumeForecast,
  MarketVolumeFact,
  MarketVolumeLegend,
  CategoriesChart,
  Targets,
  IncreaseInSales,
  AverageLine,
  SKULine,
  MarketBudget,
  MarketBudgetFact,
  MarketBudgetForecast,
  MarketBudgetLegend,
  AdvertisingStandardGraph,
  AdvertisingPie,
  GeoDistribution
}

const MuiColors = [
  blue,
  deepOrange,
  lightGreen,
  amber,
  teal,
  pink,
  red,
  green,
  cyan,
  deepPurple,
  indigo,
  lightBlue,
  lime,
  orange,
  purple,
  yellow
]

const colors = Object.values(MuiColors)

export function getColorByIdx (idx: number, shade?: 100, chartVariant? : ChartVariants) : string {
  switch (chartVariant) {
    case ChartVariants.DistributionChannels: {
      return DistributionChannelsChartColors[idx % DistributionChannelsChartColors.length]
    }
    case ChartVariants.GeoDistribution: {
      return geoDistributionColors[idx % geoDistributionColors.length]
    }
    case ChartVariants.GrowthSources: {
      return GrowthSourcesChartForecastColors[idx % GrowthSourcesChartForecastColors.length]
    }
    case ChartVariants.MarketVolume:
    case ChartVariants.MarketVolumeForecast: {
      return MarketVolumeChartForecastColors[idx % MarketVolumeChartForecastColors.length]
    }
    case ChartVariants.MarketVolumeFact: {
      return MarketVolumeChartFactColors[idx % MarketVolumeChartFactColors.length]
    }
    case ChartVariants.MarketVolumeLegend: {
      return MarketVolumeChartLegendColors[idx % MarketVolumeChartLegendColors.length]
    }
    case ChartVariants.CategoriesChart: {
      return CategoriesChartColors[idx % CategoriesChartColors.length]
    }
    case ChartVariants.Targets: {
      return TargetsByCategoryChartColors[idx % TargetsByCategoryChartColors.length]
    }
    case ChartVariants.IncreaseInSales: {
      return IncreaseInSalesChartColors[idx % IncreaseInSalesChartColors.length]
    }
    case ChartVariants.AverageLine: {
      return AveragePriceLineChartColors[idx % AveragePriceLineChartColors.length]
    }
    case ChartVariants.SKULine: {
      return SKULineChartColors[idx % SKULineChartColors.length]
    }
    case ChartVariants.MarketBudget:
    case ChartVariants.MarketBudgetForecast: {
      return MarketBudgetForecastColors[idx % MarketBudgetForecastColors.length]
    }
    case ChartVariants.MarketBudgetFact: {
      return MarketBudgetFactColors[idx % MarketBudgetFactColors.length]
    }
    case ChartVariants.MarketBudgetLegend: {
      return MarketBudgetLegendColors[idx % MarketBudgetLegendColors.length]
    }
    case ChartVariants.AdvertisingStandardGraph: {
      return AdvertisingStandardGraphColors[idx % AdvertisingStandardGraphColors.length]
    }
    case ChartVariants.AdvertisingPie: {
      return AdvertisingPieColors[idx % AdvertisingPieColors.length]
    }
    default : {
      return colors[idx % colors.length][shade || 400]
    }
  }
}

const maxLength = 15

export function normalizeChartData (data: DxChartProps['data']) {
  return data?.map((item) => (
    Object.entries(item).reduce((acc, [key, value]) => ({
      ...acc,
      [key]: key === 'argument' ? String(value) : Number(value)
    }), {})
  ))
}

export interface LegendItem {
  label?: string
  color: string
  footnote?: boolean
}

interface ChartProps extends DxChartProps {
  leftAxisUnit?: string
  rightAxisUnit?: string
  withLabels?: boolean
  legend?: LegendItem[],
  pieFootnote?: PieFootnote,
  pieFootnoteAfter?: string
}

export interface PieFootnote {
  chart: number[],
  legend: string[]
}

export function mapCollectionToFootnote (collection: TargetsResult | StandardsResult): PieFootnote | undefined {
  const { chart, legend, units } = collection.pieFootnote

  if (!(chart instanceof Array) || chart.length === 0 || typeof chart[0] !== 'object') {
    return undefined
  }

  const keys = Object.keys(chart[0])
  const values = keys.map(key => {
    const value = chart[0][key]
    return typeof value === 'number' ? value : 0
  })
  const sum = values.reduce((value, result) => result + value, 0)

  return sum <= 0 ? undefined : {
    chart: values.map(value => value * 100 / sum),
    legend: keys.map(key => {
      const { leftAxis = '%' } = units
      const value = chart[0][key]
      const title = legend[key]

      return `${title} - ${value}${leftAxis}`
    })
  }
}

export function Chart ({
  leftAxisUnit,
  rightAxisUnit,
  withLabels,
  legend,
  pieFootnote,
  pieFootnoteAfter,
  ...props
}: PropsWithChildren<ChartProps>) {
  const renderPieFooter = useCallback(() => {
    if (pieFootnote === undefined) {
      return
    }

    const { chart, legend } = pieFootnote

    const classes = {
      blocks: {
        margin: '0 12px 12px 0',
        width: '60px'
      },
      block: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white'
      },
      labels: {
        display: 'flex',
        flexDirection: 'column' as 'column',
        alignSelf: 'center'
      },
      label: {
        display: 'flex'
      },
      rect: {
        width: '15px',
        height: '15px',
        margin: '0 6px'
      }
    }

    const colors = [{
      background: '#42a5f5',
      border: '#3c5d80'
    }, {
      background: '#f6994b',
      border: '#b06e3e'
    }]

    return (
      <div style={{
        height: '100px',
        display: 'flex'
      }}>
        <div style={classes.blocks}>
          {chart.map((value, idx) => (
            <div style={{
              ...classes.block,
              height: `${value}%`,
              backgroundColor: colors[idx % colors.length].background,
              border: `1px solid ${colors[idx % colors.length].border}`
            }}>
            </div>
          ))}
        </div>
        <div style={classes.labels}>
          {legend.map((title, idx) => (
            <div style={classes.label}>
              <div style={{
                ...classes.rect,
                backgroundColor: colors[idx % colors.length].background,
                border: `1px solid ${colors[idx % colors.length].border}`
              }}/>
              <span>{title}</span>
            </div>
          ))}
        </div>
      </div>
    )
  }, [pieFootnote])

  const legendContainerProps : GridProps = {
    container: true,
    spacing: 2,
    justify: 'center'
  }

  if (pieFootnote) {
    legendContainerProps.direction = 'row'
    delete legendContainerProps.spacing
    delete legendContainerProps.justify
  }

  return (
    <>
      <Box px={1} pb={1} display="flex" justifyContent="space-between">
        <span>{leftAxisUnit}</span>
        <span>{rightAxisUnit}</span>
      </Box>

      <div style={pieFootnote === undefined ? {} : {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr'
      }}>

        <DxChart {...props} />
        <Box p={2} display="flex" flexDirection="column" justifyContent="center">
          <Grid container direction="column">
            <Grid {...legendContainerProps}>
              {legend?.map((item, idx) => (
                <Grid item key={idx}>
                  <Box maxWidth="325px">
                    <MuiTooltip title={item.label || ''}>
                      <StyledTypography noWrap>
                        <Box
                          display="inline-block"
                          component="span"
                          mr={1}
                          width="10px"
                          height="10px"
                          borderRadius="50%"
                          bgcolor={item.color}
                        />
                        {item.label}
                      </StyledTypography>
                    </MuiTooltip>
                  </Box>
                  {item.footnote && renderPieFooter()}
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Box>
      </div>
    </>
  )
}

interface TooltipProps extends DxTooltipProps {
  ignoreSeries?: string[]
}

export function Tooltip ({ ignoreSeries, ...props }: TooltipProps) {
  return (
    <DxTooltip
      overlayComponent={(props) =>
        // @ts-ignore
        !ignoreSeries?.includes(props.children.props.children.props.targetItem.series)
          ? <DxTooltip.Overlay {...props} />
          : null
      }
      contentComponent={({ text, targetItem }) => (
        <>{formatNumber(text, { maximumFractionDigits: 2 })}</>
      )}
      {...props}
    />
  )
}

interface LineSeriesProps extends DxLineSeriesProps {
  unit?: string,
  chart? : Record<string, string | number>[],
  index? : number,
  variant? : ChartVariants
}

export function LineSeries (props: LineSeriesProps) {
  const { unit, color, chart, index } = props
  const { palette } = useTheme()

  const getFactColor = (color : string) => {
    if (props.variant === ChartVariants.MarketBudget) {
      return MarketBudgetFactColorsMap.get(color)
    }

    return color
  }

  const lineStroke = (index && color) ? (chart && chart[index].isForecast ? color : getFactColor(color)) : color

  return (
    <DxLineSeries
      {...props}
      seriesComponent={(props) => (
        <>
          <DxLineSeries.Path {...props} style={{ strokeWidth: 4, stroke: lineStroke }} />
          <ScatterSeries.Path {...props} pointComponent={(props: ScatterSeries.PointProps) => {
            const { arg, val, value } = props

            return (
              <DxChart.Label
                x={arg}
                y={val - 14}
                textAnchor='middle'
                dy='5px'
                style={{
                  fill: palette.text.primary,
                  zIndex: 1000000
                }}
              >
                {`${formatNumber(value, { maximumFractionDigits: 2 })}${unit || ''}`}
              </DxChart.Label>
            )
          }} />

        </>
      )}
    />
  )
}

export function ValueAxis (props: ValueAxisProps) {
  return (
    <DxValueAxis
      {...props}
      labelComponent={({ text, ...props }) => (
        <DxValueAxis.Label
          {...props}
          text={formatNumber(String(text).replace(/,/g, '').replace('\u2212', '-'))} />
      )}
    />
  )
}

export function ArgumentAxis () {
  return (<DxArgumentAxis labelComponent={({ text, ...props }) =>
    text.toString().length > maxLength ? <><MuiTooltip title={text}>
      <DxArgumentAxis.Label
        {...props}
        text={`${text.toString().slice(0, maxLength)}...`} />
    </MuiTooltip> </> : <DxArgumentAxis.Label
      {...props}
      text={text} />
  } />
  )
}

interface ValuesBarProps extends BarSeries.PointProps {
  valueKeys?: string[]
  valueColors?: string[]
  chart?: Record<string, string | number>[]
  style?: CSSProperties
}

export function ValuesBar (props: ValuesBarProps) {
  const { arg, val, startVal, index, valueKeys, valueColors, chart, style = {} } = props

  if (!valueKeys || !valueColors || !chart) {
    return null
  }

  return (
    <>
      {valueKeys
        .map((key, idx) => Number(chart[index][key]) && (
          <DxChart.Label
            key={idx}
            x={arg}
            y={(val + startVal) / 2 - idx * 14}
            textAnchor="middle"
            dy="5px"
            style={{
              fill: valueColors[idx],
              zIndex: 1000000,
              ...style
            }}
          >
            {formatNumber(chart[index][key], { maximumFractionDigits: 2 })}
          </DxChart.Label>
        ))
      }
    </>
  )
}

interface ValuesBarSeriesProps extends BarSeriesProps {
  valueKeys: string[]
  valueColors: string[]
  chart: Record<string, string | number>[]
  style?: CSSProperties
}

export function ValuesBarSeries (props: ValuesBarSeriesProps) {
  return (
    <BarSeries
      {...props}
      pointComponent={ValuesBar}
    />
  )
}

interface HorizontalBarProps extends BarSeries.PointProps {
  chart?: Record<string, string | number>[]
}

function getHorizontalBar (valueField?: string) {
  return (props: HorizontalBarProps) => {
    const { arg, barWidth, index, maxBarWidth, val, startVal, color, chart } = props

    if (!valueField || !chart) {
      return null
    }

    const value = chart[index][valueField]
    const height = maxBarWidth * barWidth
    const width = val - startVal
    const y = arg - height / 2
    return (
      <>
        <rect
          x={startVal}
          y={y}
          width={width}
          height={height}
          fill={color}
        />

        {width > 10 && (
          <DxChart.Label
            y={arg}
            x={(val + startVal) / 2}
            textAnchor='middle'
            dy='5px'
            style={{
              fill: '#000',
              zIndex: 1000000
            }}
          >
            {formatNumber(value, { maximumFractionDigits: 2 })}
          </DxChart.Label>
        )}
      </>
    )
  }
}

interface HorizontalBarSeriesProps extends BarSeriesProps {
  chart: Record<string, string | number>[]
}

export function HorizontalBarSeries (props: HorizontalBarSeriesProps) {
  const HorizontalBar = getHorizontalBar(props.valueField)
  return (
    <BarSeries
      {...props}
      pointComponent={HorizontalBar}
    />
  )
}

interface VerticalBarProps extends BarSeries.PointProps {
  chart?: Record<string, string | number>[]
}

function getVerticalBar (valueField?: string, variant? : ChartVariants) {
  return (props: VerticalBarProps) => {
    const { arg, barWidth, maxBarWidth, index, val, startVal, color, chart } = props

    if (!valueField || !chart) {
      return null
    }

    const width = maxBarWidth * barWidth
    const height = startVal - val
    const x = arg - width / 2
    const value = chart[index][valueField]

    const getFactColor = (color : string) => {
      if (variant === ChartVariants.GrowthSources) {
        return GrowthSourcesChartFactColors.get(color)
      }
      if (variant === ChartVariants.MarketBudget) {
        return MarketBudgetFactColorsMap.get(color)
      }
      if (variant === ChartVariants.MarketVolume) {
        return MarketVolumeChartFactColorsMap.get(color)
      }
      return color
    }

    return (
      <>
        <path
          d={`M ${x} ${startVal} L ${width + x} ${startVal} L ${width + x} ${val} L ${x} ${val} Z`}
          fill={chart && chart[index].isForecast ? color : getFactColor(color)}
        />

        {Math.abs(height) > 10 && <DxChart.Label
          x={arg}
          y={(val + startVal) / 2}
          textAnchor='middle'
          dy='5px'
          style={{
            fill: '#000',
            zIndex: 1000000
          }}
        >
          {`${formatNumber(value, { maximumFractionDigits: 2 })}`}
        </DxChart.Label>}
      </>
    )
  }
}

interface VerticalBarSeriesProps extends BarSeriesProps {
  chart: Record<string, string | number>[],
  variant?: ChartVariants
}

export function VerticalBarSeries (props: VerticalBarSeriesProps) {
  const VerticalBar = getVerticalBar(props.valueField, props.variant)

  return (
    <BarSeries
      {...props}
      pointComponent={VerticalBar}
    />
  )
}

export function PieSeries ({ color, label, ...props }: PieSeriesProps & {color: (index: number) => string, label?: (value: number, index: number) => string}) {
  const { palette } = useTheme()

  return (<DxPieSeries
    {...props}
    pointComponent={props => (<>
      <DxPieSeries.Point
        {...props}
        color={color(props.index)}
      >
      </DxPieSeries.Point>
      {label &&
        <DxChart.Label
          x={props.arg + props.arg * Math.cos((props.startAngle + props.endAngle - Math.PI) / 2) * 0.5}
          y={props.val + props.arg * Math.sin((props.startAngle + props.endAngle - Math.PI) / 2) * 0.5}
          textAnchor='middle'
          style={{
            fill: palette.text.primary
          }}
        >{label(props.value, props.index)}</DxChart.Label>
      }
    </>)}
  />)
}
