import React, { useState, useEffect } from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import {
  Card,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Button,
  Box,
  IconButton,
  Dialog,
  DialogContent,
  TableBody,
  Grid,
  Typography,
  Tooltip
} from '@material-ui/core'
import { Add, Edit, People } from '@material-ui/icons'
import { Form, Formik, FormikConfig } from 'formik'
import { TextField } from 'components/TextField'
import { useAuthContext } from 'components/AuthContext'
import { useLayoutContext } from 'components/LayoutContext'
import { useTranslationContext } from 'components/TranslationContext'
import { Page } from 'components/Page'
import { companiesApi, Company } from 'api/companiesApi'
import { LoadingButton } from 'components/LoadingButton'

const defaultValues: Partial<Company> = {
  name: '',
  subscriptionExpiresData: '',
  licenseUsersTotal: 0,
  logo: ''
}

export function CompaniesPage () {
  const match = useRouteMatch()
  const { startContentLoading, finishContentLoading, isContentLoading } = useLayoutContext()
  const { handleResponseSuccess, handleResponseFailure } = useAuthContext()
  const { translation } = useTranslationContext()
  const [companies, setCompanies] = useState<Company[]>([])
  const [isCompanyFormOpen, setIsCompanyFormOpen] = useState(false)
  const [companyLogo, setCompanyLogo] = useState<Blob | null>(null)
  const [companyFormInitialValue, setCompanyFormInitialValues] = useState<Partial<Company>>()
  const showCompanyForm = (company?: Company) => async () => {
    if (company) {
      try {
        const logoRequest = await companiesApi.getCompanyLogo(company.id)
        setCompanyLogo(logoRequest)
      } catch (e) {
        // @ts-ignore
        if (e.type === 'NotFoundError') {
          setCompanyLogo(null)
        }
      }
    }
    setCompanyFormInitialValues(company)
    setIsCompanyFormOpen(true)
  }
  const hideCompanyForm = () => {
    setCompanyFormInitialValues({})
    setIsCompanyFormOpen(false)
  }
  const handleCompanyFormSubmit: FormikConfig<Partial<Company>>['onSubmit'] = (values, { setErrors }) => {
    if (values.id) {
      companiesApi.updateCompanyLogo(values.id, companyLogo)
      return companiesApi.updateCompany(values)
        .then(() => {
          const updatedCompanies = companies.map((company) => company.id === values.id
            ? { ...company, ...values }
            : company
          )
          setCompanies(updatedCompanies)
          handleResponseSuccess()
          hideCompanyForm()
        })
        .catch((error) => {
          handleResponseFailure(error, setErrors)
        })
    }

    return companiesApi.createCompany(values)
      .then((createdCompany) => {
        setCompanies([...companies, createdCompany])
        handleResponseSuccess()
        hideCompanyForm()
      })
      .catch((error) => {
        handleResponseFailure(error, setErrors)
      })
  }

  useEffect(() => {
    startContentLoading()

    companiesApi.getCompanies()
      .then(setCompanies)
      .catch(handleResponseFailure)
      .finally(finishContentLoading)
  }, [finishContentLoading, startContentLoading, handleResponseFailure])

  if (isContentLoading) {
    return null
  }

  return (
    <Page title={translation['menu.companies']}>
      <Card>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width="50%">{translation['pages.CompaniesPage.grid.col.company_name']}</TableCell>
              <TableCell width="20%">{translation['pages.CompaniesPage.grid.col.subscription_term']}</TableCell>
              <TableCell width="30%">{translation['pages.CompaniesPage.grid.col.license_users_count']}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>

          <TableBody>
            {companies.map((company) => {
              const { id, name, subscriptionExpiresData, licenseUsersTotal } = company
              return (
                <TableRow key={id}>
                  <TableCell>
                    {name}
                  </TableCell>
                  <TableCell>
                    {
                      typeof subscriptionExpiresData === 'string'
                        ? new Date(subscriptionExpiresData).toLocaleDateString()
                        : subscriptionExpiresData
                    }
                  </TableCell>
                  <TableCell>
                    {licenseUsersTotal}
                  </TableCell>
                  <TableCell>
                    <Grid container spacing={1} wrap="nowrap">
                      <Grid item>
                        <Tooltip title={<h2 style={{ color: 'white' }}>{translation['form.edit']}</h2>}>
                          <IconButton size="small" onClick={showCompanyForm(company)}>
                            <Edit />
                          </IconButton>
                        </Tooltip>
                      </Grid>

                      <Grid item>
                        <Tooltip title={<h2 style={{ color: 'white' }}>{translation['menu.company_users']}</h2>}>
                          <IconButton size="small" component={Link} to={`${match.url}/${id}/users`}>
                            <People />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>

        <Box p={1} textAlign="right">
          <Button
            color="primary"
            startIcon={<Add />}
            onClick={showCompanyForm()}
          >
            {translation['form.add']}
          </Button>
        </Box>
      </Card>

      <Dialog open={isCompanyFormOpen} onClose={hideCompanyForm}>
        <DialogContent dividers>
          <Formik
            initialValues={companyFormInitialValue ?? defaultValues}
            onSubmit={handleCompanyFormSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <TextField
                  name="name"
                  label={translation['pages.CompaniesPage.grid.col.company_name']}
                  autoFocus
                />

                <TextField
                  name="subscriptionExpiresData"
                  label={translation['pages.CompaniesPage.grid.col.subscription_term']}
                  type="date"
                  InputLabelProps={{ shrink: true }}
                />

                <TextField
                  name="licenseUsersTotal"
                  label={translation['pages.CompaniesPage.grid.col.license_users_count']}
                  type="number"
                  inputProps={{ min: 0 }}
                />

                <Typography paragraph>
                  {translation['components.CompaniesPage.logoPreviewLabel']}
                  <div>
                    {companyLogo ? <img style={{
                      maxWidth: 100
                    }} src={URL.createObjectURL(companyLogo)} alt={translation['components.CompaniesPage.logoImgAlt']} /> : translation['components.CompaniesPage.logoNotUploaded']}
                  </div>
                </Typography>

                { companyLogo && <Button onClick={() => {
                  setCompanyLogo(null)
                }}>{translation['components.CompaniesPage.deleteLogoButtonText']}</Button> }
                { !companyLogo && <Button
                  variant="contained"
                  component="label">
                  {translation['components.CompaniesPage.uploadLogoButtonText']}
                  <input
                    onChange={(event) => {
                      setCompanyLogo(event.currentTarget.files![0])
                    }}
                    name="logo"
                    type="file"
                    hidden
                    accept="image/svg+xml, image/gif, image/png"
                  />
                </Button> }

                <Box mt={2} textAlign="right">
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    isLoading={isSubmitting}
                  >
                    {translation['form.save']}
                  </LoadingButton>
                </Box>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </Page>
  )
}
