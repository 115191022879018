import React, { useEffect, useState } from 'react'
import { Page } from '../../components/Page'
import { Box, Grid } from '@material-ui/core'
import {
  GeneralDashboardCollection,
  GeneralDashboardEndpoints,
  marketAndCompanyApi
} from '../../api/marketAndCompanyApi'
import { useLayoutContext } from '../../components/LayoutContext'
import { useAuthContext } from '../../components/AuthContext'
import { Marker } from '../../components/Marker'
import { MarketAndCustomerSalesGrowthChart } from '../../components/MarketAndCustomerSalesGrowthChart'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import { useTranslationContext } from '../../components/TranslationContext'

const useStyles = makeStyles({
  root: {
    textDecoration: 'none'
  }
})

export function GeneralDashboardPage () {
  const [collection, setCollection] = useState<GeneralDashboardCollection>()
  const { startContentLoading, finishContentLoading } = useLayoutContext()
  const { handleResponseFailure } = useAuthContext()
  const { translation } = useTranslationContext()
  const classes = useStyles()

  useEffect(() => {
    startContentLoading()

    marketAndCompanyApi.getGeneralDashboardCollection()
      .then(setCollection)
      .catch(handleResponseFailure)
      .finally(finishContentLoading)
  }, [finishContentLoading, startContentLoading, handleResponseFailure])

  if (!collection) {
    return null
  }

  return (
    <Page title={translation['menu.dashboards_general']} editableTitle pageKey='generalDashboardPage'>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Grid item xs={12} lg={10}>
            <Box pb={10}>
              <Link className={classes.root} to={{
                pathname: '/strategical/market-and-company/consumption-in-kind-terms',
                search: '?kpiTableSelectedRowsIds=1'
              }}>
                <Marker {...collection[GeneralDashboardEndpoints.MARKER_COMPANY_INKIND]} />
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12} lg={10}>
            <Link className={classes.root} to={'/strategical/market-and-company/consumption-in-monetary-terms?kpiTableSelectedRowsIds=1'}>
              <Marker {...collection[GeneralDashboardEndpoints.MARKER_COMPANY_MONETARY]} />
            </Link>
          </Grid>
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid item xs={12}>
            <Link className={classes.root} to={'/strategical/market-and-company/consumption-in-kind-terms?inKindTableSelectedRows=0,1'}>
              <MarketAndCustomerSalesGrowthChart
                {...collection[GeneralDashboardEndpoints.GRAPH_GROWTH]}
              />
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </Page>
  )
}
