import React, { useCallback, useEffect, useState, Fragment } from 'react'
import { FormControl, Grid, MenuItem, Select, SelectProps } from '@material-ui/core'
import { useAuthContext } from 'components/AuthContext'
import { DataTable } from 'components/DataTable'
import { useLayoutContext } from 'components/LayoutContext'
import {
  marketStandartApi,
  SkuByCategoryCollection,
  MarketStandartEndpoints
} from 'api/marketStandartApi'
import { Table } from 'api/types'
import { SkuSalesGraph } from '../../components/SkuSalesGraph'
import { useProductCategory } from '../../hooks/productCategory'
import { useTranslationContext } from 'components/TranslationContext'

const SKU_STANDARDS_USER_CATEGORY = 'skuStandards.category'

export function StandardsPage () {
  const { startContentLoading, finishContentLoading } = useLayoutContext()
  const { handleResponseFailure, handleResponseSuccess } = useAuthContext()
  const [collection, setCollection] = useState<SkuByCategoryCollection | null>()
  const { translation } = useTranslationContext()
  const { state: productCategory, setId: setCategoryId } = useProductCategory({
    storageKey: SKU_STANDARDS_USER_CATEGORY,
    prepend: [{ id: 'all' as any, categoryName: translation['common.filter.all_categories'] }]
  })

  useEffect(() => {
    if (productCategory) {
      startContentLoading()
      // setCollection(null)
      marketStandartApi.getSkuByCategoryCollection(productCategory.id)
        .then((collection : SkuByCategoryCollection) => {
          setCollection(collection)
        })
        .catch(handleResponseFailure)
        .finally(finishContentLoading)
    }
  }, [finishContentLoading, startContentLoading, handleResponseFailure, productCategory])

  const updateCollection = useCallback(() => {
    if (productCategory) {
      marketStandartApi.getSkuByCategoryCollection(productCategory.id)
        .then((updatedCollection) => {
          // setCollection(null)
          setCollection(updatedCollection)
          handleResponseSuccess()
        })
        .catch(handleResponseFailure)
    }
  }, [handleResponseSuccess, handleResponseFailure, productCategory])

  if (!collection || !productCategory) {
    return null
  }

  const handleTableSalesUpdate = (grid: Table['grid']) => {
    marketStandartApi.updateSkuByCategorySalesTab(productCategory.id, grid)
      .then(updateCollection)
      .catch(handleResponseFailure)
  }
  const handleCategoriesFilterChange: SelectProps['onChange'] = (e) => {
    const { value } = e.target
    setCategoryId(value as number)
  }

  return (
    <Fragment>
      <Grid container spacing={3}>

        <Grid item md={4} xs={12}>
          <FormControl variant='outlined' fullWidth>
            <Select
              value={productCategory.id}
              onChange={handleCategoriesFilterChange}
              MenuProps={{ MenuListProps: { disablePadding: true } }}
            >
              {
                productCategory.list.map(el => <MenuItem value={el.id}>{el.categoryName}</MenuItem>)
              }
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      {
        <Fragment>
          {/*
          <Grid container spacing={3} alignItems='stretch'>
            { <Grid item xs={12} md={6} sm={6}>
              <Marker {...collection[MarketStandartEndpoints.COUNT_MARKER]} />
            </Grid>}
            { <Grid item xs={12} md={6} sm={6}>
              <Marker {...collection[MarketStandartEndpoints.AVERAGE_MARKER]} />
            </Grid>}
          </Grid>
          */}

          <Grid container spacing={3}>
            {collection[MarketStandartEndpoints.GRAPH] && <Grid item xs={12}>
              <SkuSalesGraph
                {...collection[MarketStandartEndpoints.GRAPH]}
              />
            </Grid>}
            {collection[MarketStandartEndpoints.SKU_SALES_GRAPH] && <Grid item xs={12}>
              <SkuSalesGraph
                {...collection[MarketStandartEndpoints.SKU_SALES_GRAPH]}
              />
            </Grid>}

            {collection[MarketStandartEndpoints.SALES_TAB] && <Grid item xs={12}>
              <DataTable
                data={collection[MarketStandartEndpoints.SALES_TAB].grid}
                hint={collection[MarketStandartEndpoints.SALES_TAB].description}
                onDataUpdate={handleTableSalesUpdate}
              />
            </Grid>}

          </Grid>
        </Fragment>
      }
    </Fragment>
  )
}
