import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Card, CardContent, FormControl, Grid, MenuItem, Select, SelectProps, Typography } from '@material-ui/core'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import { useLayoutContext } from 'components/LayoutContext'
import { useAuthContext } from 'components/AuthContext'
import { useCategoriesContext } from 'components/CategoriesContext'
import { Marker } from 'components/Marker'
import {
  ArgumentAxis,
  Chart,
  ChartVariants, EventTracker,
  getColorByIdx,
  HorizontalBarSeries,
  normalizeChartData, PieSeries,
  Stack, Tooltip,
  ValueAxis
} from 'components/Chart'
import { DataTable } from 'components/DataTable'
import { CommentForm } from 'components/CommentForm'
import { categoriesApi, DistributionCollection, DistributionEndpoints, IFilter } from 'api/categoriesApi'
import { Table } from 'api/types'
// @ts-ignore
import { stackOffsetExpand } from 'd3-shape'
import { useTranslationContext } from 'components/TranslationContext'
import calculateColumnsWidth from '../../utils/calculateColumnsWidth'
import { useQueryParams } from '../../hooks/queryParams'
import { Tabs, GrowthSourcesPageQueryParams } from '../../types/tabsCommon'

export function DistributionChannelsPage () {
  const [collection, setCollection] = useState<DistributionCollection | null>()
  const { currentCategory } = useCategoriesContext()
  const { startContentLoading, finishContentLoading } = useLayoutContext()
  const { handleResponseFailure, handleResponseSuccess } = useAuthContext()
  const selectRef = useRef<HTMLDivElement>()
  const { queryParams, setQueryParams } = useQueryParams()
  const [year, setYear] = useState(queryParams.has('year') ? queryParams.get('year') : '')
  const [activeTab, setActiveTab] = React.useState<Tabs | null>(Number(queryParams.get('unit')) ?? Tabs.inKind)
  const [filter, setFilter] = useState<IFilter>()
  const [showComment, setShowComment] = useState(true)
  const { translation } = useTranslationContext()

  const getCollection = useCallback((endpoints?: string[]) => {
    if (currentCategory && filter) {
      startContentLoading()
      setShowComment(false)
      let method = categoriesApi.getDistributionCollection.bind(categoriesApi)
      if (activeTab === Tabs.monetary) {
        method = categoriesApi.getDistributionProfitCollection.bind(categoriesApi)
      }
      method(currentCategory.id)
        .then(collection => {
          categoriesApi.getDistributionPieChart(currentCategory.id, year || ('' + filter!.optionGroups[0][0].value))
            .then(pieChart => setCollection({ ...collection, [DistributionEndpoints.PIE_CHART]: pieChart }))
        })
        .catch(handleResponseFailure)
        .finally(() => {
          finishContentLoading()
          setShowComment(true)
        })
    }
  }, [currentCategory, startContentLoading, finishContentLoading, handleResponseFailure, activeTab, filter, year])

  useEffect(() => {
    if (currentCategory) {
      startContentLoading()

      let getFilterData = categoriesApi.getDistributionFilter.bind(categoriesApi)
      if (activeTab === Tabs.monetary) {
        getFilterData = categoriesApi.getDistributionProfitFilter.bind(categoriesApi)
      }
      getFilterData()
        .then(setFilter)
        .catch(handleResponseFailure)
        .finally(finishContentLoading)
    }
  }, [startContentLoading, finishContentLoading, handleResponseFailure, currentCategory, activeTab])

  useEffect(() => {
    getCollection()
  }, [getCollection])

  if (!currentCategory || !collection || !filter) {
    return null
  }

  const onTabChange = (event: React.MouseEvent<HTMLElement>, tab: Tabs | null) => {
    if (tab !== null) {
      setQueryParams<GrowthSourcesPageQueryParams>({ unit: tab, year: queryParams.get('year') || ('' + filter.optionGroups[0][0].value) })
      setActiveTab(tab)
    }
  }
  if (!queryParams.get('unit')) {
    setQueryParams<GrowthSourcesPageQueryParams>({ unit: activeTab, year: queryParams.get('year') || ('' + filter.optionGroups[0][0].value) })
  }

  const handleYearFilterChange: SelectProps['onChange'] = (e) => {
    const { value } = e.target
    setQueryParams<GrowthSourcesPageQueryParams>({ year: value, unit: activeTab })
    setYear(value as string)
  }

  const handleTableDependenciesUpdate = (endpoint: DistributionEndpoints) => (table: Table) => {
    setCollection({ ...collection, [endpoint]: table })
    handleResponseSuccess()
    return getCollection(table.dependencies)
  }
  const handleAbsoluteTableUpdate = (grid: Table['grid']) => {
    categoriesApi.updateDistributionAbsoluteTable(currentCategory.id, grid)
      .then(handleTableDependenciesUpdate(DistributionEndpoints.ABSOLUTE_TABLE))
      .catch(handleResponseFailure)
  }
  const handleMonetaryTableUpdate = (grid: Table['grid']) => {
    categoriesApi.updateDistributionProfitMonetaryTable(currentCategory.id, grid)
      .then(handleTableDependenciesUpdate(DistributionEndpoints.MONETARY_TABLE))
      .catch(handleResponseFailure)
  }
  const handleRelativeTableUpdate = (grid: Table['grid']) => {
    categoriesApi.updateDistributionRelativeTable(currentCategory.id, grid)
      .then(handleTableDependenciesUpdate(DistributionEndpoints.RELATIVE_TABLE))
      .catch(handleResponseFailure)
  }
  const handleRelativeProfitTableUpdate = (grid: Table['grid']) => {
    categoriesApi.updateDistributionProfitRelativeTable(currentCategory.id, grid)
      .then(handleTableDependenciesUpdate(DistributionEndpoints.RELATIVE_TABLE))
      .catch(handleResponseFailure)
  }
  const handleKpiTableUpdate = (grid: Table['grid']) => {
    categoriesApi.updateDistributionKpiTable(currentCategory.id, grid)
      .then(handleTableDependenciesUpdate(DistributionEndpoints.KPI_TABLE))
      .catch(handleResponseFailure)
  }
  const handleProfitKpiTableUpdate = (grid: Table['grid']) => {
    categoriesApi.updateDistributionProfitKpiTable(currentCategory.id, grid)
      .then(handleTableDependenciesUpdate(DistributionEndpoints.KPI_TABLE))
      .catch(handleResponseFailure)
  }
  const handleCommentChange = (comment: string) => {
    categoriesApi.updateDistributionComment(currentCategory.id, comment)
      .then(handleResponseSuccess)
      .catch(handleResponseFailure)
  }

  const handleProfitCommentChange = (comment: string) => {
    categoriesApi.updateDistributionProfitComment(currentCategory.id, comment)
      .then(handleResponseSuccess)
      .catch(handleResponseFailure)
  }

  const pieChartData = Object.keys(collection[DistributionEndpoints.PIE_CHART].legend).map(el => ({
    name: collection[DistributionEndpoints.PIE_CHART].legend[el], value: collection[DistributionEndpoints.PIE_CHART].chart[0][el]
  }))

  const { columnsWidth } = calculateColumnsWidth(collection[DistributionEndpoints.RELATIVE_TABLE].grid.columns.length)

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <ToggleButtonGroup
          value={activeTab}
          onChange={onTabChange}
          exclusive
        >
          <ToggleButton value={Tabs.inKind}>
            {translation['menu.inkind']}
          </ToggleButton>

          <ToggleButton value={Tabs.monetary}>
            {translation['menu.monetary']}
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>

      {Object.entries(collection[DistributionEndpoints.MARKERS]).map(([key, marker]) => (
        <Grid key={key} item xs={12} md={3} sm={6}>
          <Marker {...marker} />
        </Grid>
      ))}

      <Grid item xs={12}>
        <Card>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <CardContent>
                <Typography variant="h6" paragraph>
                  {collection[DistributionEndpoints.CHART].title}
                </Typography>
              </CardContent>
              <Chart
                height={340}
                data={normalizeChartData(collection[DistributionEndpoints.CHART].chart)}
                leftAxisUnit={collection[DistributionEndpoints.CHART].units.leftAxis}
                legend={Object.values(collection[DistributionEndpoints.CHART].legend).map((label, idx) => ({
                  label,
                  color: getColorByIdx(idx, undefined, ChartVariants.DistributionChannels)
                }))}
                rotated
              >
                <ArgumentAxis />
                <ValueAxis />

                {Object.keys(collection[DistributionEndpoints.CHART].legend).map((key, idx) => (
                  <HorizontalBarSeries
                    key={key}
                    name={key}
                    valueField={key}
                    argumentField="argument"
                    color={getColorByIdx(idx, undefined, ChartVariants.DistributionChannels)}
                    chart={collection[DistributionEndpoints.CHART].chart}
                  />
                ))}
                <Stack
                  stacks={[
                    { series: Object.keys(collection[DistributionEndpoints.CHART].legend) }
                  ]}
                  offset={stackOffsetExpand}
                />
              </Chart>

            </Grid>

            <Grid item xs={12} md={6}>
              <CardContent>

                <Grid container spacing={3}>
                  <Grid item>

                    <Typography variant="h6" paragraph>
                      {collection[DistributionEndpoints.PIE_CHART].title}
                    </Typography>
                  </Grid>

                  <Grid item>
                    <FormControl variant="outlined" fullWidth>
                      <Select
                        ref={selectRef}
                        value={year || ('' + filter.optionGroups[0][0].value)}
                        onChange={handleYearFilterChange}
                        MenuProps={{
                          MenuListProps: { disablePadding: true },
                          style: { width: selectRef.current?.clientWidth }
                        }}
                      >

                        {filter.optionGroups[0].map(({ value, label }) => (
                          <MenuItem key={label} value={`${value}`}>
                            <Typography variant="inherit" noWrap>
                              {value}
                            </Typography>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                </Grid>

              </CardContent>

              <Chart
                height={340}
                data={normalizeChartData(pieChartData)}
                legend={Object.values(collection[DistributionEndpoints.PIE_CHART].legend).map((label, idx) => ({
                  label,
                  color: getColorByIdx(idx, undefined, ChartVariants.DistributionChannels)
                }))}
              >
                <PieSeries
                  argumentField="name"
                  valueField="value"
                  color={index => getColorByIdx(index, undefined, ChartVariants.DistributionChannels)}
                />

                <EventTracker />
                <Tooltip />
              </Chart>
            </Grid>
          </Grid>
        </Card>
      </Grid>

      { collection[DistributionEndpoints.ABSOLUTE_TABLE] && <Grid item xs={12}>
        <DataTable
          title={collection[DistributionEndpoints.ABSOLUTE_TABLE]!.title}
          description={collection[DistributionEndpoints.ABSOLUTE_TABLE]!.description}
          data={collection[DistributionEndpoints.ABSOLUTE_TABLE]!.grid}
          onDataUpdate={handleAbsoluteTableUpdate}
          columnsWidth={columnsWidth}
        />
      </Grid> }

      { collection[DistributionEndpoints.MONETARY_TABLE] && <Grid item xs={12}>
        <DataTable
          title={collection[DistributionEndpoints.MONETARY_TABLE]!.title}
          description={collection[DistributionEndpoints.MONETARY_TABLE]!.description}
          data={collection[DistributionEndpoints.MONETARY_TABLE]!.grid}
          onDataUpdate={handleMonetaryTableUpdate}
          columnsWidth={columnsWidth}
        />
      </Grid> }

      <Grid item xs={12}>
        <DataTable
          title={collection[DistributionEndpoints.RELATIVE_TABLE].title}
          description={collection[DistributionEndpoints.RELATIVE_TABLE].description}
          data={collection[DistributionEndpoints.RELATIVE_TABLE].grid}
          onDataUpdate={activeTab === Tabs.inKind ? handleRelativeTableUpdate : handleRelativeProfitTableUpdate}
          columnsWidth={columnsWidth}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <DataTable
          title={collection[DistributionEndpoints.KPI_TABLE].title}
          description={collection[DistributionEndpoints.KPI_TABLE].description}
          data={collection[DistributionEndpoints.KPI_TABLE].grid}
          onDataUpdate={activeTab === Tabs.inKind ? handleKpiTableUpdate : handleProfitKpiTableUpdate}
        />
      </Grid>

      {showComment && <Grid item xs={12} md={6}>
        <CommentForm
          label={collection[DistributionEndpoints.COMMENT].title}
          value={collection[DistributionEndpoints.COMMENT].comment}
          onChange={activeTab === Tabs.inKind ? handleCommentChange : handleProfitCommentChange}
        />
      </Grid>}

    </Grid>
  )
}
