import React from 'react'
import { Page } from 'components/Page'
import { SkuPage } from './SkuPage'
import { PartnersPage } from './PartnersPage'
import { EmployeesPage } from './EmployeesPage'
import { useTranslationContext } from 'components/TranslationContext'

export function SalesIntroductoryPage () {
  const { translation } = useTranslationContext()

  const tabs = [
    {
      label: translation['menu.sku'],
      url: 'sku',
      component: SkuPage,
      hasInfoOnly: true
    },
    {
      label: translation['menu.partners'],
      url: 'partners',
      component: PartnersPage,
      hasInfoOnly: true
    },
    {
      label: translation['menu.staff'],
      url: 'employees',
      component: EmployeesPage,
      hasInfoOnly: true
    }
  ]

  return (
    <Page title={translation['menu.sales_plan_source']} tabs={tabs} />
  )
}
