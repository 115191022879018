import React from 'react'
import { Page } from 'components/Page'
import { SummaryPage } from './SummaryPage'
import { DirectionPage } from './DirectionPage'
import { DirectChannelsPage } from './DirectChannelsPage'
import { MapPage } from './MapPage'
import { useTranslationContext } from 'components/TranslationContext'

export function SalesPlanPage () {
  const { translation } = useTranslationContext()

  const tabs = [
    {
      label: translation['menu.common'],
      url: 'summary',
      component: SummaryPage,
      hasInfoOnly: true
    },
    {
      label: translation['menu.direct1'],
      url: 'direction-1',
      component: DirectionPage,
      hasInfoOnly: true
    },
    {
      label: translation['menu.direct2'],
      url: 'direction-2',
      disabled: true
    },
    {
      label: translation['menu.direct_channels'],
      url: 'direct-channels',
      component: DirectChannelsPage,
      hasInfoOnly: true
    },
    {
      label: translation['menu.map'],
      url: 'map',
      component: MapPage,
      hasInfoOnly: true
    }
  ]

  return (
    <Page title={translation['menu.sales_plan_plan']} tabs={tabs} />
  )
}
