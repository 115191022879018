import React from 'react'
import { SalesStructurePage } from './SalesStructurePage'
import { MarketingBudgetPage } from './MarketingBudgetPage'
import { ProductPortfolioPage } from './ProductPortfolioPage'
import { Page } from 'components/Page'
import { useTranslationContext } from 'components/TranslationContext'

export function CompanyCharacteristicsPage () {
  const { translation } = useTranslationContext()

  const tabs = [
    {
      label: translation['menu.units_company_sales_structure'],
      url: 'sales-structure',
      component: SalesStructurePage
    },
    {
      label: translation['menu.units_company_marketing_budget'],
      url: 'marketing-budget',
      component: MarketingBudgetPage
    },
    {
      label: translation['menu.units_company_products'],
      url: 'product-portfolio',
      component: ProductPortfolioPage,
      hasInfoOnly: true
    }
  ]

  return (
    <Page title={translation['menu.units_company']} tabs={tabs} />
  )
}
