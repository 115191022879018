import React from 'react'
import { Button, ButtonProps, Box, CircularProgress } from '@material-ui/core'

interface LoadingButtonProps extends ButtonProps {
  isLoading: boolean
}

export function LoadingButton ({ isLoading, children, ...props }: LoadingButtonProps) {
  return (
    <Box position="relative" clone>
      <Button {...props}>
        <Box visibility={isLoading ? 'hidden' : 'visible'}>
          {children}
        </Box>

        {isLoading && (
          <Box
            style={{
              display: 'flex',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)'
            }}
          >
            <CircularProgress
              variant="indeterminate"
              color="inherit"
              size={26}
            />
          </Box>
        )}
      </Button>
    </Box>
  )
}
