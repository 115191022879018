import React from 'react'
import { Page } from 'components/Page'
import { useTranslationContext } from 'components/TranslationContext'
import { OutletsSalesTargetsPage } from './OutletsSalesTargetsPage'
import { OutletSalesStandardsPage } from './OutletSalesStandardsPage'

export function OutletSalesPage () {
  const { translation } = useTranslationContext()

  const tabs = [
    {
      label: translation['pages.OutletSalesPage.tabs.standards'],
      url: 'standards',
      component: OutletSalesStandardsPage
    },
    {
      label: translation['pages.OutletSalesPage.tabs.targets'],
      url: 'targets',
      component: OutletsSalesTargetsPage
    }
  ]

  return (
    <Page title={translation['menu.market_standards_sku_sales_tt']} tabs={tabs} />
  )
}
