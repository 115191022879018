import { Box, Card, CardContent, Grid, Typography } from '@material-ui/core'
import {
  ArgumentAxis,
  Chart,
  ChartVariants,
  getColorByIdx, HorizontalBarSeries,
  normalizeChartData, Stack,
  ValueAxis
} from '../../components/Chart'
import React from 'react'
import { HorizontalBarChart } from '../../api/marketAndCompanyApi'

export const OutletSalesTargetsGraph = ({ graph }: { graph: HorizontalBarChart }) => {
  return (<Box py={3}>
    <Grid item xs={12}>
      <Card>
        <CardContent>
          <Typography variant='h6'>
            {graph.title}
          </Typography>
        </CardContent>

        <Chart
          height={600}
          data={normalizeChartData(graph.chart)}
          legend={Object.values(graph.legend).map((label, idx) => ({
            label,
            color: getColorByIdx(idx, undefined, ChartVariants.SKULine)
          }))}
          rotated
        >
          <ArgumentAxis />
          <ValueAxis />

          {Object.keys(graph.legend).map((key, idx) => (
            <HorizontalBarSeries
              key={key}
              name={key}
              valueField={key}
              argumentField="argument"
              color={getColorByIdx(idx, undefined, ChartVariants.SKULine)}
              chart={graph.chart}
            />
          ))}
          <Stack
          />
        </Chart>

      </Card>
    </Grid>
  </Box>)
}
