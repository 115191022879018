import { request } from 'api/request'

export type Language = string

export type Translation = Record<string, string>

export const LANGUAGE_ITEM = 'language'

export const defaultLanguage = 'ru'

function getTranslation () {
  return request('translations/common')
}

export const translationApi = {
  getTranslation
}
