import React from 'react'
import { Grid } from '@material-ui/core'

export function ForecastPage () {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <img
          src='/sales-forecast.png'
          style={{ width: '100%' }}
          alt='sales forecast preview'
        />
      </Grid>
    </Grid>
  )
}
