import React from 'react'
import {
  Card,
  CardContent
} from '@material-ui/core'

import {
  Chart,
  ArgumentAxis,
  ValueAxis,
  Legend
} from '@devexpress/dx-react-chart-material-ui'
import { SkuSalesGraphProps } from 'api/marketStandartApi'
import { Stack } from '@devexpress/dx-react-chart'
import { StyledTypographyHeader } from './StyledTypography'
import { withStyles } from '@material-ui/core/styles'
import { EventTracker, normalizeChartData, Tooltip, VerticalBarSeries } from './Chart'

const legendStyles = () => ({
  root: {
    display: 'flex',
    margin: 'auto',
    flexDirection: 'row'
  }
})

// @ts-ignore
const Root = withStyles(legendStyles, { name: 'LegendRoot' })(Legend.Root)

export function SkuSalesGraph ({ title, chart, legend }: SkuSalesGraphProps) {
  const colors = ['#5b9bd5', '#ed7d31']

  // @ts-ignore
  return (
    <Card>
      <CardContent>
        <StyledTypographyHeader>
          {title}
        </StyledTypographyHeader>
      </CardContent>

      <Chart
        data={normalizeChartData(chart)}
      >
        <ArgumentAxis />
        <ValueAxis />

        {
          legend.map((el, idx) => <VerticalBarSeries
            key={idx}
            name={el.toString()}
            valueField={el.toString()}
            argumentField="argument"
            color={colors[idx]}
            chart={chart}
          />)
        }

        { /* @ts-ignore */ }
        <Legend position="bottom" rootComponent={Root} />
        <Stack />
        <EventTracker />
        <Tooltip />
      </Chart>
    </Card>
  )
}
