import { request } from 'api/request'
import { Marker, Table, Comment, OutletsPurposes } from 'api/types'
import { Option } from 'helpers/fields'
import { getCollection } from './getCollection'
import { GraphGrowth } from './marketAndCompanyApi'
import { IFilter } from './categoriesApi'

export type SkuByCategoryTab = Table<MarketStandartEndpoints[]>

export interface SkuByCategoryFilter {
  value: unknown[]
  optionGroups: [Option[]]
}

export interface CategoryGraph {
  title: string
  legend: Record<number, string>
  chart: Record<string, string | number>[]
}

export enum MarketStandardsSkuTargetsEndpoints {
  'TARGETS_TAB' = 'market_standards/sku/targets/targets_tab',
  'AVG_TAB' = 'market_standards/sku/targets/avg_tab',
  'STRUCTURE_TAB' = 'market_standards/sku/targets/structure_tab',
  'GRAPH' = 'market_standards/sku/targets/graph',
  'YEARS_FILTER' = 'market_standards/sku/targets/markers_filter'
}

export enum MarketStandardsSkuTargetsMarkersEndpoints {
  'MARKERS' = 'market_standards/sku/targets/markers'
}

export interface MarketStandardsSkuTargetsCollection {
  [MarketStandardsSkuTargetsEndpoints.TARGETS_TAB]: Table
  [MarketStandardsSkuTargetsEndpoints.AVG_TAB]: Table
  [MarketStandardsSkuTargetsEndpoints.STRUCTURE_TAB]: Table
  [MarketStandardsSkuTargetsEndpoints.GRAPH]: GraphGrowth
  [MarketStandardsSkuTargetsEndpoints.YEARS_FILTER]: IFilter
}

export interface MarketStandardsSkuTargetsMarkersCollection {
  [MarketStandardsSkuTargetsMarkersEndpoints.MARKERS]: Marker[]
}

export enum Source {
  manual = 'manual',
  excel = 'excel',
  stat = 'stat'
}

export function isSource (x: unknown): x is Source {
  return typeof x === 'string' && Object.values(Source).includes(x as Source)
}

export interface Filters {
  source: Source
}

function getMarketStandardsSkuTargetsCollection () {
  const objects = Object.values(MarketStandardsSkuTargetsEndpoints)

  return getCollection<MarketStandardsSkuTargetsCollection>('', objects)
}

function getMarketStandardsSkuTargetsMarkersCollection (year: number) {
  const objects = Object.values(MarketStandardsSkuTargetsMarkersEndpoints)

  return getCollection<MarketStandardsSkuTargetsMarkersCollection>(`/${year}`, objects)
}

function updateMarketStandardsSkuTargetsTab (grid: Table['grid']) {
  return request(MarketStandardsSkuTargetsEndpoints.TARGETS_TAB, 'PUT', { grid })
}

export interface OutletSalesStandardParams {
  categoryId: number
  regionId: number | 'others'
  purposeId: number
}

export interface OutletSalesTargetParams {
  categoryId: number
  regionId: number | 'others'
  purposeId: number
  location: 'urban' | 'rural' | 'all'
}

function getOutletSalesStandardsTab ({ categoryId, regionId, purposeId }: OutletSalesStandardParams) {
  return request(`market_standards/outlet_sales_standards/tab/${categoryId}/${regionId}/${purposeId}`)
}

function getOutletSalesStandardsTabStat ({ categoryId, regionId, purposeId }: OutletSalesStandardParams) {
  return request(`market_standards/outlet_sales_standards/tab/stat/${categoryId}/${regionId}/${purposeId}`)
}

function updateOutletSalesStandardsTab ({ categoryId, regionId, purposeId }: OutletSalesStandardParams, grid: Table['grid']) {
  return request(`market_standards/outlet_sales_standards/tab/${categoryId}/${regionId}/${purposeId}`, 'POST', { grid })
}

function downloadOutletSalesTab ({ categoryId, regionId, purposeId }: OutletSalesStandardParams) {
  return downloadXlsx(`market_standards/outlet_sales_standards/file/${categoryId}/${regionId}/${purposeId}`)
}

function downloadOutletSalesTabStat ({ categoryId, regionId, purposeId }: OutletSalesStandardParams) {
  return downloadXlsx(`market_standards/outlet_sales_standards/file-stat/${categoryId}/${regionId}/${purposeId}`)
}

function getOutletSalesTargetsTab ({ categoryId, regionId, purposeId, location }: OutletSalesTargetParams) {
  return request(`market_standards/outlet_sales_targets/tab/${categoryId}/${regionId}/${purposeId}/${location}`)
}

function getOutletSalesTargetsGraph ({ categoryId, regionId, purposeId, location }: OutletSalesTargetParams) {
  return request(`market_standards/outlet_sales_targets/graph/${categoryId}/${regionId}/${purposeId}/${location}`)
}

function updateOutletSalesTargetsTab ({ categoryId, regionId, purposeId, location }: OutletSalesTargetParams, grid: Table['grid']) {
  return request(`market_standards/outlet_sales_targets/tab/${categoryId}/${regionId}/${purposeId}/${location}`, 'POST', { grid })
}

function uploadOutletSalesTargetsXlsx ({ xlsx, categoryId, typeTab } : { xlsx: File, categoryId: number, typeTab: string }) {
  const formData = new FormData()
  xlsx && formData.append('file', xlsx)
  return request(`market_standards/outlet_sales_targets/${categoryId}/${typeTab}`, 'POST', formData)
}

function uploadOutletSalesStandardsXlsx ({ xlsx, categoryId } : { xlsx: File, categoryId: string }) {
  const formData = new FormData()
  xlsx && formData.append('file', xlsx)
  return request(`market_standards/outlet_sales_standards/upload/${categoryId}`, 'POST', formData)
}

async function downloadXlsx (endpoint: string) {
  const response = await request(endpoint)
  const url = window.URL.createObjectURL(await response.blob())
  const link = document.createElement('a')
  const dispositionHeader = response.headers.get('content-disposition')
  link.setAttribute(
    'download',
    dispositionHeader.match(/filename=(.*)/)[1]
  )
  link.href = url
  document.body.appendChild(link)
  link.click()
}

export enum MarketStandartEndpoints {
  'AVERAGE_MARKER' = 'market_standards/sku_categories_count/marker/avg',
  'COUNT_MARKER' = 'market_standards/sku_categories_count/marker/company',
  'GRAPH' = 'market_standards/sku_categories_count/graph',
  'SKU_SALES_GRAPH' = 'market_standards/sku_categories_sales/graph',
  'SALES_TAB' = 'market_standards/sku_categories_sales/tab'
}

export interface SkuSalesGraphProps {
  title: string
  chart: Record<string | number, string | number>[],
  legend: number[]
}

export interface SkuByCategoryCollection {
  [MarketStandartEndpoints.AVERAGE_MARKER]: Marker
  [MarketStandartEndpoints.COUNT_MARKER]: Marker
  [MarketStandartEndpoints.GRAPH]: SkuSalesGraphProps
  [MarketStandartEndpoints.SKU_SALES_GRAPH]: SkuSalesGraphProps
  [MarketStandartEndpoints.SALES_TAB]: SkuByCategoryTab
}

function getSkuByCategoryCollection (categoryId: number, dependencies?: string[]) {
  const objects = Object.values(MarketStandartEndpoints)

  return getCollection<SkuByCategoryCollection>(`/${categoryId}`, objects)
}

function updateSkuByCategorySalesTab (categoryId: number, grid: Table['grid']) {
  return request(MarketStandartEndpoints.SALES_TAB + `/${categoryId}`, 'PUT', { grid })
}

function getSkuByCategoryTab () {
  return request('market_standards/sku_by_category_tab')
}

function getSkuByCategoryComment () {
  return request('market_standards/sku_by_category_comment')
}

export enum SkuSalesEndpoints {
  'SKU_SALES_FILTER' = 'market_standards/sku_categories_sales/filter',
  'SKU_AVERAGE' = 'market_standards/sku_categories_sales/marker/avg',
  'SKU_COMPANY' = 'market_standards/sku_categories_sales/marker/company',
  'SKU_MAX' = 'market_standards/sku_categories_sales/marker/max',
  'SKU_SALES_GRAPH' = 'market_standards/sku_categories_sales/graph',
  'SKU_SALES_TAB' = 'market_standards/sku_categories_sales/tab',
  'SKU_SALES_COMMENT' = 'market_standards/sku_categories_sales/comment'
}

export interface SkuSalesCollection {
  [SkuSalesEndpoints.SKU_SALES_FILTER]: SkuByCategoryFilter
  [SkuSalesEndpoints.SKU_SALES_TAB]: Table
  [SkuSalesEndpoints.SKU_SALES_COMMENT]: Comment
  [SkuSalesEndpoints.SKU_SALES_GRAPH]: SkuSalesGraphProps
  [SkuSalesEndpoints.SKU_AVERAGE]: Marker,
  [SkuSalesEndpoints.SKU_MAX]: Marker,
  [SkuSalesEndpoints.SKU_COMPANY]: Marker
}

function getSkuSalesCollection (categoryId: string | null) {
  const objects = Object.values(SkuSalesEndpoints)
  return getCollection<SkuSalesCollection>(`/${categoryId}`, objects, false, [SkuSalesEndpoints.SKU_AVERAGE, SkuSalesEndpoints.SKU_MAX, SkuSalesEndpoints.SKU_COMPANY])
}

function updateSkuSalesTab (categoryId: string, grid: Table['grid']) {
  return request(SkuSalesEndpoints.SKU_SALES_TAB + `/${categoryId}`, 'PUT', { grid })
}

function updateSkuSalesComment (comment: string) {
  return request(SkuSalesEndpoints.SKU_SALES_COMMENT, 'PUT', { comment })
}

export function getOutletsPurposes () {
  return request('market_standards/tt_sale/custom_name')
}

function updateOutletsPurposes (outletPurposes: OutletsPurposes) {
  return request('market_standards/tt_sale/custom_name', 'POST', outletPurposes)
}

function deleteOutletsPurposes () {
  return request('market_standards/tt_sale/custom_name', 'DELETE')
}

function getOutletsPurposesRosstat () {
  return request('market_standards/tt_sale/rosstat_name')
}

export async function getFilters (): Promise<Filters> {
  return request('market_standards/outlet_sales_standards/filter')
}

export async function updateFilters (filters: Filters): Promise<void> {
  await request('market_standards/outlet_sales_standards/filter', 'PUT', filters)
}

export const marketStandartApi = {
  getSkuByCategoryCollection,
  getSkuByCategoryTab,
  getMarketStandardsSkuTargetsCollection,
  getMarketStandardsSkuTargetsMarkersCollection,
  updateMarketStandardsSkuTargetsTab,
  updateSkuByCategorySalesTab,
  getSkuByCategoryComment,
  getSkuSalesCollection,
  updateSkuSalesTab,
  updateSkuSalesComment,
  getOutletsPurposes,
  getOutletSalesTargetsTab,
  updateOutletSalesTargetsTab,
  downloadXlsx,
  getOutletSalesTargetsGraph,
  uploadOutletSalesTargetsXlsx,
  uploadOutletSalesStandardsXlsx,
  getOutletSalesStandardsTab,
  getOutletSalesStandardsTabStat,
  getOutletsPurposesRosstat,
  updateOutletsPurposes,
  deleteOutletsPurposes,
  updateOutletSalesStandardsTab,
  downloadOutletSalesTab,
  downloadOutletSalesTabStat,
  getFilters,
  updateFilters
}
