import { request } from 'api/request'

export const TOKEN_ITEM = 'token'

export interface Credentials {
  email: string
  password: string
}

function signIn (credentials: Credentials) {
  return request('auth', 'POST', credentials)
}

export const authApi = {
  signIn
}
