import React, { useCallback, useEffect, useState, Fragment } from 'react'
import { CardContent, Grid, Typography, Card, Box } from '@material-ui/core'
import { useAuthContext } from 'components/AuthContext'
import { DataTable } from 'components/DataTable'
import { useLayoutContext } from 'components/LayoutContext'
import { Marker } from 'components/Marker'
import {
  marketStandartApi,
  MarketStandardsSkuTargetsCollection,
  MarketStandardsSkuTargetsEndpoints,
  MarketStandardsSkuTargetsMarkersCollection,
  MarketStandardsSkuTargetsMarkersEndpoints
} from 'api/marketStandartApi'
import { Table } from 'api/types'
import {
  ArgumentAxis,
  Chart,
  ChartVariants, EventTracker,
  getColorByIdx,
  normalizeChartData, Stack,
  ValueAxis, VerticalBarSeries
} from '../../components/Chart'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'

export function TargetsPage () {
  const { startContentLoading, finishContentLoading } = useLayoutContext()
  const { handleResponseFailure, handleResponseSuccess } = useAuthContext()
  const [collection, setCollection] = useState<MarketStandardsSkuTargetsCollection | null>()
  const [markersCollection, setMarkersCollection] = useState<MarketStandardsSkuTargetsMarkersCollection | null>()
  const [year, setYear] = useState<number | null>(null)

  useEffect(() => {
    if (year) {
      marketStandartApi.getMarketStandardsSkuTargetsMarkersCollection(year)
        .then((collection : MarketStandardsSkuTargetsMarkersCollection) => {
          setMarkersCollection(collection)
        })
        .catch(handleResponseFailure)
    }
  }, [year, handleResponseFailure])

  useEffect(() => {
    startContentLoading()
    marketStandartApi.getMarketStandardsSkuTargetsCollection()
      .then((collection : MarketStandardsSkuTargetsCollection) => {
        if (collection[MarketStandardsSkuTargetsEndpoints.YEARS_FILTER]) {
          setYear(Math.max.apply(null, Object.keys(collection[MarketStandardsSkuTargetsEndpoints.YEARS_FILTER].optionGroups).map(el => Number(el))))
        }
        setCollection(collection)
      })
      .catch(handleResponseFailure)
      .finally(finishContentLoading)
  }, [finishContentLoading, startContentLoading, handleResponseFailure])

  const updateCollection = useCallback(() => {
    marketStandartApi.getMarketStandardsSkuTargetsCollection()
      .then((updatedCollection) => {
        setCollection(updatedCollection)
        handleResponseSuccess()
      })
      .catch(handleResponseFailure)
  }, [handleResponseSuccess, handleResponseFailure])

  if (!collection) {
    return null
  }

  const handleTableUpdate = (grid: Table['grid']) => {
    marketStandartApi.updateMarketStandardsSkuTargetsTab(grid)
      .then(updateCollection)
      .catch(handleResponseFailure)
  }

  const yearsGrid = <Grid item xs={12}>
    <Box pt={5}>
      <ToggleButtonGroup
        value={year}
        exclusive
        onChange={(event: React.MouseEvent<HTMLElement>, year: number | null) => {
          if (year !== null) {
            setYear(year)
          }
        }}
      >
        { Object.keys(collection[MarketStandardsSkuTargetsEndpoints.YEARS_FILTER].optionGroups).map(el => <ToggleButton selected={year === Number(el)} value={el}>{el}</ToggleButton>) }
      </ToggleButtonGroup>
    </Box>
  </Grid>

  const markersGrid = <Grid item xs={12}>
    <Box py={3}>
      {markersCollection && <Grid container spacing={3}>
        {markersCollection[MarketStandardsSkuTargetsMarkersEndpoints.MARKERS].map((marker, idx) =>
          <Grid item xs={12} md={3} sm={6} key={idx}>
            <Marker {...marker} />
          </Grid>
        )}
      </Grid>}
    </Box>
  </Grid>

  return (
    <Fragment>
      {
        <Fragment>
          <Grid container spacing={3}>

            { yearsGrid && false }
            { markersGrid && false }

            { collection[MarketStandardsSkuTargetsEndpoints.GRAPH] && <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Typography variant="h6">
                    {collection[MarketStandardsSkuTargetsEndpoints.GRAPH].title}
                  </Typography>
                </CardContent>

                <Chart
                  height={300}
                  leftAxisUnit={collection[MarketStandardsSkuTargetsEndpoints.GRAPH].units.leftAxis}
                  data={normalizeChartData(collection[MarketStandardsSkuTargetsEndpoints.GRAPH].chart)}
                  legend={Object.values(collection[MarketStandardsSkuTargetsEndpoints.GRAPH].legend).map((label, idx) => ({
                    label,
                    color: getColorByIdx(idx, undefined, ChartVariants.CategoriesChart)
                  }))}
                >
                  <ArgumentAxis/>
                  <ValueAxis/>

                  {Object.entries(collection[MarketStandardsSkuTargetsEndpoints.GRAPH].legend)
                    .map(([key, value], idx) => (
                      <VerticalBarSeries
                        key={key}
                        name={value}
                        color={getColorByIdx(idx, undefined, ChartVariants.CategoriesChart)}
                        argumentField="argument"
                        valueField={key}
                        chart={collection[MarketStandardsSkuTargetsEndpoints.GRAPH].chart}
                        variant={ChartVariants.CategoriesChart}
                      />
                    ))
                  }
                  <Stack stacks={[
                    { series: Object.values(collection[MarketStandardsSkuTargetsEndpoints.GRAPH].legend) }
                  ]}/>
                  <EventTracker/>
                </Chart>
              </Card>
            </Grid>}

            {collection[MarketStandardsSkuTargetsEndpoints.TARGETS_TAB] && <Grid item xs={12} lg={9}>
              <DataTable
                title={collection[MarketStandardsSkuTargetsEndpoints.TARGETS_TAB].title}
                data={collection[MarketStandardsSkuTargetsEndpoints.TARGETS_TAB].grid}
                hint={collection[MarketStandardsSkuTargetsEndpoints.TARGETS_TAB].description}
                onDataUpdate={handleTableUpdate}
              />
            </Grid>}
            {collection[MarketStandardsSkuTargetsEndpoints.AVG_TAB] && <Grid item xs={12} lg={3}>
              <DataTable
                title={collection[MarketStandardsSkuTargetsEndpoints.AVG_TAB].title}
                data={collection[MarketStandardsSkuTargetsEndpoints.AVG_TAB].grid}
                hint={collection[MarketStandardsSkuTargetsEndpoints.AVG_TAB].description}
              />
            </Grid>}
            {collection[MarketStandardsSkuTargetsEndpoints.STRUCTURE_TAB] && <Grid item xs={12} lg={9}>
              <DataTable
                title={collection[MarketStandardsSkuTargetsEndpoints.STRUCTURE_TAB].title}
                data={collection[MarketStandardsSkuTargetsEndpoints.STRUCTURE_TAB].grid}
                hint={collection[MarketStandardsSkuTargetsEndpoints.STRUCTURE_TAB].description}
              />
            </Grid>}
          </Grid>
        </Fragment>
      }
    </Fragment>
  )
}
