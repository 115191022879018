import { action, observable } from 'mobx'

export interface IUserRichNotificationsStore {
  showRichNotifications: boolean | null
  notificationsCountOnCurrentPage: number
  setShowRichNotifications: (value: boolean | null) => void
  setNotificationsCountOnCurrentPage: (count: number) => void
}

export const userRichNotificationsStore = observable<IUserRichNotificationsStore>({
  showRichNotifications: null,
  notificationsCountOnCurrentPage: 0,
  setShowRichNotifications: action((value : boolean | null) => {
    userRichNotificationsStore.showRichNotifications = value
  }),
  setNotificationsCountOnCurrentPage: action((count: number) => {
    userRichNotificationsStore.notificationsCountOnCurrentPage = count
  })
})
