import React, { Fragment, useCallback, useEffect, useState } from 'react'
import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectProps
} from '@material-ui/core'
import { marketCharacteristicsApi } from '../../api/marketCharacteristicsApi'
import { useQueryParams } from '../../hooks/queryParams'
import { DataSources } from '../../components/common/DataSources'
import { StyledTypography } from '../../components/StyledTypography'
import {
  marketStandartApi
} from '../../api/marketStandartApi'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import { OutletsPurposes, Region, Regions, Table } from '../../api/types'
import { countriesApi } from '../../api/countriesApi'
import { ISalesGeographyAnswers } from '../GeographyPage/ISalesGeographyAnswers'
import { commonInfoApi } from '../../api/commonInfoApi'
import { useTranslationContext } from '../../components/TranslationContext'
import { useAuthContext } from '../../components/AuthContext'
import { DataTable } from '../../components/DataTable'
import { CloudDownload } from '@material-ui/icons'
import { useLayoutContext } from '../../components/LayoutContext'
import { HorizontalBarChart } from '../../api/marketAndCompanyApi'
import { OutletSalesTargetsGraph } from './OutletSalesTargetsGraph'
import { useSnackbar } from 'notistack'
import { ProductCategory } from 'api/categoriesApi'
import { useLocalStorage } from '../../helpers/useLocalStorage'

interface PageQueryParams {
  categoryId: number
}

const OUTLET_SALES_TARGETS_USER_CATEGORY = 'OutletSalesTargets.category'
const OUTLET_SALES_TARGETS_USER_REGION = 'OutletSalesTargets.region'
const OUTLET_SALES_TARGETS_USER_PURPOSE = 'OutletSalesTargets.purpose'
const OUTLET_SALES_TARGETS_USER_STANDARD = 'OutletSalesTargets.standard'
const OUTLET_SALES_TARGETS_USER_SOURCE = 'OutletSalesTargets.source'

enum Tabs {
  manual,
  excel
}

export function OutletsSalesTargetsPage () {
  const { translation } = useTranslationContext()
  const { queryParams, setQueryParams } = useQueryParams()
  const [userCategory, setUserCategory] = useLocalStorage(OUTLET_SALES_TARGETS_USER_CATEGORY)
  const [userRegion, setUserRegion] = useLocalStorage(OUTLET_SALES_TARGETS_USER_REGION)
  const [userPurpose, setUserPurpose] = useLocalStorage(OUTLET_SALES_TARGETS_USER_PURPOSE)
  const [userStandard, setUserStandard] = useLocalStorage(OUTLET_SALES_TARGETS_USER_STANDARD)
  const [userSource, setUserSource] = useLocalStorage(OUTLET_SALES_TARGETS_USER_SOURCE)
  const [userRegions, setUserRegions] = useState<Regions | null>(null)
  const [standardRussiaRegions, setStandardRussiaRegions] = useState<Regions | null>(null)
  const [productCategories, setProductCategories] = useState<ProductCategory[]>()
  const categoryIdParam = queryParams.get('categoryId')
  const [categoryId, setCategoryId] = useState(categoryIdParam === null ? userCategory ? parseInt(userCategory) : undefined : parseInt(categoryIdParam))
  const [outletsPurposesRosstat, setOutletsPurposesRosstat] = useState<OutletsPurposes>()
  const [outletsPurposesCustom, setOutletsPurposesCustom] = useState<OutletsPurposes>()
  const [outletPurposeId, setOutletPurposeId] = useState(userPurpose ? parseInt(userPurpose) : undefined)
  // @ts-ignore
  const [outletStandardId, setOutletStandardId] = useState<'urban' | 'rural' | 'all'>(userStandard ?? undefined)
  const [personalClassification, setPersonalClassification] = useState<boolean>()
  const [regionId, setRegionId] = useState<string | null>(userRegion || null)
  const { handleResponseFailure, handleResponseSuccess, selectedCountry, currentCompany } = useAuthContext()
  const [outletsSalesTargetsTab, setOutletsSalesTargetsTab] = useState<Table | null>()
  const [outletsSalesTargetsGraph, setOutletsSalesTargetsGraph] = useState<HorizontalBarChart | null>()
  const { startContentLoading, finishContentLoading } = useLayoutContext()
  const [excelFile, setExcelFile] = useState<File | null>()
  const { enqueueSnackbar } = useSnackbar()
  // @ts-ignore
  const [tabId, setTabId] = useState<Tabs>(userSource ? Number(userSource) : undefined)

  const trans = useCallback((message: string) => translation[`pages.OutletSalesPage.${message}`], [translation])

  const updateTab = useCallback(() => {
    if (outletStandardId && regionId && outletPurposeId && productCategories !== undefined && outletsPurposesCustom !== undefined) {
      marketStandartApi.getOutletSalesTargetsTab({
        categoryId: categoryId === undefined ? productCategories[0].id : categoryId,
        regionId: regionId === 'others' ? regionId : Number(regionId),
        purposeId: outletPurposeId,
        location: outletStandardId
      })
        .then((result : Table) => {
          setOutletsSalesTargetsTab(result)
        })
        .catch(handleResponseFailure)

      marketStandartApi.getOutletSalesTargetsGraph({
        categoryId: categoryId === undefined ? productCategories[0].id : categoryId,
        regionId: regionId === 'others' ? regionId : Number(regionId),
        purposeId: outletPurposeId,
        location: outletStandardId
      })
        .then(setOutletsSalesTargetsGraph)
        .catch(handleResponseFailure)
    }
  }, [outletStandardId, categoryId, productCategories, regionId, outletsPurposesCustom, outletPurposeId, handleResponseFailure])

  useEffect(() => {
    if (excelFile && outletStandardId && productCategories !== undefined) {
      marketStandartApi.uploadOutletSalesTargetsXlsx({
        xlsx: excelFile,
        categoryId: categoryId === undefined ? productCategories[0].id : categoryId,
        typeTab: outletStandardId
      }).then((response) => {
        enqueueSnackbar(trans('file_uploaded'), { variant: 'success' })
      })
        .finally(() => {
          setExcelFile(null)
          updateTab()
          handleResponseSuccess()
        })
    }
  }, [updateTab, excelFile, categoryId, productCategories, outletStandardId, enqueueSnackbar, handleResponseSuccess, trans])

  useEffect(() => {
    updateTab()
  }, [updateTab])

  useEffect(() => {
    if (!standardRussiaRegions) {
      startContentLoading()
      countriesApi.getRegionList()
        .then((items : Region[]) => {
          setStandardRussiaRegions({ items })
        })
        .finally(finishContentLoading)
    }
  }, [standardRussiaRegions, startContentLoading, finishContentLoading])

  useEffect(() => {
    (async () => {
      startContentLoading()
      const { personalClassification }: ISalesGeographyAnswers = await commonInfoApi.getSalesGeographyAnswers()
        .finally(finishContentLoading)
      setPersonalClassification(personalClassification)
      if (personalClassification) {
        const items = await countriesApi.getCustomRegions()
        setUserRegions({ items })
      } else {
        setUserRegions(standardRussiaRegions)
      }
    })()
  }, [standardRussiaRegions, startContentLoading, finishContentLoading])

  useEffect(() => {
    if (productCategories !== undefined) {
      return
    }

    (async () => {
      try {
        const result = await marketCharacteristicsApi.getProductCategories()
        setProductCategories(result.productCategoryArr)
      } catch (error) {
        handleResponseFailure(error as any)
      }
    })()
  }, [productCategories, handleResponseFailure])

  useEffect(() => {
    if (!outletsPurposesRosstat) {
      marketStandartApi.getOutletsPurposesRosstat()
        .then((result: OutletsPurposes) => {
          setOutletsPurposesRosstat(result)
        })
        .catch(handleResponseFailure)
    }
  }, [outletsPurposesRosstat, handleResponseFailure])

  useEffect(() => {
    if (!outletsPurposesCustom) {
      marketStandartApi.getOutletsPurposes()
        .then((result: OutletsPurposes) => {
          setOutletsPurposesCustom(result)
        })
        .catch(handleResponseFailure)
    }
  }, [outletsPurposesCustom, handleResponseFailure])

  if (!outletsPurposesRosstat || !outletsPurposesCustom || !currentCompany || !userRegions || !standardRussiaRegions || !productCategories) {
    return null
  }

  if (!queryParams.get('categoryId')) {
    setQueryParams<Partial<PageQueryParams>>({ categoryId: userCategory ? parseInt(userCategory) : productCategories[0].id })
  }

  const handleCategoriesFilterChange: SelectProps['onChange'] = (e) => {
    const { value } = e.target

    if (typeof value !== 'number') {
      return
    }

    setQueryParams<Partial<PageQueryParams>>({ categoryId: value })
    setUserCategory(value.toString())
    setCategoryId(value)
  }

  const handleOutletPurposesFilterChange: SelectProps['onChange'] = (e) => {
    const { value } = e.target
    setUserPurpose(value as string)
    setOutletPurposeId(value as number)
  }

  const handleTableUpdate = (grid: Table['grid']) => {
    if (outletStandardId && regionId && outletPurposeId && productCategories !== undefined) {
      marketStandartApi.updateOutletSalesTargetsTab({
        categoryId: categoryId === undefined ? productCategories[0].id : categoryId,
        regionId: regionId === 'others' ? regionId : Number(regionId),
        purposeId: outletPurposeId,
        location: outletStandardId
      }, grid)
        .then(() => {
          updateTab()
          handleResponseSuccess()
        })
        .catch(handleResponseFailure)
    }
  }

  const graph = outletsSalesTargetsGraph // || graphFixture

  const purposes = (outletsPurposesCustom.names.length > 0) ? outletsPurposesCustom : outletsPurposesRosstat

  const setTab = (tabId: Tabs) => () => {
    setUserSource(tabId.toString())
    setTabId(tabId)
  }

  return (
    <Fragment>
      <Grid container style={{ marginTop: '10px' }}>
        <Grid xs={12} md={5}>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <FormControl variant='outlined' fullWidth>
                <Select
                  value={categoryId === undefined ? productCategories[0].id : categoryId}
                  onChange={handleCategoriesFilterChange}
                  MenuProps={{ MenuListProps: { disablePadding: true } }}
                  displayEmpty
                >
                  <MenuItem value='' disabled>{trans('choose_category')}</MenuItem>
                  {
                    productCategories.map(el => <MenuItem value={el.id}>{el.categoryName}</MenuItem>)
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <DataSources
                excel
                manual
                onManualInputClick={setTab(Tabs.manual)}
                onExcelInputClick={setTab(Tabs.excel)}
                manualInputActive={tabId === Tabs.manual}
                excelInputActive={tabId === Tabs.excel}
              />
            </Grid>
          </Grid>
          <Box>
            <StyledTypography variant='h5'>{trans('purpose')}</StyledTypography>
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <FormControl variant='outlined' fullWidth>
                <Select
                  value={outletPurposeId || ''}
                  onChange={handleOutletPurposesFilterChange}
                  MenuProps={{ MenuListProps: { disablePadding: true } }}
                  displayEmpty
                >
                  <MenuItem value='' disabled>{trans('choose_purpose')}</MenuItem>
                  {
                    purposes.names.map((el) => <MenuItem value={el.id}>{el.name}</MenuItem>)
                  }
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Box pt={5}>
            <StyledTypography variant='h5'>{trans('outlet_standard')}</StyledTypography>
            <ToggleButtonGroup
              exclusive
              value={outletStandardId}
              onChange={(event: React.MouseEvent<HTMLElement>, value) => {
                if (value) {
                  setUserStandard(value)
                  setOutletStandardId(value)
                }
              }}
            >
              <ToggleButton value='all'>{trans('outlet_type.all')}</ToggleButton>
              <ToggleButton value='urban'>{trans('outlet_type.urban')}</ToggleButton>
              <ToggleButton value='rural'>{trans('outlet_type.rural')}</ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </Grid>
        <Grid xs={12} md={7}>
          <Box style={{
            width: '100%',
            height: '100%',
            minWidth: '300px',
            minHeight: '300px',
            backgroundSize: 'cover',
            backgroundImage: 'url(\'/outlets_sales_targets_map.png\')',
            backgroundPositionX: '50%',
            backgroundPositionY: '50%'
          }} pt={3}>
          </Box>
        </Grid>
      </Grid>
      <Box pt={5}>
        <StyledTypography variant='h5'>{translation['components.GeographyConsumptionPage.regions']} {personalClassification ? currentCompany.name : selectedCountry!.shortName!}</StyledTypography>
        <ToggleButtonGroup
          exclusive
          value={regionId}
          onChange={(event: React.MouseEvent<HTMLElement>, value) => {
            if (value) {
              setUserRegion(value)
              setRegionId(value)
            }
          }}
        >
          { (personalClassification ? userRegions : standardRussiaRegions).items.map((el, idx) => <ToggleButton key={idx.toString()} value={el.id.toString()}>{el.name}</ToggleButton>) }
          <ToggleButton value={'others'}>{translation['components.GeographyConsumptionPage.others']}</ToggleButton>
        </ToggleButtonGroup>
      </Box>

      { graph && <OutletSalesTargetsGraph graph={graph} />}
      <Box py={1} >
        <Grid container spacing={1} >
          { /* <Grid item xs={12} md={8} > */ }
          { /*  { tabId === Tabs.manual && <StyledTypography variant='h6' gutterBottom style={{ marginTop: '1px' }} >3. {translation['pages.OutletSalesPage.step_3.source_manual']}</StyledTypography> } */ }
          { /*  { tabId === Tabs.excel && <StyledTypography variant='h6' gutterBottom style={{ marginTop: '1px' }} >3. {translation['pages.OutletSalesPage.step_3.source_excel']}</StyledTypography> } */ }
          { /* </Grid> */ }
          <Grid item xs={12} md={4} >
            { tabId === Tabs.excel && <Box display="flex" flexDirection="row" justifyContent="flex-end">
              <Button
                onClick={() => marketStandartApi.downloadXlsx('market_standards/outlet_sales_targets/template/' + (categoryId === undefined ? productCategories[0].id : categoryId))}
                variant="contained"
                color="primary"
                startIcon={<CloudDownload/>}
                style={{ marginRight: '3px' }}
              >
                {trans('download_template')}
              </Button>

              <Button
                component="label"
                variant="contained"
                color="primary"
                startIcon={<CloudDownload/>}
              >
                <input
                  onChange={(event) => setExcelFile(event.currentTarget.files![0])}
                  name="outletSalesStandardsXlsx"
                  type="file"
                  hidden
                  accept="application/xlsx"
                />
                {trans('upload_template')}
              </Button>
            </Box>}
          </Grid>
        </Grid>
      </Box>
      {outletsSalesTargetsTab && <Grid item xs={12}>
        <DataTable
          title={outletsSalesTargetsTab.title}
          data={outletsSalesTargetsTab.grid}
          hint={outletsSalesTargetsTab.description}
          onDataUpdate={handleTableUpdate}
        />
      </Grid>}
    </Fragment>
  )
}
