export const DashboardIcon = () => <svg width="22" height="22" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
  <path d="M0.5 12.1667H9.83333V0.5H0.5V12.1667ZM0.5 21.5H9.83333V14.5H0.5V21.5ZM12.1667 21.5H21.5V9.83333H12.1667V21.5ZM12.1667 0.5V7.5H21.5V0.5H12.1667Z"/>
</svg>

export function ServicePartIcon () {
  return <svg width="22" height="24" viewBox="0 0 22 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.33 13.0967C19.3767 12.7467 19.4 12.385 19.4 12C19.4 11.6267 19.3767 11.2534 19.3183 10.9034L21.6867 9.06005C21.8967 8.89672 21.955 8.58172 21.8267 8.34838L19.5867 4.47505C19.4467 4.21838 19.155 4.13672 18.8983 4.21838L16.11 5.33838C15.5267 4.89505 14.9083 4.52172 14.22 4.24172L13.8 1.27838C13.7533 0.998382 13.52 0.800049 13.24 0.800049H8.76002C8.48002 0.800049 8.25835 0.998382 8.21168 1.27838L7.79168 4.24172C7.10335 4.52172 6.47335 4.90672 5.90168 5.33838L3.11335 4.21838C2.85668 4.12505 2.56502 4.21838 2.42502 4.47505L0.196683 8.34838C0.0566828 8.59338 0.103349 8.89672 0.336683 9.06005L2.70502 10.9034C2.64668 11.2534 2.60002 11.6384 2.60002 12C2.60002 12.3617 2.62335 12.7467 2.68168 13.0967L0.313349 14.9401C0.103349 15.1034 0.0450161 15.4184 0.173349 15.6517L2.41335 19.5251C2.55335 19.7817 2.84502 19.8634 3.10168 19.7817L5.89002 18.6617C6.47335 19.105 7.09168 19.4784 7.78002 19.7584L8.20002 22.7217C8.25835 23.0017 8.48002 23.2001 8.76002 23.2001H13.24C13.52 23.2001 13.7533 23.0017 13.7883 22.7217L14.2083 19.7584C14.8967 19.4784 15.5267 19.105 16.0983 18.6617L18.8867 19.7817C19.1434 19.8751 19.435 19.7817 19.575 19.5251L21.815 15.6517C21.955 15.395 21.8967 15.1034 21.675 14.9401L19.33 13.0967ZM11 16.2001C8.69002 16.2001 6.80002 14.3101 6.80002 12C6.80002 9.69005 8.69002 7.80005 11 7.80005C13.31 7.80005 15.2 9.69005 15.2 12C15.2 14.3101 13.31 16.2001 11 16.2001Z"/>
  </svg>
}

export function StrategicPartIcon () {
  return <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.66666 19.3332H12.3333V0.666504H7.66666V19.3332ZM0.666656 19.3332H5.33332V9.99984H0.666656V19.3332ZM14.6667 4.1665V19.3332H19.3333V4.1665H14.6667Z"/>
  </svg>
}

export function OperationalPartIcon () {
  return <svg width="28" height="28" viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg">
    <path d="M14 2.3335L7.58334 12.8335H20.4167L14 2.3335Z"/>
    <path d="M20.4167 25.6665C23.3162 25.6665 25.6667 23.316 25.6667 20.4165C25.6667 17.517 23.3162 15.1665 20.4167 15.1665C17.5172 15.1665 15.1667 17.517 15.1667 20.4165C15.1667 23.316 17.5172 25.6665 20.4167 25.6665Z"/>
    <path d="M3.5 15.75H12.8333V25.0833H3.5V15.75Z"/>
  </svg>
}

export function UserIcon () {
  return <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 9.99984C12.5784 9.99984 14.6667 7.9115 14.6667 5.33317C14.6667 2.75484 12.5784 0.666504 10 0.666504C7.42169 0.666504 5.33335 2.75484 5.33335 5.33317C5.33335 7.9115 7.42169 9.99984 10 9.99984ZM10 12.3332C6.88502 12.3332 0.666687 13.8965 0.666687 16.9998V19.3332H19.3334V16.9998C19.3334 13.8965 13.115 12.3332 10 12.3332Z"/>
  </svg>
}

export const AppShortIcon = ({ className } : {className?: string}) =>
  process.env.REACT_APP_FRONT_TYPE === 'strela' ? (
    <svg className={className} width="22" height="28" viewBox="0 0 105 177" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M71 30c-1,-1 -1,-1 -2,-2 0,0 -2,-1 -5,-2 -2,-1 -5,-1 -8,-1 -7,0 -13,2 -18,5 -5,3 -8,9 -8,16 0,3 0,6 3,9 2,3 4,5 6,7l-17 18c-14,-9 -20,-23 -18,-40 1,-12 6,-21 17,-29 10,-7 20,-11 32,-11 7,0 13,1 20,4 6,2 11,5 14,9l-16 17zm-22 63l19 -19c4,2 7,4 10,6 3,1 6,3 10,6 4,4 7,7 9,10 2,4 4,8 6,14 1,6 2,12 2,18 0,4 -1,9 -2,13 -2,5 -4,9 -7,14 -3,4 -7,8 -11,12 -4,3 -10,6 -16,8 -7,2 -14,2 -22,2 -7,0 -13,-2 -19,-4 -6,-2 -11,-5 -14,-8 -3,-3 -6,-6 -8,-9 -3,-3 -4,-6 -5,-8 -1,-2 -1,-3 -1,-3l22 -24c0,1 0,1 0,2 0,1 0,2 1,4 0,2 0,4 1,6 0,2 1,4 3,6 1,3 2,5 4,7 2,2 4,3 7,4 3,2 6,3 10,3 7,1 14,-1 20,-5 6,-4 10,-10 11,-17 0,-4 0,-9 -1,-12 -1,-4 -2,-7 -3,-10 -2,-2 -5,-4 -8,-7 -3,-2 -6,-4 -8,-5 -3,-1 -6,-2 -10,-4z" fill="white"/>
    </svg>
  ) : (
    <svg className={className} width="22" height="28" viewBox="0 0 22 28" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M20.3479 1.55054C19.9625 0.867726 19.0943 0.435174 18.1844 0.435174C16.2001 0.421271 14.6429 1.66255 14.6429 4.03232C14.6429 6.44379 16.1313 7.69897 18.1434 7.68506C18.9012 7.67116 19.9625 7.28031 20.3479 6.47159L20.4306 7.47574H22.0156V0.602796H20.4035L20.3479 1.55054ZM0.24585 27.618L10.4819 4.04776L20.7171 27.6334L16.9006 27.618L14.7595 22.6877H9.90024L11.3833 19.2048L13.244 19.1994L10.4579 12.7845L4.01601 27.618H0.24585ZM18.3358 1.9553C20.9821 1.9553 20.9821 6.12324 18.3358 6.12324C17.2197 6.12324 16.3236 5.34233 16.3236 4.03232C16.3236 2.72153 17.2197 1.9553 18.3358 1.9553Z" fill="white"/>
    </svg>
  )

export function ShrinkIcon ({ className } : {className: string}) {
  return <svg className={className} width="10" height="16" viewBox="0 0 10 16" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.03882 1.07421C9.39326 1.45722 9.39358 2.0484 9.03956 2.4318L4.52429 7.32177C4.17055 7.70486 4.17055 8.29547 4.52429 8.67856L9.03956 13.5685C9.39358 13.9519 9.39326 14.5431 9.03882 14.9261L8.62463 15.3737C8.22881 15.8014 7.55255 15.8014 7.15672 15.3737L0.961843 8.67936C0.607119 8.29603 0.60712 7.70429 0.961844 7.32097L7.15672 0.626627C7.55255 0.198889 8.22881 0.19889 8.62463 0.626628L9.03882 1.07421Z" />
  </svg>
}

export const ExpandIcon = ({ className } : {className: string}) => <svg className={className} width="10" height="16" viewBox="0 0 10 16" xmlns="http://www.w3.org/2000/svg">
  <path d="M0.96115 1.07421C0.606709 1.45722 0.606388 2.0484 0.960412 2.4318L5.47568 7.32177C5.82942 7.70486 5.82942 8.29547 5.47568 8.67856L0.960412 13.5685C0.606388 13.9519 0.60671 14.5431 0.96115 14.9261L1.37534 15.3737C1.77116 15.8014 2.44742 15.8014 2.84325 15.3737L9.03813 8.67936C9.39285 8.29603 9.39285 7.70429 9.03813 7.32097L2.84325 0.626627C2.44742 0.198889 1.77116 0.19889 1.37534 0.626628L0.96115 1.07421Z" />
</svg>

export const AppLogoWhite = () =>
  process.env.REACT_APP_FRONT_TYPE === 'strela' ? (
    <svg width="144" height="20" viewBox="0 0 20735 2589" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M993 516c-4,-7 -11,-14 -21,-23 -9,-8 -32,-19 -68,-31 -36,-13 -76,-20 -121,-20 -95,3 -179,27 -251,74 -73,46 -111,121 -115,222 -2,49 11,93 40,133 28,39 60,69 94,90l-249 252c-195,-123 -279,-310 -251,-560 17,-160 96,-292 237,-397 141,-105 293,-158 457,-161 93,0 184,17 274,50 89,33 159,77 210,132l-236 239zm-309 888l271 -264c57,27 106,52 145,73 39,21 85,53 138,97 53,43 96,90 128,140 31,50 58,113 79,191 21,77 31,163 29,256 -2,60 -13,121 -32,185 -19,63 -50,127 -94,189 -43,63 -95,118 -156,166 -60,47 -137,85 -229,113 -92,27 -194,39 -304,35 -98,-7 -187,-26 -269,-59 -82,-33 -146,-70 -193,-112 -46,-41 -86,-84 -117,-129 -32,-44 -53,-80 -64,-106 -11,-27 -16,-42 -16,-46l309 -335c0,7 0,15 1,24 1,10 4,29 8,58 5,28 10,56 18,82 7,27 20,57 38,91 18,34 39,64 64,89 24,26 56,48 97,67 40,19 86,32 137,38 99,11 194,-13 283,-73 89,-59 140,-138 153,-235 8,-64 6,-123 -7,-177 -12,-54 -30,-98 -54,-131 -23,-33 -58,-65 -103,-97 -46,-32 -86,-55 -121,-71 -35,-16 -81,-36 -139,-59z" fill="white"/>
      <polygon points="3651,487 3651,150 5115,146 5115,487 4565,487 4565,2547 4202,2547 4205,487" fill="white"/>
      <path d="M8146 153c216,10 391,71 525,181 134,111 205,265 213,465 6,153 -13,274 -59,364 -45,91 -113,171 -202,241 -70,55 -157,95 -261,121l739 1022 -446 -4 -681 -977c-24,-2 -47,-3 -70,-3 2,329 3,657 3,984l-360 0c0,-340 1,-681 3,-1022l-3 0 0 -274c125,0 245,0 360,0 53,0 97,-1 132,-2 35,-1 77,-5 127,-12 50,-8 92,-17 126,-29 34,-12 69,-29 105,-53 36,-23 64,-50 84,-82 21,-32 36,-73 47,-123 10,-50 12,-105 6,-167 -4,-49 -24,-96 -61,-142 -36,-45 -80,-81 -133,-106 -77,-32 -221,-47 -433,-45l-360 0 0 -344c223,-2 423,0 599,7z" fill="white"/>
      <path d="M12755 146l0 338 -1324 6 0 -340 1324 -4zm-26 917l0 341 -932 0 -7 802 965 0 0 341 -1324 3 0 -1484 1298 -3z" fill="white"/>
      <polygon points="15193,159 15537,153 15543,2212 16250,2212 16244,2550 15187,2553" fill="white"/>
      <polygon points="18402,2547 19589,0 20735,2547 20334,2547 19586,809 18793,2547" fill="white"/>
    </svg>
  ) : (
    <svg width="144" height="20" viewBox="0 0 144 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M40.275 12.4063C40.6687 11.5063 41.0437 10.6626 41.4 9.81881C41.4562 9.68756 41.5687 9.68756 41.6812 9.68756C43.9875 9.68756 46.2937 9.68756 48.6187 9.68756C48.825 9.68756 48.8625 9.74381 48.8625 9.93131C48.8625 12.0126 48.8625 14.0751 48.8625 16.1563C48.8625 16.3438 48.7687 16.4563 48.6562 16.5688C46.575 18.3688 44.175 19.1751 41.4375 18.8751C38.5312 18.5563 36.2625 17.1313 34.65 14.6751C33.45 12.8376 32.9812 10.8126 33.2437 8.63756C33.5812 5.73131 35.0062 3.44381 37.4437 1.86881C39.8062 0.350058 42.375 0.0313081 45.075 0.762558C45.3187 0.837558 45.3187 0.912558 45.225 1.11881C44.925 1.77506 44.625 2.45006 44.3437 3.12506C44.2312 3.38756 44.1187 3.48131 43.8187 3.40631C40.8937 2.78756 38.0812 4.11881 36.7125 6.78131C34.8 10.4563 37.0687 15.1438 41.1375 15.9501C42.825 16.2688 44.3812 15.9876 45.8625 15.1063C46.0687 14.9751 46.1812 14.8438 46.1625 14.5813C46.1437 13.9626 46.1437 13.3438 46.1625 12.7251C46.1625 12.4626 46.0875 12.3876 45.825 12.3876C44.1187 12.4063 42.4125 12.3876 40.725 12.3876C40.575 12.4063 40.4625 12.4063 40.275 12.4063Z" fill="white"/>
      <path d="M8.86875 0.537598C11.5875 6.7251 14.2687 12.8376 16.9875 19.0063C15.9937 19.0063 15.0375 19.0063 14.1 19.0063C13.9125 19.0063 13.8938 18.8376 13.8563 18.7251C13.3688 17.6376 12.8813 16.5501 12.4313 15.4626C12.3188 15.2001 12.1875 15.1251 11.9062 15.1251C10.7625 15.1438 9.61875 15.1251 8.4375 15.1251C8.83125 14.2251 9.20625 13.3626 9.5625 12.5001C9.61875 12.3501 9.75 12.3876 9.84375 12.3876C10.2375 12.3876 10.6312 12.3876 11.0812 12.3876C10.3312 10.7001 9.61875 9.06885 8.86875 7.3626C8.325 8.54385 7.81875 9.6876 7.33125 10.8126C6.1875 13.4188 5.04375 16.0251 3.9 18.6313C3.7875 18.9126 3.6375 18.9876 3.35625 18.9876C2.5125 18.9688 1.66875 18.9876 0.75 18.9876C3.45 12.8563 6.15 6.74385 8.86875 0.537598Z" fill="white"/>
      <path d="M135.131 0.537598C137.85 6.7251 140.531 12.8376 143.25 19.0063C142.256 19.0063 141.3 19.0063 140.362 19.0063C140.175 19.0063 140.156 18.8376 140.119 18.7251C139.631 17.6376 139.144 16.5501 138.694 15.4626C138.581 15.2001 138.45 15.1251 138.169 15.1251C137.025 15.1438 135.881 15.1251 134.7 15.1251C135.094 14.2251 135.469 13.3626 135.825 12.5001C135.881 12.3501 136.013 12.3876 136.106 12.3876C136.5 12.3876 136.894 12.3876 137.344 12.3876C136.594 10.7001 135.881 9.06885 135.131 7.3626C134.606 8.54385 134.1 9.6876 133.612 10.8126C132.469 13.4188 131.325 16.0251 130.181 18.6313C130.069 18.9126 129.919 18.9876 129.638 18.9876C128.794 18.9688 127.95 18.9876 127.031 18.9876C129.731 12.8563 132.413 6.74385 135.131 0.537598Z" fill="white"/>
      <path d="M73.2563 7.38135C72.675 8.6751 72.15 9.89385 71.6062 11.1313C70.5 13.6626 69.375 16.1751 68.2687 18.7063C68.175 18.9313 68.0625 19.0063 67.8 19.0063C66.9375 18.9876 66.0562 19.0063 65.1375 19.0063C67.8375 12.8376 70.5375 6.7251 73.2563 0.537598C75.975 6.7251 78.6562 12.8376 81.375 19.0063C80.4 19.0063 79.4625 19.0063 78.5063 19.0063C78.3 19.0063 78.2813 18.8376 78.225 18.7251C77.7375 17.6376 77.2688 16.5501 76.8 15.4626C76.7063 15.2188 76.575 15.1251 76.3125 15.1438C75.15 15.1626 74.0062 15.1438 72.8062 15.1438C73.1812 14.2626 73.5563 13.4001 73.9125 12.5563C73.9688 12.4251 74.0625 12.4063 74.175 12.4063C74.4937 12.4063 74.7937 12.3876 75.1125 12.4063C75.3562 12.4251 75.45 12.3876 75.3188 12.1063C74.6438 10.5688 73.9688 9.0126 73.2563 7.38135Z" fill="white"/>
      <path d="M97.65 3.18145C97.9687 2.46895 98.3062 1.83145 98.5312 1.1377C98.7375 0.556445 99.0375 0.387695 99.6562 0.387695C103.219 0.425195 106.781 0.406445 110.362 0.406445C110.681 0.406445 110.756 0.481445 110.737 0.800195C110.719 1.4752 110.719 2.16895 110.737 2.84395C110.756 3.14395 110.644 3.18145 110.381 3.18145C108.937 3.1627 107.494 3.18145 106.05 3.1627C105.675 3.1627 105.562 3.21895 105.562 3.63145C105.581 8.61895 105.562 13.6064 105.581 18.5752C105.581 18.9127 105.487 19.0064 105.15 18.9877C104.512 18.9689 103.875 18.9689 103.256 18.9877C102.937 19.0064 102.844 18.9127 102.844 18.5939C102.862 13.6064 102.844 8.61895 102.862 3.6502C102.862 3.2752 102.787 3.1627 102.394 3.1627C100.95 3.18145 99.5062 3.18145 98.0625 3.18145C97.95 3.18145 97.8375 3.18145 97.65 3.18145Z" fill="white"/>
    </svg>
  )

export const AppLogoBlack = () =>
  process.env.REACT_APP_FRONT_TYPE === 'strela' ? (
    <svg width="144" height="20" viewBox="0 0 20735 2589" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M993 516c-4,-7 -11,-14 -21,-23 -9,-8 -32,-19 -68,-31 -36,-13 -76,-20 -121,-20 -95,3 -179,27 -251,74 -73,46 -111,121 -115,222 -2,49 11,93 40,133 28,39 60,69 94,90l-249 252c-195,-123 -279,-310 -251,-560 17,-160 96,-292 237,-397 141,-105 293,-158 457,-161 93,0 184,17 274,50 89,33 159,77 210,132l-236 239zm-309 888l271 -264c57,27 106,52 145,73 39,21 85,53 138,97 53,43 96,90 128,140 31,50 58,113 79,191 21,77 31,163 29,256 -2,60 -13,121 -32,185 -19,63 -50,127 -94,189 -43,63 -95,118 -156,166 -60,47 -137,85 -229,113 -92,27 -194,39 -304,35 -98,-7 -187,-26 -269,-59 -82,-33 -146,-70 -193,-112 -46,-41 -86,-84 -117,-129 -32,-44 -53,-80 -64,-106 -11,-27 -16,-42 -16,-46l309 -335c0,7 0,15 1,24 1,10 4,29 8,58 5,28 10,56 18,82 7,27 20,57 38,91 18,34 39,64 64,89 24,26 56,48 97,67 40,19 86,32 137,38 99,11 194,-13 283,-73 89,-59 140,-138 153,-235 8,-64 6,-123 -7,-177 -12,-54 -30,-98 -54,-131 -23,-33 -58,-65 -103,-97 -46,-32 -86,-55 -121,-71 -35,-16 -81,-36 -139,-59z" fill="black"/>
      <polygon points="3651,487 3651,150 5115,146 5115,487 4565,487 4565,2547 4202,2547 4205,487" fill="black"/>
      <path d="M8146 153c216,10 391,71 525,181 134,111 205,265 213,465 6,153 -13,274 -59,364 -45,91 -113,171 -202,241 -70,55 -157,95 -261,121l739 1022 -446 -4 -681 -977c-24,-2 -47,-3 -70,-3 2,329 3,657 3,984l-360 0c0,-340 1,-681 3,-1022l-3 0 0 -274c125,0 245,0 360,0 53,0 97,-1 132,-2 35,-1 77,-5 127,-12 50,-8 92,-17 126,-29 34,-12 69,-29 105,-53 36,-23 64,-50 84,-82 21,-32 36,-73 47,-123 10,-50 12,-105 6,-167 -4,-49 -24,-96 -61,-142 -36,-45 -80,-81 -133,-106 -77,-32 -221,-47 -433,-45l-360 0 0 -344c223,-2 423,0 599,7z" fill="black"/>
      <path d="M12755 146l0 338 -1324 6 0 -340 1324 -4zm-26 917l0 341 -932 0 -7 802 965 0 0 341 -1324 3 0 -1484 1298 -3z" fill="black"/>
      <polygon points="15193,159 15537,153 15543,2212 16250,2212 16244,2550 15187,2553" fill="black"/>
      <polygon points="18402,2547 19589,0 20735,2547 20334,2547 19586,809 18793,2547" fill="black"/>
    </svg>
  ) : (
    <svg width="144" height="20" viewBox="0 0 144 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M40.275 12.4063C40.6687 11.5063 41.0437 10.6626 41.4 9.81881C41.4562 9.68756 41.5687 9.68756 41.6812 9.68756C43.9875 9.68756 46.2937 9.68756 48.6187 9.68756C48.825 9.68756 48.8625 9.74381 48.8625 9.93131C48.8625 12.0126 48.8625 14.0751 48.8625 16.1563C48.8625 16.3438 48.7687 16.4563 48.6562 16.5688C46.575 18.3688 44.175 19.1751 41.4375 18.8751C38.5312 18.5563 36.2625 17.1313 34.65 14.6751C33.45 12.8376 32.9812 10.8126 33.2437 8.63756C33.5812 5.73131 35.0062 3.44381 37.4437 1.86881C39.8062 0.350058 42.375 0.0313081 45.075 0.762558C45.3187 0.837558 45.3187 0.912558 45.225 1.11881C44.925 1.77506 44.625 2.45006 44.3437 3.12506C44.2312 3.38756 44.1187 3.48131 43.8187 3.40631C40.8937 2.78756 38.0812 4.11881 36.7125 6.78131C34.8 10.4563 37.0687 15.1438 41.1375 15.9501C42.825 16.2688 44.3812 15.9876 45.8625 15.1063C46.0687 14.9751 46.1812 14.8438 46.1625 14.5813C46.1437 13.9626 46.1437 13.3438 46.1625 12.7251C46.1625 12.4626 46.0875 12.3876 45.825 12.3876C44.1187 12.4063 42.4125 12.3876 40.725 12.3876C40.575 12.4063 40.4625 12.4063 40.275 12.4063Z" fill="black"/>
      <path d="M8.86875 0.537598C11.5875 6.7251 14.2687 12.8376 16.9875 19.0063C15.9937 19.0063 15.0375 19.0063 14.1 19.0063C13.9125 19.0063 13.8938 18.8376 13.8563 18.7251C13.3688 17.6376 12.8813 16.5501 12.4313 15.4626C12.3188 15.2001 12.1875 15.1251 11.9062 15.1251C10.7625 15.1438 9.61875 15.1251 8.4375 15.1251C8.83125 14.2251 9.20625 13.3626 9.5625 12.5001C9.61875 12.3501 9.75 12.3876 9.84375 12.3876C10.2375 12.3876 10.6312 12.3876 11.0812 12.3876C10.3312 10.7001 9.61875 9.06885 8.86875 7.3626C8.325 8.54385 7.81875 9.6876 7.33125 10.8126C6.1875 13.4188 5.04375 16.0251 3.9 18.6313C3.7875 18.9126 3.6375 18.9876 3.35625 18.9876C2.5125 18.9688 1.66875 18.9876 0.75 18.9876C3.45 12.8563 6.15 6.74385 8.86875 0.537598Z" fill="black"/>
      <path d="M135.131 0.537598C137.85 6.7251 140.531 12.8376 143.25 19.0063C142.256 19.0063 141.3 19.0063 140.362 19.0063C140.175 19.0063 140.156 18.8376 140.119 18.7251C139.631 17.6376 139.144 16.5501 138.694 15.4626C138.581 15.2001 138.45 15.1251 138.169 15.1251C137.025 15.1438 135.881 15.1251 134.7 15.1251C135.094 14.2251 135.469 13.3626 135.825 12.5001C135.881 12.3501 136.013 12.3876 136.106 12.3876C136.5 12.3876 136.894 12.3876 137.344 12.3876C136.594 10.7001 135.881 9.06885 135.131 7.3626C134.606 8.54385 134.1 9.6876 133.612 10.8126C132.469 13.4188 131.325 16.0251 130.181 18.6313C130.069 18.9126 129.919 18.9876 129.638 18.9876C128.794 18.9688 127.95 18.9876 127.031 18.9876C129.731 12.8563 132.413 6.74385 135.131 0.537598Z" fill="black"/>
      <path d="M73.2563 7.38135C72.675 8.6751 72.15 9.89385 71.6062 11.1313C70.5 13.6626 69.375 16.1751 68.2687 18.7063C68.175 18.9313 68.0625 19.0063 67.8 19.0063C66.9375 18.9876 66.0562 19.0063 65.1375 19.0063C67.8375 12.8376 70.5375 6.7251 73.2563 0.537598C75.975 6.7251 78.6562 12.8376 81.375 19.0063C80.4 19.0063 79.4625 19.0063 78.5063 19.0063C78.3 19.0063 78.2813 18.8376 78.225 18.7251C77.7375 17.6376 77.2688 16.5501 76.8 15.4626C76.7063 15.2188 76.575 15.1251 76.3125 15.1438C75.15 15.1626 74.0062 15.1438 72.8062 15.1438C73.1812 14.2626 73.5563 13.4001 73.9125 12.5563C73.9688 12.4251 74.0625 12.4063 74.175 12.4063C74.4937 12.4063 74.7937 12.3876 75.1125 12.4063C75.3562 12.4251 75.45 12.3876 75.3188 12.1063C74.6438 10.5688 73.9688 9.0126 73.2563 7.38135Z" fill="black"/>
      <path d="M97.65 3.18145C97.9687 2.46895 98.3062 1.83145 98.5312 1.1377C98.7375 0.556445 99.0375 0.387695 99.6562 0.387695C103.219 0.425195 106.781 0.406445 110.362 0.406445C110.681 0.406445 110.756 0.481445 110.737 0.800195C110.719 1.4752 110.719 2.16895 110.737 2.84395C110.756 3.14395 110.644 3.18145 110.381 3.18145C108.937 3.1627 107.494 3.18145 106.05 3.1627C105.675 3.1627 105.562 3.21895 105.562 3.63145C105.581 8.61895 105.562 13.6064 105.581 18.5752C105.581 18.9127 105.487 19.0064 105.15 18.9877C104.512 18.9689 103.875 18.9689 103.256 18.9877C102.937 19.0064 102.844 18.9127 102.844 18.5939C102.862 13.6064 102.844 8.61895 102.862 3.6502C102.862 3.2752 102.787 3.1627 102.394 3.1627C100.95 3.18145 99.5062 3.18145 98.0625 3.18145C97.95 3.18145 97.8375 3.18145 97.65 3.18145Z" fill="black"/>
    </svg>
  )
