import React, { useEffect } from 'react'
import { Redirect, useParams } from 'react-router-dom'
import { Page } from 'components/Page'
import { useTranslationContext } from 'components/TranslationContext'
import { useCategoriesContext } from 'components/CategoriesContext'
import { InfoPage } from 'pages/InfoPage'
import { SalesAndRevenuePage } from './SalesAndRevenuePage'
import { DistributionChannelsPage } from './DistributionChannelsPage'
import { MarketingBudgetPage } from './MarketingBudgetPage'
import { GrowthSourcesPage } from './GrowthSourcesPage'
import { GeoDistributionPage } from './GeoDistributionPage'

export function CategoryPage () {
  const { translation } = useTranslationContext()
  const { categoryId } = useParams<{ categoryId: string }>()
  const { categories, setCurrentCategory } = useCategoriesContext()
  const currentCategory = categories.find(({ id }) => Number(categoryId) === id)

  const tabs = [
    {
      label: translation['menu.categories_sales'],
      url: 'sales-and-revenue',
      component: SalesAndRevenuePage
    },
    {
      label: translation['menu.categories_distribution'],
      url: 'distribution',
      component: DistributionChannelsPage
    },
    {
      label: translation['menu.categories_geo'],
      url: 'geo-distribution',
      component: GeoDistributionPage,
      hasInfoOnly: true
    },
    {
      label: translation['menu.categories_brands'],
      url: 'brands',
      component: InfoPage,
      hasInfoOnly: true
    },
    {
      label: translation['menu.categories_growth'],
      url: 'growth-sources',
      component: GrowthSourcesPage
    },
    {
      label: translation['menu.categories_market_budget'],
      url: 'marketing-budget',
      component: MarketingBudgetPage
    }
  ]

  useEffect(() => {
    setCurrentCategory(currentCategory)
  }, [setCurrentCategory, currentCategory])

  return categories.length === 0 || currentCategory ? (
    <Page title={currentCategory?.categoryName} tabs={tabs} />
  ) : (
    <Redirect to="/market-characteristics" />
  )
}
