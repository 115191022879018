import React from 'react'
import { Box, Tooltip, Typography } from '@material-ui/core'

interface DataTableHeadCellTitleProps {
  title: string | number
  subtitle?: string
}

const limitTitle = 35

export function DataTableHeadCellTitle ({ title, subtitle }: DataTableHeadCellTitleProps) {
  return (
    <Box>
      {
        title.toString().length > limitTitle
          ? <Tooltip title={<h2>{title}</h2>}>
            <Typography>
              {`${title.toString().slice(0, limitTitle)}...`}
            </Typography>
          </Tooltip> : <Typography>{title}</Typography>
      }
      <Typography color="textSecondary">{subtitle}</Typography>
    </Box>
  )
}
