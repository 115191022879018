import React, { useCallback, useEffect, useRef, useState } from 'react'
import { FormControl, Grid, MenuItem, Select, SelectProps, Typography } from '@material-ui/core'
import { DataTable } from 'components/DataTable'
import { useAuthContext } from 'components/AuthContext'
import { useLayoutContext } from 'components/LayoutContext'
import {
  marketAndCompanyApi,
  CompanyBySegmentCollection,
  CompanyBySegmentEndpoints, ICompanyBySegmentFilter
} from 'api/marketAndCompanyApi'
import { Table } from 'api/types'
import calculateColumnsWidth from '../../utils/calculateColumnsWidth'
import { useQueryParams } from '../../hooks/queryParams'

interface CompanyBySegmentPageQueryParams {
  categoryId: string | unknown
}

export function CompanyBySegmentPage () {
  const { startContentLoading, finishContentLoading } = useLayoutContext()
  const { handleResponseFailure, handleResponseSuccess } = useAuthContext()
  const [collection, setCollection] = useState<CompanyBySegmentCollection>()
  const selectRef = useRef<HTMLDivElement>()
  const [filter, setFilter] = useState<ICompanyBySegmentFilter>()
  const { queryParams, setQueryParams } = useQueryParams()
  const [categoryId, setCategoryId] = useState(queryParams.has('categoryId') ? queryParams.get('categoryId') : 'all')

  const getCollection = useCallback((endpoints?: string[], deps?: boolean) => {
    if (filter) {
      startContentLoading()

      marketAndCompanyApi.getCompanyBySegmentCollection(endpoints, categoryId, deps)
        .then((collection) => setCollection((prevCollection) => prevCollection
          ? { ...prevCollection, ...collection }
          : collection
        ))
        .catch(handleResponseFailure)
        .finally(finishContentLoading)
    }
  }, [startContentLoading, finishContentLoading, handleResponseFailure, filter, categoryId])

  useEffect(() => {
    startContentLoading()

    marketAndCompanyApi.getCompanyBySegmentFilter()
      .then((filterParams : ICompanyBySegmentFilter) => {
        setFilter(filterParams)
      })
      .catch(handleResponseFailure)
      .finally(finishContentLoading)
  }, [startContentLoading, finishContentLoading, handleResponseFailure])

  useEffect(() => {
    getCollection()
  }, [getCollection])

  if (!filter || !collection) {
    return null
  }

  const handleCategoriesFilterChange: SelectProps['onChange'] = (e) => {
    const { value } = e.target
    setQueryParams<CompanyBySegmentPageQueryParams>({ categoryId: value })
    setCategoryId(value as string)
  }

  const handleTableDependenciesUpdate = (endpoint: CompanyBySegmentEndpoints) => (table: Table) => {
    handleResponseSuccess()
    return getCollection()
  }

  const handleCompanyInKindTableUpdate = (grid: Table['grid']) => {
    marketAndCompanyApi.updateCompanyBySegmentInKindTable(grid, categoryId)
      .then(handleTableDependenciesUpdate(CompanyBySegmentEndpoints.IN_KIND_TABLE))
      .catch(handleResponseFailure)
  }

  const handleCompanyAverageTableUpdate = (grid: Table['grid']) => {
    marketAndCompanyApi.updateCompanyBySegmentAverageTable(grid, categoryId)
      .then(handleTableDependenciesUpdate(CompanyBySegmentEndpoints.AVERAGE_TABLE))
      .catch(handleResponseFailure)
  }

  const { columnsWidth } = calculateColumnsWidth(collection[CompanyBySegmentEndpoints.IN_KIND_TABLE].grid.columns.length)
  return (
    <Grid container spacing={3}>
      <Grid item md={4} />
      <Grid item xs={12} md={4}>
        <FormControl variant="outlined" fullWidth>
          <Select
            ref={selectRef}
            value={queryParams.has('categoryId') ? queryParams.get('categoryId') : filter.optionGroups[0][0].label}
            onChange={handleCategoriesFilterChange}
            MenuProps={{
              MenuListProps: { disablePadding: true },
              style: { width: selectRef.current?.clientWidth }
            }}
          >
            {filter.optionGroups[0].map(({ value, label }) => (
              <MenuItem key={label} value={label}>
                <Typography variant="inherit" noWrap>
                  {value}
                </Typography>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <DataTable
          title={collection[CompanyBySegmentEndpoints.IN_KIND_TABLE].title}
          description={collection[CompanyBySegmentEndpoints.IN_KIND_TABLE].description}
          data={collection[CompanyBySegmentEndpoints.IN_KIND_TABLE].grid}
          onDataUpdate={handleCompanyInKindTableUpdate}
          columnsWidth={columnsWidth}
        />
      </Grid>

      <Grid item xs={12}>
        <DataTable
          title={collection[CompanyBySegmentEndpoints.MONETARY_TABLE].title}
          description={collection[CompanyBySegmentEndpoints.MONETARY_TABLE].description}
          data={collection[CompanyBySegmentEndpoints.MONETARY_TABLE].grid}
          columnsWidth={columnsWidth}
        />
      </Grid>

      <Grid item xs={12}>
        <DataTable
          title={collection[CompanyBySegmentEndpoints.AVERAGE_TABLE].title}
          description={collection[CompanyBySegmentEndpoints.AVERAGE_TABLE].description}
          data={collection[CompanyBySegmentEndpoints.AVERAGE_TABLE].grid}
          onDataUpdate={handleCompanyAverageTableUpdate}
          columnsWidth={columnsWidth}
        />
      </Grid>

    </Grid>
  )
}
