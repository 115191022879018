import React from 'react'
import { Grid } from '@material-ui/core'

export function ExpertReviewPage () {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <img
          src='/expert-review.png'
          style={{ width: '100%' }}
          alt='expert review preview'
        />
      </Grid>
    </Grid>
  )
}
