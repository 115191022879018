import React, { CSSProperties, useEffect, useState } from 'react'
import { TableBody, TableCell, TableRow } from '@material-ui/core'
import { useDataTableContext, CellClickHandler, CustomColumn } from 'components/DataTableContext'
import { DataTableBodyCell } from 'components/DataTableBodyCell'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import { useQueryParams } from '../hooks/queryParams'

interface DataTableBodyProps {
  highlightedRows?: string | null
  cellStyle?: Record<string, Record<string, CSSProperties>>
  cellClick?: Record<string, Record<string, CellClickHandler>>
  customColumns?: CustomColumn[],
  footer?: () => JSX.Element
}

const useStyles = makeStyles({
  root: {
    background: 'rgba(252,231,218)!important',
    '& > td': {
      background: 'rgba(252,231,218)!important'
    }
  }
})

export function DataTableBody ({ highlightedRows, cellStyle = {}, cellClick = {}, customColumns = [], footer } : DataTableBodyProps) {
  const { data, hiddenRows, sortBy, rowsCompareFn } = useDataTableContext()
  const filteredRows = data.rows.filter((row) => hiddenRows.indexOf(row) === -1)
  const sortedRows = sortBy ? filteredRows.sort(rowsCompareFn) : filteredRows
  const classes = useStyles()
  const [isActiveSelectedRow, setIsActiveSelectedRow] = useState<boolean>(!!highlightedRows)
  const { location, history } = useQueryParams()
  useEffect(() => {
    const disableRowHighlight = () => {
      setIsActiveSelectedRow(false)
      history.replace(location.pathname)
    }
    if (isActiveSelectedRow) {
      document.body.addEventListener('click', disableRowHighlight)
    }

    return function cleanup () {
      document.body.removeEventListener('click', disableRowHighlight)
    }
  }, [isActiveSelectedRow, location.pathname, history])

  const highlightedRowsArray = highlightedRows ? highlightedRows.split(',').map(el => Number(el)) : null

  return (
    <TableBody>
      {sortedRows.map((row, idx) => (
        <TableRow className={clsx({ [classes.root]: (highlightedRowsArray?.indexOf(idx) !== -1) && isActiveSelectedRow })} key={idx}>
          {data.columns.map((column, idx) => (
            <DataTableBodyCell
              key={column.title}
              column={column}
              row={row}
              idx={idx}
              style={cellStyle[row.rowId] ? cellStyle[row.rowId][column.field] : {}}
              onClick={cellClick[row.rowId] ? () => cellClick[row.rowId][column.field](row, column) : undefined}
            />
          ))}
          {customColumns.map(column =>
            <TableCell>{column.render(row, idx)}</TableCell>
          )}
        </TableRow>
      ))}
      {footer &&
        <TableRow key='footer'>
          <TableCell colSpan={data.columns.length + customColumns.length}>{footer()}</TableCell>
        </TableRow>
      }
    </TableBody>
  )
}
