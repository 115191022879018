import React, { useState, useEffect } from 'react'
import { IconButton, Tooltip } from '@material-ui/core'
import { FileCopySharp, CheckCircle, Cancel, SvgIconComponent } from '@material-ui/icons'
import { copyForExcel } from '../utils/clipboard'
import { Data } from './DataTableContext'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { useSnackbar } from 'notistack'
import { useTranslationContext } from 'components/TranslationContext'

interface CopyButtonProps {
  data : Data
}

const useStyles = makeStyles(() =>
  createStyles({
    iconFill: { fill: '#c5c5c5' }
  })
)

export function CopyButton ({ data } : CopyButtonProps) {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const { translation } = useTranslationContext()

  const [Icon, setIcon] = useState<SvgIconComponent>(FileCopySharp)

  let message = ''

  const copy = async () => {
    try {
      await copyForExcel(data, translation['components.CopyButton.increaseRow'])
      setIcon(CheckCircle)
      message = translation['components.CopyButton.success']
    } catch (e) {
      setIcon(Cancel)
      message = translation['components.CopyButton.error']
    }

    enqueueSnackbar(message, { variant: 'success' })
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setIcon(FileCopySharp)
    }, 3000)
    return () => clearTimeout(timer)
  }, [Icon])

  return (
    <Tooltip title={<h2 style={{ color: 'white' }}>{translation['components.CopyButton.press']}</h2>}>
      <IconButton onClick={copy} color='primary' aria-label="Copy table data" component="div">
        <Icon className={classes.iconFill} />
      </IconButton>
    </Tooltip>
  )
}
