import React, { useEffect, useMemo, Fragment, useState, useRef } from 'react'
import { Form, Formik, useFormikContext } from 'formik'
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  ListSubheader,
  MenuItem,
  Typography
} from '@material-ui/core'
import { useAuthContext } from 'components/AuthContext'
import { useTranslationContext } from 'components/TranslationContext'
import { useLayoutContext } from 'components/LayoutContext'
import { TextField } from 'components/TextField'
import { LoadingButton } from 'components/LoadingButton'
import { marketCharacteristicsApi, UnitsValues } from 'api/marketCharacteristicsApi'
import { Field, getFieldsValues, Option, OptionGroup } from 'helpers/fields'
import { useSnackbar } from 'notistack'
import { IMarketUnitsStore, marketUnitsStore } from '../../stores/marketUnitsStore'
import { observer } from 'mobx-react-lite'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'

function renderOptions (options: Option[] = []) {
  return options.map(({ value, label }) => (
    <MenuItem key={value} value={value}>
      {label}
    </MenuItem>
  ))
}

function renderOptionGroups (optionGroups: OptionGroup[]) {
  return optionGroups.map((optionGroup) => ([
    optionGroup.label && (
      <ListSubheader disableSticky color="primary">
        <Typography variant="h6">
          {optionGroup.label}
        </Typography>
      </ListSubheader>
    ),

    ...renderOptions(optionGroup.options)
  ]))
}

function findOptionByValue (options: Option[] = [], optionValue: string) {
  return options.find((option) => (option.value === optionValue))
}

function findOptionGroupByOptionValue (optionGroups: OptionGroup[], optionValue: string) {
  return optionGroups.find((optionGroup) => (
    findOptionByValue(optionGroup.options, optionValue)
  ))
}

interface MinimumUnitFieldProps extends Field {
  name: keyof UnitsValues
  maximumValueField: keyof UnitsValues
}

function MinimumUnitField ({ name, label, hint, maximumValueField, optionGroups }: MinimumUnitFieldProps) {
  const { values, setFieldValue } = useFormikContext<UnitsValues>()
  const value = values[name]
  const maximumValue = values[maximumValueField]
  const optionGroup = findOptionGroupByOptionValue(optionGroups, maximumValue)
  const minimumAvailableOption = findOptionByValue(optionGroup?.options, maximumValue)
  const currentOption = findOptionByValue(optionGroup?.options, value)
  const options = useMemo(() => optionGroup?.options.filter((_, idx) => (
    minimumAvailableOption && optionGroup.options.indexOf(minimumAvailableOption) >= idx
  )), [minimumAvailableOption, optionGroup])

  useEffect(() => {
    if (options && (!currentOption || !options.includes(currentOption))) {
      setFieldValue(name, options[0].value)
    }
  }, [name, value, options, currentOption, setFieldValue])

  return (
    <TextField
      name={name}
      label={label}
      hint={hint}
      disabled={!maximumValue}
      select
    >
      {renderOptions(options)}
    </TextField>
  )
}

function getMonetaryUnitFilters (currency: string) {
  const filters = new Map([
    ['currency_of_country', [
      'market_monetary_usd',
      'market_monetary_eur',
      'price_usd',
      'price_eur'
    ]],
    ['currency_usd', [
      'currency_of_country',
      'market_monetary_eur',
      'price_eur'
    ]],
    ['currency_eur', [
      'currency_of_country',
      'market_monetary_usd',
      'price_usd'
    ]]])

  return filters.get(currency) ?? []
}

function MinimumMonetaryUnitField ({ name, label, hint, maximumValueField, optionGroups }: MinimumUnitFieldProps) {
  const { values, setFieldValue } = useFormikContext<UnitsValues>()
  const value = values[name]
  const currencyValue = values.currency
  const filters = getMonetaryUnitFilters(currencyValue)
  const maximumValue = values[maximumValueField]
  const optionGroup = findOptionGroupByOptionValue(optionGroups, maximumValue)
  const currentOption = findOptionByValue(optionGroup?.options, value)
  const options = useMemo(() => optionGroup?.options.filter((option, idx) =>
    !filters.includes(option.value.toString())
  ), [optionGroup, filters])

  useEffect(() => {
    if (options && (!currentOption || !options.includes(currentOption))) {
      setFieldValue(name, options[0].value)
    }
  }, [name, value, options, currentOption, setFieldValue, currencyValue])

  return (
    <TextField
      name={name}
      label={label}
      hint={hint}
      disabled={!maximumValue}
      select
    >
      {renderOptions(options)}
    </TextField>
  )
}

const MarketMonetaryUnitField = ({ name, marketMonetaryUnit }: {name: string, marketMonetaryUnit: Field, currency: Field}) => {
  const { values } = useFormikContext<UnitsValues>()
  const value = values.currency
  const filters = getMonetaryUnitFilters(value)

  const options = useMemo(() => marketMonetaryUnit.optionGroups[0].options.filter((option, idx) =>
    !filters.includes(option.value.toString())
  ), [marketMonetaryUnit.optionGroups, filters])
  return (
    <TextField
      name={name}
      label={marketMonetaryUnit.label}
      hint={marketMonetaryUnit.hint}
      select
    >
      {renderOptions(options)}
    </TextField>
  )
}

interface UnitsPageProps {
  marketUnitsStore : IMarketUnitsStore
}

function UnitsPageWithStore ({ marketUnitsStore } : UnitsPageProps) {
  const { handleResponseSuccess, handleResponseFailure } = useAuthContext()
  const { startContentLoading, finishContentLoading } = useLayoutContext()
  const { translation } = useTranslationContext()
  const { units, setUnits } = marketUnitsStore
  const { enqueueSnackbar } = useSnackbar()
  const [open, setOpen] = useState(false)
  const valuesRef = useRef<UnitsValues>()

  const updateUnits = (values : UnitsValues) => {
    return marketCharacteristicsApi.updateUnits(values)
      .then(() => {
        marketCharacteristicsApi.getUnits()
          .then(setUnits)
          .catch(handleResponseFailure)
        const marketUnitLabel = findOptionGroupByOptionValue(marketUnit.optionGroups, values.marketUnit)?.options.find(({ value, label }) => value === values.marketUnit)?.label
        handleResponseSuccess(translation['components.AuthContext.new_volume_unit_is_set'] + ' - ' + marketUnitLabel)
      })
      .catch(handleResponseFailure)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSubmit = (values: UnitsValues) => {
    const openModal = () => {
      setOpen(true)
    }
    if (units) {
      const { marketUnit } = units.fields
      const optionGroupWithOldMarketUnitValue = findOptionGroupByOptionValue(marketUnit.optionGroups, marketUnit.value)
      const optionGroupWithNewMarketUnitValue = findOptionGroupByOptionValue(marketUnit.optionGroups, values.marketUnit)

      if ((optionGroupWithOldMarketUnitValue !== undefined) && optionGroupWithOldMarketUnitValue !== optionGroupWithNewMarketUnitValue) {
        openModal()
        valuesRef.current = values
        return Promise.reject(new Error())
      }
    }

    return updateUnits(values)
  }

  useEffect(() => {
    startContentLoading()
    setUnits(null)
    marketCharacteristicsApi.getUnits()
      .then(setUnits)
      .catch(handleResponseFailure)
      .finally(finishContentLoading)
  }, [finishContentLoading, startContentLoading, handleResponseFailure, setUnits])

  if (!units) {
    return null
  }

  const initialValues = getFieldsValues<UnitsValues>(units.fields)
  const { marketUnit, minimumNaturalUnit, currency, marketMonetaryUnit, priceUnit } = units.fields

  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{translation['components.UnitsPage.confirm_dialog.title']}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {translation['components.UnitsPage.confirm_dialog.body']}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {translation['components.UnitsPage.confirm_dialog.cancel']}
          </Button>
          <Button onClick={() => {
            enqueueSnackbar(translation['components.UnitsPage.not_recalculate'], { variant: 'error' })
            updateUnits(valuesRef.current!)
            handleClose()
          }} color="primary">
            {translation['components.UnitsPage.confirm_dialog.confirm']}
          </Button>
        </DialogActions>
      </Dialog>
      <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
        {({ isSubmitting }) => (
          <Form>
            <Card>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <CardHeader title={units.titles.naturalUnits} />

                  <CardContent>

                    <TextField
                      name="marketUnit"
                      label={marketUnit.label}
                      hint={marketUnit.hint}
                      select
                      autoFocus
                    >
                      {renderOptionGroups(marketUnit.optionGroups)}
                    </TextField>

                    <MinimumUnitField
                      {...minimumNaturalUnit}
                      name="minimumNaturalUnit"
                      maximumValueField="marketUnit"
                      optionGroups={marketUnit.optionGroups} />
                  </CardContent>
                </Grid>

                <Grid item xs={12} md={6}>
                  <CardHeader title={units.titles.monetaryExpression} />

                  <CardContent>
                    <TextField
                      name="currency"
                      label={currency.label}
                      hint={currency.hint}
                      select
                    >
                      {renderOptionGroups(currency.optionGroups)}
                    </TextField>

                    <MarketMonetaryUnitField
                      name='marketMonetaryUnit'
                      marketMonetaryUnit={marketMonetaryUnit}
                      currency={currency}
                    />

                    <MinimumMonetaryUnitField
                      {...priceUnit}
                      name="priceUnit"
                      maximumValueField="marketMonetaryUnit"
                      optionGroups={marketMonetaryUnit.optionGroups} />

                    <Box mt={2} textAlign="right">
                      <LoadingButton
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="large"
                        disableElevation
                        disabled={isSubmitting}
                        isLoading={isSubmitting}
                      >
                        {translation['form.save']}
                      </LoadingButton>
                    </Box>
                  </CardContent>
                </Grid>
              </Grid>
            </Card>
          </Form>
        )}
      </Formik>
    </Fragment>
  )
}

const UnitsPageObserver = observer(UnitsPageWithStore)

export const UnitsPage = () => <UnitsPageObserver marketUnitsStore={marketUnitsStore} />
