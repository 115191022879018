import React from 'react'
import { Card, CardContent, Grid, Typography } from '@material-ui/core'
import {
  ArgumentAxis,
  Chart,
  ChartVariants,
  EventTracker,
  getColorByIdx,
  LineSeries,
  normalizeChartData,
  Stack,
  Tooltip,
  ValueAxis,
  VerticalBarSeries
} from 'components/Chart'
import { ChartData } from 'api/categorySummaryApi'
import { formatNumber } from 'helpers/formatNumber'

interface AdvBudgetChartProps {
  absolute: ChartData
  relative: ChartData
}

export function AdvBudgetChart ({ absolute, relative }: AdvBudgetChartProps) {
  const absChart = absolute.chart.map((el) => {
    const result = {
      isForecast: el.isForecast,
      argument: el.argument,
      type_01f: el.type_01,
      type_02f: el.type_02,
      type_03f: el.type_03,
      ...(el.type_04 === undefined ? {} : { type_04f: el.type_04 })
    }

    return el.isForecast ? result : Object.assign(result, {
      type_01: el.type_01,
      type_02: el.type_02,
      type_03: el.type_03,
      ...(el.type_04 === undefined ? {} : { type_04: el.type_04 })
    })
  })

  return (
    <Card>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <CardContent>
            <Typography variant="h6">
              {absolute.title}
            </Typography>
          </CardContent>

          <Chart
            key={'chart:absolute:' + Object.keys(relative.legend).join(':')}
            height={300}
            data={normalizeChartData(absChart)}
            legend={Object.values(absolute.legend).map((label, idx) => ({
              label,
              color: getColorByIdx(idx, undefined, ChartVariants.MarketBudgetLegend)
            }))}
          >
            <ArgumentAxis />
            <ValueAxis />
            {Object.keys(absolute.legend).map((key, idx) => (
              <LineSeries
                index={idx}
                chart={absChart}
                argumentField="argument"
                valueField={key + 'f'}
                key={`${key}:${idx}:f`}
                color={getColorByIdx(idx, undefined, ChartVariants.MarketBudgetForecast)}
              />
            ))}
            {Object.keys(absolute.legend).map((key, idx) => (
              <LineSeries
                index={idx}
                chart={absChart}
                argumentField="argument"
                valueField={key}
                key={`${key}:${idx}`}
                color={getColorByIdx(idx, undefined, ChartVariants.MarketBudgetFact)}
              />
            ))}
            <EventTracker />
            <Tooltip />
          </Chart>
        </Grid>

        <Grid item xs={12} md={6}>
          <CardContent>
            <Typography variant="h6">
              {relative.title}
            </Typography>
          </CardContent>

          <Chart
            key={'chart:relative:' + Object.keys(relative.legend).join(':')}
            height={300}
            data={normalizeChartData(relative.chart)}
            legend={Object.values(relative.legend).map((label, idx) => ({
              label,
              color: getColorByIdx(idx, undefined, ChartVariants.MarketBudgetLegend)
            }))}
          >
            <ArgumentAxis />
            <ValueAxis />

            {Object.entries(relative.legend)
              .map(([key, value], idx) => (
                <VerticalBarSeries
                  key={`${key}:${idx}`}
                  name={value}
                  color={getColorByIdx(idx, undefined, ChartVariants.MarketBudget)}
                  argumentField="argument"
                  valueField={key}
                  chart={relative.chart}
                  variant={ChartVariants.MarketBudget}
                />
              ))
            }
            <Stack stacks={[
              { series: Object.values(relative.legend) }
            ]} />
            <EventTracker />
            <Tooltip
              contentComponent={({ targetItem: { point, series } }) => {
                const valueKey = Object.keys(relative.legend).find((key) => (
                  series === relative.legend[key]
                ))
                const value = valueKey && relative.chart[point][valueKey]
                return (
                  <>
                    {formatNumber(value, { maximumFractionDigits: 2 })}%
                  </>
                )
              }}
            />
          </Chart>
        </Grid>
      </Grid>
    </Card>
  )
}
