import { request } from 'api/request'
import { Marker, Table, Comment } from 'api/types'
import { Field, Option } from 'helpers/fields'

export type InKindTab = Table<InKindEndpoints[]>

export interface GraphVol {
  title: string
  filters: {
    years: Field<number[]>
    firstForecastPeriod: Field
  }
  legend: {
    company: string
    market: string
    marketShare: string
  }
  units: {
    leftAxis: string
    rightAxis: string
  }
  chart: Record<string, string | number>[]
}
export interface HorizontalBarChart {
  title: string
  legend: {
    standards: string
    salesOneOutlet: string
  }
  units?: {
    leftAxis: string
    rightAxis: string
  } | []
  chart: {
    argument: string
    standards: number
    salesOneOutlet: number
  }[]
}

export interface GraphVolCommon {
  title: string
  legend: {
    companyActual: string
    companyForecast: string
    marketActual: string
    marketForecast: string
    marketShareActual: string
    marketShareForecast: string
  }
  units: {
    leftAxis: string
    rightAxis: string
  }
  chart: Record<string, string | number>[]
}

export interface GraphVolFilter {
  year: number[]
  firstForecastPeriod: string
}

export interface GraphGrowth {
  title: string
  legend: {
    companyActual: string
    companyForecast: string
    marketActual: string
    marketForecast: string
  }
  units: {
    leftAxis: string
  }
  chart: Record<string, string | number>[]
}

export enum GeneralDashboardEndpoints {
  'GRAPH_GROWTH' = 'market_and_company/summary/inkind/graph_growth',
  'MARKER_COMPANY_INKIND' = 'market_and_company/summary/inkind/marker/company',
  'MARKER_COMPANY_MONETARY' = 'market_and_company/summary/monetary/marker/company',
}

export interface GeneralDashboardCollection {
  [GeneralDashboardEndpoints.GRAPH_GROWTH]: GraphGrowth
  [GeneralDashboardEndpoints.MARKER_COMPANY_MONETARY]: Marker
  [GeneralDashboardEndpoints.MARKER_COMPANY_INKIND]: Marker
}

function getGeneralDashboardCollection () {
  const objects = [
    GeneralDashboardEndpoints.GRAPH_GROWTH,
    GeneralDashboardEndpoints.MARKER_COMPANY_MONETARY,
    GeneralDashboardEndpoints.MARKER_COMPANY_INKIND
  ]
  return request('collection', 'POST', { objects })
}

export enum InKindEndpoints {
  'TAB1' = 'market_and_company/summary/inkind/tab',
  'TAB2_KPI' = 'market_and_company/summary/inkind/kpi_tab',
  'GRAPH_1_VOL' = 'market_and_company/summary/inkind/graph_vol',
  'GRAPH_2_GROWTH' = 'market_and_company/summary/inkind/graph_growth',
  'MARKER_MARKET' = 'market_and_company/summary/inkind/marker/market',
  'MARKER_COMPANY' = 'market_and_company/summary/inkind/marker/company',
  'MARKER_MARKET_SHARE' = 'market_and_company/summary/inkind/marker/market_share',
  'MARKER_INCREASE' = 'market_and_company/summary/inkind/marker/increase',
  'COMMENT' = 'market_and_company/summary/inkind/comment',
}

function getCollection<Collection> (prefix: string = '', endpoints: string[], deps?: boolean, excludedItems?: string[], cutText: string = '') {
  const objects = endpoints.map((endpoint) => {
    if (excludedItems && excludedItems.indexOf(endpoint) !== -1) {
      return endpoint
    }
    return `${endpoint}${deps ? '' : prefix}`
  })

  return request('collection', 'POST', { objects })
    .then((response: Collection) => (
      Object.entries(response)
        .reduce((acc, [key, value]) => ({
          ...acc,
          [key.replace(prefix, '').replace(cutText, '')]: value
        }), {}) as Collection
    ))
}

export interface InKindCollection {
  [InKindEndpoints.TAB1]: InKindTab
  [InKindEndpoints.TAB2_KPI]: InKindTab
  [InKindEndpoints.GRAPH_1_VOL]: GraphVolCommon
  [InKindEndpoints.GRAPH_2_GROWTH]: GraphGrowth
  [InKindEndpoints.MARKER_MARKET]: Marker
  [InKindEndpoints.MARKER_COMPANY]: Marker
  [InKindEndpoints.MARKER_MARKET_SHARE]: Marker
  [InKindEndpoints.MARKER_INCREASE]: Marker
  [InKindEndpoints.COMMENT]: Comment
}
function getInKindCollection (countryId: number) {
  const objects = [
    InKindEndpoints.TAB1,
    InKindEndpoints.TAB2_KPI,
    InKindEndpoints.GRAPH_1_VOL,
    InKindEndpoints.GRAPH_2_GROWTH,
    InKindEndpoints.MARKER_MARKET,
    InKindEndpoints.MARKER_COMPANY,
    InKindEndpoints.MARKER_MARKET_SHARE,
    InKindEndpoints.MARKER_INCREASE,
    InKindEndpoints.COMMENT
  ]
  return getCollection<InKindCollection>('/' + countryId, objects, false, [
    InKindEndpoints.TAB1,
    InKindEndpoints.TAB2_KPI,
    InKindEndpoints.MARKER_MARKET,
    InKindEndpoints.MARKER_COMPANY,
    InKindEndpoints.MARKER_MARKET_SHARE,
    InKindEndpoints.MARKER_INCREASE,
    InKindEndpoints.GRAPH_1_VOL,
    InKindEndpoints.GRAPH_2_GROWTH
  ])
}

function updateInKindTab1 (grid: InKindTab['grid']) {
  return request(InKindEndpoints.TAB1, 'PUT', { grid })
}

function updateInKindTab2Kpi (grid: InKindTab['grid']) {
  return request(InKindEndpoints.TAB2_KPI, 'PUT', { grid })
}

function updateInKindComment (comment: string) {
  return request(InKindEndpoints.COMMENT, 'PUT', { comment })
}

export enum MonetaryEndpoints {
  'TAB1' = 'market_and_company/summary/monetary/tab',
  'TAB2' = 'market_and_company/summary/monetary/kpi_tab',
  'GRAPH_VOL' = 'market_and_company/summary/monetary/graph_vol',
  'GRAPH_GROWTH' = 'market_and_company/summary/monetary/graph_growth',
  'MARKER_MARKET' = 'market_and_company/summary/monetary/marker/market',
  'MARKER_COMPANY' = 'market_and_company/summary/monetary/marker/company',
  'MARKER_MARKET_SHARE' = 'market_and_company/summary/monetary/marker/market_share',
  'COMMENT' = 'market_and_company/summary/monetary/comment',
}

export type MonetaryTab = Table<MonetaryEndpoints[]>

export interface MonetaryCollection {
  [MonetaryEndpoints.TAB1]: MonetaryTab
  [MonetaryEndpoints.TAB2]: MonetaryTab
  [MonetaryEndpoints.GRAPH_VOL]: GraphVolCommon
  [MonetaryEndpoints.GRAPH_GROWTH]: GraphGrowth
  [MonetaryEndpoints.MARKER_MARKET]: Marker
  [MonetaryEndpoints.MARKER_COMPANY]: Marker
  [MonetaryEndpoints.MARKER_MARKET_SHARE]: Marker
  [MonetaryEndpoints.COMMENT]: Comment
}

function getMonetaryCollection (countryId: number) {
  const objects = Object.values(MonetaryEndpoints)
  return getCollection<MonetaryCollection>('/' + countryId, objects, false, [
    MonetaryEndpoints.MARKER_MARKET,
    MonetaryEndpoints.TAB1,
    MonetaryEndpoints.TAB2,
    MonetaryEndpoints.MARKER_COMPANY,
    MonetaryEndpoints.MARKER_MARKET_SHARE,
    MonetaryEndpoints.GRAPH_VOL,
    MonetaryEndpoints.GRAPH_GROWTH
  ])
}

function updateMonetaryTab1 (grid: MonetaryTab['grid']) {
  return request(MonetaryEndpoints.TAB1, 'PUT', { grid })
}

function updateMonetaryTab2 (grid: MonetaryTab['grid']) {
  return request(MonetaryEndpoints.TAB2, 'PUT', { grid })
}

function updateMonetaryComment (comment: string) {
  return request(MonetaryEndpoints.COMMENT, 'PUT', { comment })
}

export enum AverageEndpoints {
  'TAB1' = 'market_and_company/average_tab1',
  'TAB2' = 'market_and_company/average_kpi',
  'GRAPH_COMPANY' = 'market_and_company/average_graph_company_common',
  'MARKER_MARKET' = 'market_and_company/average_marker/market',
  'MARKER_COMPANY_PRICE_1' = 'market_and_company/average_marker/company_price',
  'MARKER_COMPANY_PRICE_2' = 'market_and_company/average_marker/company_price_2',
  'COMMENT' = 'market_and_company/average_comment',
}

export type AverageTab = Table<AverageEndpoints[]>

export interface AverageCollection {
  [AverageEndpoints.TAB1]: AverageTab
  [AverageEndpoints.TAB2]: AverageTab
  [AverageEndpoints.GRAPH_COMPANY]: GraphVolCommon
  [AverageEndpoints.MARKER_MARKET]: Marker
  [AverageEndpoints.MARKER_COMPANY_PRICE_1]: Marker
  [AverageEndpoints.MARKER_COMPANY_PRICE_2]: Marker
  [AverageEndpoints.COMMENT]: Comment
}

function getAverageCollection () {
  const objects = Object.values(AverageEndpoints)
  return request('collection', 'POST', { objects })
}

function updateAverageTab1 (grid: AverageTab['grid']) {
  return request(AverageEndpoints.TAB1, 'PUT', { grid })
}

function updateAverageTab2 (grid: AverageTab['grid']) {
  return request(AverageEndpoints.TAB2, 'PUT', { grid })
}

function updateAverageComment (comment: string) {
  return request(AverageEndpoints.COMMENT, 'PUT', { comment })
}

function getProductCategories () {
  return request('market_and_company/product_categories')
}

function updateProductCategories (grid: Table['grid']) {
  return request('market_and_company/product_categories', 'PUT', { grid })
}

export interface ProductCategoriesGraph {
  title: string
  filters: {
    years: Field<number[]>
  }
  legend: Record<string, string>
  chart: Record<string, string | number>
}

export interface ProductCategoriesGraphFilter {
  year: number[]
}

function getProductCategoriesGraph () {
  return request('market_and_company/product_category_graph')
}

function updateProductCategoriesGraph (filter: ProductCategoriesGraphFilter) {
  return request('market_and_company/product_category_graph', 'PUT', filter)
}

function getProductCategoriesComment () {
  return request('market_and_company/product_category_comment')
}

function updateProductCategoriesComment (comment: string) {
  return request('market_and_company/product_category_comment', 'PUT', { comment })
}

export interface CompetitorsChart {
  title: string
  legend: Record<string, string>
  chart: Record<string, string | number>[]
}

export enum CompetitorsEndpoints {
  'CHART' = 'market_and_company/summary/competitors/graph',
  'TABLE' = 'market_and_company/summary/competitors/tab',
  'COMMENT' = 'market_and_company/summary/competitors/comment',
}

export interface CompetitorsCollection {
  [CompetitorsEndpoints.CHART]: CompetitorsChart
  [CompetitorsEndpoints.TABLE]: Table
  [CompetitorsEndpoints.COMMENT]: Comment
}

function getCompetitorsCollection (dependencies?: string[]) {
  const objects = dependencies || Object.values(CompetitorsEndpoints)
  return request('collection', 'POST', { objects })
}

function updateCompetitorsTable (grid: Table['grid']) {
  return request(CompetitorsEndpoints.TABLE, 'PUT', { grid })
}

function updateCompetitorsComment (comment: string) {
  return request(CompetitorsEndpoints.COMMENT, 'PUT', { comment })
}

export enum CompanyBySegmentEndpoints {
  'FILTER' = 'market_and_company/categories/company/filter',
  'IN_KIND_TABLE' = 'market_and_company/categories/inkind/company_tab',
  'MONETARY_TABLE' = 'market_and_company/categories/monetary/company_tab',
  'AVERAGE_TABLE' = 'market_and_company/categories/average/company_tab',
}

export interface CompanyBySegmentCollection {
  [CompanyBySegmentEndpoints.IN_KIND_TABLE]: Table
  [CompanyBySegmentEndpoints.MONETARY_TABLE]: Table
  [CompanyBySegmentEndpoints.AVERAGE_TABLE]: Table
}

function getCompanyBySegmentFilter () {
  return request(CompanyBySegmentEndpoints.FILTER)
}

function getCompanyBySegmentCollection (dependencies?: string[], categoryId?: string | null, deps?: boolean) {
  const objects = dependencies || [
    `${CompanyBySegmentEndpoints.IN_KIND_TABLE}`,
    `${CompanyBySegmentEndpoints.MONETARY_TABLE}`,
    `${CompanyBySegmentEndpoints.AVERAGE_TABLE}`
  ]

  return getCollection<CompanyBySegmentCollection>(`/${categoryId}`, objects, deps)
}

export enum MarketBySegmentEndpoints {
  'FILTER' = 'market_and_company/categories/market/filter',
  'IN_KIND_TABLE' = 'market_and_company/categories/inkind/tab',
  'MONETARY_TABLE' = 'market_and_company/categories/monetary/tab',
  'AVERAGE_TABLE' = 'market_and_company/categories/average/tab',
}

export interface MarketBySegmentCollection {
  [MarketBySegmentEndpoints.IN_KIND_TABLE]: Table
  [MarketBySegmentEndpoints.MONETARY_TABLE]: Table
  [MarketBySegmentEndpoints.AVERAGE_TABLE]: Table
}

function getMarketBySegmentFilter () {
  return request('market_and_company/categories/market/filter')
}

function getMarketBySegmentCollection (dependencies?: string[], categoryId?: string | null, deps?: boolean) {
  const objects = dependencies || [
    `${MarketBySegmentEndpoints.IN_KIND_TABLE}`,
    `${MarketBySegmentEndpoints.MONETARY_TABLE}`,
    `${MarketBySegmentEndpoints.AVERAGE_TABLE}`
  ]

  return getCollection<MarketBySegmentCollection>(`/${categoryId}`, objects, deps)
}

function updateMarketBySegmentInKindTable (grid: Table['grid'], categoryId : number | unknown) {
  return request(`${MarketBySegmentEndpoints.IN_KIND_TABLE}/${categoryId}`, 'PUT', { grid })
}
function updateMarketBySegmentMonetaryTable (grid: Table['grid'], categoryId : number | unknown) {
  return request(`${MarketBySegmentEndpoints.MONETARY_TABLE}/${categoryId}`, 'PUT', { grid })
}
function updateCompanyBySegmentInKindTable (grid: Table['grid'], categoryId : number | unknown) {
  return request(`${CompanyBySegmentEndpoints.IN_KIND_TABLE}/${categoryId}`, 'PUT', { grid })
}
function updateCompanyBySegmentAverageTable (grid: Table['grid'], categoryId : number | unknown) {
  return request(`${CompanyBySegmentEndpoints.AVERAGE_TABLE}/${categoryId}`, 'PUT', { grid })
}

export interface CategoriesCompetitorsChart {
  title: string
  legend: Record<string, string>
  chart: Record<string, string | number>[]
}

export interface CategoriesCompetitorsFilter {
  value: unknown[]
  optionGroups: [Option[]]
}

export interface IMarketBySegmentFilter extends CategoriesCompetitorsFilter {}

export interface ICompanyBySegmentFilter extends CategoriesCompetitorsFilter {}

export enum CategoriesCompetitorsEndpoints {
  'CHART' = 'market_and_company/categories/competitors/graph',
  'TABLE' = 'market_and_company/categories/competitors/tab',
  'COMMENT' = 'market_and_company/categories/competitors/comment',
}

export interface CategoriesCompetitorsCollection {
  [CategoriesCompetitorsEndpoints.CHART]: CompetitorsChart
  [CategoriesCompetitorsEndpoints.TABLE]: Table
  [CategoriesCompetitorsEndpoints.COMMENT]: Comment
}

function getCategoriesCompetitorsFilter () {
  return request('market_and_company/categories/competitors/filter')
}
function updateCategoriesCompetitorsFilter (filter: Partial<CategoriesCompetitorsFilter>) {
  return request('market_and_company/categories/competitors/filter', 'PUT', filter)
}

function getCategoriesCompetitorsCollection (categoryId: string | null, dependencies?: string[]) {
  const objects = dependencies || [
    `${CategoriesCompetitorsEndpoints.CHART}`,
    `${CategoriesCompetitorsEndpoints.TABLE}`,
    CategoriesCompetitorsEndpoints.COMMENT
  ]

  return getCollection<CategoriesCompetitorsCollection>(`/${categoryId}`, objects, false, [CategoriesCompetitorsEndpoints.COMMENT])
}

function updateCategoriesCompetitorsTable (categoryId: string | null, grid: Table['grid']) {
  return request(`${CategoriesCompetitorsEndpoints.TABLE}/${categoryId}`, 'PUT', { grid })
}

function updateCategoriesCompetitorsComment (comment: string) {
  return request(CategoriesCompetitorsEndpoints.COMMENT, 'PUT', { comment })
}

export const marketAndCompanyApi = {
  getInKindCollection,
  getGeneralDashboardCollection,
  updateInKindTab1,
  updateInKindTab2Kpi,
  updateInKindComment,
  getMonetaryCollection,
  updateMonetaryTab1,
  updateMonetaryTab2,
  updateMonetaryComment,
  getAverageCollection,
  updateAverageTab1,
  updateAverageTab2,
  updateAverageComment,
  getProductCategories,
  updateProductCategories,
  getProductCategoriesGraph,
  updateProductCategoriesGraph,
  getProductCategoriesComment,
  updateProductCategoriesComment,
  getCompetitorsCollection,
  updateCompetitorsTable,
  updateCompetitorsComment,
  updateMarketBySegmentInKindTable,
  updateCompanyBySegmentInKindTable,
  updateCompanyBySegmentAverageTable,
  updateMarketBySegmentMonetaryTable,
  getCategoriesCompetitorsFilter,
  getCompanyBySegmentFilter,
  getMarketBySegmentFilter,
  updateCategoriesCompetitorsFilter,
  getCategoriesCompetitorsCollection,
  updateCategoriesCompetitorsTable,
  updateCategoriesCompetitorsComment,
  getCompanyBySegmentCollection,
  getMarketBySegmentCollection
}
