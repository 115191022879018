import React, { ComponentType, PropsWithChildren, useEffect, useRef } from 'react'
import {
  Link,
  LinkProps,
  Route,
  Switch,
  useRouteMatch,
  useLocation,
  Redirect
} from 'react-router-dom'
import { Box, Container, Paper, Tab, TabProps, Tabs, Typography } from '@material-ui/core'
import { createStyles, withStyles } from '@material-ui/core/styles'
import { useLayoutContext } from 'components/LayoutContext'
import { GoTopButton } from 'components/GoTopButton'
import { userPagesTitlesApi } from '../api/userPagesTitlesApi'
import { useAuthContext } from './AuthContext'

const StyledPaper = withStyles(() =>
  createStyles({
    root: {
      position: 'sticky',
      top: 0,
      zIndex: 2
    }
  })
)(Paper)

const StyledTabs = withStyles((theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(2),

      '@media print': {
        display: 'none'
      }
    }
  })
)(Tabs)

const StyledTab = withStyles(() => {
  return createStyles({
    root: {
      textTransform: 'none',
      minWidth: 200
    },
    wrapped: {
      '& p': {
        fontSize: 16
      }
    }
  })
})((props: TabProps & LinkProps) => <Tab component={Link} {...props} />)

interface ITab {
  label: string
  url: string
  component?: ComponentType
  hasInfoOnly?: boolean
  disabled?: boolean
}

interface PageProps {
  title?: string
  tabs?: ITab[]
  editableTitle?: boolean
  pageKey?: string
}

export function Page ({ title, tabs, pageKey, editableTitle, children }: PropsWithChildren<PageProps>) {
  const { setTitle, setEditableTitle, setPageKey } = useLayoutContext()
  const { currentUser } = useAuthContext()
  const match = useRouteMatch()
  const location = useLocation()
  const currentTabIdx = tabs
    ? tabs.findIndex((tab) => location.pathname === `${match.url}/${tab.url}`)
    : -1

  const contentRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    setEditableTitle(editableTitle)
    setPageKey(pageKey)
    if (editableTitle && currentUser) {
      userPagesTitlesApi.getPageTitle(pageKey as string, currentUser.id)
        .then(({ title }) => {
          setTitle(title)
        })
        .catch(() => {
          setTitle(title)
        })
    } else {
      setTitle(title)
    }
  }, [setTitle, title, setEditableTitle, editableTitle, setPageKey, pageKey, currentUser])

  return (
    <Container maxWidth="xl" ref={contentRef}>
      {tabs && (
        <StyledPaper>
          <StyledTabs
            variant="standard"
            indicatorColor="primary"
            value={currentTabIdx > -1 ? currentTabIdx : false}
          >
            {tabs.map((tab) => (
              <StyledTab
                key={tab.label}
                to={`${match.url}/${tab.url}`}
                disabled={tab.disabled}
                style={{
                  opacity: 1
                }}
                label={
                  <Typography variant="body2">
                    {tab.label}
                  </Typography>
                }
                wrapped
              />
            ))}
          </StyledTabs>
        </StyledPaper>
      )}

      <Box py={3}>
        {tabs ? (
          <Switch>
            {tabs.map(({ label, url, component: Component }) => (
              <Route key={label} path={`${match.url}/${url}`} component={Component} />
            ))}

            <Redirect to={`${match.url}/${tabs[0].url}`} />
          </Switch>
        ) : (
          children
        )}
      </Box>
      <GoTopButton contentRef={contentRef} />
    </Container>
  )
}
