import React, { Fragment } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { useAuthContext } from 'components/AuthContext'
import { CommonInfoPage } from 'pages/CommonInfoPage'
import { UserManagementPage } from 'pages/UserManagementPage'
import { UserManagementInfoPage } from 'pages/UserManagementInfoPage'
import { DataManagementPage } from 'pages/DataManagementPage'
import { MarketCharacteristicsPage } from 'pages/MarketCharacteristicsPage'
import { MarketPositioningPage } from 'pages/MarketPositioningPage'
import { MarketAssortmentDevelopmentPage } from 'pages/MarketAssortmentDevelopmentPage'
import { CompanyCharacteristicsPage } from 'pages/CompanyCharacteristicsPage'
import { PeriodsPage } from 'pages/PeriodsPage'
import { MarketAndCompanyPage } from 'pages/MarketAndCompanyPage'
import { MarketSegmentsPage } from 'pages/MarketSegmentsPage'
import { CategoryPage } from 'pages/CategoryPage'
import { CategorySummaryPage } from 'pages/CategorySummaryPage'
import { SalesIntroductoryPage } from 'pages/SalesIntroductoryPage'
import { SalesPlanPage } from 'pages/SalesPlanPage'
import { SalesDirectionPage } from 'pages/SalesDirectionPage'
import { SalesSummaryPage } from 'pages/SalesSummaryPage'
import { DirectionsPage } from 'pages/DirectionsPage'
import { PlanImplementationPage } from 'pages/PlanImplementationPage'
import { AbcXyzAnalysisPage } from 'pages/AbcXyzAnalysis'
import { AestheticsAssessmentPage } from 'pages/AestheticsAssessmentPage'
import { NewProductsLaunchPage } from 'pages/NewProductsLaunchPage'
import { PricingPage } from 'pages/PricingPage'
import { ForeignMarketsPage } from 'pages/ForeignMarketsPage'
import { ProfilePage } from 'pages/ProfilePage'
import { PlaceQuantitativeDistributionPage } from 'pages/PlaceQuantitativeDistributionPage'
import { PlaceQualitativeDistributionPage } from 'pages/PlaceQualitativeDistributionPage'
import { PlaceLiquidityThroughOutletPage } from 'pages/PlaceLiquidityThroughOutletPage'
import { PromoCommunicationByTaskPage } from 'pages/PromoCommunicationByTaskPage'
import { PromoMarketingPlanAndBudgetPage } from 'pages/PromoMarketingPlanAndBudgetPage'
import { InfoPage } from 'pages/InfoPage'
import { UserRole } from 'api/usersApi'
import { GeneralDashboardPage } from '../pages/dashboards/GeneralDashboardPage'
import { GeographyPage } from '../pages/GeographyPage'
import { SkuPage } from '../pages/SkuPage'
import { OutletSalesPage } from '../pages/OutletSalesPage'
import { AdvertisingPage } from '../pages/AdvertisingPage'
import { CompaniesManagementPage } from '../pages/CompaniesManagementPage'
import { StatisticalDataManagementPage } from '../pages/StatisticalDataManagementPage'
import { HomePage } from 'pages/HomePage'

export function Routes () {
  const { currentUser } = useAuthContext()

  if (!currentUser) {
    return null
  }

  return (
    <Fragment>
      {
        currentUser.role === UserRole.APP_ADMIN && <Switch>
          <Route
            path="/service/administrator/companies-management"
            component={CompaniesManagementPage}
          />

          <Route
            path={'/service/administrator/statistical-management'}
            component={StatisticalDataManagementPage}
          />
          <Route
            path="/profile"
            component={ProfilePage}
          />
          <Redirect to="/service/administrator/companies-management" />
        </Switch>
      }
      {
        currentUser.role !== UserRole.APP_ADMIN && <Switch>
          <Route
            path="/service/company-profile/common-info"
            component={CommonInfoPage}
          />
          <Route
            path="/service/company-profile/user-management"
            component={
              currentUser?.role === UserRole.MANAGER || currentUser?.role === UserRole.ADMIN
                ? UserManagementPage
                : UserManagementInfoPage
            }
          />
          <Route
            path="/service/company-profile/data-management"
            component={DataManagementPage}
          />
          <Route
            path="/service/directory/market-characteristics"
            component={MarketCharacteristicsPage}
          />
          <Route
            path="/service/directory/company-characteristics"
            component={CompanyCharacteristicsPage}
          />
          <Route
            path="/service/directory/periods"
            component={PeriodsPage}
          />
          <Route
            path="/service/directory/segments"
            component={MarketSegmentsPage}
          />
          <Route
            path="/service/directory/geography"
            component={GeographyPage}
          />
          <Route
            path="/segments"
            component={MarketSegmentsPage}
          />
          <Route
            path="/strategical/market-and-company/consumption-in-kind-terms"
            component={MarketAndCompanyPage}
          />
          <Route
            path="/strategical/market-and-company/positioning"
            component={MarketPositioningPage}
          />
          <Route
            path="/strategical/market-and-company/assortment-development"
            component={MarketAssortmentDevelopmentPage}
          />
          <Route
            path="/strategical/market-standards/sku"
            component={SkuPage}
          />
          <Route
            path="/strategical/market-standards/outlets-sales"
            component={OutletSalesPage}
          />
          <Route
            path="/strategical/market-standards/advertising_efficiency"
            component={AdvertisingPage}
          />
          <Route
            path="/strategical/market-standards/info:id"
            component={InfoPage}
          />
          <Route
            path="/strategical/categories/category/:categoryId"
            component={CategoryPage}
          />
          <Route
            path="/strategical/category-summary"
            component={CategorySummaryPage}
          />
          <Route
            path="/sales-introductory"
            component={SalesIntroductoryPage}
          />
          <Route
            path="/sales-plan"
            component={SalesPlanPage}
          />
          <Route
            path="/sales-direction"
            component={SalesDirectionPage}
          />
          <Route
            path="/sales-summary"
            component={SalesSummaryPage}
          />
          <Route
            path="/directions"
            component={DirectionsPage}
          />
          <Route
            path="/plan-implementaion"
            component={PlanImplementationPage}
          />
          <Route
            path="/operational-part/product/abc-xyz-analysis"
            component={AbcXyzAnalysisPage}
          />
          <Route
            path="/operational-part/product/aesthetics-assessment"
            component={AestheticsAssessmentPage}
          />
          <Route
            path="/operational-part/product/new-products-launch"
            component={NewProductsLaunchPage}
          />
          <Route
            path="/operational-part/price/pricing"
            component={PricingPage}
          />
          <Route
            path="/operational-part/price/prices_for_foreign_markets"
            component={ForeignMarketsPage}
          />
          <Route
            path="/profile"
            component={ProfilePage}
          />
          <Route
            path={'/operational-part/product/info:id'}
            component={InfoPage}
          />
          <Route
            path={'/operational-part/price/info:id'}
            component={InfoPage}
          />
          <Route
            path={'/operational-part/place/info:id'}
            component={InfoPage}
          />
          <Route
            path={'/operational-part/promotion/info:id'}
            component={InfoPage}
          />
          <Route
            path={'/operational-part/place/quantitative-distribution'}
            component={PlaceQuantitativeDistributionPage}
          />
          <Route
            path={'/operational-part/place/qualitative-distribution'}
            component={PlaceQualitativeDistributionPage}
          />
          <Route
            path={'/operational-part/place/liquidity-through-outlet'}
            component={PlaceLiquidityThroughOutletPage}
          />
          <Route
            path={'/operational-part/promotion/communication-by-task'}
            component={PromoCommunicationByTaskPage}
          />
          <Route
            path={'/operational-part/promotion/marketing-plan-and-budget'}
            component={PromoMarketingPlanAndBudgetPage}
          />
          <Route
            path={'/dashboards/info:id'}
            component={InfoPage}
          />
          <Route
            path={'/dashboards/general'}
            component={GeneralDashboardPage}
          />
          {process.env.REACT_APP_FRONT_TYPE === 'strela' ? <>
            <Redirect to="/dashboards/general"/>
          </> : <>
            <Route
              path={'/home'}
              component={HomePage}
            />
            <Redirect to="/home"/>
          </>}
        </Switch>
      }
    </Fragment>
  )
}
