import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { useSnackbar } from 'notistack'
import { useLocalStorage } from 'helpers/useLocalStorage'
import { SignInForm } from 'components/SignInForm'
import { TOKEN_ITEM } from 'api/authApi'
import { User, usersApi, UserRole } from 'api/usersApi'
import { companiesApi, Company } from 'api/companiesApi'
import { useTranslationContext } from 'components/TranslationContext'
import { countriesApi } from 'api/countriesApi'
import { Country } from 'api/types'

type DetailedErrorType = 'AuthError' | 'ValidationError'

type ErrorDetails = Record<string | number, string>

interface DetailedError {
  details: ErrorDetails
  type: DetailedErrorType
}

type ErrorsSetter = (errors: Record<string, string>) => void

interface Context {
  currentUser?: User
  setCurrentUser: (user: User) => void
  currentCompany?: Company
  selectedCountry?: Country
  setCurrentCompany: (company: Company) => void
  signIn: (token: string, persistToken: boolean) => void
  signOut: () => void
  handleResponseFailure: (error: DetailedError | string, setErrors?: ErrorsSetter) => void
  handleResponseSuccess: (message?: string) => void,
  companyLogo?: string | ArrayBuffer | null
}

const AuthContext = createContext<Context | null>(null)

export function AuthContextProvider ({ children }: React.PropsWithChildren<{}>) {
  const { enqueueSnackbar } = useSnackbar()
  const [token, setToken] = useLocalStorage(TOKEN_ITEM)
  const [isAuthorized, setIsAuthorized] = useState(!!token)
  const [currentUser, setCurrentUser] = useState<User>()
  const [currentCompany, setCurrentCompany] = useState<Company>()
  const [selectedCountry, setSelectedCountry] = useState<Country>()
  const [companyLogo, setCompanyLogo] = useState<string | ArrayBuffer | null>()
  const { translation } = useTranslationContext()

  const signIn = (token: string, persistToken: boolean) => {
    setToken(token)
    setIsAuthorized(true)

    if (!persistToken) {
      window.onbeforeunload = () => setToken('')
    }
  }
  const signOut = useCallback(() => {
    setToken('')
    setIsAuthorized(false)
    setCompanyLogo(null)
    setCurrentUser(undefined)
    setCurrentCompany(undefined)
  }, [setToken])
  const showError = useCallback((message: string) => {
    enqueueSnackbar(message, { variant: 'error' })
  }, [enqueueSnackbar])

  const showErrorDetails = useCallback((details: ErrorDetails) => {
    const message = Object.entries(details)
      .map(([key, value]) => `${key}: ${value}`)
      .join('\n')
    showError(message || translation['components.AuthContext.default_error'])
  }, [showError, translation])
  const handleResponseFailure = useCallback((error: DetailedError | string, setErrors?: ErrorsSetter) => {
    if (typeof error === 'string') {
      showError(error)
    } else {
      if (error.type === 'AuthError') {
        signOut()
      }

      if (error.details) {
        if (error.type === 'ValidationError' && setErrors) {
          setErrors(error.details)
        } else {
          showErrorDetails(error.details)
        }
      }
    }
  }, [showError, showErrorDetails, signOut])

  const handleResponseSuccess = (message? : string) => {
    enqueueSnackbar(message || translation['components.AuthContext.changes_are_saved'], { variant: 'success' })
  }

  useEffect(() => {
    if (token) {
      usersApi.getCurrentUser()
        .then(setCurrentUser)
        .catch(handleResponseFailure)

      companiesApi.getCurrentCompany()
        .then(setCurrentCompany)
        .catch(handleResponseFailure)
    }
  }, [handleResponseFailure, token])

  useEffect(() => {
    if (token && currentUser && currentCompany) {
      countriesApi.getCountriesList()
        .then((countriesList: Country[]) => {
          setSelectedCountry(countriesList.find(country => country.id === currentUser?.selectedCountry?.id))
        })
      if (currentUser.role !== UserRole.ADMIN) {
        companiesApi.getCompanyLogo(currentCompany.id)
          .then(logoBlob => {
            const fr = new FileReader()

            fr.readAsDataURL(logoBlob)
            fr.onload = () => {
              setCompanyLogo(fr.result)
            }
          })
      }
    }
  }, [token, currentUser, currentCompany])

  const value = {
    currentUser,
    setCurrentUser,
    currentCompany,
    selectedCountry,
    companyLogo,
    setCurrentCompany,
    signIn,
    signOut,
    handleResponseFailure,
    handleResponseSuccess
  }

  return (
    <AuthContext.Provider value={value}>
      {isAuthorized
        ? children
        : <SignInForm />}
    </AuthContext.Provider>
  )
}

export function useAuthContext () {
  const context = useContext(AuthContext)

  if (!context) {
    throw new Error('Used outside of "AuthContextProvider"')
  }

  return context
}
