import { request } from 'api/request'
import { Field } from 'helpers/fields'

export interface Periods {
  fields: {
    desc: string
    initialPeriod: Field
    lastActualPeriod: Field
    forecastPeriodsCount: Field
  }
}

export interface PeriodsValues {
  initialPeriod: number
  lastActualPeriod: number
  forecastPeriodsCount: number
}

function getPeriods () {
  return request('companies/periods')
}

function updatePeriods (periods: PeriodsValues) {
  return request('companies/periods', 'PUT', periods)
}

export const periodsApi = {
  getPeriods,
  updatePeriods
}
