import React, { useEffect } from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { IMarketUnitsStore, marketUnitsStore } from '../stores/marketUnitsStore'
import { observer } from 'mobx-react-lite'
import { Option, OptionGroup } from '../helpers/fields'
import { marketCharacteristicsApi } from '../api/marketCharacteristicsApi'
import { useAuthContext } from './AuthContext'
import { useHistory } from 'react-router'
import { Box } from '@material-ui/core'
import { useTranslationContext } from './TranslationContext'

interface BasicMarketUnitsSelectProps {
  marketUnitsStore : IMarketUnitsStore
}

function findOptionByValue (options: Option[] = [], optionValue: string) {
  return options.find((option) => (option.value === optionValue))
}

function findOptionGroupByOptionValue (optionGroups: OptionGroup[], optionValue: string) {
  return optionGroups.find((optionGroup) => (
    findOptionByValue(optionGroup.options, optionValue)
  ))
}

function renderOptions (options: Option[] = []) {
  return options.map(({ value, label }) => (
    <MenuItem key={value} value={value}>
      {label}
    </MenuItem>
  ))
}

function BasicMarketUnitsSelectWithStore ({ marketUnitsStore } : BasicMarketUnitsSelectProps) {
  const { handleResponseSuccess, handleResponseFailure } = useAuthContext()
  const { units, setUnits } = marketUnitsStore
  const { translation } = useTranslationContext()
  const history = useHistory()

  useEffect(() => {
    marketCharacteristicsApi.getUnits()
      .then(setUnits)
      .catch(handleResponseFailure)
  }, [handleResponseFailure, setUnits])

  if (!units) {
    return null
  }

  const { marketUnit, minimumNaturalUnit, currency, marketMonetaryUnit, priceUnit } = units.fields
  const optionGroup = findOptionGroupByOptionValue(marketUnit.optionGroups, marketUnit.value)
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    return marketCharacteristicsApi.updateUnits({
      marketUnit: event.target.value as string,
      minimumNaturalUnit: minimumNaturalUnit.value,
      currency: currency.value,
      marketMonetaryUnit: marketMonetaryUnit.value,
      priceUnit: priceUnit.value
    })
      .then(() => {
        marketCharacteristicsApi.getUnits()
          .then(setUnits)
          .catch(handleResponseFailure)
        const marketUnitLabel = findOptionGroupByOptionValue(marketUnit.optionGroups, event.target.value as string)?.options.find(({ value, label }) => value === event.target.value as string)?.label
        handleResponseSuccess(translation['components.AuthContext.new_volume_unit_is_set'] + ' - ' + marketUnitLabel)
        setTimeout(() => {
          history.go(0)
        }, 1500)
      })
      .catch(handleResponseFailure)
  }
  return (
    <Box px={5}>
      <FormControl>
        <Select
          value={marketUnit.value}
          onChange={handleChange}
          displayEmpty
        >
          {
            renderOptions(optionGroup?.options)
          }
        </Select>
      </FormControl>
    </Box>
  )
}

const BasicMarketUnitsSelectObserver = observer(BasicMarketUnitsSelectWithStore)

export const BasicMarketUnitsSelect = () => <BasicMarketUnitsSelectObserver marketUnitsStore={marketUnitsStore} />
