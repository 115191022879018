import { Translation } from '../../api/translationApi'
import { UserRole } from '../../api/usersApi'
import { ProductCategory } from '../../api/categoriesApi'
import { DashboardIcon, OperationalPartIcon, ServicePartIcon, StrategicPartIcon, UserIcon } from '../../icons/MenuIcons'

import { IMenuItem } from './common'

export const menuItems = (
  shortName: string | undefined,
  translation: Translation,
  userRole?: UserRole | undefined,
  categories?: ProductCategory[],
  userName?: string
) : IMenuItem[] => {
  const isAdmin = userRole === UserRole.APP_ADMIN

  return [
    {
      title: translation['menu.dashboards'],
      icon: DashboardIcon,
      hidden: isAdmin,
      href: '/dashboards',
      children: [
        {
          title: translation['menu.dashboards_general'],
          href: '/dashboards/general'
        },
        {
          title: translation['menu.dashboards_general_director'],
          href: '/dashboards/info2'
        },
        {
          title: translation['menu.dashboards_director_of_development'],
          href: '/dashboards/info3'
        },
        {
          title: translation['menu.dashboards_director_of_sales'],
          href: '/dashboards/info4'
        },
        {
          title: translation['menu.dashboards_marketing_director'],
          href: '/dashboards/info5'
        }
      ]
    },
    {
      title: translation['menu.service_part'],
      href: '/service',
      icon: ServicePartIcon,
      children: [
        {
          title: translation['menu.service_part_subtitle'],
          href: '/service/administrator',
          hidden: !isAdmin,
          children: [
            {
              title: translation['menu.companies'],
              href: '/service/administrator/companies-management'
            },
            {
              title: translation['menu.statistical_data_management'],
              href: '/service/administrator/statistical-management'
              // children: [
              //   {
              //     title: translation['components.IndicatorsPage.title'],
              //     href: '/service/administrator/statistical-management/indicators'
              //   },
              //   {
              //     title: translation['components.PopulationPage.title'],
              //     href: '/service/administrator/statistical-management/population'
              //   },
              //   {
              //     title: translation['components.OutletsCountPage.title'],
              //     href: '/service/administrator/statistical-management/outlets-count'
              //   },
              //   {
              //     title: translation['components.AdvertisingPage.title'],
              //     href: '/service/administrator/statistical-management/advertising-efficiency'
              //   }
              // ]
            }
          ]
        },
        {
          title: translation['menu.company_profile'],
          href: '/company-profile',
          hidden: isAdmin,
          children: [
            {
              title: translation['menu.company_info'],
              href: '/service/company-profile/common-info'
            },
            {
              title: translation['menu.company_users'],
              href: '/service/company-profile/user-management'
            },
            {
              title: translation['menu.company_data'],
              href: '/service/company-profile/data-management'
            }
          ]
        },
        {
          title: translation['menu.units'],
          href: '/service/directory',
          hidden: isAdmin,
          children: [
            {
              title: translation['menu.units_periods'],
              href: '/service/directory/periods'
              // children: [
              //   {
              //     title: translation['menu.units_periods_main'],
              //     href: '/service/directory/periods/calculation-periods'
              //   },
              //   {
              //     title: translation['menu.units_control_periods'],
              //     href: '/service/directory/periods/monitoring-periods'
              //   }
              // ]
            },
            {
              title: translation['menu.units_market'],
              href: '/service/directory/market-characteristics'
              // children: [
              //   {
              //     title: translation['menu.units_market_units'],
              //     href: '/service/directory/market-characteristics/units'
              //   },
              //   {
              //     title: translation['menu.units_market_price_coefficients'],
              //     href: '/service/directory/market-characteristics/price-coefficients'
              //   },
              //   {
              //     title: translation['menu.units_market_categories'],
              //     href: '/service/directory/market-characteristics/product-categories'
              //   },
              //   {
              //     title: translation['menu.units_market_curreny'],
              //     href: '/service/directory/market-characteristics/currency-rates'
              //   },
              //   {
              //     title: translation['menu.units_market_key_players'],
              //     href: '/service/directory/market-characteristics/key-players'
              //   }
              // ]
            },
            {
              title: translation['menu.units_company'],
              href: '/service/directory/company-characteristics'
              // children: [
              //   {
              //     title: translation['menu.units_company_sales_structure'],
              //     href: '/service/directory/company-characteristics/sales-structure'
              //   },
              //   {
              //     title: translation['menu.units_company_marketing_budget'],
              //     href: '/service/directory/company-characteristics/marketing-budget'
              //   },
              //   {
              //     title: translation['menu.units_company_products'],
              //     href: '/service/directory/company-characteristics/product-portfolio'
              //   }
              // ]
            },
            {
              title: translation['menu.segments'],
              href: '/service/directory/segments'
              // children: [
              //   {
              //     title: translation['pages.MarketAndCompanyCategoriesPage.tabs.market_by_segments'],
              //     href: '/service/directory/segments/market-by-segment'
              //   },
              //   {
              //     title: `${shortName}${translation['pages.MarketAndCompanyCategoriesPage.tabs.by_segments']}`,
              //     href: '/service/directory/segments/company-by-segment'
              //   },
              //   {
              //     title: translation['menu.market_and_company_competiotors'],
              //     href: '/service/directory/segments/competitors'
              //   }
              // ]
            },
            {
              title: translation['menu.geography'],
              href: '/service/directory/geography'
              // children: [
              //   {
              //     title: translation['components.GeographyPage.sales_title'],
              //     href: '/service/directory/geography/sales'
              //   },
              //   {
              //     title: translation['components.GeographyPage.consumption_title'],
              //     href: '/service/directory/geography/consumption'
              //   }
              // ]
            }
          ]
        }
      ]
    },
    {
      title: translation['menu.strategic_part'],
      href: '/strategical',
      icon: StrategicPartIcon,
      hidden: isAdmin,
      children: [
        {
          title: translation['menu.market_and_company_menu'],
          href: '/strategical/market-and-company',
          children: [
            {
              title: translation['menu.market_and_company'],
              href: '/strategical/market-and-company/consumption-in-kind-terms'
            },
            {
              title: translation['menu.positioning'],
              href: '/strategical/market-and-company/positioning'
            },
            {
              title: translation['menu.assortment_development'],
              href: '/strategical/market-and-company/assortment-development'
            }
          ]
          // children: [
          //   {
          //     title: translation['menu.market_and_company_inkind'],
          //     href: '/strategical/market-and-company/consumption-in-kind-terms'
          //   },
          //   {
          //     title: translation['menu.market_and_company_monetary'],
          //     href: '/strategical/market-and-company/consumption-in-monetary-terms'
          //   }
          // ]
        },
        {
          title: translation['menu.market_standards'],
          href: '/strategical/market-standards',
          children: [
            {
              title: translation['menu.sku'],
              href: '/strategical/market-standards/sku'
              // children: [
              //   {
              //     title: 'Нормативы',
              //     href: '/strategical/market-standards/sku/standards'
              //   },
              //   {
              //     title: 'Цели',
              //     href: '/strategical/market-standards/sku/targets'
              //   }
              // ]
            },
            {
              title: translation['menu.market_standards_sku_sales_tt'],
              href: '/strategical/market-standards/outlets-sales'
              // children: [
              //   {
              //     title: 'Нормативы',
              //     href: '/strategical/market-standards/outlets-sales/standards'
              //   },
              //   {
              //     title: 'Цели',
              //     href: '/strategical/market-standards/outlets-sales/targets'
              //   }
              // ]
            },
            {
              title: translation['menu.market_standards_distribution'],
              href: '/strategical/market-standards/info20'
            },
            {
              title: translation['menu.market_standards_consumption'],
              href: '/strategical/market-standards/info16'
            },
            {
              title: translation['menu.market_standards_adv'],
              href: '/strategical/market-standards/advertising_efficiency'
              // children: [
              //   {
              //     title: 'Нормативы',
              //     href: '/strategical/market-standards/advertising_efficiency/standards'
              //   },
              //   {
              //     title: 'Цели',
              //     href: '/strategical/market-standards/advertising_efficiency/targets'
              //   }
              // ]
            }
          ]
        },
        {
          title: translation['menu.categories'],
          href: '/strategical/categories',
          children: categories && [
            ...categories.map(({ categoryName, id }) => ({
              title: categoryName,
              href: `/strategical/categories/category/${id}`
              // children: [
              //   {
              //     title: translation['menu.categories_sales'],
              //     href: `/strategical/categories/category/${id}/sales-and-revenue`
              //   },
              //   {
              //     title: translation['menu.categories_distribution'],
              //     href: `/strategical/categories/category/${id}/distribution`
              //   },
              //   {
              //     title: translation['menu.categories_geo'],
              //     href: `/strategical/categories/category/${id}/sales-geography`
              //   },
              //   {
              //     title: translation['menu.categories_brands'],
              //     href: `/strategical/categories/category/${id}/brands`
              //   },
              //   {
              //     title: translation['menu.categories_growth'],
              //     href: `/strategical/categories/category/${id}/growth-sources`
              //   },
              //   {
              //     title: translation['menu.categories_market_budget'],
              //     href: `/strategical/categories/category/${id}/marketing-budget`
              //   }
              // ]
            }))
          ]
        },
        {
          title: translation['menu.categories_summary'],
          href: '/strategical/category-summary'
          // children: [
          //   {
          //     title: translation['menu.categories_summary'],
          //     href: '/strategical/category-summary/summary'
          //   },
          //   {
          //     title: translation['menu.categories_summary_targets'],
          //     href: '/strategical/category-summary/targets-by-category'
          //   },
          //   {
          //     title: translation['menu.categories_summary_targets_geo'],
          //     href: '/strategical/category-summary/targets-by-geo'
          //   },
          //   {
          //     title: translation['menu.categories_summary_targets_brands'],
          //     href: '/strategical/category-summary/targets-by-brand'
          //   },
          //   {
          //     title: translation['menu.growth_categories'],
          //     href: '/strategical/category-summary/categories-context'
          //   },
          //   {
          //     title: translation['menu.categories_summary_targets_market_budget'],
          //     href: '/strategical/category-summary/targets-by-marketing-budget'
          //   }
          // ]
        }
      ]
    },
    {
      title: translation['menu.operating_part'],
      icon: OperationalPartIcon,
      href: '/operational-part',
      hidden: isAdmin,
      children: [
        // {
        //   title: translation['menu.sales_menu'],
        //   icon: MonetizationOn,
        //   children: [
        //     {
        //       title: translation['menu.sales_menu_directions'],
        //       href: '/directions'
        //     },
        //     {
        //       title: translation['menu.sales_menu_plan'],
        //       href: '/plan-implementaion'
        //     }
        //   ]
        // },
        {
          title: translation['menu.product_portfolio'],
          href: '/operational-part/product',
          children: [
            {
              title: translation['menu.abc_xyz_analysis'],
              href: '/operational-part/product/abc-xyz-analysis'
            },
            {
              title: translation['menu.new_products_launch'],
              href: '/operational-part/product/new-products-launch'
            },
            {
              title: translation['menu.aesthetics_assessment'],
              href: '/operational-part/product/aesthetics-assessment'
            },
            {
              title: translation['menu.optimal_batch_of_new_products'],
              href: '/operational-part/product/info7'
            },
            {
              title: translation['menu.pilot_sales'],
              href: '/operational-part/product/info8'
            }
          ]
        },
        {
          title: translation['menu.price_policy'],
          href: '/operational-part/price',
          children: [
            {
              title: translation['menu.price_monitoring'],
              href: '/operational-part/price/info17'
            },
            {
              title: translation['menu.optimal_price'],
              href: '/operational-part/price/pricing'
            },
            {
              title: translation['menu.prices_by_sales_channel'],
              href: '/operational-part/price/info9'
            },
            {
              title: translation['menu.prices_for_foreign_markets'],
              href: '/operational-part/price/prices_for_foreign_markets'
            }
          ]
        },
        {
          title: translation['menu.distribution'],
          href: '/operational-part/place',
          children: [
            {
              title: translation['menu.sales_management'],
              href: '/operational-part/place/info19'
            },
            {
              title: translation['menu.quantitative_distribution'],
              href: '/operational-part/place/quantitative-distribution'
            },
            {
              title: translation['menu.quality_distribution'],
              href: '/operational-part/place/qualitative-distribution'
            },
            {
              title: translation['menu.liquidity_through_pos'],
              href: '/operational-part/place/liquidity-through-outlet'
            }
          ]
        },
        {
          title: translation['menu.promotion'],
          href: '/operational-part/promotion',
          children: [
            {
              title: translation['menu.promotion_communication'],
              href: '/operational-part/promotion/communication-by-task'
            },
            {
              title: translation['menu.promotion_market_plan_budget'],
              href: '/operational-part/promotion/marketing-plan-and-budget'
            }
          ]
        }
      ]
    },
    {
      title: userName!,
      icon: UserIcon,
      widthSeparator: true,
      href: '/profile',
      children: [
        {
          title: translation['menu.profile.title'],
          href: '/profile'
        }
      ]
    }
  ]
}
