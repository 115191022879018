import React, { useCallback, useEffect, useState } from 'react'
import { Form, Formik, FormikConfig } from 'formik'
import { Box, Card, CardContent, Grid, Typography } from '@material-ui/core'
import { useAuthContext } from 'components/AuthContext'
import { useLayoutContext } from 'components/LayoutContext'
import { useTranslationContext } from 'components/TranslationContext'
import { TextField } from 'components/TextField'
import { LoadingButton } from 'components/LoadingButton'
import { periodsApi, Periods, PeriodsValues } from 'api/periodsApi'
import { getFieldsValues } from 'helpers/fields'
import { useSnackbar } from 'notistack'

export function PeriodsForCalculationsPage () {
  const { handleResponseSuccess, handleResponseFailure } = useAuthContext()
  const { startContentLoading, finishContentLoading } = useLayoutContext()
  const { translation } = useTranslationContext()
  const [periods, setPeriods] = useState<Periods>()
  const [periodsValues, setPeriodsValues] = useState<PeriodsValues>()
  const { enqueueSnackbar } = useSnackbar()
  const maxYear = 2100
  const minYear = 1900

  const isValidYear = (year: number) => {
    return year >= minYear && year <= maxYear
  }
  const validate = ({ initialPeriod, lastActualPeriod, forecastPeriodsCount }: PeriodsValues) => {
    const errors: Partial<Record<keyof PeriodsValues, string>> = {}

    if (!initialPeriod) {
      errors.initialPeriod = translation['validation.required_field']
    } else if (!isValidYear(initialPeriod)) {
      errors.initialPeriod = `${translation['pages.PeriodsForCalculationsPage.error.available_value']} ${minYear} - ${maxYear}`
    }

    if (!lastActualPeriod) {
      errors.lastActualPeriod = translation['validation.required_field']
    } else if (!isValidYear(lastActualPeriod)) {
      errors.lastActualPeriod = `${translation['pages.PeriodsForCalculationsPage.error.available_value']} ${minYear} - ${maxYear}`
    } else if (initialPeriod && lastActualPeriod < initialPeriod) {
      errors.lastActualPeriod = translation['pages.PeriodsForCalculationsPage.error.wrong_dates_range']
    }

    if (!forecastPeriodsCount) {
      errors.forecastPeriodsCount = translation['validation.required_field']
    }
    if (forecastPeriodsCount <= 0 || forecastPeriodsCount > 10) {
      errors.forecastPeriodsCount = translation['validation.required_field']
    }

    return errors
  }
  const getPeriods = useCallback(() => {
    startContentLoading()
    periodsApi.getPeriods()
      .then((periods: Periods) => {
        setPeriods(periods)
        const { initialPeriod, lastActualPeriod, forecastPeriodsCount } = periods.fields
        setPeriodsValues(getFieldsValues<PeriodsValues>({ initialPeriod, lastActualPeriod, forecastPeriodsCount }))
      })
      .catch(handleResponseFailure)
      .finally(finishContentLoading)
  }, [startContentLoading, handleResponseFailure, finishContentLoading])

  const handleSubmit: FormikConfig<PeriodsValues>['onSubmit'] = (values, { setErrors }) => {
    return periodsApi.updatePeriods(values)
      .then((periodsValues: PeriodsValues) => {
        setPeriodsValues(periodsValues)
        handleResponseSuccess()
      })
      .catch((error) => {
        handleResponseFailure(error, setErrors)
        enqueueSnackbar(error.details[''], { variant: 'success' })
      })
  }

  useEffect(() => {
    getPeriods()
  }, [getPeriods])

  if (!periods || !periodsValues) {
    return null
  }

  const { initialPeriod, lastActualPeriod, forecastPeriodsCount, desc } = periods.fields
  return (
    <Grid container>
      <Grid item xs={12} md={4}>
        <Card>
          <CardContent>
            <Formik
              enableReinitialize
              initialValues={periodsValues}
              onSubmit={handleSubmit}
              validate={validate}
            >
              {({ isSubmitting }) => (
                <Form>
                  <TextField
                    name="initialPeriod"
                    label={initialPeriod.label}
                    hint={initialPeriod.hint}
                    type="number"
                    autoFocus
                  />

                  <TextField
                    name="lastActualPeriod"
                    label={lastActualPeriod.label}
                    hint={lastActualPeriod.hint}
                    type="number"
                    autoFocus
                  />

                  <TextField
                    name="forecastPeriodsCount"
                    label={forecastPeriodsCount.label}
                    hint={forecastPeriodsCount.hint}
                    type="number"
                    autoFocus
                  />

                  <Typography variant="caption" color="textSecondary">
                    {desc}
                  </Typography>

                  <Box mt={2} textAlign="right">
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      color="primary"
                      size="large"
                      disableElevation
                      disabled={isSubmitting}
                      isLoading={isSubmitting}
                    >
                      {translation['form.save']}
                    </LoadingButton>
                  </Box>
                </Form>
              )}
            </Formik>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}
