import React from 'react'
import { InfoPage } from './infoPage'
import { Page } from 'components/Page'
import { useTranslationContext } from 'components/TranslationContext'

export function PlaceQualitativeDistributionPage () {
  const { translation } = useTranslationContext()

  const tabs = [
    {
      label: translation['menu.assortment_development_tab.by_categories'],
      url: 'by-categories',
      component: InfoPage
    },
    {
      label: translation['menu.assortment_development_tab.by_brands'],
      url: 'by-brands',
      component: InfoPage
    },
    {
      label: translation['menu.assortment_development_tab.by_pricing_categories'],
      url: 'by-pricing-categories',
      component: InfoPage
    }
  ]

  return (
    <Page title={translation['menu.quality_distribution']} tabs={tabs} />
  )
}
