import React from 'react'
import { Page } from 'components/Page'
import { ConsumptionInKindTermsPage } from './ConsumptionInKindTermsPage'
import { ConsumptionInMonetaryTermsPage } from './ConsumptionInMonetaryTermsPage'
import { useTranslationContext } from 'components/TranslationContext'
import { AverageSellingPricePage } from './AverageSellingPricePage'

export function MarketAndCompanyPage () {
  const { translation } = useTranslationContext()

  const tabs = [
    {
      label: translation['menu.market_and_company_inkind'],
      url: 'consumption-in-kind-terms',
      component: ConsumptionInKindTermsPage
    },
    {
      label: translation['menu.market_and_company_monetary'],
      url: 'consumption-in-monetary-terms',
      component: ConsumptionInMonetaryTermsPage
    },
    {
      label: translation['menu.market_and_company_average'],
      url: 'average-selling-price',
      component: AverageSellingPricePage
    }
  ]

  return (
    <Page title={translation['menu.market_and_company']} tabs={tabs} />
  )
}
