import React from 'react'
import { Page } from 'components/Page'
import { StatusPage } from './StatusPage'
import { ImplementationPage } from './ImplementationPage'
import { useTranslationContext } from 'components/TranslationContext'

export function PlanImplementationPage () {
  const { translation } = useTranslationContext()
  const tabs = [
    {
      label: translation['pages.PlanImplementationPage.tabs.status'],
      url: 'status',
      component: StatusPage,
      hasInfoOnly: true
    },
    {
      label: translation['pages.PlanImplementationPage.tabs.implementation'],
      url: 'implementation',
      component: ImplementationPage,
      hasInfoOnly: true
    }
  ]
  return (
    <Page title={translation['pages.PlanImplementationPage.title']} tabs={tabs} />
  )
}
