import React, { useEffect, useCallback, useState } from 'react'
import {
  CardContent,
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectProps,
  Button,
  Typography,
  Card
} from '@material-ui/core'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import {
  Chart,
  ChartVariants,
  getColorByIdx,
  normalizeChartData,
  PieSeries,
  mapCollectionToFootnote
} from 'components/Chart'

import {
  getStandards,
  StandardsResult,
  getStandardTypes,
  StandardType,
  getMarketRoles,
  MarketRole,
  FiltersRnd,
  Filters,
  getFilters,
  updateFilters,
  updateStandardComment
} from 'api/advertisingApi'
import { marketCharacteristicsApi } from '../../api/marketCharacteristicsApi'
import { ProductCategory } from 'api/categoriesApi'

import { useAuthContext } from 'components/AuthContext'
import { DataTable } from 'components/DataTable'
import { useLayoutContext } from 'components/LayoutContext'
import { CommentForm } from 'components/CommentForm'
import { useTranslationContext } from 'components/TranslationContext'
import { useQueryParams } from '../../hooks/queryParams'
import { Rnd, isRnd, AdvertisingPageQueryParams } from '../../types/tabsCommon'
import { formatNumber } from 'helpers/formatNumber'

interface State extends Partial<AdvertisingPageQueryParams> {
  filters?: Filters,
}

export function StandardsPage () {
  const { startContentLoading, finishContentLoading } = useLayoutContext()
  const { handleResponseFailure, handleResponseSuccess } = useAuthContext()
  const { translation } = useTranslationContext()
  const [collection, setCollection] = useState<StandardsResult | null>()
  const [showComment, setShowComment] = useState(true)
  const { queryParams, setQueryParams } = useQueryParams()

  const [productCategories, setProductCategories] = useState<ProductCategory[]>()
  const [marketRoles, setMarketRoles] = useState<MarketRole[]>()
  const [types, setTypes] = useState<StandardType[]>()

  const getStateFromQuery = (): State => {
    const categoryId = queryParams.get('categoryId')
    const marketRoleId = queryParams.get('marketRoleId')
    const type = queryParams.get('type')
    const rnd = queryParams.get('rnd')

    const result:State = {}

    if (categoryId !== null) {
      result.categoryId = parseInt(categoryId)
    }

    if (marketRoleId !== null) {
      result.marketRoleId = parseInt(marketRoleId)
    }

    if (type !== null) {
      result.type = type
    }

    if (isRnd(rnd)) {
      result.rnd = rnd
    }

    return result
  }

  const [state, setState] = useState<State>(getStateFromQuery())

  useEffect(() => {
    (async () => {
      try {
        const categories = await marketCharacteristicsApi.getProductCategories()
        setProductCategories(categories.productCategoryArr)
        const marketRoles = await getMarketRoles()
        setMarketRoles(marketRoles)
        const types = await getStandardTypes()
        setTypes(types)
      } catch (error) {
        handleResponseFailure(error as any)
      }
    })()
  }, [handleResponseFailure])

  const setCategoryId = useCallback(async (categoryId: number, oldState: State = {}) => {
    try {
      const filters = await getFilters(categoryId)
      const params: Partial<AdvertisingPageQueryParams> = {
        categoryId,
        marketRoleId: filters.marketRoleId,
        type: filters.type,
        ...oldState
      }

      if (filters.rnd !== FiltersRnd.hidden) {
        params.rnd = filters.rnd
      }

      params.categoryId = categoryId

      setState({ ...params, categoryId, filters })
      setQueryParams(params)
    } catch (error) {
      handleResponseFailure(error as any)
    }
  }, [setQueryParams, handleResponseFailure])

  useEffect(() => {
    if (state.categoryId === undefined && productCategories !== undefined) {
      setCategoryId(productCategories[0].id)
    } else if (state.categoryId !== undefined && state.filters === undefined) {
      setCategoryId(state.categoryId, state)
    }
  }, [handleResponseFailure, setCategoryId, state, productCategories])

  useEffect(() => {
    const { filters } = state

    if (productCategories === undefined || filters === undefined) {
      return
    }

    const {
      categoryId = productCategories[0].id,
      marketRoleId = filters.marketRoleId,
      type = filters.type,
      rnd = filters.rnd
    } = state

    startContentLoading()
    setShowComment(false)

    ;(async () => {
      try {
        const collection = await getStandards({
          categoryId,
          marketRoleId,
          type,
          rnd: rnd === FiltersRnd.hidden ? Rnd.without : rnd
        })
        setCollection(collection)
      } catch (error) {
        handleResponseFailure(error as any)
      } finally {
        finishContentLoading()
        setShowComment(true)
      }
    })()
  }, [
    startContentLoading,
    handleResponseFailure,
    finishContentLoading,
    productCategories,
    state
  ])

  if (!collection || !productCategories || !marketRoles || !types || !state.filters) {
    return null
  }

  const handleCategoriesFilterChange: SelectProps['onChange'] = async (e) => {
    const { value } = e.target
    setCategoryId(value as number)
  }

  const handleMarketRolesFilterChange: SelectProps['onChange'] = (e) => {
    const { value } = e.target
    setQueryParams<Partial<AdvertisingPageQueryParams>>({ marketRoleId: value as number })
    setState({ ...state, marketRoleId: value as number })
  }

  const handleTypesFilterChange: SelectProps['onChange'] = (e) => {
    const { value } = e.target
    setQueryParams<Partial<AdvertisingPageQueryParams>>({ type: value as string })
    setState({ ...state, type: value as string })
  }

  const handleRndFilterChange = (event: React.MouseEvent<HTMLElement>, rnd: unknown) => {
    if (isRnd(rnd)) {
      setQueryParams<Partial<AdvertisingPageQueryParams>>({ rnd })
      setState({ ...state, rnd })
    }
  }

  const handleCommentChange = async (comment: string): Promise<void> => {
    try {
      await updateStandardComment(comment)
      handleResponseSuccess()
    } catch (error) {
      handleResponseFailure(error as any)
    }
  }

  const isFiltersChanged = (): boolean => {
    const { marketRoleId, type, rnd, filters } = state

    return filters !== undefined && (
      (marketRoleId !== undefined && marketRoleId !== filters.marketRoleId) ||
      (type !== undefined && type !== filters.type) ||
      (filters.rnd !== FiltersRnd.hidden && rnd !== undefined && rnd !== filters.rnd)
    )
  }

  const handleUpdateFilters = async () => {
    const { categoryId, marketRoleId, type, rnd, filters } = state

    if (productCategories === undefined || filters === undefined) {
      return
    }

    const newFilters: Filters = {
      marketRoleId: marketRoleId === undefined ? filters.marketRoleId : marketRoleId,
      type: type === undefined ? filters.type : type,
      rnd: filters.rnd === FiltersRnd.hidden || rnd === undefined ? filters.rnd : rnd
    }

    setState({ ...state, filters: newFilters })

    try {
      await updateFilters(categoryId === undefined ? productCategories[0].id : categoryId, { ...newFilters, rnd: rnd === Rnd.with })
      handleResponseSuccess()
    } catch (error) {
      handleResponseFailure(error as any)
    }
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} style={{ display: 'flex', justifyContent: 'left' }}>
        <Grid md={4}>
          <FormControl variant="outlined" fullWidth>
            <Select
              value={state.categoryId === undefined ? productCategories[0].id : state.categoryId}
              onChange={handleCategoriesFilterChange}
              MenuProps={{
                MenuListProps: { disablePadding: true }
              }}
            >
              { productCategories.map(({ id, categoryName }) => (
                <MenuItem key={id} value={id}>
                  <Typography variant="inherit" noWrap>
                    {categoryName}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              { state.filters.rnd !== FiltersRnd.hidden &&
                <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                  <ToggleButtonGroup
                    value={state.rnd === undefined ? state.filters.rnd : state.rnd}
                    onChange={handleRndFilterChange}
                    exclusive
                  >
                    <ToggleButton value={Rnd.without}>
                      {translation['pages.AdvertisingPage.rnd_switch.without']}
                    </ToggleButton>

                    <ToggleButton value={Rnd.with}>
                      {translation['pages.AdvertisingPage.rnd_switch.with']}
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              }

              <Grid item xs={12} md={4}>
                <FormControl variant="outlined" fullWidth>
                  <Select
                    value={state.type === undefined ? state.filters.type : state.type}
                    onChange={handleTypesFilterChange}
                    MenuProps={{
                      MenuListProps: { disablePadding: true }
                    }}
                  >
                    { types.map(({ id, name }) => (
                      <MenuItem key={id} value={id}>
                        <Typography variant="inherit" noWrap>
                          {name}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl variant="outlined" fullWidth>
                  <Select
                    value={state.marketRoleId === undefined ? state.filters.marketRoleId : state.marketRoleId}
                    onChange={handleMarketRolesFilterChange}
                    MenuProps={{
                      MenuListProps: { disablePadding: true }
                    }}
                  >
                    { marketRoles.map(({ id, name }) => (
                      <MenuItem key={id} value={id}>
                        <Typography variant="inherit" noWrap>
                          {name}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleUpdateFilters}
                  disabled={!isFiltersChanged()}
                >
                  {translation['pages.AdvertisingPage.button_save_filters']}
                </Button>
              </Grid>

              <Grid item xs={12} md={6}>
                { collection.tab && <DataTable
                  title={collection.tab.title}
                  description={collection.tab.description}
                  data={collection.tab.grid}
                />}
              </Grid>

              <Grid item xs={12} md={6}>
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item>
                      <Typography variant="h6" paragraph>
                        {collection.pie.title}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>

                <Chart
                  height={340}
                  data={normalizeChartData(Object.keys(collection.pie.legend ?? []).map(el => ({
                    name: collection.pie.legend[el],
                    value: collection.pie.chart[0][el]
                  })))}
                  legend={Object.entries(collection.pie.legend ?? []).map(([key, label], idx) => ({
                    label,
                    color: getColorByIdx(idx, undefined, ChartVariants.AdvertisingPie),
                    footnote: key === 'adv'
                  }))}
                  pieFootnote={mapCollectionToFootnote(collection)}
                >
                  <PieSeries
                    argumentField="name"
                    valueField="value"
                    color={index => getColorByIdx(index, undefined, ChartVariants.AdvertisingPie)}
                    label={value => formatNumber(value, { maximumFractionDigits: 2 }) + '%'}
                  />
                </Chart>

              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        {showComment && <CommentForm
          label={collection.comment.title}
          value={collection.comment.comment}
          onChange={handleCommentChange}
        />}
      </Grid>
    </Grid>
  )
}
