import { Box, Tooltip } from '@material-ui/core'
import { ChartInterface } from 'api/pricingApi'
import { formatNumber } from 'helpers/formatNumber'
import { Info } from '@material-ui/icons'

export interface Params {
  chart: ChartInterface
  minPercent?: number
  maxPercent?: number
  up?: string[]
}

export function GradientGraph ({ chart, minPercent = 0, maxPercent = 100, up = [] }: Params) {
  const [{ argument, ...values }] = chart.chart

  const data: {
    key: string,
    price: number,
    priceText: string,
    percent: number,
    label: string,
    info: string,
    up: boolean,
  }[] = []

  for (const [key, price] of Object.entries(values)) {
    const label = chart.legend[key]
    const info = chart.legend[`${key}:info`]

    if (typeof price === 'number' && label !== undefined && info !== undefined) {
      data.push({
        key,
        price,
        priceText: formatNumber(price, { maximumFractionDigits: 2 }) + ' ' + chart.units.leftAxis,
        percent: 0,
        label,
        info,
        up: up.includes(key)
      })
    }
  }

  if (data.length === 0) {
    return <></>
  }

  data.sort((a, b) => a.price - b.price)
  const minPrice = data[0].price
  const maxPrice = data[data.length - 1].price

  for (const item of data) {
    item.percent = (item.price - minPrice) * (maxPercent - minPercent) / (maxPrice - minPrice) + minPercent
  }

  return <div style={{ padding: '0 100px' }}>
    <svg width="100%" height="230" version="1.1" xmlns="http://www.w3.org/2000/svg" overflow="visible">
      <defs>
        <linearGradient id="linearGradientStops">
          <stop style={{ stopColor: '#640a83' }} offset="0%"/>
          <stop style={{ stopColor: '#0803f0' }} offset="20%"/>
          <stop style={{ stopColor: '#08fdff' }} offset="40%"/>
          <stop style={{ stopColor: '#39fe00' }} offset="60%"/>
          <stop style={{ stopColor: '#fffe0b' }} offset="80%"/>
          <stop style={{ stopColor: '#f1040a' }} offset="100%"/>
        </linearGradient>
      </defs>
      <rect x="0" y="100" width="100%" height="30" fill="url(#linearGradientStops)"/>
      {data.map(({ priceText, percent, label, info, up }, idx) => <>
        <line x1={percent + '%'} y1={up ? 80 : 100} x2={percent + '%'} y2={up ? 130 : 150} stroke="#888" />
        <g style={{ transform: 'translate(-100px)' }}>
          <foreignObject x={percent + '%'} y={up ? -120 : 150} width="200" height="200" overflow="visible">

            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
              <div style={{ display: 'flex', flexFlow: up ? 'column-reverse' : 'column', marginTop: up ? 'auto' : '0' }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', margin: '0.2em 0' }}>
                  <Box sx={{ border: '1px solid #888', borderRadius: '3px', padding: '0.6em', position: 'relative' }}>
                    {priceText}
                    <Box clone sx={{ position: 'absolute', top: '-0.5em' }}>
                      <Tooltip title={<h2 style={{ color: 'white' }}>{info}</h2>}>
                        <Info fontSize="small" color="primary" />
                      </Tooltip>
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ textAlign: 'center', margin: '0.5em' }}>
                  {label}
                </Box>
              </div>
            </Box>

          </foreignObject>
        </g>
      </>)}
    </svg>
  </div>
}
