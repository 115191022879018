import React, { useState } from 'react'
import { Formik, Form, Field } from 'formik'
import { TextField } from 'formik-material-ui'
import { Box, IconButton } from '@material-ui/core'
import { useTranslationContext } from 'components/TranslationContext'
import { LoadingButton } from 'components/LoadingButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import { Visibility, VisibilityOff } from '@material-ui/icons'

interface Values {
  newPassword: string
  newPasswordConfirm: string
}

interface ChangePasswordFormProps {
  autoFocus?: boolean;
  onSubmit: (newPassword: string) => void
}

export function ChangePasswordForm ({ autoFocus = true, onSubmit }: ChangePasswordFormProps) {
  const { translation } = useTranslationContext()
  const [showPass, setShowPass] = useState(false)
  const initialValues: Values = { newPassword: '', newPasswordConfirm: '' }
  const validate = ({ newPassword, newPasswordConfirm }: Values) => {
    const errors: Partial<Values> = {}

    if (!newPassword) {
      errors.newPassword = translation['components.ChangePasswordForm.enter_new_password']
    }

    if (!newPasswordConfirm) {
      errors.newPasswordConfirm = translation['components.ChangePasswordForm.confirm_new_password']
    } else if (newPassword !== newPasswordConfirm) {
      errors.newPasswordConfirm = translation['components.ChangePasswordForm.passwords_are_different']
    }

    return errors
  }
  const handleSubmit = ({ newPassword }: Values) => {
    return onSubmit(newPassword)
  }

  return (
    <Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit}>
      {({ isSubmitting }) => (
        <Form>
          <Field
            component={TextField}
            name="newPassword"
            label={translation['menu.profile.new_password']}
            type={showPass ? 'text' : 'password'}
            variant="outlined"
            fullWidth
            margin="normal"
            autoComplete="new-password"
            autoFocus={autoFocus}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => {
                      setShowPass(!showPass)
                    }}
                  >
                    {showPass ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />

          <Field
            component={TextField}
            name="newPasswordConfirm"
            label={translation['menu.profile.new_password']}
            type={showPass ? 'text' : 'password'}
            variant="outlined"
            fullWidth
            autoComplete="new-password"
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => {
                      setShowPass(!showPass)
                    }}
                  >
                    {showPass ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />

          <Box mt={2} textAlign="right">
            <LoadingButton
              type="submit"
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              isLoading={isSubmitting}
            >
              {translation['menu.profile.change_password']}
            </LoadingButton>
          </Box>
        </Form>
      )}
    </Formik>
  )
}
