import React from 'react'
import { Button, Menu, MenuItem } from '@material-ui/core'
import { useTranslationContext } from 'components/TranslationContext'
import { Language } from 'api/translationApi'

export function LayoutLanguage () {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
  const { language, changeLanguage } = useTranslationContext()

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleItemClick = (language: Language) => () => {
    changeLanguage(language)
    handleClose()
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <Button color="default" onClick={handleButtonClick}>
        {language}
      </Button>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleItemClick('ru')}>RU</MenuItem>
        <MenuItem onClick={handleItemClick('en')}>EN</MenuItem>
      </Menu>
    </div>
  )
}
