import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { useAuthContext } from '../../components/AuthContext'
import { marketStandartApi } from '../../api/marketStandartApi'
import { OutletsPurposes, Table } from '../../api/types'
import { useQueryParams } from '../../hooks/queryParams'
import { Box, Button, FormControl, Grid, MenuItem, Select, SelectProps } from '@material-ui/core'
import { StyledTypography } from '../../components/StyledTypography'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import { DataTable } from '../../components/DataTable'
import { useTranslationContext } from '../../components/TranslationContext'
import { TabInterface } from './TabInterface'
import { CloudDownload } from '@material-ui/icons'
import { useLocalStorage } from '../../helpers/useLocalStorage'

interface PageQueryParams {
  categoryId: number | unknown
}

const OUTLET_SALES_STANDARDS_USER_CATEGORY = 'OutletSalesStandardsRosstat.category'
const OUTLET_SALES_STANDARDS_USER_REGION = 'OutletSalesStandardsRosstat.region'
const OUTLET_SALES_STANDARDS_USER_PURPOSE = 'OutletSalesStandardsRosstat.purpose'

export function RosstatTab ({ productCategories, userRegions, personalClassification, standardRussiaRegions }: TabInterface) {
  const { translation } = useTranslationContext()
  const [userCategory, setUserCategory] = useLocalStorage(OUTLET_SALES_STANDARDS_USER_CATEGORY)
  const [userRegion, setUserRegion] = useLocalStorage(OUTLET_SALES_STANDARDS_USER_REGION)
  const [userPurpose, setUserPurpose] = useLocalStorage(OUTLET_SALES_STANDARDS_USER_PURPOSE)
  const { queryParams, setQueryParams } = useQueryParams()
  const { handleResponseFailure, selectedCountry, currentCompany } = useAuthContext()
  const [outletsPurposesRosstat, setOutletsPurposesRosstat] = useState<OutletsPurposes>()
  const [outletsSalesStandardTab, setOutletsSalesStandardTab] = useState<Table | null>()
  const [outletPurposeId, setOutletPurposeId] = useState<number | null>(Number(userPurpose) || null)
  const [categoryId, setCategoryId] = useState<string | null>(
    queryParams.has('categoryId')
      ? queryParams.get('categoryId')
      : (userCategory as unknown) as string || ''
  )
  const [regionId, setRegionId] = useState<string | null>(userRegion || null)

  const trans = useCallback((message: string) => translation[`pages.OutletSalesPage.${message}`], [translation])

  useEffect(() => {
    if (!outletsPurposesRosstat) {
      marketStandartApi.getOutletsPurposesRosstat()
        .then((result: OutletsPurposes) => {
          setOutletsPurposesRosstat(result)
        })
        .catch(handleResponseFailure)
    }
  }, [outletsPurposesRosstat, handleResponseFailure])

  useEffect(() => {
    if (categoryId && regionId && outletPurposeId) {
      marketStandartApi.getOutletSalesStandardsTabStat({
        categoryId: Number(categoryId),
        regionId: regionId === 'others' ? regionId : Number(regionId),
        purposeId: outletPurposeId
      })
        .then(setOutletsSalesStandardTab)
        .catch(handleResponseFailure)
    }
  }, [handleResponseFailure, categoryId, regionId, outletPurposeId, personalClassification])

  const handleOutletPurposesFilterChange: SelectProps['onChange'] = (e) => {
    const { value } = e.target
    setUserPurpose(value as string)
    setOutletPurposeId(value as number)
  }

  const handleCategoriesFilterChange: SelectProps['onChange'] = (e) => {
    const { value } = e.target
    setQueryParams<PageQueryParams>({ categoryId: value })
    setUserCategory(e.target.value as string)
    setCategoryId(value as string)
  }

  if (!outletsPurposesRosstat || !currentCompany) {
    return null
  }
  return (
    <Fragment>
      <Box pb={2}>
        <Grid container spacing={3}>
          <Grid item md={4}>
            <FormControl variant="outlined" fullWidth>
              <StyledTypography variant="h5">{trans('choose_category')}</StyledTypography>
              <Select
                value={categoryId}
                onChange={handleCategoriesFilterChange}
                MenuProps={{ MenuListProps: { disablePadding: true } }}
                displayEmpty
              >
                <MenuItem value="" disabled>{trans('choose_category')}</MenuItem>
                {
                  productCategories.productCategoryArr.map(el => <MenuItem value={el.id}>{el.categoryName}</MenuItem>)
                }
              </Select>
            </FormControl>
          </Grid>

        </Grid>
      </Box>

      <Box py={2}>
        <Grid container spacing={3}>
          <Grid item md={4} xs={12}>
            <FormControl variant="outlined" fullWidth>
              <StyledTypography variant="h5">{trans('purpose')}</StyledTypography>
              <Select
                value={outletPurposeId || ''}
                onChange={handleOutletPurposesFilterChange}
                MenuProps={{ MenuListProps: { disablePadding: true } }}
                displayEmpty
              >
                <MenuItem value="" disabled>{trans('choose_purpose')}</MenuItem>
                {
                  outletsPurposesRosstat.names.map((el) => <MenuItem value={el.id}>{el.name}</MenuItem>)
                }
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>

      {userRegions && standardRussiaRegions && <Box py={2}>
        <StyledTypography
          variant="h5">{translation['components.GeographyConsumptionPage.regions']} {personalClassification ? currentCompany.name : selectedCountry!.shortName!}</StyledTypography>
        <ToggleButtonGroup
          exclusive
          value={regionId}
          onChange={(event: React.MouseEvent<HTMLElement>, value) => {
            if (value) {
              setUserRegion(value)
              setRegionId(value)
            }
          }}
        >
          {(personalClassification ? userRegions : standardRussiaRegions).items.map((el, idx) => <ToggleButton
            key={idx.toString()} value={el.id.toString()}>{el.name}</ToggleButton>)}
          <ToggleButton value={'others'}>{translation['components.GeographyConsumptionPage.others']}</ToggleButton>
        </ToggleButtonGroup>
      </Box>}
      {
        categoryId && regionId && outletPurposeId && outletsPurposesRosstat &&
        <Box pb={3} display='flex' flexDirection='row' justifyContent='flex-end'>
          <Button
            style={{ marginLeft: 3 }}
            onClick={() => {
              if (categoryId && regionId && outletPurposeId && outletsPurposesRosstat) {
                marketStandartApi.downloadOutletSalesTabStat({
                  categoryId: Number(categoryId),
                  regionId: regionId === 'others' ? regionId : Number(regionId),
                  purposeId: outletPurposeId
                })
              }
            }}
            variant='contained'
            color='primary'
            startIcon={<CloudDownload />}
          >
            {trans('download')}
          </Button>
        </Box>
      }
      { outletsSalesStandardTab && <Grid item xs={12}>
        <DataTable
          title={outletsSalesStandardTab.title}
          data={outletsSalesStandardTab.grid}
          hint={outletsSalesStandardTab.description}
          showYear
        />
      </Grid>}
    </Fragment>
  )
}
