import React, { CSSProperties, useCallback, useEffect, useState } from 'react'
import { Button, Dialog, Grid } from '@material-ui/core'
import { useLayoutContext } from 'components/LayoutContext'
import { useAuthContext } from 'components/AuthContext'
import { useCategoriesContext } from 'components/CategoriesContext'
import { Marker } from 'components/Marker'
import { AdvBudgetChart } from 'components/AdvBudgetChart'
import { DataTable, CellClickHandler } from 'components/DataTable'
import { CommentForm } from 'components/CommentForm'
import { categoriesApi, MarketingCollection, MarketingEndpoints } from 'api/categoriesApi'
import { Table } from 'api/types'
import calculateColumnsWidth from '../../utils/calculateColumnsWidth'
import { MarketingBudgetEditableDialog } from 'components/MarketingBudgetEditableDialog'
import { useTranslationContext } from 'components/TranslationContext'

export function MarketingBudgetPage () {
  const [collection, setCollection] = useState<MarketingCollection>()
  const { currentCategory } = useCategoriesContext()
  const { startContentLoading, finishContentLoading } = useLayoutContext()
  const { handleResponseFailure, handleResponseSuccess } = useAuthContext()
  const [isEnableDialogOpen, setEnableDialogOpen] = useState<boolean>(false)
  const [enableDialogDescription, setEnableDialogDescription] = useState<string>()
  const [isEnablingEdit, setEnablingEdit] = useState<boolean>(false)
  const [collectionUpdateTick, setCollectionUpdateTick] = useState(1)
  const { translation } = useTranslationContext()

  const getCollection = useCallback((endpoints?: string[]) => {
    if (currentCategory && collectionUpdateTick) {
      startContentLoading()

      ;(async () => {
        try {
          const result = await categoriesApi.getMarketingCollection(currentCategory.id)
          setCollection(result)
        } catch (error) {
          handleResponseFailure(error as any)
        } finally {
          finishContentLoading()
        }
      })()
    }
  }, [currentCategory, collectionUpdateTick, startContentLoading, finishContentLoading, handleResponseFailure])

  useEffect(() => {
    getCollection()
  }, [getCollection])

  if (!currentCategory || !collection) {
    return null
  }

  const handleTableDependenciesUpdate = (endpoint: MarketingEndpoints) => (table: Table) => {
    setCollection({ ...collection, [endpoint]: table })
    handleResponseSuccess()
    return getCollection(table.dependencies)
  }

  const handleAbsoluteTableUpdate = (grid: Table['grid']) => {
    categoriesApi.updateMarketingAbsoluteTable(currentCategory.id, grid)
      .then(handleTableDependenciesUpdate(MarketingEndpoints.ABSOLUTE_TABLE))
      .catch(handleResponseFailure)
  }
  const handleRelativeTableUpdate = (grid: Table['grid']) => {
    categoriesApi.updateMarketingRelativeTable(currentCategory.id, grid)
      .then(handleTableDependenciesUpdate(MarketingEndpoints.RELATIVE_TABLE))
      .catch(handleResponseFailure)
  }
  const handleCommentChange = (comment: string) => {
    categoriesApi.updateMarketingComment(currentCategory.id, comment)
      .then(handleResponseSuccess)
      .catch(handleResponseFailure)
  }

  const isEditable = (): boolean => {
    if (!collection) {
      return false
    }

    for (const row of collection[MarketingEndpoints.RELATIVE_TABLE].grid.rows) {
      for (const cell of Object.values(row.cells)) {
        if (cell.isEditable) {
          return true
        }
      }
    }

    return false
  }

  const { columnsWidth } = calculateColumnsWidth(collection[MarketingEndpoints.ABSOLUTE_TABLE].grid.columns.length)

  const childIndicatorStyle = {
    indicator: {
      justifyContent: 'right',
      textAlign: 'right',
      fontStyle: 'italic',
      fontSize: '0.9em'
    }
  } as const

  const totalBudgetStyle: Record<string, CSSProperties> = {}
  const totalBudgetClick: Record<string, CellClickHandler> = {}

  const absoluteGrid = collection[MarketingEndpoints.ABSOLUTE_TABLE].grid
  const totalBudgetRow = absoluteGrid.rows.find(row => row.rowId === 'totalBudget')

  if (totalBudgetRow !== undefined && !isEditable()) {
    for (const column of absoluteGrid.columns) {
      const { error } = totalBudgetRow.cells[column.field]

      if (!error) {
        continue
      }

      totalBudgetStyle[column.field] = {
        textDecoration: 'underline',
        textDecorationStyle: 'dotted',
        cursor: 'pointer'
      }

      totalBudgetClick[column.field] = (row, column) => {
        setEnableDialogOpen(true)
        setEnableDialogDescription(error)
      }
    }
  }

  const hideEnableDialog = () => {
    setEnableDialogOpen(false)
    setEnableDialogDescription(undefined)
  }

  const handleSetEditable = async (enable: boolean) => {
    setEnablingEdit(true)
    try {
      await categoriesApi.setMarketingEditable(currentCategory.id, enable)
    } catch (error) {
      handleResponseFailure(error as any)
    }
    setCollectionUpdateTick(collectionUpdateTick % 2 + 1)
    setEnableDialogOpen(false)
    setEnablingEdit(false)
  }

  return (
    <>
      <Grid container spacing={3}>

        <Grid item xs={12} md={3} sm={6}>
          <Marker {...collection[MarketingEndpoints.MARKET_TOTAL_BUDGET]} />
        </Grid>

        <Grid item xs={12} md={3} sm={6}>
          <Marker {...collection[MarketingEndpoints.MARKER_ADVERTISING]} />
        </Grid>

        <Grid item xs={12} md={3} sm={6}>
          <Marker {...collection[MarketingEndpoints.MARKER_MARGIN]} />
        </Grid>

        <Grid item xs={12}>
          <AdvBudgetChart
            absolute={collection[MarketingEndpoints.ABSOLUTE_CHART]}
            relative={collection[MarketingEndpoints.RELATIVE_CHART]}
          />
        </Grid>

        <Grid item xs={12}>
          <DataTable
            title={collection[MarketingEndpoints.ABSOLUTE_TABLE].title}
            description={collection[MarketingEndpoints.ABSOLUTE_TABLE].description}
            data={absoluteGrid}
            onDataUpdate={handleAbsoluteTableUpdate}
            columnsWidth={columnsWidth}
            cellStyle={{
              type_01_online: childIndicatorStyle,
              type_01_offline: childIndicatorStyle,
              totalBudget: totalBudgetStyle
            }}
            cellClick={{
              totalBudget: totalBudgetClick
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <DataTable
            title={collection[MarketingEndpoints.RELATIVE_TABLE].title}
            description={collection[MarketingEndpoints.RELATIVE_TABLE].description}
            data={collection[MarketingEndpoints.RELATIVE_TABLE].grid}
            onDataUpdate={handleRelativeTableUpdate}
            columnsWidth={columnsWidth}
            cellStyle={{
              type_01_online: childIndicatorStyle,
              type_01_offline: childIndicatorStyle
            }}
          />
        </Grid>

        {isEditable() && <Grid item xs={12}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleSetEditable(false)}
            disabled={isEnablingEdit}
          >{translation['components.MarketingBudgetEnableDialog.cancel_button']}</Button>
        </Grid>}

        <Grid item xs={12}>
          <CommentForm
            label={collection[MarketingEndpoints.COMMENT].title}
            value={collection[MarketingEndpoints.COMMENT].comment}
            onChange={handleCommentChange}
          />
        </Grid>
      </Grid>

      <Dialog open={isEnableDialogOpen} onClose={hideEnableDialog}>
        <MarketingBudgetEditableDialog yes={() => handleSetEditable(true)} no={hideEnableDialog} waiting={isEnablingEdit} description={enableDialogDescription}/>
      </Dialog>
    </>
  )
}
