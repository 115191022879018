import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid, InputLabel, MenuItem, Paper, Radio,
  RadioGroup, Select, Table, TableHead, TableBody, TableCell, TableContainer, TableRow,
  Typography, Checkbox,
  Menu
} from '@material-ui/core'
import { Page } from 'components/Page'
import { CustomRegionsTable } from '../../components/CustomRegionsTable'
import { CurrentSubjectSelection, Region, Regions, Subject } from '../../api/types'
import { commonInfoApi } from '../../api/commonInfoApi'
import { useAuthContext } from '../../components/AuthContext'
import { countriesApi } from '../../api/countriesApi'
import { marketCharacteristicsApi, ProductCategories } from '../../api/marketCharacteristicsApi'
import { geographyApi } from '../../api/geographyApi'
import { useSnackbar } from 'notistack'
import { companiesApi, Company } from '../../api/companiesApi'
import { useTranslationContext } from '../../components/TranslationContext'
import clsx from 'clsx'
import CheckIcon from '@material-ui/icons/Check'
import { makeStyles } from '@material-ui/core/styles'
import { useLocalStorage } from '../../helpers/useLocalStorage'
import { ISalesGeographyAnswers } from './ISalesGeographyAnswers'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: 55,
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  icon: {
    borderRadius: 5,
    width: '100%',
    height: 55,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: 'inset 0 0 0 1.5px rgba(16,22,26,.2), inset 0 -2px 0 rgba(16,22,26,.1)',
    backgroundColor: '#ffffff',
    'input:hover ~ &': {
      backgroundColor: 'rgba(225,244,251)'
    }
  },
  checkedIcon: {
    backgroundColor: 'rgba(225,244,251)',
    boxShadow: 'none'
  }
})
interface SalesGeographyConsumption {
  subject: number
  value: number
}

const GEOGRAPHY_SALES_USER_CATEGORY = 'geographySales.category'
const GEOGRAPHY_SALES_USER_REGION = 'geographySales.region'

export function GeographySalesPage () {
  const { translation } = useTranslationContext()
  const [userCategory, setUserCategory] = useLocalStorage(GEOGRAPHY_SALES_USER_CATEGORY)
  const [userRegion, setUserRegion] = useLocalStorage(GEOGRAPHY_SALES_USER_REGION)
  const { handleResponseSuccess, handleResponseFailure, selectedCountry } = useAuthContext()
  const [firstAnswer, setFirstAnswer] = useState<string | null>(null)
  const [secondAnswer, setSecondAnswer] = useState<string | null>(null)
  const [userRegions, setUserRegions] = useState<Regions | null>(null)
  const [standardRussiaRegions, setStandardRussiaRegions] = useState<Regions | null>(null)
  const [productCategories, setProductCategories] = useState<ProductCategories>()
  const [productCategoryId, setProductCategoryId] = useState<number | null>((userCategory as unknown) as number || null)
  const [standardRussiaRegionId, setStandardRussiaRegionId] = useState<number | null>(userRegion !== null && userRegion.match(/^\d{1,16}$/) ? Number.parseInt(userRegion) : null)
  const [subjectList, setSubjectList] = useState<Subject[]>()
  const [salesGeography, setSalesGeography] = useState<CurrentSubjectSelection[]>()
  const [salesGeographyConsumption, setSalesGeographyConsumption] = useState(null)
  const [currentSubjectSelection, setCurrentSubjectSelection] = useState<CurrentSubjectSelection[]>([])
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()
  const [currentCompany, setCurrentCompany] = useState<Company>()
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

  useEffect(() => {
    if (!currentCompany) {
      companiesApi.getCurrentCompany()
        .then(setCurrentCompany)
        .catch(handleResponseFailure)
    }
  }, [currentCompany, handleResponseFailure])

  useEffect(() => {
    if (!salesGeographyConsumption) {
      geographyApi.getSalesGeographyConsumption()
        .then(response => setSalesGeographyConsumption(response))
    }
  }, [salesGeographyConsumption])

  useEffect(() => {
    if (!productCategories) {
      marketCharacteristicsApi.getProductCategories()
        .then(setProductCategories)
        .catch(handleResponseFailure)
    }

    if (!salesGeography) {
      geographyApi.getSalesGeography()
        .then(response => setSalesGeography(response))
    }

    if (!subjectList) {
      countriesApi.getSubjectList()
        .then((subjects) => {
          setSubjectList(subjects)
        })
    }
  }, [handleResponseFailure, subjectList, productCategories, salesGeography])

  useEffect(() => {
    if (!standardRussiaRegions) {
      countriesApi.getRegionList()
        .then((items : Region[]) => {
          setStandardRussiaRegions({ items })
        })
    }
  }, [standardRussiaRegions])

  useEffect(() => {
    if (!currentCompany) {
      return
    }

    (async () => {
      const { divisionBySubject, personalClassification }: ISalesGeographyAnswers = await commonInfoApi.getSalesGeographyAnswers()
      const firstAnswer = divisionBySubject ? 'yes' : 'no'
      const secondAnswer = personalClassification ? 'yes' : 'no'

      setFirstAnswer(firstAnswer)
      setSecondAnswer(secondAnswer)

      if (personalClassification) {
        const items = await countriesApi.getCustomRegions()
        setUserRegions({ items })
      } else {
        setUserRegions(standardRussiaRegions)
      }
    })()
  }, [currentCompany, standardRussiaRegions])

  useEffect(() => {
    if (salesGeography && productCategoryId) {
      const currentSubjectSelectionByCategory = salesGeography.filter(el => el.categoryId === Number(productCategoryId))
      setCurrentSubjectSelection(currentSubjectSelectionByCategory)
    }
  }, [productCategoryId, salesGeography])

  if (!currentCompany || !productCategories || !subjectList || !salesGeographyConsumption) {
    return null
  }

  const handleChangeFirstAnswer = (event: React.ChangeEvent<HTMLInputElement>) => {
    const answer = event.target.value as string
    if (currentCompany) {
      commonInfoApi.updateSalesGeographyAnswers({ divisionBySubject: answer === 'yes' })
        .then(() => {
          setFirstAnswer(answer)
        })
    }
  }

  const handleChangeSecondAnswer = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const answer = event.target.value as string

    try {
      if (currentCompany) {
        await commonInfoApi.updateSalesGeographyAnswers({ divisionBySubject: firstAnswer === 'yes', personalClassification: answer === 'yes' })
        setSecondAnswer(answer)
      }

      if (answer === 'yes') {
        const items = await countriesApi.getCustomRegions()
        setUserRegions({ items })
      } else {
        setUserRegions(standardRussiaRegions)
      }

      const response = await geographyApi.resetSalesGeography()
      enqueueSnackbar(translation[answer === 'yes' ? 'components.GeographySalesPage.reset_custom_regions' : 'components.GeographySalesPage.reset_regions'], { variant: 'success' })
      setSalesGeography(response.salesGeographyArr)
    } catch (err) {
      handleResponseFailure(err as any)
    }
  }

  const handleChangeCustomRegion = (subject : Subject, customRegion?: Region) => {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      const checked = event.target.checked
      const currentSubjectSelectionReflection = currentSubjectSelection.slice()

      const currentRowIndex = currentSubjectSelectionReflection.findIndex((el) => el.subject === subject.id)

      const newCurrentRowData : CurrentSubjectSelection = {
        region: subject.region.id,
        subject: subject.id
      }

      if (customRegion) {
        newCurrentRowData.customRegion = customRegion.id
      }

      if (currentRowIndex !== -1) {
        newCurrentRowData.id = currentSubjectSelectionReflection[currentRowIndex].id
        currentSubjectSelectionReflection.splice(currentRowIndex, 1)
      }

      if (checked) {
        currentSubjectSelectionReflection.push(newCurrentRowData)
      }

      setCurrentSubjectSelection(currentSubjectSelectionReflection)
    }
  }

  const handleSubmitCustomRegions = async ({ items } : Regions) => {
    try {
      await countriesApi.setCustomRegions(items)
      const newItems = await countriesApi.getCustomRegions()
      setUserRegions({ items: newItems })
      enqueueSnackbar(translation['components.GeographySalesPage.regions_are_saved'], { variant: 'success' })

      if (salesGeography && salesGeography.length > 0) {
        // const response = await geographyApi.resetSalesGeography()
        // enqueueSnackbar(translation['components.GeographySalesPage.reset_custom_regions'], { variant: 'success' })
      }
      const response = await geographyApi.getSalesGeography()
      setSalesGeography(response.salesGeographyArr)
    } catch (err) {
      handleResponseFailure(err as any)
    }
  }

  // @ts-ignore
  const consumptionByCategory : SalesGeographyConsumption[] = salesGeographyConsumption && Number(productCategoryId) ? salesGeographyConsumption[Number(productCategoryId)] : null

  return (
    <Page title={translation['components.GeographyPage.title']}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <Typography variant="h6" gutterBottom>
                1. {translation['components.GeographySalesPage.first_question']}
              </Typography>
              <RadioGroup value={firstAnswer} onChange={handleChangeFirstAnswer}>
                <FormControlLabel value='yes' control={<Radio color="primary" />} label={translation['components.GeographySalesPage.yes']} />
                <FormControlLabel value='no' control={<Radio color="primary" />} label={translation['components.GeographySalesPage.no']} />
              </RadioGroup>
            </FormControl>
          </Grid>

          {firstAnswer === 'yes' && <Grid item xs={12}>
            <Box pt={3}>
              <FormControl component="fieldset">
                <Box>
                  <Typography variant="h6" gutterBottom>
                    2. {translation['components.GeographySalesPage.second_question']}
                  </Typography>
                </Box>
                <RadioGroup value={secondAnswer} onChange={handleChangeSecondAnswer}>
                  <FormControlLabel value="yes" control={<Radio color="primary"/>} label={translation['components.GeographySalesPage.yes']} />
                  <FormControlLabel value="no" control={<Radio color="primary"/>} label={translation['components.GeographySalesPage.no']} />
                </RadioGroup>
              </FormControl>
            </Box>
          </Grid>}

          { firstAnswer === 'yes' && secondAnswer !== null && <Grid item xs={12}>
            <Box pt={3}>
              <Typography variant="h6" gutterBottom>
                3. {secondAnswer === 'yes' ? translation['components.GeographySalesPage.custom_regions_title'] : translation['components.GeographySalesPage.regions_title']}
              </Typography>
            </Box>
            <Box style={{ maxWidth: 500 }}>
              {<CustomRegionsTable handleSubmit={handleSubmitCustomRegions} regions={userRegions} withoutInputs={secondAnswer !== 'yes'} />}
            </Box>
          </Grid>}

          { firstAnswer === 'yes' && secondAnswer !== null && <Grid item xs={12}>
            <Box pt={3}>
              <Typography variant="h6" gutterBottom>
                4. {translation['components.GeographySalesPage.fourth_question']}
              </Typography>
            </Box>

            <Box style={{ marginBottom: 30 }}>
              <FormControl style={{ minWidth: 250 }}>
                <InputLabel id="demo-simple-select-label">{translation['components.GeographySalesPage.category']}</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={productCategoryId}
                  onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                    setUserCategory(event.target.value as string)
                    setProductCategoryId(event.target.value as number)
                  }}
                >
                  {
                    productCategories.productCategoryArr.map((el, idx) => <MenuItem key={idx.toString()} value={el.id}>{el.categoryName}</MenuItem>)
                  }
                </Select>
              </FormControl>
            </Box>
          </Grid>}
        </Grid>
        <Grid xs={12} md={6}>
          <img
            src='/geography_sales_map.png'
            style={{ width: '100%' }}
            alt='geography sales'
          />
        </Grid>

        {<Grid xs={12}>
          {consumptionByCategory && <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Button style={{ textTransform: 'none' }} color="default" onClick={event => setAnchorEl(event.currentTarget)}>
                      {
                        standardRussiaRegions?.items?.find(({ id }) => id === standardRussiaRegionId)?.name ??
                        <InputLabel id="demo-simple-select-label">{translation['components.GeographySalesPage.choose_region']} {selectedCountry?.shortName!}</InputLabel>
                      }
                      <ArrowDropDownIcon />
                    </Button>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={() => setAnchorEl(null)}
                    >
                      <MenuItem value="" disabled>
                        {translation['components.GeographySalesPage.region']} {selectedCountry?.shortName!}
                      </MenuItem>
                      {standardRussiaRegions && standardRussiaRegions.items.map(({ id, name }, idx) =>
                        <MenuItem
                          key={idx.toString()}
                          value={id}
                          onClick={() => {
                            setUserRegion(`${id}`)
                            setStandardRussiaRegionId(id)
                            setAnchorEl(null)
                          }}
                        >{name}</MenuItem>
                      )}
                    </Menu>
                  </TableCell>
                  <TableCell align="left">{translation['components.GeographySalesPage.subject']}</TableCell>
                  <TableCell align="left">{translation['components.GeographySalesPage.consumption']}</TableCell>
                  {
                    secondAnswer === 'yes' && userRegions ? userRegions.items.map((el, idx) => <TableCell style={{ maxWidth: 90, minWidth: 90, paddingLeft: 16, paddingRight: 16 }} key={idx.toString()} align="center">{el.name}</TableCell>) : <TableCell style={{ width: 90 }} align="center" />
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  productCategoryId && subjectList.filter((subject : Subject) => {
                    return subject.region.id === Number(standardRussiaRegionId)
                  }).map((subject, idx) => {
                    const consumptionBySubject = consumptionByCategory.find(el => el.subject === subject.id)
                    const currentRowInState = currentSubjectSelection.find((el) => el.subject === subject.id)
                    return consumptionBySubject && <TableRow key={idx.toString()}>
                      <TableCell align="left">{subject.region.name}</TableCell>
                      <TableCell align="left">{subject.name}</TableCell>
                      <TableCell align="center">{consumptionBySubject!.value}%</TableCell>
                      {
                        secondAnswer === 'yes' && userRegions ? userRegions.items.map((customRegion, idx) => {
                          const disabled = currentRowInState ? currentRowInState.customRegion !== customRegion.id : false
                          const checked = currentRowInState ? currentRowInState.customRegion === customRegion.id : false

                          return <TableCell key={idx.toString()} align="center">
                            <FormControlLabel
                              style={{ padding: 0, width: '100%', height: 50, marginLeft: 0, marginRight: 0 }}
                              control={
                                <Checkbox
                                  disableRipple
                                  size="medium"
                                  style={{ padding: 0 }}
                                  className={classes.root}
                                  checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} ><CheckIcon fontSize="small" /></span>}
                                  icon={<span className={classes.icon} />}
                                  name={'checkedA' + subject.id + subject.region.id + idx}
                                  color="default"
                                  disabled={disabled}
                                  checked={checked}
                                  value={currentRowInState && currentRowInState.customRegion}
                                  onChange={handleChangeCustomRegion(subject, customRegion)}
                                />
                              }
                              label=''
                            />
                          </TableCell>
                        }) : <TableCell align="center"><FormControl component="fieldset">
                          <FormControlLabel
                            style={{ padding: 0, width: 90 }}
                            control={
                              <Checkbox
                                disableRipple
                                size="medium"
                                style={{ padding: 0, width: '100%', height: 50 }}
                                className={classes.root}
                                checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} ><CheckIcon fontSize="small" /></span>}
                                icon={<span className={classes.icon} />}
                                name="region"
                                color="default"
                                checked={currentRowInState ? currentRowInState.region === subject.region.id : false}
                                onChange={handleChangeCustomRegion(subject)}
                              />
                            }
                            label=''
                          />
                        </FormControl>
                        </TableCell>
                      }
                    </TableRow>
                  })
                }
              </TableBody>
            </Table>
          </TableContainer>}
          <Box style={{ marginTop: 10 }}>
            <Button disabled={!productCategoryId} onClick={() => {
              productCategoryId && geographyApi.updateSalesGeography(productCategoryId, currentSubjectSelection)
                .then(({ salesGeographyArr }) => {
                  setSalesGeography(salesGeographyArr)
                  handleResponseSuccess()
                })
                .catch(handleResponseFailure)
            }} variant="contained" color="primary">
              {translation['components.GeographySalesPage.save_data_for_category']}
            </Button>
          </Box>
        </Grid>}
      </Grid>
    </Page>
  )
}
