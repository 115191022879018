import React, { createContext, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { Language, LANGUAGE_ITEM, defaultLanguage, Translation, translationApi } from 'api/translationApi'
import { useLocalStorage } from 'helpers/useLocalStorage'

interface Context {
  language: Language
  changeLanguage: (language: Language) => void
  translation: Translation
}

const TranslationContext = createContext<Context | null>(null)

export function TranslationContextProvider ({ children }: React.PropsWithChildren<{}>) {
  const [translation, setTranslation] = useState<Translation>()
  const [language, setLanguage] = useLocalStorage(LANGUAGE_ITEM)
  const history = useHistory()
  const changeLanguage = (newLanguage: Language) => {
    if (newLanguage !== language) {
      setLanguage(newLanguage)
      history.go(0)
    }
  }

  useEffect(() => {
    translationApi.getTranslation()
      .then((fetchedTranslation) => {
        setTranslation({ ...fetchedTranslation })
      })
  }, [])

  if (!language) {
    setLanguage(defaultLanguage)
  }

  if (!translation || !language) {
    return null
  }

  const value = {
    language,
    changeLanguage,
    translation
  }

  return (
    <TranslationContext.Provider value={value}>
      {children}
    </TranslationContext.Provider>
  )
}

export function useTranslationContext () {
  const context = useContext(TranslationContext)

  if (!context) {
    throw new Error('Used outside of "TranslationContextProvider"')
  }

  return context
}
