import React from 'react'
import { Page } from 'components/Page'
import { DirectionPage } from './DirectionPage'
import { useTranslationContext } from 'components/TranslationContext'

export function DirectionsPage () {
  const { translation } = useTranslationContext()
  const tabs = [
    {
      label: translation['pages.DirectionsPage.direction1'],
      url: 'direction',
      component: DirectionPage,
      hasInfoOnly: true
    },
    {
      label: translation['pages.DirectionsPage.direction2'],
      url: 'direction',
      disabled: true
    }
  ]
  return (
    <Page title={translation['pages.DirectionsPage.title']} tabs={tabs} />
  )
}
