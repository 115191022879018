import React from 'react'
import { useAuthContext } from 'components/AuthContext'
import { useTranslationContext } from 'components/TranslationContext'
import { Page } from 'components/Page'
import { UsersList } from 'components/UsersList'

export function UserManagementPage () {
  const { currentCompany } = useAuthContext()
  const { translation } = useTranslationContext()

  if (!currentCompany) {
    return null
  }

  return (
    <Page title={translation['menu.company_users_page_header'] + currentCompany.name}>
      <UsersList companyId={currentCompany.id} />
    </Page>
  )
}
