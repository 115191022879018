import React from 'react'
import { Grid } from '@material-ui/core'

export function DirectionPage () {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <img
          src='/sales-direction.jpg'
          style={{ width: '100%' }}
          alt='sales direction preview'
        />
      </Grid>
    </Grid>
  )
}
