import React, { useEffect, useState } from 'react'
import { Form, Formik } from 'formik'
import { Box, Card, CardContent, Grid, MenuItem, Typography } from '@material-ui/core'
import { useAuthContext } from 'components/AuthContext'
import { useLayoutContext } from 'components/LayoutContext'
import { useTranslationContext } from 'components/TranslationContext'
import { TextField } from 'components/TextField'
import { LoadingButton } from 'components/LoadingButton'
import { marketCharacteristicsApi, PriceCoefficients, PriceCoefficientsValues } from 'api/marketCharacteristicsApi'
import { getFieldsValues } from 'helpers/fields'

const vatRates = [
  '18_OR_20_PERCENTS',
  '10_PERCENTS',
  '0_PERCENTS'
]

export function PriceCoefficientsPage () {
  const { handleResponseSuccess, handleResponseFailure } = useAuthContext()
  const { startContentLoading, finishContentLoading } = useLayoutContext()
  const { translation } = useTranslationContext()
  const [priceCoefficients, setPriceCoefficients] = useState<PriceCoefficients>()
  const validate = ({ markupCoefficient }: PriceCoefficientsValues) => {
    const errors: Partial<Record<keyof PriceCoefficientsValues, string>> = {}

    if (!markupCoefficient) {
      errors.markupCoefficient = translation['validation.required_field']
    } else if (markupCoefficient <= 0) {
      errors.markupCoefficient = translation['validation.must_be_positive']
    }

    return errors
  }
  const handleSubmit = (values: PriceCoefficientsValues) => {
    return marketCharacteristicsApi.updatePriceCoefficients(values)
      .then(() => handleResponseSuccess())
      .catch(handleResponseFailure)
  }

  useEffect(() => {
    startContentLoading()

    marketCharacteristicsApi.getPriceCoefficients()
      .then(setPriceCoefficients)
      .catch(handleResponseFailure)
      .finally(finishContentLoading)
  }, [finishContentLoading, startContentLoading, handleResponseFailure])

  if (!priceCoefficients) {
    return null
  }

  const initialValues = getFieldsValues<PriceCoefficientsValues>(priceCoefficients.fields)
  const { markupCoefficient, vatRate } = priceCoefficients.fields

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Card>
          <CardContent>
            <Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit}>
              {({ isSubmitting }) => (
                <Form>
                  <TextField
                    name="markupCoefficient"
                    label={markupCoefficient.label}
                    hint={markupCoefficient.hint}
                    type="number"
                    autoFocus
                  />

                  <TextField
                    name="vatRate"
                    label={vatRate.label}
                    hint={vatRate.hint}
                    select
                  >
                    {vatRates.map((key) => (
                      <MenuItem key={key} value={key}>{translation[`price_coefficient.vatRates.${key}`]}</MenuItem>
                    ))}
                  </TextField>

                  <Box mt={2} textAlign="right">
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      color="primary"
                      size="large"
                      disableElevation
                      disabled={isSubmitting}
                      isLoading={isSubmitting}
                    >
                      {translation['form.save']}
                    </LoadingButton>
                  </Box>
                </Form>
              )}
            </Formik>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} md={6}>
        <Card>
          <CardContent>
            <Typography>
              {priceCoefficients.desc}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}
