import { request } from './request'
import { CurrentSubjectSelection, RegionId } from './types'

export function getSalesGeography () {
  return request('sales-geography/')
}

export function getSalesGeographyConsumption () {
  return request('sales-geography/consumption')
}

function getConsumptionGeography (year : number, categoryId : number, classificationType : 'standard' | 'custom', regionIds: RegionId[]) {
  const params = regionIds.map((el) => `regionIds[]=${el}`).join('&')
  const endpoint = `consumption-geography/${year}/${categoryId}/${classificationType}?${params}`
  return request(endpoint)
}

function getConsumptionGeographyComment () {
  return request('consumption-geography/comment')
}

function setConsumptionGeographyComment (comment: string) {
  return request('consumption-geography/comment', 'PUT', { comment })
}

function updateSalesGeography (categoryId: number, salesGeographyArr: CurrentSubjectSelection[]) {
  return request(`sales-geography/${categoryId}`, 'PUT', { salesGeographyArr })
}

function resetSalesGeography () {
  return request('sales-geography/reset', 'POST')
}

export const geographyApi = {
  getSalesGeography,
  getSalesGeographyConsumption,
  updateSalesGeography,
  getConsumptionGeography,
  resetSalesGeography,
  getConsumptionGeographyComment,
  setConsumptionGeographyComment
}
