import { request } from 'api/request'
import { Country } from './types'

export enum UserRole {
  ADMIN = 'ROLE_ADMIN',
  APP_ADMIN = 'ROLE_APP_ADMIN',
  MANAGER = 'ROLE_MANAGER',
  OPERATOR = 'ROLE_OPERATOR',
}

export interface UserEditableFields {
  id: number
  username: string
  position: string
  email: string
}

export interface User extends UserEditableFields {
  role: UserRole
  password?: string,
  showRichNotifications: boolean
  selectedCountry?: Country
}

function get () {
  return request('users')
}

function update (user: Partial<User>) {
  return request(`users/${user.id}`, 'PATCH', user)
}

function create (user: Partial<User>, companyId: number) {
  return request(`users/${companyId}`, 'POST', user)
}

function remove (user: User) {
  return request(`users/${user.id}`, 'DELETE')
}

function getCurrentUser () {
  return request('users/current')
}

function changePassword (userId: User['id'], newPassword: string) {
  return request(`users/change_password/${userId}`, 'PATCH', { password: newPassword })
}

function changePasswordForUser (userId: User['id'], newPassword: string) {
  return request(`users/change_password_for_user/${userId}`, 'PATCH', { password: newPassword })
}

export const usersApi = {
  get,
  update,
  create,
  remove,
  getCurrentUser,
  changePassword,
  changePasswordForUser
}
