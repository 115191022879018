import { request } from 'api/request'
import { Comment, Marker, Table } from 'api/types'
import { Option } from '../helpers/fields'
import { getCategoryEndpoint } from './common'

export interface ProductCategory {
  id: number
  categoryName: string,
  indicator?: any,
  branch?: any
}

function getMenuCategories () {
  return request('menu/categories')
}

export interface IFilter {
  value: unknown[]
  optionGroups: [Option[]]
}

export function getCollection<Collection> (prefix: string, endpoints: string[], filters?: string[] | undefined) {
  const objects = endpoints.map((endpoint) => `${prefix}${endpoint}`)

  return request('collection', 'POST', { filters, objects })
    .then((response: Collection) => (
      Object.entries(response)
        .reduce((acc, [key, value]) => ({
          ...acc,
          [key.replace(prefix, '')]: value
        }), {}) as Collection
    ))
}

export interface ChartData {
  chart: Record<string, string | number>[]
  title: string
  legend: Record<string, string>
  units: {
    leftAxis: string
    rightAxis: string
  }
}

export interface ChartFilter {
  year: number[]
  firstForecastPeriod: string
}

export enum SalesEndpoints {
  'MARKER_SALES' = 'marker_sales',
  'MARKER_PROFIT' = 'marker_profit',
  'MARKER_AVERAGE' = 'marker_average',
  'MARKER_SKU' = 'marker_sku',
  'CHART' = 'graph_common',
  'CHART_AVG' = 'graph_average',
  'MARKET_TABLE' = 'market_tab',
  'SALES_TABLE' = 'sales_tab',
  'KPI_TABLE' = 'kpi_tab',
  'GROWTH_TABLE' = 'increase_tab',
  'COMMENT' = 'comment'
}

export interface SalesCollection {
  [SalesEndpoints.MARKER_SALES]: Marker
  [SalesEndpoints.MARKER_PROFIT]: Marker
  [SalesEndpoints.MARKER_AVERAGE]: Marker
  [SalesEndpoints.MARKER_SKU]: Marker
  [SalesEndpoints.CHART]: ChartData
  [SalesEndpoints.CHART_AVG]: ChartData
  [SalesEndpoints.MARKET_TABLE]: Table
  [SalesEndpoints.SALES_TABLE]: Table
  [SalesEndpoints.KPI_TABLE]: Table
  [SalesEndpoints.GROWTH_TABLE]: Table
  [SalesEndpoints.COMMENT]: Comment
}

function getSalesEndpoint (categoryId: ProductCategory['id']) {
  return getCategoryEndpoint(categoryId, 'sales/')
}

function getSalesCollection (categoryId: ProductCategory['id'], dependencies?: string[]) {
  const prefix = getSalesEndpoint(categoryId)
  const endpoints = dependencies
    ? dependencies.map((dep) => dep.replace(prefix, ''))
    : Object.values(SalesEndpoints)
  return getCollection<SalesCollection>(prefix, endpoints)
}

function updateChart (categoryId: ProductCategory['id'], filter: ChartFilter) {
  return request(`${getSalesEndpoint(categoryId)}graph`, 'PUT', filter)
}

function updateMarketTable (categoryId: ProductCategory['id'], grid: Table['grid']) {
  return request(`${getSalesEndpoint(categoryId)}market_tab`, 'PUT', { grid })
}

function updateSalesTable (categoryId: ProductCategory['id'], grid: Table['grid']) {
  return request(`${getSalesEndpoint(categoryId)}sales_tab`, 'PUT', { grid })
}

function updateSalesKpiTable (categoryId: ProductCategory['id'], grid: Table['grid']) {
  return request(`${getSalesEndpoint(categoryId)}kpi_tab`, 'PUT', { grid })
}

function updateGrowthTable (categoryId: ProductCategory['id'], grid: Table['grid']) {
  return request(`${getSalesEndpoint(categoryId)}increase_tab`, 'PUT', { grid })
}

function updateSalesComment (categoryId: ProductCategory['id'], comment: string) {
  return request(`${getSalesEndpoint(categoryId)}comment`, 'PUT', { comment })
}

function getDistributionProfitEndpoint (categoryId: ProductCategory['id'] | any) {
  return getCategoryEndpoint(categoryId, 'distribution_profit/')
}

export enum DistributionEndpoints {
  'MARKERS' = 'markers',
  'CHART' = 'graph',
  'ABSOLUTE_TABLE' = 'inkind_tab',
  'RELATIVE_TABLE' = 'percent_tab',
  'KPI_TABLE' = 'kpi_tab',
  'COMMENT' = 'comment',
  'MONETARY_TABLE' = 'monetary_tab',
  'PIE_CHART' = 'percent_graph',
  'FILTER' = 'percent_graph_filter'
}

function getDistributionProfitCollection (categoryId: ProductCategory['id']) {
  const prefix = getDistributionProfitEndpoint(categoryId)
  const endpoints = Object.values(DistributionEndpoints)
  return getCollection<DistributionCollection>(prefix, endpoints, ['categories/any/distribution_profit/percent_graph_filter'])
}

function getDistributionFilter () {
  return request('categories/any/distribution/percent_graph_filter')
}

function getDistributionProfitFilter () {
  return request('categories/any/distribution_profit/percent_graph_filter')
}

function updateDistributionFilter (categoryId: ProductCategory['id'], filter: Partial<IFilter>) {
  const prefix = getDistributionProfitEndpoint(categoryId)
  return request(prefix + DistributionEndpoints.FILTER, 'PUT', filter)
}

export interface DistributionCollection {
  [DistributionEndpoints.MARKERS]: Record<number, Marker>
  [DistributionEndpoints.CHART]: ChartData
  [DistributionEndpoints.PIE_CHART]: ChartData
  [DistributionEndpoints.ABSOLUTE_TABLE]?: Table
  [DistributionEndpoints.MONETARY_TABLE]?: Table
  [DistributionEndpoints.RELATIVE_TABLE]: Table
  [DistributionEndpoints.KPI_TABLE]: Table
  [DistributionEndpoints.COMMENT]: Comment
  [DistributionEndpoints.FILTER]: IFilter
}

function getDistributionEndpoint (categoryId: ProductCategory['id'] | any) {
  return getCategoryEndpoint(categoryId, 'distribution/')
}

function getDistributionPieChart (categoryId: ProductCategory['id'], year: string) {
  return request(`${getDistributionEndpoint(categoryId)}percent_graph/${year}`, 'get')
}

function getDistributionCollection (categoryId: ProductCategory['id']) {
  const prefix = getDistributionEndpoint(categoryId)
  const endpoints = Object.values(DistributionEndpoints)
  return getCollection<DistributionCollection>(prefix, endpoints, ['categories/any/distribution/percent_graph_filter'])
}

function updateDistributionAbsoluteTable (categoryId: ProductCategory['id'], grid: Table['grid']) {
  return request(`${getDistributionEndpoint(categoryId)}inkind_tab`, 'PUT', { grid })
}

function updateDistributionProfitMonetaryTable (categoryId: ProductCategory['id'], grid: Table['grid']) {
  return request(`${getDistributionProfitEndpoint(categoryId)}monetary_tab`, 'PUT', { grid })
}

function updateDistributionRelativeTable (categoryId: ProductCategory['id'], grid: Table['grid']) {
  return request(`${getDistributionEndpoint(categoryId)}percent_tab`, 'PUT', { grid })
}

function updateDistributionProfitRelativeTable (categoryId: ProductCategory['id'], grid: Table['grid']) {
  return request(`${getDistributionProfitEndpoint(categoryId)}percent_tab`, 'PUT', { grid })
}

function updateDistributionKpiTable (categoryId: ProductCategory['id'], grid: Table['grid']) {
  return request(`${getDistributionEndpoint(categoryId)}kpi_tab`, 'PUT', { grid })
}

function updateDistributionProfitKpiTable (categoryId: ProductCategory['id'], grid: Table['grid']) {
  return request(`${getDistributionProfitEndpoint(categoryId)}kpi_tab`, 'PUT', { grid })
}

function updateDistributionComment (categoryId: ProductCategory['id'], comment: string) {
  return request(`${getDistributionEndpoint(categoryId)}comment`, 'PUT', { comment })
}

function updateDistributionProfitComment (categoryId: ProductCategory['id'], comment: string) {
  return request(`${getDistributionProfitEndpoint(categoryId)}comment`, 'PUT', { comment })
}

export enum GrowthEndpoints {
  'MARKER_GROWTH' = 'markers/increase',
  'CHART_GROWTH' = 'inkind_graph',
  'CHART_SOURCES' = 'share_graph',
  'IN_KIND_TABLE' = 'inkind_tab',
  'SHARE_TABLE' = 'share_tab',
  'COMMENT' = 'comment',
}

export interface GrowthCollection {
  [GrowthEndpoints.MARKER_GROWTH]: Marker
  [GrowthEndpoints.CHART_GROWTH]: ChartData
  [GrowthEndpoints.CHART_SOURCES]: ChartData
  [GrowthEndpoints.IN_KIND_TABLE]: Table
  [GrowthEndpoints.SHARE_TABLE]: Table
  [GrowthEndpoints.COMMENT]: Comment
}

function getGrowthEndpoint (categoryId: ProductCategory['id']) {
  return getCategoryEndpoint(categoryId, 'growth/')
}

function getGrowthCollection (categoryId: ProductCategory['id']) {
  const prefix = getGrowthEndpoint(categoryId)
  const endpoints = Object.values(GrowthEndpoints)
  return getCollection<GrowthCollection>(prefix, endpoints)
}

function updateGrowthComment (categoryId: ProductCategory['id'], comment: string) {
  return request(`${getGrowthEndpoint(categoryId)}comment`, 'PUT', { comment })
}

export enum MarketingEndpoints {
  'MARKER_ADVERTISING' = 'marker_advertising',
  'MARKET_TOTAL_BUDGET' = 'marker_total_budget',
  'MARKER_MARGIN' = 'marker_margin',
  'ABSOLUTE_CHART' = 'monetary_graph',
  'RELATIVE_CHART' = 'percent_graph',
  'ABSOLUTE_TABLE' = 'monetary_tab',
  'RELATIVE_TABLE' = 'percent_tab',
  'COMMENT' = 'comment',
}

export interface MarketingCollection {
  [MarketingEndpoints.MARKER_ADVERTISING]: Marker
  [MarketingEndpoints.MARKET_TOTAL_BUDGET]: Marker
  [MarketingEndpoints.MARKER_MARGIN]: Marker
  [MarketingEndpoints.ABSOLUTE_CHART]: ChartData
  [MarketingEndpoints.RELATIVE_CHART]: ChartData
  [MarketingEndpoints.ABSOLUTE_TABLE]: Table
  [MarketingEndpoints.RELATIVE_TABLE]: Table
  [MarketingEndpoints.COMMENT]: Comment
}

function getMarketingEndpoint (categoryId: ProductCategory['id']) {
  return getCategoryEndpoint(categoryId, 'market_budget/')
}

function getMarketingCollection (categoryId: ProductCategory['id']) {
  const prefix = getMarketingEndpoint(categoryId)
  const endpoints = Object.values(MarketingEndpoints)
  return getCollection<MarketingCollection>(prefix, endpoints)
}

function updateMarketingAbsoluteTable (categoryId: ProductCategory['id'], grid: Table['grid']) {
  return request(`${getMarketingEndpoint(categoryId)}monetary_tab`, 'PUT', { grid })
}

function updateMarketingRelativeTable (categoryId: ProductCategory['id'], grid: Table['grid']) {
  return request(`${getMarketingEndpoint(categoryId)}percent_tab`, 'PUT', { grid })
}

function setMarketingEditable (categoryId: ProductCategory['id'], enable: boolean) {
  return request(`${getMarketingEndpoint(categoryId)}percent_tab/editable/${enable ? '1' : '0'}`, 'PUT')
}

function updateMarketingComment (categoryId: ProductCategory['id'], comment: string) {
  return request(`${getMarketingEndpoint(categoryId)}comment`, 'PUT', { comment })
}

export const categoriesApi = {
  getMenuCategories,
  getSalesCollection,
  updateChart,
  updateMarketTable,
  updateSalesTable,
  updateSalesKpiTable,
  updateGrowthTable,
  updateSalesComment,
  getDistributionCollection,
  getDistributionProfitCollection,
  updateDistributionAbsoluteTable,
  updateDistributionProfitMonetaryTable,
  updateDistributionRelativeTable,
  updateDistributionProfitRelativeTable,
  updateDistributionKpiTable,
  updateDistributionProfitKpiTable,
  updateDistributionComment,
  updateDistributionProfitComment,
  getGrowthCollection,
  updateGrowthComment,
  getMarketingCollection,
  updateMarketingAbsoluteTable,
  updateMarketingRelativeTable,
  setMarketingEditable,
  updateMarketingComment,
  getDistributionFilter,
  getDistributionProfitFilter,
  updateDistributionFilter,
  getDistributionPieChart
}
