import React, { useEffect, useRef, useState, Fragment } from 'react'
import { Box, Card, CardContent, FormControl, Grid, MenuItem, Select, SelectProps, Typography } from '@material-ui/core'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import { useAuthContext } from 'components/AuthContext'
import { useLayoutContext } from 'components/LayoutContext'
import { Marker } from 'components/Marker'
import {
  ArgumentAxis,
  Chart,
  ChartVariants,
  EventTracker,
  getColorByIdx,
  LineSeries,
  normalizeChartData,
  Tooltip,
  ValueAxis,
  ValueScale,
  VerticalBarSeries
} from 'components/Chart'
import { CommentForm } from 'components/CommentForm'
import {
  CategoryCollection,
  CategoryEndpoints,
  categorySummaryApi
} from 'api/categorySummaryApi'
import { useTranslationContext } from '../../components/TranslationContext'

import { Tabs, TargetsByCategoryQueryParams } from '../../types/tabsCommon'
import { useQueryParams } from '../../hooks/queryParams'

export function TargetsByCategoryPage () {
  const selectRef = useRef<HTMLDivElement>()
  const [collection, setCollection] = useState<CategoryCollection | null>()
  const { translation } = useTranslationContext()
  const { startContentLoading, finishContentLoading } = useLayoutContext()
  const { handleResponseFailure, handleResponseSuccess } = useAuthContext()
  const { queryParams, setQueryParams } = useQueryParams()
  const [category, setCategory] = useState(queryParams.get('categoryId') || 'all')
  const [activeTab, setActiveTab] = React.useState<Tabs | null>(Number(queryParams.get('unit')) ?? Tabs.inKind)
  const [showComment, setShowComment] = useState(true)

  useEffect(() => {
    startContentLoading()
    setShowComment(false)
    const method = activeTab === Tabs.inKind ? categorySummaryApi.getCategoryCollection : categorySummaryApi.getCategoryMonetaryCollection

    method.call(categorySummaryApi, category)
      .then((fetchedCollection) => {
        setCollection(fetchedCollection)
        setShowComment(true)
      })
      .catch(handleResponseFailure)
      .finally(finishContentLoading)
  }, [category, finishContentLoading, startContentLoading, handleResponseFailure, activeTab])

  if (!collection) {
    return null
  }

  const onTabChange = (event: React.MouseEvent<HTMLElement>, tab: Tabs | null) => {
    if (tab !== null) {
      // @ts-ignore
      setQueryParams<TargetsByCategoryQueryParams>({ unit: tab, categoryId: queryParams.get('categoryId') || collection[CategoryEndpoints.FILTER].optionGroups[0][0].label })
      setActiveTab(tab)
    }
  }
  if (!queryParams.get('unit')) {
    // @ts-ignore
    setQueryParams<TargetsByCategoryQueryParams>({ unit: activeTab, categoryId: queryParams.get('categoryId') || collection[CategoryEndpoints.FILTER].optionGroups[0][0].label })
  }

  const handleChartFilterChange: SelectProps['onChange'] = (e) => {
    const { value } = e.target
    setQueryParams<TargetsByCategoryQueryParams>({ unit: activeTab, categoryId: value })
    setCategory(value as string)
  }
  const handleCommentChange = (comment: string) => {
    categorySummaryApi.updateCategoryComment(comment)
      .then(handleResponseSuccess)
      .catch(handleResponseFailure)
  }

  const handleProfitCommentChange = (comment: string) => {
    categorySummaryApi.updateCategoryMonetaryComment(comment)
      .then(handleResponseSuccess)
      .catch(handleResponseFailure)
  }

  const salesChart = activeTab === Tabs.inKind ? collection[CategoryEndpoints.IN_KIND_CHART] : collection[CategoryEndpoints.MONETARY_CHART]
  const increaseChart = activeTab === Tabs.inKind ? collection[CategoryEndpoints.INCREASE_IN_KIND_CHART] : collection[CategoryEndpoints.INCREASE_MONETARY_CHART]
  const aspSkuChart = activeTab === Tabs.inKind ? collection[CategoryEndpoints.SKU_CHART] : collection[CategoryEndpoints.ASP_CHART]

  const filteredSalesChart = salesChart?.chart.map(({
    isForecast,
    argument,
    sum = 0,
    delta = 0
  }) => {
    const result = {
      isForecast,
      argument,
      sumForecast: sum,
      deltaForecast: delta
    }

    return isForecast ? result : Object.assign(result, { sum, delta })
  })

  const filteredIncreaseChart = increaseChart?.chart.map(({
    isForecast,
    argument,
    increase
  }) => {
    const result = {
      isForecast,
      argument,
      increaseForecast: increase
    }

    return isForecast ? result : Object.assign(result, { increase })
  })

  const filteredAspSkuChart = aspSkuChart?.chart.map(({
    isForecast,
    argument,
    sum = 0,
    delta = 0,
    asp = 0
  }) => {
    const result = {
      isForecast,
      argument,
      sumForecast: sum,
      deltaForecast: delta,
      aspForecast: asp
    }

    return isForecast ? result : Object.assign(result, { sum, delta, asp })
  })

  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <ToggleButtonGroup
            value={activeTab}
            onChange={onTabChange}
            exclusive
          >
            <ToggleButton value={Tabs.inKind}>
              {translation['menu.inkind']}
            </ToggleButton>

            <ToggleButton value={Tabs.monetary}>
              {translation['menu.monetary']}
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>

        <Grid item xs={12} md={4}>
          {(
            <FormControl variant="outlined" fullWidth>
              <Select
                ref={selectRef}
                value={queryParams.has('categoryId') ? queryParams.get('categoryId') : collection[CategoryEndpoints.FILTER].optionGroups[0][0].label}
                onChange={handleChartFilterChange}
                MenuProps={{
                  MenuListProps: { disablePadding: true },
                  style: { width: selectRef.current?.clientWidth }
                }}
              >
                {collection[CategoryEndpoints.FILTER].optionGroups[0].map(({ value, label }) => (
                  <MenuItem key={value} value={label}>
                    <Typography variant="inherit" noWrap>
                      {value}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Grid>
      </Grid>

      <Box pb={2}>
        <Grid container spacing={3}>
          {collection[CategoryEndpoints.MARKER_SALES] && <Grid item xs={12} md={3} sm={6}>
            <Marker {...collection[CategoryEndpoints.MARKER_SALES]} />
          </Grid>}
          {collection[CategoryEndpoints.MARKER_INCREASE] && <Grid item xs={12} md={3} sm={6}>
            <Marker {...collection[CategoryEndpoints.MARKER_INCREASE]} />
          </Grid>}
          {collection[CategoryEndpoints.MARKER_SKU] && <Grid item xs={12} md={3} sm={6}>
            <Marker {...collection[CategoryEndpoints.MARKER_SKU]} />
          </Grid>}
          {collection[CategoryEndpoints.MARKER_ASP] && <Grid item xs={12} md={3} sm={6}>
            <Marker {...collection[CategoryEndpoints.MARKER_ASP]} />
          </Grid>}
        </Grid>
      </Box>
      <Grid container spacing={3}>

        {salesChart && <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" paragraph>
                {salesChart.title}
              </Typography>

              <Chart
                height={300}
                data={normalizeChartData(filteredSalesChart)}
                leftAxisUnit={salesChart.units.leftAxis}
                rightAxisUnit={salesChart.units.rightAxis}
                legend={Object.values(salesChart.legend).map((label, idx) => ({
                  label,
                  color: getColorByIdx(idx, undefined, ChartVariants.Targets)
                }))}
              >
                <ValueScale name="sum"/>
                <ValueScale name="delta"/>
                <ArgumentAxis/>
                <ValueAxis
                  scaleName="sum"
                />
                <ValueAxis
                  scaleName="delta"
                  position="right"
                  showGrid={false}
                />
                <VerticalBarSeries
                  argumentField="argument"
                  valueField="sumForecast"
                  scaleName="sum"
                  color={getColorByIdx(0, undefined, ChartVariants.MarketVolumeForecast)}
                  chart={filteredSalesChart}
                />
                <VerticalBarSeries
                  argumentField="argument"
                  valueField="sum"
                  scaleName="sum"
                  color={getColorByIdx(0, undefined, ChartVariants.MarketVolumeFact)}
                  chart={filteredSalesChart}
                />
                <LineSeries
                  argumentField="argument"
                  valueField="deltaForecast"
                  scaleName="delta"
                  unit="%"
                  color={getColorByIdx(2, undefined, ChartVariants.MarketVolumeForecast)}
                />
                <LineSeries
                  argumentField="argument"
                  valueField="delta"
                  scaleName="delta"
                  unit="%"
                  color={getColorByIdx(2, undefined, ChartVariants.MarketVolumeFact)}
                />
                <EventTracker/>
                <Tooltip/>
              </Chart>
            </CardContent>
          </Card>
        </Grid>}

        {increaseChart && <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" paragraph>
                {increaseChart.title}
              </Typography>

              <Chart
                height={344}
                data={normalizeChartData(filteredIncreaseChart)}
              >
                <ArgumentAxis/>
                <ValueAxis/>
                <VerticalBarSeries
                  argumentField="argument"
                  valueField="increaseForecast"
                  color={getColorByIdx(4, undefined, ChartVariants.IncreaseInSales)}
                  chart={filteredIncreaseChart}
                />
                <VerticalBarSeries
                  argumentField="argument"
                  valueField="increase"
                  color={getColorByIdx(9, undefined, ChartVariants.IncreaseInSales)}
                  chart={filteredIncreaseChart}
                />
                <EventTracker/>
                <Tooltip/>
              </Chart>
            </CardContent>
          </Card>
        </Grid>}

        {aspSkuChart && <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" paragraph>
                {aspSkuChart.title}
              </Typography>

              <Chart
                height={300}
                data={normalizeChartData(filteredAspSkuChart)}
              >
                <ArgumentAxis/>
                <ValueAxis/>
                <LineSeries
                  argumentField="argument"
                  valueField={activeTab === Tabs.inKind ? 'sumForecast' : 'aspForecast'}
                  color={getColorByIdx(1, undefined, activeTab === Tabs.inKind ? ChartVariants.SKULine : ChartVariants.AverageLine)}
                />
                <LineSeries
                  argumentField="argument"
                  valueField={activeTab === Tabs.inKind ? 'sum' : 'asp'}
                  color={getColorByIdx(0, undefined, activeTab === Tabs.inKind ? ChartVariants.SKULine : ChartVariants.AverageLine)}
                />
                <EventTracker/>
                <Tooltip/>
              </Chart>
            </CardContent>
          </Card>
        </Grid>}

        {showComment && <Grid item xs={12} md={6}>
          <CommentForm
            label={collection[CategoryEndpoints.COMMENT].title}
            value={collection[CategoryEndpoints.COMMENT].comment}
            onChange={activeTab === Tabs.inKind ? handleCommentChange : handleProfitCommentChange}
          />
        </Grid>}
      </Grid>
    </Fragment>
  )
}
