import React from 'react'
import { InfoPage } from './infoPage'
import { Page } from 'components/Page'
import { useTranslationContext } from 'components/TranslationContext'

export function PromoMarketingPlanAndBudgetPage () {
  const { translation } = useTranslationContext()

  const tabs = [
    {
      label: translation['menu.promotion_market_plan_budget_tabs.direct_ad_budget'],
      url: 'direct-ad-budget',
      component: InfoPage
    },
    {
      label: translation['menu.promotion_market_plan_budget_tabs.merchandising_budget'],
      url: 'merchandising-budget',
      component: InfoPage
    },
    {
      label: translation['menu.promotion_market_plan_budget_tabs.trade_marketing_budget'],
      url: 'trade-marketing-budget',
      component: InfoPage
    },
    {
      label: translation['menu.promotion_market_plan_budget_tabs.promo'],
      url: 'promo',
      component: InfoPage
    },
    {
      label: translation['menu.promotion_market_plan_budget_tabs.rnd_budget'],
      url: 'rnd-budget',
      component: InfoPage
    },
    {
      label: translation['menu.promotion_market_plan_budget_tabs.summary'],
      url: 'summary',
      component: InfoPage
    }
  ]

  return (
    <Page title={translation['menu.promotion_market_plan_budget']} tabs={tabs} />
  )
}
