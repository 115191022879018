import React from 'react'
import { render } from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { SnackbarProvider } from 'notistack'
import { CssBaseline } from '@material-ui/core'
import { makeStyles, ThemeProvider } from '@material-ui/core/styles'
import { AuthContextProvider } from 'components/AuthContext'
import { Layout } from 'components/Layout'
import { LayoutContextProvider } from 'components/LayoutContext'
import { Routes } from 'components/Routes'
import { CategoriesContextProvider } from 'components/CategoriesContext'
import { TranslationContextProvider } from 'components/TranslationContext'
import { theme } from 'theme'

const useStyles = makeStyles(({ palette }) => ({
  variantSuccess: {
    backgroundColor: palette.backgrounds.snackbar
  },
  variantError: {
    backgroundColor: palette.backgrounds.snackbar
  }
}))

function SnackBarProviderWithClasses ({ children } : any) {
  const classes = useStyles()

  return (
    <SnackbarProvider maxSnack={3} classes={classes} children={children} />
  )
}

render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <SnackBarProviderWithClasses>
        <Router>
          <TranslationContextProvider>
            <AuthContextProvider>
              <CssBaseline />

              <CategoriesContextProvider>
                <LayoutContextProvider>
                  <Layout>
                    <Routes />
                  </Layout>
                </LayoutContextProvider>
              </CategoriesContextProvider>
            </AuthContextProvider>
          </TranslationContextProvider>
        </Router>
      </SnackBarProviderWithClasses>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
