import React from 'react'
import { Page } from 'components/Page'
import { GeographySalesPage } from './GeographySalesPage'
import { GeographyConsumptionPage } from './GeographyConsumptionPage'
import { useTranslationContext } from '../../components/TranslationContext'

export function GeographyPage () {
  const { translation } = useTranslationContext()

  const tabs = [
    {
      label: translation['components.GeographyPage.sales_title'],
      url: 'sales',
      component: GeographySalesPage
    },
    {
      label: translation['components.GeographyPage.consumption_title'],
      url: 'consumption',
      component: GeographyConsumptionPage
    }
  ]

  return (
    <Page title={translation['components.GeographyPage.title']} tabs={tabs} />
  )
}
