import React from 'react'
import { Field } from 'formik'
import { MenuItem, TableCell, FormControl, FormHelperText } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { Select } from 'formik-material-ui'

const TableCellStyled = withStyles(() => ({
  root: {
    position: 'relative',

    '& .MuiTextField-root': {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,

      '& .MuiOutlinedInput-root': {
        height: '100%',

        '& .MuiOutlinedInput-input': {
          height: '100%'
        }
      }
    },

    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent'
    },

    '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent'
    }
  }
}))(TableCell)

interface TableCellSelectItem {
  value?: number
  name: string
}

interface TableCellSelectProps {
  value?: number
  items: TableCellSelectItem[]
  name: string
  placeholder?: string
}

export function TableCellSelect ({ name, value, items, placeholder } : TableCellSelectProps) {
  return (
    <TableCellStyled>
      <FormControl style={{ width: 400 }}>
        {placeholder && <FormHelperText>{placeholder}</FormHelperText>}
        <Field
          component={Select}
          value={value}
          name={name}
        >
          {
            items.map(({ value, name }, idx) => <MenuItem key={idx} value={value}>{name}</MenuItem>)
          }
        </Field>
      </FormControl>
    </TableCellStyled>
  )
}
