export enum Tabs {
  inKind,
  monetary
}

export enum Rnd {
  with = 'rnd',
  without = 'no_rnd'
}

export function isRnd (x: unknown): x is Rnd {
  return typeof x === 'string' && Object.values(Rnd).includes(x as Rnd)
}

export interface PageWithTabsQueryParams {
  unit: string | unknown
}

export interface GrowthSourcesPageQueryParams extends PageWithTabsQueryParams {
  year: string | unknown
}

export interface AdvertisingPageQueryParams extends PageWithTabsQueryParams {
  categoryId: number,
  marketRoleId: number,
  rnd: Rnd,
  type: string
}

export interface TargetsByCategoryQueryParams extends PageWithTabsQueryParams {
  categoryId: string | unknown
}

export interface TargetsByMarketingBudgetQueryParams {
  categoryId: string | unknown
}

export interface CategoriesContextPageQueryParams extends PageWithTabsQueryParams {
  categoryId: string | unknown
}

export interface StandardsPageQueryParams {
  categoryId: number
}
