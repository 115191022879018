import React, { useEffect, useState } from 'react'
import {
  Link
} from 'react-router-dom'

import {
  Card,
  CardContent,
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectProps,
  Typography,
  TableRow,
  TableCell,
  TableBody,
  Button
} from '@material-ui/core'
import {
  Chart,
  ChartVariants,
  getColorByIdx,
  normalizeChartData,
  PieSeries,
  mapCollectionToFootnote
} from 'components/Chart'

import {
  getTargets,
  TargetsResult,
  getStandardTypes,
  StandardType,
  getMarketRoles,
  MarketRole,
  updateBudgetTab,
  FiltersRnd,
  Filters,
  getFilters,
  updateTargetComment
} from 'api/advertisingApi'

import { useAuthContext } from 'components/AuthContext'
import { DataTable } from 'components/DataTable'
import { useLayoutContext } from 'components/LayoutContext'
import { CommentForm } from 'components/CommentForm'
import { useTranslationContext } from 'components/TranslationContext'
import { Rnd } from '../../types/tabsCommon'
import { Table } from 'api/types'
import { StyledTable } from 'components/StyledTable'
import { formatNumber } from 'helpers/formatNumber'
import { useProductCategory } from '../../hooks/productCategory'

const ADVERTISING_USER_CATEGORY = 'advertising.category'

export function TargetsPage () {
  const { startContentLoading, finishContentLoading } = useLayoutContext()
  const { handleResponseFailure, handleResponseSuccess } = useAuthContext()
  const { translation } = useTranslationContext()
  const [collection, setCollection] = useState<TargetsResult | null>()
  const [showComment, setShowComment] = useState(true)
  const [marketRoles, setMarketRoles] = useState<MarketRole[]>()
  const [types, setTypes] = useState<StandardType[]>()
  const [filters, setFilters] = useState<Filters>()
  const [collectionUpdateTick, setCollectionUpdateTick] = useState(1)
  const { state: productCategory, setId: setCategoryId } = useProductCategory({ storageKey: ADVERTISING_USER_CATEGORY })

  useEffect(() => {
    (async () => {
      try {
        const marketRoles = await getMarketRoles()
        setMarketRoles(marketRoles)
        const types = await getStandardTypes()
        setTypes(types)
      } catch (error) {
        handleResponseFailure(error as any)
      }
    })()
  }, [handleResponseFailure])

  useEffect(() => {
    if (productCategory === undefined || !collectionUpdateTick) {
      return
    }

    startContentLoading()
    setShowComment(false)

    ;(async () => {
      try {
        const filters = await getFilters(productCategory.id)
        const { marketRoleId, type, rnd } = filters
        const collection = await getTargets({
          categoryId: productCategory.id,
          marketRoleId,
          type,
          rnd: rnd === FiltersRnd.hidden ? Rnd.without : rnd
        })
        setFilters(filters)
        setCollection(collection)
      } catch (error) {
        handleResponseFailure(error as any)
      } finally {
        finishContentLoading()
        setShowComment(true)
      }
    })()
  }, [
    startContentLoading,
    handleResponseFailure,
    finishContentLoading,
    productCategory,
    collectionUpdateTick
  ])

  if (!collection || !productCategory || !marketRoles || !types || !filters) {
    return null
  }

  const handleCategoriesFilterChange: SelectProps['onChange'] = async (e) => {
    const { value } = e.target
    setCategoryId(value as number)
  }

  const handleCommentChange = async (comment: string): Promise<void> => {
    try {
      await updateTargetComment(comment)
      handleResponseSuccess()
    } catch (error) {
      handleResponseFailure(error as any)
    }
  }

  const handleBudgetTableUpdate = async (grid: Table['grid']) => {
    if (filters === undefined || productCategory === undefined) {
      return
    }

    const { rnd, type } = filters

    try {
      await updateBudgetTab(grid, {
        categoryId: productCategory.id,
        type,
        rnd: rnd === FiltersRnd.hidden ? Rnd.without : rnd
      })
      setCollectionUpdateTick(collectionUpdateTick % 2 + 1)
      handleResponseSuccess()
    } catch (error) {
      handleResponseFailure(error as any)
    }
  }

  const getFiltersTitle = () => {
    const title = translation['pages.AdvertisingPage.filters.title']
    const part = title.split('%category%')
    const categoryName = productCategory.list.find(c => c.id === productCategory.id)?.categoryName ?? ''

    return (<>
      {part[0]}
      {categoryName}
      {part[1]}:
    </>)
  }

  const childIndicatorStyle = {
    indicator: {
      justifyContent: 'right',
      textAlign: 'right',
      fontStyle: 'italic',
      fontSize: '0.9em'
    }
  } as const

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={4}>
        <FormControl variant="outlined" fullWidth>
          <Select
            value={productCategory.id}
            onChange={handleCategoriesFilterChange}
            MenuProps={{
              MenuListProps: { disablePadding: true }
            }}
          >
            { productCategory.list.map(({ id, categoryName }) => (
              <MenuItem key={id} value={id}>
                <Typography variant="inherit" noWrap>
                  {categoryName}
                </Typography>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h6" paragraph>
                  {getFiltersTitle()}
                </Typography>
                <StyledTable style={{ width: '40em' }}>
                  <TableBody>
                    <TableRow key='type'>
                      <TableCell>
                        <Typography>
                          {translation['pages.AdvertisingPage.filters.type']}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {types.find(t => t.id === filters.type)?.name}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow key='market_role'>
                      <TableCell>
                        <Typography>
                          {translation['pages.AdvertisingPage.filters.market_role']}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {marketRoles.find(t => t.id === filters.marketRoleId)?.name}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow key='rnd'>
                      <TableCell>
                        <Typography>
                          {translation['pages.AdvertisingPage.filters.rnd']}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {filters.rnd === Rnd.with ? translation['pages.AdvertisingPage.rnd_switch.with'] : translation['pages.AdvertisingPage.rnd_switch.without']}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </StyledTable>
              </Grid>
              <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  component={Link}
                  variant="contained"
                  color="primary"
                  to={`/strategical/market-standards/advertising_efficiency/standards?categoryId=${productCategory.id}`}
                >
                  {translation['pages.AdvertisingPage.change_filters']}
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              { collection.budgetTab && <DataTable
                title={collection.budgetTab.title}
                description={collection.budgetTab.description}
                data={collection.budgetTab.grid}
                onDataUpdate={handleBudgetTableUpdate}
              />}
            </Grid>

            <Grid item xs={12} md={6}>
              <CardContent>
                <Typography variant="h6" paragraph>
                  {collection.pie.title}
                </Typography>
              </CardContent>

              <Chart
                height={340}
                data={normalizeChartData(Object.keys(collection.pie.legend ?? []).map(el => ({
                  name: collection.pie.legend[el],
                  value: collection.pie.chart[0][el]
                })))}
                legend={Object.entries(collection.pie.legend ?? []).map(([key, label], idx) => ({
                  label,
                  color: getColorByIdx(idx, undefined, ChartVariants.AdvertisingPie),
                  footnote: key === 'adv'
                }))}
                pieFootnote={mapCollectionToFootnote(collection)}
              >
                <PieSeries
                  argumentField="name"
                  valueField="value"
                  color={index => getColorByIdx(index, undefined, ChartVariants.AdvertisingPie)}
                  label={value => formatNumber(value, { maximumFractionDigits: 2 }) + '%'}
                />

              </Chart>
            </Grid>
          </Grid>
        </Card>
      </Grid>

      { collection.targetTab && <Grid item xs={12}>
        <DataTable
          title={collection.targetTab.title}
          description={collection.targetTab.description}
          data={collection.targetTab.grid}
        />
      </Grid> }

      <Grid item xs={12}>
        <Card>
          <Grid container style={{ display: 'flex', gridAutoRows: '1fr' }}>
            { collection.structTab && <Grid item xs={12} md={6}>
              <DataTable
                title={collection.structTab.title}
                description={collection.structTab.description}
                data={collection.structTab.grid}
                cellStyle={{
                  adv_online: childIndicatorStyle,
                  adv_offline: childIndicatorStyle
                }}
              />
            </Grid> }

            <Grid item xs={12} md={6}>
              {showComment && <CommentForm
                label={collection.comment.title}
                value={collection.comment.comment}
                onChange={handleCommentChange}
                style={{ height: '100%' }}
              />}
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  )
}
