import React from 'react'
import {
  Card,
  CardContent
} from '@material-ui/core'
import {
  ArgumentAxis,
  Chart,
  EventTracker,
  LineSeries,
  Tooltip,
  ValueAxis,
  normalizeChartData,
  getColorByIdx, ChartVariants
} from 'components/Chart'
import { GraphGrowth } from 'api/marketAndCompanyApi'
import { StyledTypographyHeader } from './StyledTypography'

export function MarketAndCustomerSalesGrowthChart (props: GraphGrowth) {
  const { title, chart, legend, units } = props

  return (
    <Card>
      <CardContent>
        <StyledTypographyHeader>
          {title}
        </StyledTypographyHeader>
      </CardContent>

      <Chart
        height={units.leftAxis ? 300 : 320}
        leftAxisUnit={units.leftAxis}
        data={normalizeChartData(chart)}
        legend={[
          {
            label: legend.marketActual,
            color: getColorByIdx(0, undefined, ChartVariants.MarketVolumeLegend)
          },
          {
            label: legend.companyActual,
            color: getColorByIdx(1, undefined, ChartVariants.MarketVolumeLegend)
          }
        ]}
      >
        <ArgumentAxis />
        <ValueAxis />
        <LineSeries
          name="marketActual"
          argumentField="argument"
          valueField="marketActual"
          color={getColorByIdx(0, undefined, ChartVariants.MarketVolumeFact)}
          unit="%"
        />
        <LineSeries
          name="marketForecast"
          argumentField="argument"
          valueField="marketForecast"
          color={getColorByIdx(0, undefined, ChartVariants.MarketVolumeForecast)}
          unit="%"
        />
        <LineSeries
          name="companyActual"
          argumentField="argument"
          valueField="companyActual"
          color={getColorByIdx(1, undefined, ChartVariants.MarketVolumeFact)}
          unit="%"
        />
        <LineSeries
          name="companyForecast"
          argumentField="argument"
          valueField="companyForecast"
          color={getColorByIdx(1, undefined, ChartVariants.MarketVolumeForecast)}
          unit="%"
        />
        <EventTracker />
        <Tooltip />
      </Chart>
    </Card>
  )
}
