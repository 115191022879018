import type { Data } from '../components/DataTableContext'

export const formatDataGridToExcelString = ({ columns, rows } : Data, increaseRowTranslation: string) => {
  let excelString = ''

  const columnTitles = []
  const columnSubtitles = []
  const rowsArray = []

  for (const column of columns) {
    columnTitles.push(column.title)
    columnSubtitles.push(column.subtitle)
  }

  for (const row of rows) {
    const cellsArray = []
    const increasePercentageCells = []
    let columnsIndex = 0
    let cellsIndex = 0

    while (cellsIndex < columns.length) {
      for (const [key, { value, delta }] of Object.entries(row.cells)) {
        if (columnsIndex <= (columns.length - 1) && columns[columnsIndex].field === key) {
          columns[columnsIndex].field !== 'indicator'
            ? increasePercentageCells.push(delta?.replace('.', ','))
            : increasePercentageCells.push(increaseRowTranslation)

          cellsArray.push(value?.replace('.', ','))
          columnsIndex++
        }
      }
      cellsIndex++
    }
    rowsArray.push(cellsArray)
    rowsArray.push(increasePercentageCells)
  }

  excelString += columnTitles.join('\t') + '\n'
  excelString += columnSubtitles.join('\t') + '\n'

  rowsArray.forEach((row) => {
    excelString += row.join('\t') + '\n'
  })

  return excelString
}

const copyToClipboard = (str : string) => navigator.clipboard.writeText(str)

export const copyForExcel = (dataGrid : Data, increaseRowTranslation: string) => copyToClipboard(formatDataGridToExcelString(dataGrid, increaseRowTranslation))
