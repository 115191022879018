import React from 'react'
import { Formik, Form } from 'formik'
import { Card, CardContent, Typography, Box, Grid } from '@material-ui/core'
import { useAuthContext } from 'components/AuthContext'
import { useTranslationContext } from 'components/TranslationContext'
import { TextField } from 'components/TextField'
import { ChangePasswordForm } from 'components/ChangePasswordForm'
import { LoadingButton } from 'components/LoadingButton'
import { profileApi } from 'api/profileApi'
import { Page } from 'components/Page'
import { User } from 'api/usersApi'

export function ProfilePage () {
  const {
    currentUser,
    setCurrentUser,
    handleResponseSuccess,
    handleResponseFailure
  } = useAuthContext()
  const { translation } = useTranslationContext()

  if (!currentUser) {
    return null
  }

  const handleSubmit = (values: User) => {
    return profileApi.update(values)
      .then(() => {
        setCurrentUser(values)
        handleResponseSuccess()
      })
      .catch(handleResponseFailure)
  }
  const handleChangePasswordFormSubmit = (newPassword: string) => {
    return profileApi.changePassword(currentUser?.id, newPassword)
      .then(handleResponseSuccess)
      .catch(handleResponseFailure)
  }

  return (
    <Page title={translation['menu.profile.title']}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" paragraph>
                {translation['menu.profile.title']}
              </Typography>

              <Formik initialValues={currentUser} onSubmit={handleSubmit}>
                {({ isSubmitting }) => (
                  <Form>
                    <TextField
                      name="username"
                      label={translation['menu.profile.name']}
                      autoFocus
                    />

                    <TextField
                      name="position"
                      label={translation['menu.profile.position']}
                    />

                    <TextField
                      name="email"
                      label={translation['menu.profile.email']}
                      autoComplete="email"
                    />

                    <Box mt={2} textAlign="right">
                      <LoadingButton
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="large"
                        disableElevation
                        disabled={isSubmitting}
                        isLoading={isSubmitting}
                      >
                        {translation['form.save']}
                      </LoadingButton>
                    </Box>
                  </Form>
                )}
              </Formik>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" paragraph>
                {translation['menu.profile.change_password']}
              </Typography>

              <ChangePasswordForm autoFocus={false} onSubmit={handleChangePasswordFormSubmit} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Page>
  )
}
