import { request } from 'api/request'
import { Comment, Marker, Table } from 'api/types'
import { Field, Option } from 'helpers/fields'
import { getCollection as gc } from './getCollection'

function getCollection<Collection> (prefix: string, endpoints: string[], suffix?: string) {
  const objects = endpoints.map((endpoint) => `${prefix}${endpoint}`)
  return request('collection', 'POST', { objects })
    .then((response: Collection) => (
      Object.entries(response)
        .reduce((acc, [key, value]) => ({
          ...acc,
          [key.replace(prefix, '').replace(suffix || '', '')]: value
        }), {}) as Collection
    ))
}

export interface ChartData {
  title: string
  chart: Record<string, string | number>[]
  legend: Record<string, string>
  units: {
    leftAxis: string
    rightAxis: string
  }
  filters?: {
    firstForecastPeriod?: Field
    years?: Field<number[]>
    productCategory?: Field
  }
}

export interface ChartFilter {
  productCategory: unknown[]
}

export enum SummaryEndpoints {
  'MARKER_SALES' = 'marker_sales',
  'MARKER_PROFIT' = 'marker_profit',
  'MARKER_ASP' = 'marker_asp',
  // 'MARKER_SALES_PER_SKU' = 'marker_sales_per_sku',
  'SALES_TABLE' = 'sales_tab',
  'GROWTH_TABLE' = 'increase_tab',
  'MONETARY_TABLE' = 'monetary_tab',
  'IN_KIND_TABLE' = 'inkind_tab',
  'COMMENT' = 'comment',
}

export interface SummaryCollection {
  [SummaryEndpoints.MARKER_SALES]: Marker
  [SummaryEndpoints.MARKER_PROFIT]: Marker
  [SummaryEndpoints.MARKER_ASP]: Marker
  // [SummaryEndpoints.MARKER_SALES_PER_SKU]: TripleMarker
  [SummaryEndpoints.SALES_TABLE]: Table
  [SummaryEndpoints.GROWTH_TABLE]: Table
  [SummaryEndpoints.MONETARY_TABLE]: Table
  [SummaryEndpoints.IN_KIND_TABLE]: Table
  [SummaryEndpoints.COMMENT]: Comment
}

function getSummaryCollection () {
  const prefix = 'categories/summary/main/'
  const endpoints = Object.values(SummaryEndpoints)
  return getCollection<SummaryCollection>(prefix, endpoints)
}

function updateSummaryComment (comment: string) {
  return request('categories/summary/main/comment', 'PUT', { comment })
}

export enum MarketingBudgetEndpoints {
  'MARKER_ADVERTISING' = 'marker_advertising',
  'MARKER_MARGIN' = 'marker_margin',
  'ABSOLUTE_CHART' = 'monetary_graph',
  'RELATIVE_CHART' = 'percent_graph',
  'ABSOLUTE_TABLE' = 'monetary_tab',
  'RELATIVE_TABLE' = 'percent_tab',
  'COMMENT' = 'comment'
}

export interface MarketingBudgetCollection {
  [MarketingBudgetEndpoints.MARKER_ADVERTISING]: Marker
  [MarketingBudgetEndpoints.MARKER_MARGIN]: Marker
  [MarketingBudgetEndpoints.ABSOLUTE_CHART]: ChartData
  [MarketingBudgetEndpoints.RELATIVE_CHART]: ChartData
  [MarketingBudgetEndpoints.ABSOLUTE_TABLE]: Table
  [MarketingBudgetEndpoints.RELATIVE_TABLE]: Table
  [MarketingBudgetEndpoints.COMMENT]: Comment
}

function getMarketingBudgetCollection (categoryId: string) {
  const prefix = 'categories/summary/market_budget/'
  const endpoints = [
    `${MarketingBudgetEndpoints.MARKER_ADVERTISING}/${categoryId}`,
    `${MarketingBudgetEndpoints.MARKER_MARGIN}/${categoryId}`,
    `${MarketingBudgetEndpoints.ABSOLUTE_CHART}/${categoryId}`,
    `${MarketingBudgetEndpoints.RELATIVE_CHART}/${categoryId}`,
    `${MarketingBudgetEndpoints.ABSOLUTE_TABLE}/${categoryId}`,
    `${MarketingBudgetEndpoints.RELATIVE_TABLE}/${categoryId}`,
    MarketingBudgetEndpoints.COMMENT
  ]
  const objects = endpoints.map((endpoint) => `${prefix}${endpoint}`)
  return request('collection', 'POST', { objects })
    .then((response: MarketingBudgetCollection) => (
      Object.entries(response)
        .reduce((acc, [key, value]) => ({
          ...acc,
          [key.replace(prefix, '').replace(`/${categoryId}`, '')]: value
        }), {}) as MarketingBudgetCollection
    ))
}

function updateAbsoluteChart (filter: ChartFilter) {
  return request('categories/summary/market_budget/monetary_graph', 'PUT', filter)
}

function updateRelativeChart (filter: ChartFilter) {
  return request('categories/summary/market_budget/percent_graph', 'PUT', filter)
}

function updateMarketingComment (comment: string) {
  return request('categories/summary/market_budget/comment', 'PUT', { comment })
}

export enum CategoryEndpoints {
  'MARKER_SALES' = 'marker_sales',
  'MARKER_INCREASE' = 'marker_increase',
  'MARKER_SKU' = 'marker_sku',
  'IN_KIND_CHART' = 'inkind_graph',
  'MONETARY_CHART' = 'monetary_graph',
  'INCREASE_IN_KIND_CHART' = 'increase_inkind_graph',
  'INCREASE_MONETARY_CHART' = 'increase_monetary_graph',
  'SKU_CHART' = 'sku_graph',
  'COMMENT' = 'comment',
  'ASP_CHART' = 'asp_graph',
  'MARKER_ASP' = 'marker_asp',
  'FILTER' = 'filter'
}

interface ProductCategoryFilter {
  optionGroups: [Option[]]
}

export interface CategoryCollection {
  [CategoryEndpoints.MARKER_SALES]: Marker
  [CategoryEndpoints.MARKER_INCREASE]: Marker
  [CategoryEndpoints.MARKER_SKU]: Marker
  [CategoryEndpoints.MARKER_ASP]: Marker
  [CategoryEndpoints.INCREASE_IN_KIND_CHART]: ChartData
  [CategoryEndpoints.INCREASE_MONETARY_CHART]: ChartData
  [CategoryEndpoints.IN_KIND_CHART]: ChartData
  [CategoryEndpoints.MONETARY_CHART]: ChartData
  [CategoryEndpoints.SKU_CHART]: ChartData
  [CategoryEndpoints.ASP_CHART]: ChartData
  [CategoryEndpoints.COMMENT]: Comment
  [CategoryEndpoints.FILTER]: ProductCategoryFilter
}

function getCategoryCollection (category: string) {
  const prefix = 'categories/summary/targets/'
  const suffix = `/${category}/`
  const endpoints = [
    `${prefix}${CategoryEndpoints.MARKER_SALES}`,
    `${prefix}${CategoryEndpoints.MARKER_INCREASE}`,
    `${prefix}${CategoryEndpoints.MARKER_SKU}`,
    `${prefix}${CategoryEndpoints.IN_KIND_CHART}`,
    `${prefix}${CategoryEndpoints.INCREASE_IN_KIND_CHART}`,
    `${prefix}${CategoryEndpoints.SKU_CHART}`,
    `${prefix}${CategoryEndpoints.COMMENT}`,
    `${prefix}${CategoryEndpoints.FILTER}`
  ]

  return gc<CategoryCollection>(suffix, endpoints, false, [], prefix)
}

function getCategoryMonetaryCollection (category: string) {
  const prefix = 'categories/summary/targets_profit/'
  const suffix = `/${category}`
  const endpoints = [
    `${prefix}${CategoryEndpoints.MARKER_SALES}`,
    `${prefix}${CategoryEndpoints.MARKER_INCREASE}`,
    `${prefix}${CategoryEndpoints.MARKER_SKU}`,
    `${prefix}${CategoryEndpoints.MARKER_ASP}`,
    `${prefix}${CategoryEndpoints.MONETARY_CHART}`,
    `${prefix}${CategoryEndpoints.INCREASE_MONETARY_CHART}`,
    `${prefix}${CategoryEndpoints.SKU_CHART}`,
    `${prefix}${CategoryEndpoints.ASP_CHART}`,
    `${prefix}${CategoryEndpoints.COMMENT}`,
    `${prefix}${CategoryEndpoints.FILTER}`
  ]

  return gc<CategoryCollection>(suffix, endpoints, false, [], prefix)
}

function updateInKindChart (filter: ChartFilter) {
  return request('categories/summary/targets/inkind_graph', 'PUT', filter)
}

function updateMonetaryChart (filter: ChartFilter) {
  return request('categories/summary/targets_profit/monetary_graph', 'PUT', filter)
}

function updateAspChart (filter: ChartFilter) {
  return request('categories/summary/targets_profit/asp_graph', 'PUT', filter)
}

function updateIncreaseInKindChart (filter: ChartFilter) {
  return request('categories/summary/targets/increase_inkind_graph', 'PUT', filter)
}

function updateIncreaseMonetaryChart (filter: ChartFilter) {
  return request('categories/summary/targets_profit/increase_monetary_graph', 'PUT', filter)
}

function updateSkuChart (filter: ChartFilter) {
  return request('categories/summary/targets/sku_graph', 'PUT', filter)
}

function updateCategoryComment (comment: string) {
  return request('categories/summary/targets/comment', 'PUT', { comment })
}

function updateCategoryMonetaryComment (comment: string) {
  return request('comment/categories/summary/targets_profit/comment', 'PUT', { comment })
}

export const categorySummaryApi = {
  getSummaryCollection,
  updateSummaryComment,
  getMarketingBudgetCollection,
  updateAbsoluteChart,
  updateRelativeChart,
  updateMarketingComment,
  getCategoryCollection,
  getCategoryMonetaryCollection,
  updateInKindChart,
  updateAspChart,
  updateIncreaseInKindChart,
  updateSkuChart,
  updateCategoryComment,
  updateCategoryMonetaryComment,
  updateMonetaryChart,
  updateIncreaseMonetaryChart
}
