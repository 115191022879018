import { ProductCategory } from 'api/categoriesApi'
import { request } from 'api/request'
import { Field } from 'helpers/fields'
import { VatRate } from './types'

export interface Units {
  titles: {
    naturalUnits: string
    monetaryExpression: string
  }
  fields: {
    marketUnit: Field<string>
    minimumNaturalUnit: Field<string>
    currency: Field<string>
    marketMonetaryUnit: Field<string>
    priceUnit: Field<string>
  }
}

export interface UnitsValues {
  marketUnit: string
  minimumNaturalUnit: string
  currency: string
  marketMonetaryUnit: string
  priceUnit: string
}

function getUnits () {
  return request('market_units')
}

function updateUnits (values: UnitsValues) {
  return request('market_units', 'PUT', values)
}

export interface PriceCoefficients {
  fields: {
    markupCoefficient: Field<string>
    vatRate: Field<string>
  },
  desc: string
}

export interface PriceCoefficientsValues {
  markupCoefficient: number
  vatRate: VatRate
}

function getPriceCoefficients () {
  return request('companies/price_coefficient')
}

function updatePriceCoefficients (values: PriceCoefficientsValues) {
  return request('companies/price_coefficient', 'PUT', values)
}

export interface ProductCategories {
  description: string
  productCategoryArr: ProductCategory[]
}

function getProductCategories () {
  return request('companies/product_categories')
}

function updateProductCategories (productCategories: ProductCategories) {
  return request('companies/product_categories', 'PUT', productCategories)
}

export interface KeyPlayer {
  id: number
  competitorName: string
  categoryIds: number[]
}

export interface KeyPlayers {
  description: string
  elements: KeyPlayer[]
}

function getKeyPlayers () {
  return request('companies/competitors')
}

function updateKeyPlayers (keyPlayers: KeyPlayers) {
  return request('companies/competitors', 'PUT', keyPlayers)
}

export const marketCharacteristicsApi = {
  getUnits,
  updateUnits,
  getPriceCoefficients,
  updatePriceCoefficients,
  getProductCategories,
  updateProductCategories,
  getKeyPlayers,
  updateKeyPlayers
}
