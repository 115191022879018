import { useState, useEffect } from 'react'

export function useLocalStorage (localStorageItemKey: string) {
  const storedValue = localStorage.getItem(localStorageItemKey)
  const state = useState(storedValue)
  const [value] = state

  useEffect(() => {
    if (value) {
      localStorage.setItem(localStorageItemKey, value)
    } else {
      localStorage.removeItem(localStorageItemKey)
    }
  }, [localStorageItemKey, value])

  return state
}
