import React, { useEffect, useState } from 'react'
import {
  Grid,
  Card,
  CardContent,
  Box,
  TableBody,
  TableRow,
  TableCell, Typography
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Formik, Form, Field } from 'formik'
import { LoadingButton } from 'components/LoadingButton'
import { TextField } from 'components/TextField'
import { Page } from 'components/Page'
import { useAuthContext } from 'components/AuthContext'
import { useTranslationContext } from 'components/TranslationContext'
import { commonInfoApi, CommonInfo, CommonInfoValues } from 'api/commonInfoApi'
import { getFieldsValues } from 'helpers/fields'
import { StyledTable } from '../components/StyledTable'
import { observer } from 'mobx-react-lite'
import { countriesStore, ICountriesStore } from '../stores/countriesStore'
import { countriesApi } from '../api/countriesApi'
import { Country } from '../api/types'
import { useHistory } from 'react-router'

const useStyles = makeStyles({
  hideLastBorder: {
    '&:last-child td': {
      border: 0
    }
  }
})

interface CommonInfoPageProps {
  countriesStore: ICountriesStore
}

function CommonInfoPageWithProps ({ countriesStore } : CommonInfoPageProps) {
  const classes = useStyles()
  const { currentCompany, handleResponseSuccess, handleResponseFailure } = useAuthContext()
  const { translation } = useTranslationContext()
  const [commonInfo, setCommonInfo] = useState<CommonInfo>()
  const { countriesList, setCountriesList } = countriesStore
  const history = useHistory()

  const handleSubmit = (values: CommonInfoValues) => {
    return commonInfoApi.updateCommonInfo(values)
      .then(() => {
        handleResponseSuccess()
        history.go(0)
      })
      .catch(handleResponseFailure)
  }

  useEffect(() => {
    if (!countriesList) {
      countriesApi.getCountriesList()
        .then((countries: Country[]) => {
          setCountriesList(countries)
        })
    }
  })

  useEffect(() => {
    commonInfoApi.getCommonInfo()
      .then(setCommonInfo)
      .catch(handleResponseFailure)
  }, [handleResponseFailure])

  if (!commonInfo || !currentCompany || !countriesList) {
    return null
  }

  const { name, shortName, market, subscriptionExpires, licenseUsersTotal } = commonInfo.fields

  const initialValues = getFieldsValues<CommonInfoValues>(commonInfo.fields)

  return (
    <Page title={translation['menu.company_info']}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                {({ isSubmitting }) => (
                  <Form>
                    <TextField
                      name="name"
                      label={name.label}
                      hint={name.hint}
                    />

                    <TextField
                      name="shortName"
                      label={shortName.label}
                      hint={shortName.hint}
                    />

                    <TextField
                      name="market"
                      label={market.label}
                      hint={market.hint}
                    />
                    <Box pt={2}>
                      <Typography variant='subtitle1'>
                        {translation['common_info.selected_countries']}
                      </Typography>
                      <Box>
                        {
                          countriesList.map((el: Country) => (
                            <label>
                              <Box style={{ display: 'flex', flexDirection: 'row', width: 150, justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography>{ el.name }</Typography>
                                <Field
                                  type='checkbox'
                                  name='countries'
                                  value={el.id.toString()}
                                />
                              </Box>
                            </label>
                          ))
                        }
                      </Box>
                    </Box>

                    <Box mt={2} textAlign="right">
                      <LoadingButton
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="large"
                        disableElevation
                        disabled={isSubmitting}
                        isLoading={isSubmitting}
                      >
                        {translation['form.save']}
                      </LoadingButton>
                    </Box>
                  </Form>
                )}
              </Formik>
            </CardContent>
          </Card>
          <Card style={{ marginTop: '20px' }}>
            <StyledTable>
              <TableBody>
                <TableRow>
                  <TableCell variant="head">
                    {subscriptionExpires.label}
                  </TableCell>

                  <TableCell>
                    {
                      typeof subscriptionExpires.value === 'string'
                        ? new Date(subscriptionExpires.value).toLocaleDateString()
                        : subscriptionExpires.value
                    }
                  </TableCell>
                </TableRow>

                <TableRow className={classes.hideLastBorder}>
                  <TableCell variant="head">
                    {licenseUsersTotal.label}
                  </TableCell>

                  <TableCell>
                    {currentCompany.licenseUsersActive} {translation['pages.CommonInfoPage.from']} {licenseUsersTotal.value}
                  </TableCell>
                </TableRow>
              </TableBody>
            </StyledTable>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <img
            src='/general_information_map.png'
            style={{ width: '100%' }}
            alt='general information'
          />
        </Grid>
      </Grid>
    </Page>
  )
}

const CommonInfoPageObserver = observer(CommonInfoPageWithProps)

export const CommonInfoPage = () => <CommonInfoPageObserver countriesStore={countriesStore} />
