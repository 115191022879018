import { categoriesApi, ProductCategory } from 'api/categoriesApi'
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { useAuthContext } from './AuthContext'
import { UserRole } from '../api/usersApi'

interface Context {
  fetchCategories: () => void
  categories: ProductCategory[]
  setCategories: (categories: ProductCategory[]) => void
  currentCategory?: ProductCategory
  setCurrentCategory: (category?: ProductCategory) => void
}

const CategoriesContext = createContext<Context | null>(null)

export function CategoriesContextProvider ({ children }: React.PropsWithChildren<{}>) {
  const [categories, setCategories] = useState<ProductCategory[]>([])
  const [currentCategory, setCurrentCategory] = useState<ProductCategory>()
  const { currentUser } = useAuthContext()
  const fetchCategories = useCallback(() => {
    categoriesApi.getMenuCategories()
      .then(({ productCategoryArr }) => setCategories(productCategoryArr))
  }, [])
  const value = {
    fetchCategories,
    categories,
    setCategories,
    currentCategory,
    setCurrentCategory
  }

  useEffect(() => {
    if (currentUser && currentUser.role !== UserRole.APP_ADMIN) {
      fetchCategories()
    }
  }, [fetchCategories, currentUser])

  return (
    <CategoriesContext.Provider value={value}>
      {children}
    </CategoriesContext.Provider>
  )
}

export function useCategoriesContext () {
  const context = useContext(CategoriesContext)

  if (!context) {
    throw new Error('Used outside of "CategoriesContextProvider"')
  }

  return context
}
