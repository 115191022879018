import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Card, CardContent, FormControl, Grid, MenuItem, Select, SelectProps, Typography } from '@material-ui/core'
import { formatNumber } from 'helpers/formatNumber'
import { useAuthContext } from 'components/AuthContext'
import { DataTable } from 'components/DataTable'
import { useLayoutContext } from 'components/LayoutContext'
import {
  ArgumentAxis,
  Chart,
  ChartVariants,
  EventTracker,
  getColorByIdx,
  normalizeChartData,
  Plugin,
  Stack,
  Tooltip,
  ValueAxis,
  VerticalBarSeries
} from 'components/Chart'
import { CommentForm } from 'components/CommentForm'
import {
  CategoriesCompetitorsCollection,
  CategoriesCompetitorsEndpoints,
  CategoriesCompetitorsFilter,
  marketAndCompanyApi
} from 'api/marketAndCompanyApi'
import { Table } from 'api/types'
import { useQueryParams } from '../../hooks/queryParams'

interface PageQueryParams {
  categoryId: string | unknown
}

export function CompetitorsPage () {
  const { startContentLoading, finishContentLoading } = useLayoutContext()
  const { handleResponseFailure, handleResponseSuccess } = useAuthContext()
  const selectRef = useRef<HTMLDivElement>()
  const [filter, setFilter] = useState<CategoriesCompetitorsFilter>()
  const [collection, setCollection] = useState<CategoriesCompetitorsCollection>()
  const { queryParams, setQueryParams } = useQueryParams()
  const [categoryId, setCategoryId] = useState(queryParams.has('categoryId') ? queryParams.get('categoryId') : 'all')

  const getCollection = useCallback((endpoints?: string[]) => {
    if (filter) {
      startContentLoading()

      marketAndCompanyApi.getCategoriesCompetitorsCollection(categoryId, endpoints)
        .then((collection) => setCollection((prevCollection) => prevCollection
          ? { ...prevCollection, ...collection }
          : collection
        ))
        .catch(handleResponseFailure)
        .finally(finishContentLoading)
    }
  }, [startContentLoading, finishContentLoading, handleResponseFailure, filter, categoryId])

  useEffect(() => {
    startContentLoading()

    marketAndCompanyApi.getCategoriesCompetitorsFilter()
      .then(setFilter)
      .catch(handleResponseFailure)
      .finally(finishContentLoading)
  }, [startContentLoading, finishContentLoading, handleResponseFailure])

  useEffect(() => {
    getCollection()
  }, [getCollection])

  if (!collection || !filter) {
    return null
  }

  const handleCategoriesFilterChange: SelectProps['onChange'] = (e) => {
    const { value } = e.target
    setQueryParams<PageQueryParams>({ categoryId: value })
    setCategoryId(value as string)
  }

  const handleTableDependenciesUpdate = (dependencies: string[]) => {
    return getCollection(dependencies.map((dep) => dep.replace('/{number}', '')))
  }
  const handleTableUpdate = (grid: Table['grid']) => {
    marketAndCompanyApi.updateCategoriesCompetitorsTable(categoryId, grid)
      .then((response) => {
        setCollection({ ...collection, [CategoriesCompetitorsEndpoints.TABLE]: response })
        handleTableDependenciesUpdate(response.dependencies)
        handleResponseSuccess()
      })
      .catch(handleResponseFailure)
  }
  const handleCommentChange = (comment: string) => {
    marketAndCompanyApi.updateCategoriesCompetitorsComment(comment)
      .then(handleResponseSuccess)
      .catch(handleResponseFailure)
  }

  return (
    <Grid container spacing={3}>
      <Grid item md={4} />

      <Grid item xs={12} md={4}>
        <FormControl variant="outlined" fullWidth>
          <Select
            ref={selectRef}
            value={queryParams.has('categoryId') ? queryParams.get('categoryId') : filter.optionGroups[0][0].label}
            onChange={handleCategoriesFilterChange}
            MenuProps={{
              MenuListProps: { disablePadding: true },
              style: { width: selectRef.current?.clientWidth }
            }}
          >
            {filter.optionGroups[0].map(({ value, label }) => (
              <MenuItem key={label} value={label}>
                <Typography variant="inherit" noWrap>
                  {value}
                </Typography>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item md={4} />

      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Typography variant="h6" style={{
              fontSize: 20
            }}>
              {collection[CategoriesCompetitorsEndpoints.CHART].title}
            </Typography>
          </CardContent>

          <Chart
            height={300}
            data={normalizeChartData(collection[CategoriesCompetitorsEndpoints.CHART].chart)}
            legend={Object.values(collection[CategoriesCompetitorsEndpoints.CHART].legend).map((label, idx) => ({
              label,
              color: getColorByIdx(idx, undefined, ChartVariants.CategoriesChart)
            }))}
          >
            <ArgumentAxis />
            <ValueAxis />

            <Plugin>
              {Object.entries(collection[CategoriesCompetitorsEndpoints.CHART].legend)
                .map(([key, value], idx) => (
                  <VerticalBarSeries
                    key={idx}
                    name={value}
                    color={getColorByIdx(idx, undefined, ChartVariants.CategoriesChart)}
                    argumentField="argument"
                    valueField={key}
                    chart={collection[CategoriesCompetitorsEndpoints.CHART].chart}
                  />
                ))
              }
            </Plugin>

            <Stack stacks={[
              { series: Object.values(collection[CategoriesCompetitorsEndpoints.CHART].legend) }
            ]} />
            <EventTracker />
            <Tooltip
              contentComponent={({ targetItem: { point, series } }) => {
                const valueKey = Object.keys(collection[CategoriesCompetitorsEndpoints.CHART].legend).find((key) => (
                  series === collection[CategoriesCompetitorsEndpoints.CHART].legend[key]
                ))
                const value = valueKey && collection[CategoriesCompetitorsEndpoints.CHART].chart[point][valueKey]
                return (
                  <>
                    {formatNumber(value, { maximumFractionDigits: 2 })}%
                  </>
                )
              }}
            />
          </Chart>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <DataTable
          title={collection[CategoriesCompetitorsEndpoints.TABLE].title}
          data={collection[CategoriesCompetitorsEndpoints.TABLE].grid}
          onDataUpdate={handleTableUpdate}
        />
      </Grid>

      <Grid item xs={12}>
        <CommentForm
          label={collection[CategoriesCompetitorsEndpoints.COMMENT].title}
          value={collection[CategoriesCompetitorsEndpoints.COMMENT].comment}
          onChange={handleCommentChange}
        />
      </Grid>
    </Grid>
  )
}
