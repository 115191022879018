import React from 'react'
import { ArrayHelpers, FieldArray, Form, Formik } from 'formik'
import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core'
import { Add, Delete } from '@material-ui/icons'
import { useTranslationContext } from 'components/TranslationContext'
import { LoadingButton } from 'components/LoadingButton'
import { TableCellField } from 'components/TableCellField'
import { required } from '../helpers/validators'

interface CustomRegionsTableProps {
  items?: {
    names: {
      id: number, name: string
    }[]
  }
  handleSubmit: (items: {
    names: {
      id: number, name: string
    }[]
  }) => void
  header: string
}

export function CustomFormEditable ({ items, handleSubmit, header } : CustomRegionsTableProps) {
  const { translation } = useTranslationContext()

  if (!items) {
    return null
  }

  const add = (arrayHelpers: ArrayHelpers) => () => {
    arrayHelpers.push('')
  }
  const deleteItem = (arrayHelpers: ArrayHelpers, idx: number) => () => {
    arrayHelpers.remove(idx)
  }

  const requiredField = required(translation['validation.required_field'])

  return (
    <Paper>
      {<Formik
        initialValues={items}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({ values, isSubmitting }) => (
          <Form>
            <FieldArray
              name="names"
              render={(arrayHelpers) => (
                <>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell />

                        <TableCell width=">100%">{header}</TableCell>

                        <TableCell />
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {values.names.map((item, idx) => (
                        <TableRow key={idx}>
                          <TableCell>{idx + 1}</TableCell>

                          <TableCellField
                            validate={requiredField}
                            error={Boolean(!values.names[idx].name)}
                            autoFocus
                            autoComplete={'off'}
                            name={`names[${idx}].name`}
                          />

                          <TableCell padding="none">
                            <IconButton
                              size="small"
                              onClick={deleteItem(arrayHelpers, idx)}
                              disabled={isSubmitting}
                            >
                              <Delete/>
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>

                  <Box p={1} textAlign="right">
                    <Grid container spacing={1} justify="flex-end">
                      <Grid item>
                        <Button
                          color="primary"
                          startIcon={<Add/>}
                          onClick={add(arrayHelpers)}
                          disabled={isSubmitting}
                        >
                          {translation['form.add']}
                        </Button>
                      </Grid>

                      <Grid item>
                        <LoadingButton
                          type="submit"
                          variant="contained"
                          color="primary"
                          disableElevation
                          disabled={isSubmitting}
                          isLoading={isSubmitting}
                        >
                          {translation['form.save']}
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  </Box>
                </>
              )}
            />
          </Form>
        )}
      </Formik>}
    </Paper>
  )
}
