import { marketCharacteristicsApi, ProductCategories } from '../api/marketCharacteristicsApi'
import { ProductCategory } from 'api/categoriesApi'
import { useQueryParams } from '../hooks/queryParams'
import { useState, useEffect, useCallback } from 'react'
import { useLocalStorage } from '../helpers/useLocalStorage'
import { useAuthContext } from 'components/AuthContext'

export interface Options {
  storageKey: string,
  queryParam?: string,
  prepend?: ProductCategory[]
}

export const useProductCategory = ({ storageKey, queryParam = 'categoryId', prepend = [] }: Options) => {
  const [state, setState] = useState<{
    list: ProductCategory[],
    id: number
  }>()
  const { queryParams, setQueryParams } = useQueryParams()
  const [storageCategoryId, setStorageCategoryId] = useLocalStorage(storageKey)
  const [pending, setPending] = useState<boolean>(false)
  const { handleResponseFailure } = useAuthContext()

  useEffect(() => {
    if (state !== undefined || pending) {
      return
    }

    setPending(true)

    ;(async () => {
      try {
        const { productCategoryArr: fetchedList } = await marketCharacteristicsApi.getProductCategories() as ProductCategories
        const list = [...prepend, ...fetchedList]
        const queryCategoryId = queryParams.get(queryParam)
        const queryCategory = queryCategoryId === null ? undefined : list.find(c => `${c.id}` === queryParams.get(queryParam))
        const storageCategory = storageCategoryId === null ? undefined : list.find(c => `${c.id}` === storageCategoryId)
        const category =
          queryCategory !== undefined ? queryCategory
            : storageCategory !== undefined ? storageCategory
              : list[0]

        setQueryParams({ [queryParam]: `${category.id}` })
        setStorageCategoryId(`${category.id}`)
        setState({ list, id: category.id })
      } catch (error) {
        handleResponseFailure(error as any)
      }
    })()
  }, [
    prepend,
    state,
    queryParams,
    queryParam,
    setQueryParams,
    storageCategoryId,
    setStorageCategoryId,
    pending,
    handleResponseFailure
  ])

  const setId = useCallback((id: number): void => {
    if (state !== undefined) {
      setQueryParams({ [queryParam]: `${id}` })
      setStorageCategoryId(`${id}`)
      setState({ ...state, id })
    }
  }, [state, queryParam, setQueryParams, setStorageCategoryId])

  return { state, setId }
}
