import React, { Fragment, useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import { Typography, Box, Button, Menu, MenuItem, ListItemIcon } from '@material-ui/core'
import { Person, ExitToApp, ExpandLess, ExpandMore } from '@material-ui/icons'
import { useAuthContext } from 'components/AuthContext'
import { useTranslationContext } from 'components/TranslationContext'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconFill: { fill: theme.palette.backgrounds.corporateColor }
  })
)

export function LayoutUserMenu () {
  const classes = useStyles()
  const { translation } = useTranslationContext()
  const { currentUser, signOut } = useAuthContext()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const showUserMenu = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget)
  }, [])
  const hideUserMenu = useCallback(() => {
    setAnchorEl(null)
  }, [])

  return (
    <Fragment>
      <Button
        color="default"
        onClick={showUserMenu}
        disableRipple
      >
        <Box px={2} style={{ textTransform: 'none' }} textAlign="right">
          <Typography variant="body2">{currentUser?.username}</Typography>
          <Typography variant="caption">{currentUser?.email}</Typography>
        </Box>

        {anchorEl ? <ExpandLess className={classes.iconFill} /> : <ExpandMore className={classes.iconFill} />}
      </Button>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        getContentAnchorEl={null}
        onClose={hideUserMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuItem component={Link} to="/profile" onClick={hideUserMenu}>
          <ListItemIcon>
            <Person />
          </ListItemIcon>
          {translation['menu.profile.title']}
        </MenuItem>

        <MenuItem onClick={signOut}>
          <ListItemIcon>
            <ExitToApp />
          </ListItemIcon>
          {translation['menu.exit']}
        </MenuItem>
      </Menu>
    </Fragment>
  )
}
