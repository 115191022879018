import React, { useState, useEffect, useRef } from 'react'
import { Box, Paper, TextField } from '@material-ui/core'
import { Cell, useDataTableContext } from 'components/DataTableContext'
import { formatNumber } from 'helpers/formatNumber'

const adjustValue = (value?: string, removeTrailingPoint?: boolean) => (
  value && value
    .replace('.', ',')
    .split('')
    .map((char, idx, str) => {
      // Max 10 digits before point
      if (idx === 10 && char !== ',' && !str.includes(',')) {
        return ''
      }

      // Remove point duplicates
      if (char === ',' && str.indexOf(char) !== idx) {
        return ''
      }

      // Remove leading point
      if (char === ',' && idx === 0) {
        return ''
      }

      // Remove non-numeric characters
      if (!(/\d|,/).test(char)) {
        return ''
      }

      // Remove trailing point
      if (removeTrailingPoint && char === ',' && idx === str.length - 1) {
        return ''
      }

      return char
    })
    .join('')
)

interface DataTableBodyCellEditProps {
  cell: Cell
  onClose: () => void
}

export function DataTableBodyCellEdit ({ cell, onClose }: DataTableBodyCellEditProps) {
  const { value, maxValue, type } = cell
  const ref = useRef<HTMLFormElement>()
  const [newValue, setNewValue] = useState(value)
  const { handdleCellValueChange } = useDataTableContext()
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget

    if (type === 'string') {
      setNewValue(value)
    } else {
      const adjustedValue = adjustValue(value)
      const actualValue = maxValue && Number(adjustedValue?.replace(',', '.')) > maxValue
        ? String(maxValue)
        : adjustedValue

      setNewValue(actualValue)
    }
  }
  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    e.target.select()
  }
  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (type === 'string') {
      handdleCellValueChange(cell, newValue)
    } else {
      const adjustedValue = adjustValue(newValue, true)?.replace(',', '.')
      const formattedValue = formatNumber(adjustedValue, { locale: 'en', useGrouping: false })

      if (formattedValue !== value) {
        handdleCellValueChange(cell, formattedValue)
      }
    }
    onClose()
  }
  const focusNextCell = () => {
    const currentCell = ref.current?.closest('td')
    const cellList: NodeListOf<HTMLTableCellElement> = document?.querySelectorAll('td[tabindex="0"]')

    if (currentCell && cellList) {
      const currentIdx = Array.from(cellList).indexOf(currentCell)
      cellList[currentIdx < cellList.length - 1 ? currentIdx + 1 : 0]?.focus()
    }
  }
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Escape') {
      onClose()
    }
    if (e.key === 'Enter' || e.key === 'Tab') {
      e.preventDefault()
      focusNextCell()
    }
  }
  const handlePaste = (e: React.ClipboardEvent) => {
    e.preventDefault()
    const splitToRows = e.clipboardData.getData('text/plain').split('\n')
    const rowsAndCellsArray = splitToRows.map(el => el.split('\t'))

    const adjustedRows = rowsAndCellsArray.map(cells => cells.map((value) => adjustValue(value)))
    const formattedRows = adjustedRows.map(adjustedCells => adjustedCells.map((value) => (
      value && formatNumber(value.replace(',', '.'), { locale: 'en', useGrouping: false }))
    ))
    setNewValue(formattedRows[0][0])
    handdleCellValueChange(cell, undefined, formattedRows)
  }

  useEffect(() => {
    setNewValue(type === 'string' ? value : formatNumber(value, { useGrouping: false }))
  }, [value, type])

  return (
    <Box
      width="calc(100% + 48px)"
      minWidth="80px"
      position="absolute"
      zIndex="1"
      top="50%"
      left="50%"
      style={{ transform: 'translate(-50%, -50%)' }}
    >
      <Paper ref={ref}>
        <TextField
          variant="outlined"
          inputProps={{ style: { textAlign: 'center' } }}
          value={newValue || ''}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          onPaste={handlePaste}
          autoFocus
        />
      </Paper>
    </Box>
  )
}
