import React from 'react'
import { ConsumerPage } from './ConsumerPage'
import { Page } from 'components/Page'
import { useTranslationContext } from 'components/TranslationContext'

export function MarketPositioningPage () {
  const { translation } = useTranslationContext()

  const tabs = [
    {
      label: translation['menu.units_company_consumer'],
      url: 'consumer',
      component: ConsumerPage
    }
  ]

  return (
    <Page title={translation['menu.positioning']} tabs={tabs} />
  )
}
