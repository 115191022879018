import { request } from 'api/request'
import { Table, Comment } from 'api/types'
import { Rnd } from '../types/tabsCommon'

interface Chart {
  title: string
  chart: Record<string, string | number>[]
  legend: Record<string, string>
  units: {
    leftAxis?: string
    rightAxis?: string
  }
}

export interface CollectionParams {
  rnd: Rnd,
  type: string,
  categoryId: number,
  marketRoleId: number
}

export interface StandardsResult {
  tab: Table,
  pie: Chart,
  pieFootnote: Chart,
  comment: Comment
}

export interface TargetsResult {
  budgetTab: Table,
  targetTab: Table,
  structTab: Table,
  pie: Chart,
  pieFootnote: Chart,
  comment: Comment
}

export interface MarketRole {
  id: number,
  name: string
}

export interface StandardType {
  id: string,
  name: string
}

export enum FiltersRnd {
  hidden = 'hidden'
}

export interface Filters {
  marketRoleId: number,
  type: string,
  rnd: Rnd | FiltersRnd
}

const prefix = 'market_standards/as'

const getCollection = async <Result extends Record<keyof Result, unknown>>(endpoints: Record<keyof Result, string>): Promise<Result> => {
  const result = {} as Record<keyof Result, any>
  const keys = Object.keys(endpoints) as (keyof Result)[]
  const objects = Object.values(endpoints)
  const filters = {}
  const response = await request('collection', 'POST', { objects, filters })

  for (const key of keys) {
    result[key] = response[endpoints[key]]
  }

  return result
}

export function getStandards ({ rnd, type, categoryId, marketRoleId }: CollectionParams) {
  const tab = `${prefix}/standard/as/tab/${rnd}/${categoryId}/${marketRoleId}`
  const pie = `${prefix}/standard/struct/pie/${rnd}/${type}/${categoryId}/${marketRoleId}`
  const comment = `${prefix}/standard/comment`

  return getCollection<StandardsResult>({
    tab,
    pie: `${pie}/`,
    pieFootnote: `${pie}/footnote`,
    comment
  })
}

export function getMarketRoles (): Promise<MarketRole[]> {
  return request(`${prefix}/standard/market-roles`)
}

export function getStandardTypes (): Promise<StandardType[]> {
  return request(`${prefix}/standard/types`)
}

export async function updateStandardComment (comment: string): Promise<void> {
  await request(`comment/${prefix}/standard/comment`, 'PUT', { comment })
}

export function getTargets ({ rnd, type, categoryId, marketRoleId }: CollectionParams) {
  const budgetTab = `${prefix}/budget/tab/${rnd}/${type}/${categoryId}`
  const targetTab = `${prefix}/target/tab/${rnd}/${type}/${categoryId}/${marketRoleId}`
  const structTab = `${prefix}/standard/struct/tab/${rnd}/${type}/${categoryId}/${marketRoleId}`
  const pie = `${prefix}/target/struct/pie/${rnd}/${type}/${categoryId}/${marketRoleId}`
  const comment = `${prefix}/target/comment`

  return getCollection<TargetsResult>({
    budgetTab,
    targetTab,
    structTab,
    pie: `${pie}/`,
    pieFootnote: `${pie}/footnote`,
    comment
  })
}

export async function updateTargetComment (comment: string): Promise<void> {
  await request(`comment/${prefix}/target/comment`, 'PUT', { comment })
}

export async function updateBudgetTab (
  grid: Table['grid'],
  { rnd, type, categoryId }: Omit<CollectionParams, 'marketRoleId'>
): Promise<void> {
  return request(`${prefix}/budget/tab/${rnd}/${type}/${categoryId}`, 'PUT', { grid })
}

export async function getFilters (categoryId: number): Promise<Filters> {
  const { market_role: marketRoleId, type, rnd } = await request(`${prefix}/standard/filter/${categoryId}`)

  return { marketRoleId, type, rnd }
}

export async function updateFilters (categoryId: number, { marketRoleId, type, rnd }: Omit<Filters, 'rnd'> & { rnd: boolean }): Promise<void> {
  await request(`${prefix}/standard/filter/${categoryId}`, 'PUT', { market_role: marketRoleId, type, rnd })
}
