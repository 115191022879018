import React, { useEffect, useState, useCallback } from 'react'
import {
  Box,
  Button,
  FormControl,
  Grid,
  Input,
  Paper, Table, TableBody, TableCell,
  TableContainer, TableHead,
  TableRow
} from '@material-ui/core'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import { CloudDownload, CloudUpload } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import { staticalManagementApi, Endpoint } from '../../api/staticalManagementApi'
import { useSnackbar } from 'notistack'
import { useTranslationContext } from '../../components/TranslationContext'
import { Pagination } from 'components/Pagination'
import { useAuthContext } from 'components/AuthContext'

const useStyles = makeStyles({
  root: {
    width: 120
  }
})
export function IndicatorsPage () {
  const classes = useStyles()
  const { translation } = useTranslationContext()

  const [years, setYears] = useState<string[]>([])
  const [activeYear, setActiveYear] = useState<string>()
  const [appendYear, setAppendYear] = useState<string>('')
  const [totals, setTotals] = useState<{ [x: string]: number }>({})

  const [collection, setCollection] = useState(null)
  const [collectionUpdateTick, setCollectionUpdateTick] = useState(1)

  const { enqueueSnackbar } = useSnackbar()
  const { handleResponseFailure } = useAuthContext()

  useEffect(() => {
    staticalManagementApi.getYears(Endpoint.indicator)
      .then((res) => {
        setTotals(res)
        const years = Object.keys(res).sort()
        setYears(years)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [collectionUpdateTick])

  useEffect(() => {
    if (years.length > 0 && activeYear === undefined) {
      setActiveYear(years[0])
    }
  }, [years, activeYear])

  const loadData = useCallback((page, limit) => {
    if (activeYear === undefined) {
      return
    }

    staticalManagementApi.getData({
      endpoint: Endpoint.indicator,
      page,
      limit,
      year: activeYear
    })
      .then((collection) => {
        setCollection(collection)
      })
      .catch((err) => {
        console.error(err)
      })
  }, [activeYear])

  const handleUpload = async (csv: Blob | string | null) => {
    if (csv && activeYear) {
      staticalManagementApi.uploadCsv({ csv, endpoint: Endpoint.indicator, year: activeYear })
        .then((response) => {
          if (response.empty > 0) {
            enqueueSnackbar(translation['components.IndicatorsPage.zero_value'], { variant: 'success' })
          } else {
            enqueueSnackbar(translation['components.IndicatorsPage.success_upload'].replace('%year%', activeYear), { variant: 'success' })
          }
          setCollectionUpdateTick(collectionUpdateTick % 2 + 1)
        }).catch(handleResponseFailure)
    }
  }

  const collectionByActiveYear = collection && activeYear ? collection ?? [] : []

  const onTabChange = (event: React.MouseEvent<HTMLElement>, year: string) => {
    if (year !== null) {
      setActiveYear(year)
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAppendYear(event.target.value as string)
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <ToggleButtonGroup
          value={activeYear}
          onChange={onTabChange}
          exclusive
        >
          { years.map(year => <ToggleButton classes={classes} value={year}>
            {year}
          </ToggleButton>) }
        </ToggleButtonGroup>
      </Grid>
      <Grid item xs={12}>
        <Box>
          <FormControl>
            <Input
              type={'text'}
              value={appendYear}
              onChange={handleChange}
            />
          </FormControl>

          <Button
            style={{ marginLeft: 10 }}
            onClick={() => {
              setYears(years.concat([appendYear]))
            }}
            disabled={(years.indexOf(appendYear) !== -1) || !(/^\d{4}$/.test(appendYear))} variant="contained" color="primary">
            {translation['components.IndicatorsPage.add_year']}
          </Button>
        </Box>
      </Grid>
      <Grid item md={8} xs={12}>
        <Box py={2} display='inline-flex'>
          <Box>
            <Button
              onClick={() => staticalManagementApi.downloadCsv({ endpoint: Endpoint.indicator, year: activeYear as string })}
              variant="contained"
              color="primary"
              style={{ margin: 2 }}
              startIcon={<CloudDownload />}
              disabled={!activeYear || collectionByActiveYear.length === 0}
            >
              {translation['components.IndicatorsPage.download_csv']}
            </Button>
          </Box>
          <Box>
            <Button
              variant="contained"
              color="primary"
              component="label"
              style={{ margin: 2 }}
              startIcon={<CloudUpload />}
              disabled={!activeYear}
            >
              <input
                onChange={(event) => {
                  handleUpload(event.currentTarget.files![0])
                  event.target.value = ''
                }}
                name="indicatorCsv"
                type="file"
                hidden
                accept="text/csv"
              />
              {translation['components.IndicatorsPage.upload_csv']}
            </Button>
          </Box>
        </Box>
        <TableContainer component={Paper}>
          <Table>
            { collectionByActiveYear.length > 0 && <TableHead>
              <TableRow>
                <TableCell>{translation['components.IndicatorsPage.region']}</TableCell>
                <TableCell align="left">{translation['components.IndicatorsPage.subject']}</TableCell>
                <TableCell align="left">{translation['components.IndicatorsPage.indicator']}</TableCell>
                <TableCell align="left">%</TableCell>
              </TableRow>
            </TableHead>
            }
            <TableBody>
              { collectionByActiveYear.map(({ indicator, region, subject, value }, idx) => (
                <TableRow key={idx}>
                  <TableCell align="left">{region}</TableCell>
                  <TableCell align="left">{subject}</TableCell>
                  <TableCell align="left">{indicator}</TableCell>
                  <TableCell align="left">{value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {!!activeYear && (
          <Pagination
            loader={loadData}
            deps={[activeYear, collectionUpdateTick]}
            total={totals[+activeYear]}
            limit={10}
          />
        )}
      </Grid>
    </Grid>
  )
}
