import { request } from 'api/request'
import { Table } from './types'

const prefix = 'operational_part/price_sensitivity_meter'

export interface Step1Result {
  skuTab: Table
}

export interface ChartInterface {
  title: string
  chart: Record<string, number | string>[]
  legend: Record<string, string>
  units: {
    leftAxis?: string
    rightAxis?: string
  }
}

export interface Step2Result {
  surveyTab: Table
  crossPoints: ChartInterface
  graphVol: ChartInterface
}

const getCollection = async <Result extends Record<keyof Result, unknown>>(endpoints: Record<keyof Result, string>): Promise<Result> => {
  const result = {} as Record<keyof Result, any>
  const keys = Object.keys(endpoints) as (keyof Result)[]
  const objects = Object.values(endpoints)
  const filters = {}
  const response = await request('collection', 'POST', { objects, filters })

  for (const key of keys) {
    result[key] = response[endpoints[key]]
  }

  return result
}

export function getStep1Collections (): Promise<Step1Result> {
  const skuTab = `${prefix}/sku/tab`

  return getCollection<Step1Result>({
    skuTab
  })
}

export function getStep2Collections (skuId: string): Promise<Step2Result> {
  const surveyTab = `${prefix}/survey/tab/${skuId}`
  const crossPoints = `${prefix}/сross_points/graph/${skuId}`
  const graphVol = `${prefix}/survey_result/graph_vol/${skuId}`

  return getCollection<Step2Result>({
    surveyTab,
    crossPoints,
    graphVol
  })
}

export async function updateSkuTab (grid: Table['grid']): Promise<Table> {
  return request(`${prefix}/sku/tab`, 'PUT', { grid })
}

export async function updateSurveyTab (skuId: string, grid: Table['grid']): Promise<Table> {
  return request(`${prefix}/survey/tab/${skuId}`, 'PUT', { grid })
}
