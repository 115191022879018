import { TOKEN_ITEM } from 'api/authApi'
import { LANGUAGE_ITEM, defaultLanguage } from 'api/translationApi'

export function handleResponseSuccess (response: Response) {
  const contentType = response.headers.get('content-type')
  const mimeType = contentType?.split(';').shift()

  switch (mimeType) {
    case 'text/plain':
      return response.text()
    case 'application/pdf':
    case 'image/tiff':
    case 'image/png':
    case 'image/svg+xml':
    case 'image/svg':
    case 'image/gif':
      return response.blob()
    case 'text/csv':
    case 'application/xlsx':
      return response
    case 'application/json':
    default:
      return response.json()
  }
}

export async function handleResponseFailure (response: Response) {
  try {
    const error = await response.json()
    return Promise.reject(error)
  } catch (error) {
    return Promise.reject(response.statusText)
  }
}

export function handleResponse (response: Response) {
  const { ok, status } = response

  if (ok && status >= 200 && status < 300) {
    if (status === 204) return

    return handleResponseSuccess(response)
  } else {
    return handleResponseFailure(response)
  }
}

export function createUrl (endpoint : string) {
  const language = window.localStorage.getItem(LANGUAGE_ITEM) || defaultLanguage
  return `/api/${language}/v1.0/${endpoint}`
}

export function request (endpoint: string, method?: string, data?: Object | FormData) {
  const token = window.localStorage.getItem(TOKEN_ITEM)
  const language = window.localStorage.getItem(LANGUAGE_ITEM) || defaultLanguage
  const url = `/api/${language}/v1.0/${endpoint}`
  const body = data instanceof FormData ? data : JSON.stringify(data)
  const headers = { Authorization: `Bearer ${token}` }
  return fetch(url, { body, method, headers }).then(handleResponse)
}
