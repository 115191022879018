import React, { CSSProperties } from 'react'
import {
  Paper,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
  Tooltip,
  TableCell
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { DataTableContextProvider, Data, DataUpdateHandler, CustomColumn } from 'components/DataTableContext'
import { DataTableHeadCell } from 'components/DataTableHeadCell'
import { DataTableBody } from 'components/DataTableBody'
import { TextWithBreaks } from 'components/TextWithBreaks'
import { HelpOutline } from '@material-ui/icons'
import { CopyButton } from './CopyButton'
import { StyledTable } from './StyledTable'

import type { CellClickHandler } from 'components/DataTableContext'
export type { Data, DataUpdateHandler, Column, Row, Cell, CellClickHandler } from 'components/DataTableContext'

interface DataTableProps {
  title?: string
  description?: string
  hint?: string
  data: Data
  onDataUpdate?: DataUpdateHandler
  columnsWidth?: string[]
  customColumns?: CustomColumn[],
  highlightedRows?: string | null
  showYear?: boolean
  cellStyle?: Record<string, Record<string, CSSProperties>>
  cellClick?: Record<string, Record<string, CellClickHandler>>
  footer?: () => JSX.Element
  height?: string
}

const useStyles = makeStyles({
  hideLastBorder: {
    '& tbody tr:last-child td': {
      border: 0
    }
  },
  tableTitle: {
    fontSize: 20,
    fontWeight: 500
  }
})

export function DataTable ({ title, description, hint, data, onDataUpdate, columnsWidth, customColumns = [], highlightedRows, showYear, cellStyle, cellClick, footer, height }: DataTableProps) {
  const classes = useStyles()

  const spanTitles = data.columns.filter(({ spanTitle }) => !!spanTitle && !parseInt(spanTitle))
  const year = data.columns.find(({ spanTitle }) => parseInt(spanTitle || ''))

  return (
    <DataTableContextProvider data={data} onDataUpdate={onDataUpdate}>
      <Paper>
        <TableContainer>
          <Box px={2} pt={2} pb={1} display="inline-flex" width="100%" alignItems="center">
            <Box display="flex" justifyContent="flex-start" alignItems="center" flexBasis="100%">
              {title && (
                <Typography className={classes.tableTitle}>
                  {title}
                </Typography>
              )}
              {hint && (
                <Box px={2}>
                  <Tooltip
                    title={
                      <Typography color="inherit" variant="body2">
                        <TextWithBreaks text={hint} />
                      </Typography>
                    }
                  >
                    <HelpOutline color="action" />
                  </Tooltip>
                </Box>
              )}
            </Box>
            <Box display="flex" justifyContent="flex-end" flexBasis="100%">
              <CopyButton data={data} />
            </Box>
          </Box>
          <Box style={{ height: height, overflowY: height === undefined ? 'visible' : 'scroll' }}>
            <StyledTable className={classes.hideLastBorder}>
              <TableHead>
                <TableRow>
                  {(showYear && !year) && (
                    <TableCell align="center" colSpan={2}>
                    </TableCell>
                  )}
                  {(showYear && !!spanTitles.length) && spanTitles.map((column) => (
                    <TableCell colSpan={column.spanColumnsNumber} align="center" key={column.spanTitle}>
                      {column.spanTitle}
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  {data.columns.map((column, idx) => (
                    <DataTableHeadCell
                      key={column.title}
                      column={column}
                      width={columnsWidth && columnsWidth[idx]}

                    />
                  ))}

                  {customColumns.map((column, idx) => (
                    <TableCell width={columnsWidth && columnsWidth[idx + data.columns.length]}>
                      {column.title}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <DataTableBody highlightedRows={highlightedRows} cellStyle={cellStyle} cellClick={cellClick} footer={footer} customColumns={customColumns}/>
            </StyledTable>
          </Box>
        </TableContainer>
      </Paper>
      {description && (
        <Box mt={1}>
          <Typography variant="caption" color="textSecondary">
            <TextWithBreaks text={description} />
          </Typography>
        </Box>
      )}
    </DataTableContextProvider>
  )
}
