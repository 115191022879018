import { Card, CardContent, FormControl, Grid, MenuItem, Select, Typography } from '@material-ui/core'
import {
  Chart,
  ChartVariants,
  EventTracker,
  getColorByIdx,
  normalizeChartData,
  PieSeries, Tooltip
} from '../../../components/Chart'
import React, { Dispatch, MutableRefObject, SetStateAction } from 'react'
import { ChartData } from '../../../api/categoriesApi'

interface IGeoPercentGraph {
  percentGraph: ChartData,
  year: string | null,
  setYear: Dispatch<SetStateAction<string | null>>
  selectRef: MutableRefObject<HTMLDivElement | undefined>
}

export default function GeoPercentGraph ({ percentGraph, year, selectRef, setYear }: IGeoPercentGraph) {
  const pieChartData = Object.keys(percentGraph.legend).map(el => ({
    name: percentGraph.legend[el], value: percentGraph.chart[Number(year) || Number(Object.keys(percentGraph.chart)[0])][el]
  }))

  return (
    <Card>

      <CardContent>

        <Grid container spacing={3}>
          <Grid item>

            <Typography variant="h6" paragraph>
              {percentGraph.title}
            </Typography>
          </Grid>

          <Grid item>
            <FormControl variant="outlined" fullWidth>
              <Select
                ref={selectRef}
                value={year || ('' + Object.keys(percentGraph.chart)[0])}
                onChange={(e) => {
                  setYear(e.target.value as string)
                }}
                MenuProps={{
                  MenuListProps: { disablePadding: true },
                  style: { width: selectRef.current?.clientWidth }
                }}
              >

                {Object.keys(percentGraph.chart).map((el) => (
                  <MenuItem key={el.toString()} value={el}>
                    <Typography variant="inherit" noWrap>
                      {el}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

        </Grid>

      </CardContent>

      <Chart
        height={340}
        data={normalizeChartData(pieChartData)}
        legend={Object.values(percentGraph.legend).map((label, idx) => ({
          label,
          color: getColorByIdx(idx, undefined, ChartVariants.GeoDistribution)
        }))}
      >
        <PieSeries
          argumentField="name"
          valueField="value"
          color={index => getColorByIdx(index, undefined, ChartVariants.GeoDistribution)}
        />

        <EventTracker />
        <Tooltip />
      </Chart>
    </Card>
  )
}
