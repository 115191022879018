import React, { useEffect, useState } from 'react'
import { ArrayHelpers, FieldArray, Form, Formik } from 'formik'
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Paper,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core'
import { Add, Delete } from '@material-ui/icons'
import { useAuthContext } from 'components/AuthContext'
import { useTranslationContext } from 'components/TranslationContext'
import { useLayoutContext } from 'components/LayoutContext'
import { LoadingButton } from 'components/LoadingButton'
import { TableCellField } from 'components/TableCellField'
import { companyCharacteristicsApi, SalesStructure } from 'api/companyCharacteristicsApi'
import { required } from 'helpers/validators'
import { StyledTable } from '../../components/StyledTable'
import { StyledTypography } from '../../components/StyledTypography'

export function SalesStructurePage () {
  const { handleResponseSuccess, handleResponseFailure } = useAuthContext()
  const { startContentLoading, finishContentLoading } = useLayoutContext()
  const { translation } = useTranslationContext()
  const [salesStructure, setSalesStructure] = useState<SalesStructure>()
  const addElement = (arrayHelpers: ArrayHelpers) => () => {
    arrayHelpers.push('')
  }
  const deleteElement = (arrayHelpers: ArrayHelpers, idx: number) => () => {
    arrayHelpers.remove(idx)
  }
  const handleSubmit = (salesStructure: SalesStructure) => {
    return companyCharacteristicsApi.updateSalesStructure(salesStructure)
      .then(() => handleResponseSuccess())
      .catch(handleResponseFailure)
  }

  useEffect(() => {
    startContentLoading()

    companyCharacteristicsApi.getSalesStructure()
      .then(setSalesStructure)
      .catch(handleResponseFailure)
      .finally(finishContentLoading)
  }, [finishContentLoading, startContentLoading, handleResponseFailure])

  if (!salesStructure) {
    return null
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Paper>
          <Formik
            initialValues={salesStructure}
            onSubmit={handleSubmit}
          >
            {({ values, isSubmitting }) => (
              <Form>
                <FieldArray
                  name="elements"
                  render={(arrayHelpers) => (
                    <>
                      <StyledTable>
                        <TableHead>
                          <TableRow>
                            <TableCell></TableCell>

                            <TableCell width="100%">{translation['pages.SalesStructurePage.grid.col.name']}</TableCell>

                            <TableCell></TableCell>
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {values.elements.map((element, idx) => (
                            <TableRow key={idx}>
                              <TableCell>{idx + 1}</TableCell>

                              <TableCellField
                                name={`elements[${idx}].structureName`}
                                autoFocus
                                validate={required(translation['validation.required_field'])}
                              />

                              <TableCell padding="none">
                                <IconButton
                                  size="small"
                                  onClick={deleteElement(arrayHelpers, idx)}
                                  disabled={isSubmitting}
                                >
                                  <Delete />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </StyledTable>

                      <Box p={1} textAlign="right">
                        <Grid container spacing={1} justify="flex-end">
                          <Grid item>
                            <Button
                              color="primary"
                              startIcon={<Add />}
                              onClick={addElement(arrayHelpers)}
                              disabled={isSubmitting}
                            >
                              {translation['form.add']}
                            </Button>
                          </Grid>

                          <Grid item>
                            <LoadingButton
                              type="submit"
                              variant="contained"
                              color="primary"
                              disableElevation
                              disabled={isSubmitting}
                              isLoading={isSubmitting}
                            >
                              {translation['form.save']}
                            </LoadingButton>
                          </Grid>
                        </Grid>
                      </Box>
                    </>
                  )}
                />
              </Form>
            )}
          </Formik>
        </Paper>
      </Grid>

      <Grid item xs={12} md={6}>
        <Card>
          <CardContent>
            <StyledTypography>
              {salesStructure.description}
            </StyledTypography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}
