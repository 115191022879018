import React from 'react'
import { Card, CardContent, Typography, useTheme } from '@material-ui/core'
import {
  ArgumentAxis,
  BarSeries,
  Chart,
  ChartVariants,
  EventTracker,
  getColorByIdx,
  normalizeChartData,
  Stack,
  Tooltip,
  ValueAxis,
  ValuesBarSeries,
  ValueScale
} from 'components/Chart'
import { ChartData } from 'api/categoriesApi'

export function SalesAndRevenueChart (props: ChartData) {
  const { palette } = useTheme()
  const { title, legend, units, chart } = props
  const recalculateForecast = (actual?: number, forecast?: number) => {
    const adjustedForecast = (actual && forecast) ? forecast - actual : forecast
    return adjustedForecast && (adjustedForecast > 0 ? adjustedForecast : 0)
  }
  const maxSalesValue = normalizeChartData(chart)?.reduce((prev: number, item: Record<string, number>) => {
    const values = [prev, item.salesActual, item.salesForecast].filter(isFinite)
    return Math.max(...values)
  }, 0)
  const maxProfitValue = normalizeChartData(chart)?.reduce((prev: number, item: Record<string, number>) => {
    const values = [prev, item.profitActual, item.profitForecast].filter(isFinite)
    return Math.max(...values)
  }, 0)
  const recalculatedChart = normalizeChartData(chart)?.map((item: Record<string, number>) => ({
    ...item,
    salesForecast: recalculateForecast(item.salesActual, item.salesForecast),
    profitForecast: recalculateForecast(item.profitActual, item.profitForecast),
    salesValues: Number(maxSalesValue) * 0.1,
    profitValues: Number(maxProfitValue) * 0.1
  }))

  return (
    <Card>
      <CardContent>
        <Typography variant="h6" paragraph>
          {title}
        </Typography>
      </CardContent>

      <Chart
        height={300}
        leftAxisUnit={units.leftAxis}
        rightAxisUnit={units.rightAxis}
        data={recalculatedChart}
        legend={[
          {
            label: legend.salesActual,
            color: getColorByIdx(0, undefined, ChartVariants.MarketVolumeLegend)
          },
          {
            label: legend.profitActual,
            color: getColorByIdx(2, undefined, ChartVariants.MarketVolumeLegend)
          }
        ]}
      >
        <ValueScale name="sales" />
        <ValueScale name="profit" />
        <ArgumentAxis />
        <ValueAxis
          scaleName="sales"
        />
        <ValueAxis
          scaleName="profit"
          position="right"
          showGrid={false}
        />
        <BarSeries
          name="salesActual"
          argumentField="argument"
          valueField="salesActual"
          scaleName="sales"
          color={getColorByIdx(0, undefined, ChartVariants.MarketVolumeFact)}
        />
        <BarSeries
          name="salesForecast"
          argumentField="argument"
          valueField="salesForecast"
          scaleName="sales"
          color={getColorByIdx(3, undefined, ChartVariants.MarketVolumeForecast)}
        />
        <ValuesBarSeries
          name="salesValues"
          argumentField="argument"
          valueField="salesValues"
          valueKeys={['salesActual', 'salesForecast']}
          valueColors={[palette.text.primary, palette.text.secondary]}
          chart={chart}
          scaleName="sales"
        />
        <BarSeries
          name="profitActual"
          argumentField="argument"
          valueField="profitActual"
          scaleName="profit"
          color={getColorByIdx(2, undefined, ChartVariants.MarketVolumeFact)}
        />
        <BarSeries
          name="profitForecast"
          argumentField="argument"
          valueField="profitForecast"
          scaleName="profit"
          color={getColorByIdx(5, undefined, ChartVariants.MarketVolumeForecast)}
        />
        <ValuesBarSeries
          name="profitValues"
          argumentField="argument"
          valueField="profitValues"
          valueKeys={['profitActual', 'profitForecast']}
          valueColors={[palette.text.primary, palette.text.secondary]}
          chart={chart}
          scaleName="profit"
        />
        <Stack
          stacks={[
            { series: ['salesActual', 'salesForecast', 'salesValues'] },
            { series: ['profitActual', 'profitForecast', 'profitValues'] }
          ]}
        />
        <EventTracker />
        <Tooltip ignoreSeries={['salesValues', 'profitValues']} />
      </Chart>
    </Card>
  )
}
