import { Country, Region } from './types'
import { request } from './request'

export interface Countries {
  countries: Country[]
}

export function getCountriesList () {
  return request('country/list')
}
export function getCompanyCountriesList () {
  return request('country/all-of-the-company')
}

export function getRegionList () {
  return request('region/list')
}

export function getSubjectList () {
  return request('subject/list')
}

function getYears () {
  return request('population/years')
}

export async function getCustomRegions () {
  const res:{id: number, name: string}[] = await request('region/custom/list')
  return res.sort((a, b) => a.id === b.id ? 0 : (a.id > b.id ? 1 : -1))
}

export function setCustomRegions (customRegionArr: Region[]) {
  return request('region/custom', 'PUT', { customRegionArr })
}

export const countriesApi = {
  getCountriesList,
  getCompanyCountriesList,
  getRegionList,
  getCustomRegions,
  setCustomRegions,
  getSubjectList,
  getYears
}
