import { request } from './request'

export enum Endpoint {
  ttcount = 'ttcount',
  population = 'population',
  indicator = 'indicator/subject',
  advertising = 'market_standards/as/standard'
}

function getYears (endpoint: Endpoint) {
  return request(`${endpoint}/list/years`)
}

interface DataType {
  endpoint: Endpoint
  page: number
  limit: number
  year: string | null
}

function getData ({ endpoint, page, limit, year }: DataType) {
  return request(`${endpoint}/list?limit=${limit}&page=${page}&year=${year}`)
}

function getBranches () {
  return request(`${Endpoint.advertising}/branches`)
}

function getAllIndicators () {
  return request('indicator/list')
}

async function downloadCsv ({ endpoint, year } : Partial<UploadCsvParams>) {
  const response = await request(`${endpoint}/download?year=${year}`)
  const url = window.URL.createObjectURL(await response.blob())
  const link = document.createElement('a')
  const dispositionHeader = response.headers.get('content-disposition')
  link.setAttribute(
    'download',
    dispositionHeader.match(/filename=(.*)/)[1]
  )
  link.href = url
  document.body.appendChild(link)
  link.click()
}

interface UploadCsvParams {
  csv: Blob | string | null
  endpoint: Endpoint
  year: string
}

function uploadCsv ({ csv, endpoint, year } : UploadCsvParams) {
  const formData = new FormData()
  csv && formData.append('file', csv)
  year && formData.append('year', year)
  return request(`${endpoint}/upload`, 'POST', formData)
}

function getProductCategoryIndicator (categoryId: number) {
  return request('companies/product_categories_indicator/' + categoryId)
}

function setProductCategoryIndicator (category: number, indicator: number) {
  return request('companies/product_categories_indicator', 'POST', { category, indicator })
}

export const staticalManagementApi = {
  getYears,
  getBranches,
  getData,
  getAllIndicators,
  uploadCsv,
  downloadCsv,
  getProductCategoryIndicator,
  setProductCategoryIndicator
}
