import React, { Fragment, useEffect, useState } from 'react'
import {
  Grid,
  Button,
  Box
} from '@material-ui/core'
import { DataSources } from '../../components/common/DataSources'
import { countriesApi } from '../../api/countriesApi'
import { Region, Regions } from '../../api/types'
import { ISalesGeographyAnswers } from '../GeographyPage/ISalesGeographyAnswers'
import { commonInfoApi } from '../../api/commonInfoApi'
import { useLayoutContext } from '../../components/LayoutContext'
import { useAuthContext } from '../../components/AuthContext'
import { marketCharacteristicsApi, ProductCategories } from '../../api/marketCharacteristicsApi'
import { RosstatTab } from './RosstatTab'
import { ManualInputTab } from './ManualInputTab'
import { marketStandartApi, Source, isSource, Filters } from '../../api/marketStandartApi'
import { useQueryParams } from '../../hooks/queryParams'
import { useTranslationContext } from '../../components/TranslationContext'

interface State extends Partial<Filters> {
  filters?: Filters,
}

export function OutletSalesStandardsPage () {
  const { startContentLoading, finishContentLoading } = useLayoutContext()
  const [standardRussiaRegions, setStandardRussiaRegions] = useState<Regions | null>(null)
  const [personalClassification, setPersonalClassification] = useState<boolean>()
  const [userRegions, setUserRegions] = useState<Regions | null>(null)
  const [productCategories, setProductCategories] = useState<ProductCategories>()
  const { queryParams, setQueryParams } = useQueryParams()
  const { translation } = useTranslationContext()

  const getStateFromQuery = (): State => {
    const source = queryParams.get('source')

    const result:State = {}

    if (isSource(source)) {
      result.source = source
    }

    return result
  }

  const [state, setState] = useState<State>(getStateFromQuery())
  const [stateLoading, setStateLoading] = useState<boolean>(false)

  const { handleResponseFailure, handleResponseSuccess, currentCompany } = useAuthContext()

  useEffect(() => {
    if (!productCategories) {
      marketCharacteristicsApi.getProductCategories()
        .then(setProductCategories)
        .catch(handleResponseFailure)
    }
  }, [productCategories, handleResponseFailure])

  useEffect(() => {
    if (!standardRussiaRegions) {
      startContentLoading()
      countriesApi.getRegionList()
        .then((items : Region[]) => {
          setStandardRussiaRegions({ items })
        })
        .finally(finishContentLoading)
    }
  }, [standardRussiaRegions, finishContentLoading, startContentLoading])

  useEffect(() => {
    if (stateLoading || state.filters !== undefined) {
      return
    }

    setStateLoading(true)

    ;(async () => {
      try {
        const filters = await marketStandartApi.getFilters()
        const params: Partial<Filters> = {
          source: filters.source,
          ...state
        }

        setState({ ...params, filters })
        setQueryParams(params)
      } catch (error) {
        handleResponseFailure(error as any)
      }
    })()
  }, [handleResponseFailure, state, setState, setQueryParams, stateLoading, setStateLoading])

  useEffect(() => {
    (async () => {
      startContentLoading()
      const { personalClassification }: ISalesGeographyAnswers = await commonInfoApi.getSalesGeographyAnswers()
        .finally(finishContentLoading)
      setPersonalClassification(personalClassification)
      if (personalClassification) {
        const items = await countriesApi.getCustomRegions()
        setUserRegions({ items })
      } else {
        setUserRegions(standardRussiaRegions)
      }
    })()
  }, [standardRussiaRegions, finishContentLoading, startContentLoading])

  if (!currentCompany || !userRegions || !standardRussiaRegions || !productCategories || !state.filters) {
    return null
  }

  const setSource = (source: Source) => () => {
    setQueryParams<Partial<Filters>>({ source })
    setState({ ...state, source })
  }

  const isFiltersChanged = (): boolean => {
    const { source, filters } = state

    return filters !== undefined && (
      (source !== undefined && source !== filters.source)
    )
  }

  const handleUpdateFilters = async () => {
    const { source, filters } = state

    if (filters === undefined) {
      return
    }

    const newFilters: Filters = {
      source: source === undefined ? filters.source : source
    }

    setState({ ...state, filters: newFilters })

    try {
      await marketStandartApi.updateFilters(newFilters)
      handleResponseSuccess()
    } catch (error) {
      handleResponseFailure(error as any)
    }
  }

  const tabsProps = {
    productCategories,
    userRegions,
    personalClassification,
    standardRussiaRegions
  }

  const source = state.source === undefined ? state.filters.source : state.source

  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <DataSources
            rosstatActive={source === Source.stat}
            manualInputActive={source === Source.manual}
            excelInputActive={source === Source.excel}
            onManualInputClick={setSource(Source.manual)}
            onRosstatClick={setSource(Source.stat)}
            onExcelInputClick={setSource(Source.excel)}
            excel
            manual
            rosstat
          />
          {isFiltersChanged() &&
            <Box mb={4}>
              <Button
                color="primary"
                variant="contained"
                onClick={handleUpdateFilters}
                disabled={false}
              >
                {translation['pages.OutletSalesPage.source_select_button']}
              </Button>
            </Box>
          }
        </Grid>
        { /* <Grid xs={12} md={6}> */ }
        { /*  <img */ }
        { /*    src='/outlets_sales_map.png' */ }
        { /*    style={{ width: '100%' }} */ }
        { /*    alt='outlets sales' */ }
        { /*  /> */ }
        { /* </Grid> */ }
      </Grid>

      { source === Source.manual && <ManualInputTab {...tabsProps} /> }
      { source === Source.excel && <ManualInputTab {...tabsProps} excel /> }
      { source === Source.stat && <RosstatTab {...tabsProps} /> }

    </Fragment>
  )
}
