import React from 'react'
import {
  Card,
  CardContent,
  Typography
} from '@material-ui/core'
import {
  EventTracker,
  ArgumentAxis,
  Chart,
  LineSeries,
  ValueAxis,
  Tooltip,
  normalizeChartData,
  getColorByIdx
} from 'components/Chart'
import { GraphVolCommon } from 'api/marketAndCompanyApi'

export function AverageSellPriceChart (props: GraphVolCommon) {
  const { title, units, chart, legend } = props
  return (
    <Card>
      <CardContent>
        <Typography variant="h6" paragraph>
          {title}
        </Typography>
      </CardContent>

      <Chart
        height={300}
        leftAxisUnit={units.leftAxis}
        data={normalizeChartData(chart)}
        legend={[
          {
            label: legend.marketActual,
            color: getColorByIdx(0)
          },
          {
            label: legend.companyActual,
            color: getColorByIdx(1)
          }
        ]}
      >
        <ArgumentAxis />
        <ValueAxis />
        <LineSeries
          argumentField="argument"
          valueField="marketActual"
          color={getColorByIdx(0)}
        />
        <LineSeries
          argumentField="argument"
          valueField="marketForecast"
          color={getColorByIdx(0, 100)}
        />
        <LineSeries
          argumentField="argument"
          valueField="companyActual"
          color={getColorByIdx(1)}
        />
        <LineSeries
          argumentField="argument"
          valueField="companyForecast"
          color={getColorByIdx(1, 100)}
        />
        <EventTracker />
        <Tooltip />
      </Chart>
    </Card>
  )
}
