export const DistributionChannelsChartColors = [
  '#E3DCB6',
  '#CCBA8E',
  '#96A379',
  '#51877A',
  '#025D92',
  '#FEE4B9',
  '#EDCEAA',
  '#BDB3A8',
  '#949494',
  '#757575'
]

export const GrowthSourcesChartForecastColors = [
  '#D0EF7D',
  '#87D3A2',
  '#4DA5A7',
  '#4175AD',
  '#3C4E74'
]

export const GrowthSourcesChartFactColors = new Map<string, string>([
  [GrowthSourcesChartForecastColors[0], '#D0EF7D'],
  [GrowthSourcesChartForecastColors[1], '#87D3A2'],
  [GrowthSourcesChartForecastColors[2], '#4DA5A7'],
  [GrowthSourcesChartForecastColors[3], '#4175AD'],
  [GrowthSourcesChartForecastColors[4], '#3C4E74']
])

export const IncreaseInSalesChartColors = [
  '#DFE3BD',
  '#C3E3CE',
  '#B3D0D1',
  '#A1B8D1',
  '#9AA6BF',
  '#BECF90',
  '#95C7A7',
  '#7BAFB0',
  '#7191B3',
  '#67799E'
]

export const MarketVolumeChartForecastColors = [
  '#00BDBD',
  '#00AEED',
  '#B086EB'
]

export const MarketVolumeChartFactColors = [
  '#00BDBD',
  '#00AEED',
  '#B086EB'
]

export const MarketVolumeChartFactColorsMap = new Map<string, string>([
  [MarketVolumeChartForecastColors[0], '#00BDBD'],
  [MarketVolumeChartForecastColors[1], '#00AEED'],
  [MarketVolumeChartForecastColors[2], '#B086EB']
])

export const MarketVolumeChartLegendColors = [
  '#00BDBD',
  '#00AEED',
  '#B086EB'
]

export const TargetsByCategoryChartColors = [
  '#00BDBD',
  '#B086EB',
  '#DFC1F7',
  '#00AEED',
  '#9EF2D5',
  '#96EAF5'
]

export const AveragePriceLineChartColors = [
  '#00BDBD',
  '#9EF2D5'
]

export const SKULineChartColors = [
  '#00AEED',
  '#96EAF5'
]

export const CategoriesChartColors = [
  '#D0AFF2',
  '#74D9F0',
  '#B4BEF7',
  '#64E8D2',
  '#BF9BF2',
  '#54C6F0',
  '#96AFF2',
  '#11D6C2',
  '#B086EB',
  '#00AEED',
  '#859AED',
  '#00BDBD',
  '#647CDB',
  '#1491CF',
  '#9264DB',
  '#15A4A6',
  '#7A00CC',
  '#007BB0',
  '#4D5CD1',
  '#008D8F'
]

export const geoDistributionColors = [
  '#007BB0',
  '#1491CF',
  '#00AEED',
  '#54C6F0',
  '#74D9F0'
]

export const MarketBudgetForecastColors = [
  '#FFBD61',
  '#F58B85',
  '#E640BD',
  '#79358C'
]

export const MarketBudgetFactColors = [
  '#FFBD61',
  '#F58B85',
  '#E640BD',
  '#79358C'
]

export const MarketBudgetLegendColors = [
  '#FFBD61',
  '#F58B85',
  '#E640BD',
  '#79358C'
]

export const MarketBudgetFactColorsMap = new Map<string, string>([
  [MarketBudgetForecastColors[0], '#FFBD61'],
  [MarketBudgetForecastColors[1], '#F58B85'],
  [MarketBudgetForecastColors[2], '#E640BD'],
  [MarketBudgetForecastColors[3], '#79358C']
])

export const AdvertisingStandardGraphColors = [
  '#4BACC6',
  '#FFFF00'
]

export const AdvertisingPieColors = [
  '#4F81BD',
  '#C0504D',
  '#9BBB59'
]
