import React, { useEffect, useState } from 'react'
import { ArrayHelpers, Field, FieldArray, Form, Formik } from 'formik'
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip, Typography
} from '@material-ui/core'
import { Add, Delete } from '@material-ui/icons'
import { Checkbox } from 'formik-material-ui'
import { useAuthContext } from 'components/AuthContext'
import { useLayoutContext } from 'components/LayoutContext'
import { useCategoriesContext } from 'components/CategoriesContext'
import { useTranslationContext } from 'components/TranslationContext'
import { LoadingButton } from 'components/LoadingButton'
import { TableCellField } from 'components/TableCellField'
import { KeyPlayers, marketCharacteristicsApi } from 'api/marketCharacteristicsApi'
import { required } from 'helpers/validators'
import { TextWithBreaks } from 'components/TextWithBreaks'
import { StyledTypography } from '../../components/StyledTypography'
import { StyledTable } from '../../components/StyledTable'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import CheckIcon from '@material-ui/icons/Check'

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: 55,
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  icon: {
    borderRadius: 5,
    width: '100%',
    height: 55,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: 'inset 0 0 0 1.5px rgba(16,22,26,.2), inset 0 -2px 0 rgba(16,22,26,.1)',
    backgroundColor: '#ffffff',
    'input:hover ~ &': {
      backgroundColor: 'rgba(225,244,251)'
    }
  },
  checkedIcon: {
    backgroundColor: 'rgba(225,244,251)',
    boxShadow: 'none'
  }
})

export function KeyPlayersPage () {
  const classes = useStyles()
  const { handleResponseSuccess, handleResponseFailure } = useAuthContext()
  const { startContentLoading, finishContentLoading } = useLayoutContext()
  const { fetchCategories, categories } = useCategoriesContext()
  const { translation } = useTranslationContext()
  const [keyPlayers, setKeyPlayers] = useState<KeyPlayers>()
  const addKeyPlayer = (arrayHelpers: ArrayHelpers) => () => {
    arrayHelpers.push({ categoryIds: [] })
  }
  const deleteKeyPlayer = (arrayHelpers: ArrayHelpers, idx: number) => () => {
    arrayHelpers.remove(idx)
  }
  const handleSubmit = (values: KeyPlayers) => {
    return marketCharacteristicsApi.updateKeyPlayers(values)
      .then(() => {
        handleResponseSuccess()
      })
      .catch(handleResponseFailure)
  }

  useEffect(() => {
    startContentLoading()

    fetchCategories()

    marketCharacteristicsApi.getKeyPlayers()
      .then(setKeyPlayers)
      .catch(handleResponseFailure)
      .finally(finishContentLoading)
  }, [fetchCategories, finishContentLoading, startContentLoading, handleResponseFailure])

  if (!keyPlayers) {
    return null
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <StyledTypography>
              {keyPlayers.description}
              <Tooltip
                title= {
                  <Typography color="inherit">
                    <h4>
                      <TextWithBreaks text={translation['market_and_company.competitors_description_3']} />
                    </h4>
                  </Typography>
                }
              >
                <Typography style={{ color: 'red' }} variant="body1" component='span'>
                  {translation['market_and_company.competitors_description_2']}
                </Typography>
              </Tooltip>
            </StyledTypography>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Paper>
          <TableContainer>
            <Formik
              initialValues={keyPlayers}
              onSubmit={handleSubmit}
            >
              {({ values, setFieldValue, isSubmitting }) => (
                <Form>
                  <FieldArray
                    name="elements"
                    render={(arrayHelpers) => (
                      <>
                        <StyledTable>
                          <TableHead>
                            <TableRow>
                              <TableCell width="50px"></TableCell>

                              <TableCell>{translation['pages.KeyPlayersPage.grid.col.competitors']}</TableCell>

                              {categories.map((category) => (
                                <TableCell style={{ maxWidth: 170, minWidth: 170 }} key={category.id} align="center">
                                  {category.categoryName}
                                </TableCell>
                              ))}

                              <TableCell></TableCell>
                            </TableRow>
                          </TableHead>

                          <TableBody>
                            {values.elements.map((keyPlayer, idx) => (
                              <TableRow key={idx}>
                                <TableCell>{idx + 1}</TableCell>

                                <TableCellField
                                  autoFocus
                                  name={`elements.${idx}.competitorName`}
                                  validate={required(translation['validation.required_field'])}
                                  inputProps={{ maxLength: 40 }}
                                />

                                {categories.map((category) => (
                                  <TableCell key={category.id} align="center">
                                    <Field
                                      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} ><CheckIcon fontSize="small" /></span>}
                                      icon={<span className={classes.icon} />}
                                      component={Checkbox}
                                      type="checkbox"
                                      className={classes.root}
                                      name={`elements[${idx}].categoryIds`}
                                      value={String(category.id)}
                                      color="default"
                                      checked={keyPlayer.categoryIds.map(String).includes(String(category.id))}
                                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        const value = event.target.checked
                                          ? [...keyPlayer.categoryIds, category.id]
                                          : keyPlayer.categoryIds.filter((id) => category.id !== id)
                                        setFieldValue(`elements[${idx}].categoryIds`, value)
                                      }}
                                      disableRipple
                                      size="large"
                                      style={{ padding: 0 }}
                                    />
                                  </TableCell>
                                ))}

                                <TableCell padding="none">
                                  <IconButton
                                    size="small"
                                    onClick={deleteKeyPlayer(arrayHelpers, idx)}
                                    disabled={isSubmitting}
                                  >
                                    <Delete />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </StyledTable>

                        <Box p={1} textAlign="right">
                          <Grid container spacing={1} justify="flex-end">
                            <Grid item>
                              <Button
                                color="primary"
                                startIcon={<Add />}
                                onClick={addKeyPlayer(arrayHelpers)}
                                disabled={isSubmitting}
                              >
                                {translation['form.add']}
                              </Button>
                            </Grid>

                            <Grid item>
                              <LoadingButton
                                type="submit"
                                variant="contained"
                                color="primary"
                                disableElevation
                                disabled={isSubmitting}
                                isLoading={isSubmitting}
                              >
                                {translation['form.save']}
                              </LoadingButton>
                            </Grid>
                          </Grid>
                        </Box>
                      </>
                    )}
                  />
                </Form>
              )}
            </Formik>
          </TableContainer>
        </Paper>
      </Grid>
    </Grid>
  )
}
