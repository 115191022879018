import { useHistory, useLocation } from 'react-router-dom'

export const useQueryParams = () => {
  const history = useHistory()
  const location = useLocation()
  const query = new URLSearchParams(useLocation().search)
  return {
    setQueryParams: <T> (queryParams : T) => {
      for (const [key, value] of Object.entries(queryParams)) {
        query.has(key) && query.delete(key)
        query.append(key, value)
      }
      history.replace(`${location.pathname}?${query.toString()}`)
    },
    queryParams: query,
    history,
    location
  }
}
