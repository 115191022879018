import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Card, CardContent, Grid, Typography } from '@material-ui/core'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import { useLayoutContext } from 'components/LayoutContext'
import { useAuthContext } from 'components/AuthContext'
import { useCategoriesContext } from 'components/CategoriesContext'
import {
  ArgumentAxis,
  Chart,
  ChartVariants,
  getColorByIdx,
  HorizontalBarSeries,
  normalizeChartData,
  Stack,
  ValueAxis
} from 'components/Chart'
import { DataTable } from 'components/DataTable'
import { CommentForm } from 'components/CommentForm'
import { geoDistributionApi, GeoDistributionCollection, GeoDistributionEndpoints } from '../../../api/geoDistributionApi'
import { Table } from 'api/types'
// @ts-ignore
import { stackOffsetExpand } from 'd3-shape'
import { useTranslationContext } from 'components/TranslationContext'
import calculateColumnsWidth from '../../../utils/calculateColumnsWidth'
import { useQueryParams } from '../../../hooks/queryParams'
import { Tabs, PageWithTabsQueryParams } from '../../../types/tabsCommon'
import GeoPercentGraph from './GeoPercentGraph'
import { Marker } from '../../../components/Marker'

export function GeoDistributionPage () {
  const [collection, setCollection] = useState<GeoDistributionCollection | null>()
  const { currentCategory } = useCategoriesContext()
  const { startContentLoading, finishContentLoading } = useLayoutContext()
  const { handleResponseFailure, handleResponseSuccess } = useAuthContext()
  const selectRef = useRef<HTMLDivElement>()
  const { queryParams, setQueryParams } = useQueryParams()
  const [year, setYear] = useState(queryParams.has('year') ? queryParams.get('year') : '')
  const [activeTab, setActiveTab] = React.useState<Tabs | null>(Number(queryParams.get('unit')) ?? Tabs.inKind)
  const [showComment, setShowComment] = useState(true)
  const { translation } = useTranslationContext()

  const getCollection = useCallback((endpoints?: string[]) => {
    if (currentCategory) {
      startContentLoading()
      setShowComment(false)
      let method = geoDistributionApi.getGeoDistributionCollection.bind(geoDistributionApi)
      if (activeTab === Tabs.monetary) {
        method = geoDistributionApi.getGeoDistributionProfitCollection.bind(geoDistributionApi)
      }
      method(currentCategory.id)
        .then(collection => {
          setCollection(collection)
        })
        .catch(handleResponseFailure)
        .finally(() => {
          finishContentLoading()
          setShowComment(true)
        })
    }
  }, [currentCategory, startContentLoading, finishContentLoading, handleResponseFailure, activeTab])

  useEffect(() => {
    getCollection()
  }, [getCollection])

  if (!currentCategory || !collection) {
    return null
  }

  const onTabChange = (event: React.MouseEvent<HTMLElement>, tab: Tabs | null) => {
    if (tab !== null) {
      setQueryParams<PageWithTabsQueryParams>({ unit: tab })
      setActiveTab(tab)
    }
  }
  if (!queryParams.get('unit')) {
    setQueryParams<PageWithTabsQueryParams>({ unit: activeTab })
  }

  const handleTableDependenciesUpdate = (endpoint: GeoDistributionEndpoints) => (table: Table) => {
    setCollection({ ...collection, [endpoint]: table })
    handleResponseSuccess()
    return getCollection(table.dependencies)
  }

  const handleValuesTableUpdate = (grid: Table['grid']) => {
    geoDistributionApi.updateGeoDistributionTable(GeoDistributionEndpoints.VALUES_TABLE, !!activeTab, currentCategory.id, grid)
      .then(handleTableDependenciesUpdate(GeoDistributionEndpoints.VALUES_TABLE))
      .catch(handleResponseFailure)
  }

  const handleCurrentYearTableUpdate = (grid: Table['grid']) => {
    geoDistributionApi.updateGeoDistributionTable(GeoDistributionEndpoints.CURRENT_YEAR_TABLE, !!activeTab, currentCategory.id, grid)
      .then(handleTableDependenciesUpdate(GeoDistributionEndpoints.CURRENT_YEAR_TABLE))
      .catch(handleResponseFailure)
  }

  const handlePercentsTableUpdate = (grid: Table['grid']) => {
    geoDistributionApi.updateGeoDistributionTable(GeoDistributionEndpoints.PERCENTS_TABLE, !!activeTab, currentCategory.id, grid)
      .then(handleTableDependenciesUpdate(GeoDistributionEndpoints.PERCENTS_TABLE))
      .catch(handleResponseFailure)
  }

  const handleCommentUpdate = (comment: string) => {
    geoDistributionApi.updateComment(comment, !!activeTab, currentCategory.id)
      .then(handleResponseSuccess)
      .catch(handleResponseFailure)
  }

  const { columnsWidth } = calculateColumnsWidth(collection[GeoDistributionEndpoints.PERCENTS_TABLE].grid.columns.length)

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <ToggleButtonGroup
          value={activeTab}
          onChange={onTabChange}
          exclusive
        >
          <ToggleButton value={Tabs.inKind}>
            {translation['menu.inkind']}
          </ToggleButton>

          <ToggleButton value={Tabs.monetary}>
            {translation['menu.monetary']}
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>

      {Object.entries(collection[GeoDistributionEndpoints.MARKERS]).map(([key, marker]) => (
        <Grid key={key} item xs={12} md={3} sm={6}>
          <Marker {...marker} />
        </Grid>
      ))}

      {
        collection[GeoDistributionEndpoints.SALES_GRAPH] &&
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Card>
                <CardContent>
                  <Typography variant="h6" paragraph>
                    {collection[GeoDistributionEndpoints.SALES_GRAPH].title}
                  </Typography>
                </CardContent>
                <Chart
                  height={340}
                  data={normalizeChartData(collection[GeoDistributionEndpoints.SALES_GRAPH].chart)}
                  leftAxisUnit={collection[GeoDistributionEndpoints.SALES_GRAPH].units.leftAxis}
                  legend={Object.values(collection[GeoDistributionEndpoints.SALES_GRAPH].legend).map((label, idx) => ({
                    label,
                    color: getColorByIdx(idx, undefined, ChartVariants.GeoDistribution)
                  }))}
                  rotated
                >
                  <ArgumentAxis />
                  <ValueAxis />

                  {Object.keys(collection[GeoDistributionEndpoints.SALES_GRAPH].legend).map((key, idx) => (
                    <HorizontalBarSeries
                      key={key}
                      name={key}
                      valueField={key}
                      argumentField="argument"
                      color={getColorByIdx(idx, undefined, ChartVariants.GeoDistribution)}
                      chart={collection[GeoDistributionEndpoints.SALES_GRAPH].chart}
                    />
                  ))}
                  <Stack
                    stacks={[
                      { series: Object.keys(collection[GeoDistributionEndpoints.SALES_GRAPH].legend) }
                    ]}
                    offset={stackOffsetExpand}
                  />
                </Chart>

              </Card>
            </Grid>

            <Grid item xs={12} md={6}>

              {
                collection[GeoDistributionEndpoints.PERCENTS_GRAPH] && <GeoPercentGraph year={year} setYear={setYear} selectRef={selectRef} percentGraph={collection[GeoDistributionEndpoints.PERCENTS_GRAPH]} />
              }

            </Grid>
          </Grid>
        </Grid>
      }

      { collection[GeoDistributionEndpoints.VALUES_TABLE] && <Grid item xs={8}>
        <DataTable
          title={collection[GeoDistributionEndpoints.VALUES_TABLE]!.title}
          description={collection[GeoDistributionEndpoints.VALUES_TABLE]!.description}
          data={collection[GeoDistributionEndpoints.VALUES_TABLE]!.grid}
          onDataUpdate={handleValuesTableUpdate}
          columnsWidth={columnsWidth}
        />
      </Grid> }

      <Grid item xs={4}>
        <DataTable
          title={collection[GeoDistributionEndpoints.CURRENT_YEAR_TABLE].title}
          description={collection[GeoDistributionEndpoints.CURRENT_YEAR_TABLE].description}
          data={collection[GeoDistributionEndpoints.CURRENT_YEAR_TABLE].grid}
          onDataUpdate={handleCurrentYearTableUpdate}
          columnsWidth={columnsWidth}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <DataTable
          title={collection[GeoDistributionEndpoints.PERCENTS_TABLE].title}
          description={collection[GeoDistributionEndpoints.PERCENTS_TABLE].description}
          data={collection[GeoDistributionEndpoints.PERCENTS_TABLE].grid}
          onDataUpdate={handlePercentsTableUpdate}
        />
      </Grid>

      {showComment && <Grid item xs={12} md={6}>
        <CommentForm
          label={collection[GeoDistributionEndpoints.COMMENT].title}
          value={collection[GeoDistributionEndpoints.COMMENT].comment}
          onChange={handleCommentUpdate}
        />
      </Grid>}
    </Grid>
  )
}
