import React, { useEffect, useRef, useState } from 'react'
import { Box, Card, CardContent, FormControl, Grid, MenuItem, Select, SelectProps, Typography } from '@material-ui/core'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import { useTranslationContext } from 'components/TranslationContext'
import { useCategoriesContext } from 'components/CategoriesContext'
import { useAuthContext } from 'components/AuthContext'
import { useLayoutContext } from 'components/LayoutContext'
import { Marker } from 'components/Marker'
import {
  ArgumentAxis,
  Chart,
  ChartVariants,
  EventTracker,
  getColorByIdx,
  normalizeChartData,
  Stack,
  Tooltip,
  ValueAxis,
  VerticalBarSeries
} from 'components/Chart'
import { DataTable } from 'components/DataTable'
import { CommentForm } from 'components/CommentForm'
import { formatNumber } from 'helpers/formatNumber'
import { CategoryCollection, CategoryEndpoints, growthSourcesApi } from 'api/growthSourcesApi'
import calculateColumnsWidth from '../../utils/calculateColumnsWidth'

import { Tabs, TargetsByCategoryQueryParams } from '../../types/tabsCommon'
import { useQueryParams } from '../../hooks/queryParams'

export function CategoriesContextPage () {
  const selectRef = useRef<HTMLDivElement>()
  const { categories, currentCategory } = useCategoriesContext()
  const [collection, setCollection] = useState<CategoryCollection | null>()
  const { translation } = useTranslationContext()
  const { startContentLoading, finishContentLoading } = useLayoutContext()
  const { handleResponseFailure, handleResponseSuccess } = useAuthContext()
  const { queryParams, setQueryParams } = useQueryParams()
  const [category, setCategory] = useState(queryParams.get('categoryId') || 'all')
  const [activeTab, setActiveTab] = React.useState<Tabs | null>(Number(queryParams.get('unit')) ?? Tabs.inKind)

  useEffect(() => {
    startContentLoading()
    setCollection(null)

    growthSourcesApi.getCategoryCollection(category, activeTab === Tabs.monetary)
      .then(setCollection)
      .catch(handleResponseFailure)
      .finally(finishContentLoading)
  }, [category, finishContentLoading, startContentLoading, handleResponseFailure, activeTab])

  if (!collection) {
    return null
  }

  const onTabChange = (event: React.MouseEvent<HTMLElement>, tab: Tabs | null) => {
    if (tab !== null) {
      setQueryParams<TargetsByCategoryQueryParams>({ unit: tab, categoryId: queryParams.get('categoryId') || 'all' })
      setActiveTab(tab)
    }
  }

  if (!queryParams.get('unit')) {
    setQueryParams<TargetsByCategoryQueryParams>({ unit: activeTab, categoryId: queryParams.get('categoryId') || 'all' })
  }

  const handleCategoryChange: SelectProps['onChange'] = (e) => {
    setCategory(`${e.target.value}`)
    setQueryParams<TargetsByCategoryQueryParams>({ unit: activeTab, categoryId: e.target.value })
  }

  const handleCommentChange = (monetary: boolean) => {
    return (comment: string) => {
      growthSourcesApi.updateCategoryComment(comment, category, monetary, true)
        .then(handleResponseSuccess)
        .catch(handleResponseFailure)
    }
  }

  const { columnsWidth } = calculateColumnsWidth(collection[CategoryEndpoints.RELATIVE_TABLE].grid.columns.length)

  const inKindMarkers = [
    collection[CategoryEndpoints.MARKER_TOTAL],
    collection[CategoryEndpoints.MARKER_ASSORTIMENT],
    collection[CategoryEndpoints.MARKER_DISTRIBUTION],
    collection[CategoryEndpoints.MARKER_ADVERTISING]
  ]

  const monetaryMarkers = [
    collection[CategoryEndpoints.MARKER_MONETARY_TOTAL],
    collection[CategoryEndpoints.MARKER_MONETARY_ASSORTIMENT],
    collection[CategoryEndpoints.MARKER_MONETARY_DISTRIBUTION],
    collection[CategoryEndpoints.MARKER_MONETARY_ADVERTISING],
    collection[CategoryEndpoints.MARKER_MONETARY_PRICE]
  ]

  const markers = activeTab === Tabs.inKind ? inKindMarkers : monetaryMarkers
  markers.length = 4

  const monetaryRelativeChartLegendSorted = {
    assortiment: collection[CategoryEndpoints.RELATIVE_CHART].legend.assortiment,
    monetary_policy: collection[CategoryEndpoints.RELATIVE_CHART].legend.monetary_policy,
    distribution: collection[CategoryEndpoints.RELATIVE_CHART].legend.distribution,
    advertising: collection[CategoryEndpoints.RELATIVE_CHART].legend.advertising
  }

  const getRelativeChartSortedLegend = () => {
    return activeTab === Tabs.monetary && collection[CategoryEndpoints.RELATIVE_CHART].legend.monetary_policy ? monetaryRelativeChartLegendSorted : collection[CategoryEndpoints.RELATIVE_CHART].legend
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={4}>
        <ToggleButtonGroup
          value={activeTab}
          onChange={onTabChange}
          exclusive
        >
          <ToggleButton value={Tabs.inKind}>
            {translation['menu.inkind']}
          </ToggleButton>

          <ToggleButton value={Tabs.monetary}>
            {translation['menu.monetary']}
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>

      <Grid item xs={12} md={4}>
        {!currentCategory && (
          <FormControl variant="outlined" fullWidth>
            <Select
              ref={selectRef}
              value={category}
              onChange={handleCategoryChange}
              MenuProps={{
                MenuListProps: { disablePadding: true },
                style: { width: selectRef.current?.clientWidth }
              }}
            >
              <MenuItem key="all" value="all">
                {translation['pages.CategoriesContextPage.filter.all']}
              </MenuItem>

              {categories.map(({ id, categoryName }) => (
                <MenuItem key={id} value={id}>
                  <Typography variant="inherit" noWrap>
                    {categoryName}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Grid>
      <Grid item md={4} />

      { markers.some((el) => Boolean(el)) && markers.map(marker => <Grid item xs={12} md={markers.length === 5 ? 2 : 3} sm={6}>
        <Marker {...marker} />
      </Grid>) }

      { collection[CategoryEndpoints.ABSOLUTE_CHART] && <Grid item xs={12} md={6}>
        <Card>
          <CardContent>
            <Typography variant="h6" paragraph>
              {collection[CategoryEndpoints.ABSOLUTE_CHART].title}
            </Typography>
          </CardContent>

          <Chart
            height={300}
            data={normalizeChartData(collection[CategoryEndpoints.ABSOLUTE_CHART].chart)}
          >
            <ArgumentAxis/>
            <ValueAxis/>
            <VerticalBarSeries
              argumentField="argument"
              valueField="rowResult"
              color={getColorByIdx(4, undefined, ChartVariants.GrowthSources)}
              chart={collection[CategoryEndpoints.ABSOLUTE_CHART].chart}
            />
            <EventTracker/>
            <Tooltip/>
          </Chart>

          <Box height={24}/>
        </Card>
      </Grid> }

      { collection[CategoryEndpoints.MONETARY_CHART] && <Grid item xs={12} md={6}>
        <Card>
          <CardContent>
            <Typography variant="h6" paragraph>
              {collection[CategoryEndpoints.MONETARY_CHART].title}
            </Typography>
          </CardContent>

          <Chart
            height={300}
            data={normalizeChartData(collection[CategoryEndpoints.MONETARY_CHART].chart)}
          >
            <ArgumentAxis/>
            <ValueAxis/>
            <VerticalBarSeries
              argumentField="argument"
              valueField="rowResult"
              color={getColorByIdx(4, undefined, ChartVariants.GrowthSources)}
              chart={collection[CategoryEndpoints.MONETARY_CHART].chart}
            />
            <EventTracker/>
            <Tooltip/>
          </Chart>

          <Box height={24}/>
        </Card>
      </Grid> }

      <Grid item xs={12} md={6}>
        <Card>
          <CardContent>
            <Typography variant="h6" paragraph>
              {collection[CategoryEndpoints.RELATIVE_CHART].title}
            </Typography>
          </CardContent>

          <Chart
            height={300}
            data={normalizeChartData(collection[CategoryEndpoints.RELATIVE_CHART].chart)}
            legend={Object.values(getRelativeChartSortedLegend()).map((label, idx) => ({
              label,
              color: getColorByIdx(idx, undefined, ChartVariants.GrowthSources)
            }))}
          >
            <ArgumentAxis />
            <ValueAxis />

            {Object.entries(getRelativeChartSortedLegend())
              .map(([key, value], idx) => (
                <VerticalBarSeries
                  key={key}
                  name={value}
                  color={getColorByIdx(idx, undefined, ChartVariants.GrowthSources)}
                  argumentField="argument"
                  valueField={key}
                  chart={collection[CategoryEndpoints.RELATIVE_CHART].chart}
                  variant={ChartVariants.GrowthSources}
                />
              ))
            }

            <Stack stacks={[
              { series: Object.values(getRelativeChartSortedLegend()) }
            ]} />
            <EventTracker />
            <Tooltip
              contentComponent={({ targetItem: { point, series } }) => {
                const valueKey = Object.keys(collection[CategoryEndpoints.RELATIVE_CHART].legend).find((key) => (
                  series === collection[CategoryEndpoints.RELATIVE_CHART].legend[key]
                ))
                const value = valueKey && collection[CategoryEndpoints.RELATIVE_CHART].chart[point][valueKey]
                return (
                  <>
                    {formatNumber(value, { maximumFractionDigits: 2 })}%
                  </>
                )
              }}
            />
          </Chart>
        </Card>
      </Grid>

      { collection[CategoryEndpoints.ABSOLUTE_TABLE] && <Grid item xs={12}>
        <DataTable
          title={collection[CategoryEndpoints.ABSOLUTE_TABLE].title}
          description={collection[CategoryEndpoints.ABSOLUTE_TABLE].description}
          data={collection[CategoryEndpoints.ABSOLUTE_TABLE].grid}
          columnsWidth={columnsWidth}
        />
      </Grid> }

      { collection[CategoryEndpoints.MONETARY_TABLE] && <Grid item xs={12}>
        <DataTable
          title={collection[CategoryEndpoints.MONETARY_TABLE].title}
          description={collection[CategoryEndpoints.MONETARY_TABLE].description}
          data={collection[CategoryEndpoints.MONETARY_TABLE].grid}
          columnsWidth={columnsWidth}
        />
      </Grid> }

      <Grid item xs={12}>
        <DataTable
          title={collection[CategoryEndpoints.RELATIVE_TABLE].title}
          description={collection[CategoryEndpoints.RELATIVE_TABLE].description}
          data={collection[CategoryEndpoints.RELATIVE_TABLE].grid}
          columnsWidth={columnsWidth}
        />
      </Grid>

      <Grid item xs={12}>
        <CommentForm
          label={collection[CategoryEndpoints.SUMMARY_COMMENT].title}
          value={collection[CategoryEndpoints.SUMMARY_COMMENT].comment}
          onChange={handleCommentChange(activeTab === Tabs.monetary)}
        />
      </Grid>
    </Grid>
  )
}
