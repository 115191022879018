import { request } from 'api/request'

function getPageTitle (pageKey: string, userId: number) {
  return request(`users/${userId}/pages/${pageKey}`)
}

function setPageTitle (pageKey: string, userId: number, title: string) {
  return request(`users/${userId}/pages/${pageKey}`, 'PUT', { title })
}

export const userPagesTitlesApi = {
  getPageTitle,
  setPageTitle
}
