import { debounce } from '@material-ui/core'
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'

export interface Cell {
  type: 'number' | 'percent' | 'string'
  value?: string
  maxValue?: number
  delta?: string
  showDelta?: boolean
  description?: string
  isEditable?: boolean
  checked?: boolean | null
  error?: string
}

export interface Column {
  title: string | number
  field: string
  spanTitle?: string
  spanColumnsNumber?: number
  subtitle?: string
  align?: 'left' | 'center' | 'right'
  isSortable?: boolean
  hasRowsManager?: true
}

export interface Row {
  cells: Record<string | number, Cell>
  rowId: string
}

export interface Data {
  columns: Column[]
  rows: Row[]
}

export interface CustomColumn {
  title: string,
  render: (row: Row, idx: number) => JSX.Element
}

export type DataUpdateHandler = (data: Data) => void

export type CellClickHandler = (row: Row, column: Column) => void

type SortBy = Column | undefined

type SortDirection = 'asc' | 'desc'

type RowsCompareFn = (rowA: Row, rowB: Row) => number

type SortChangeHandler = (column: Column) => void

type RowVisibilityChangeHandler = (row: Row) => void

type CellGetter = (row: Row, column: Column) => Cell

type CellValueUpdateHandler = (cell: Cell, newValue?: string, newRows? : (string | undefined)[][]) => void

interface Context {
  data: Data
  sortBy: SortBy
  sortDirection: SortDirection
  rowsCompareFn: RowsCompareFn
  onSortChange: SortChangeHandler
  hiddenRows: Row[]
  onRowVisibilityChange: RowVisibilityChangeHandler
  onDataUpdate: DataUpdateHandler
  getCell: CellGetter
  handdleCellValueChange: CellValueUpdateHandler
}

const DataTableContext = createContext<Context | null>(null)

function updateCellProp<Key extends keyof Cell> (
  row: Row, field: keyof Row['cells'], key: Key, value: Cell[Key]
) {
  return {
    ...row.cells[field],
    [key]: value
  }
}

function updateRow (row: Row, field: keyof Row['cells'], cell: Cell) {
  return {
    ...row,
    cells: {
      ...row.cells,
      [field]: cell
    }
  }
}

function updateRows (data: Data, row: Row, updatedRow: Row) {
  return data.rows.map((r) => r === row ? updatedRow : r)
}

function updateData (data: Data, updatedRows: Row[]) {
  return {
    ...data,
    rows: updatedRows
  }
}

export function updateDataRowCellProp<Prop extends keyof Cell> (
  data: Data, row: Row, field: keyof Row['cells'], prop: Prop, newValue: Cell[Prop]
) {
  const updatedCell = updateCellProp<Prop>(row, field, prop, newValue)
  const updatedRow = updateRow(row, field, updatedCell)
  const updatedRows = updateRows(data, row, updatedRow)
  return updateData(data, updatedRows)
}

interface DataTableContextProviderOwnProps {
  data: Data
  onDataUpdate?: DataUpdateHandler
}

type DataTableContextProviderProps = React.PropsWithChildren<DataTableContextProviderOwnProps>

export function DataTableContextProvider (props: DataTableContextProviderProps) {
  const { children, data: initialData, onDataUpdate = () => {} } = props
  const [data, setData] = useState(initialData)
  const [sortBy, setSortrBy] = useState<SortBy>(undefined)
  const [sortDirection, setSortDirection] = useState<SortDirection>('asc')
  const rowsCompareFn: RowsCompareFn = (rowA, rowB) => {
    const valueA = sortBy && rowA.cells[sortBy.field].value
    const valueB = sortBy && rowB.cells[sortBy.field].value
    const diff = valueA && valueB && valueA > valueB ? -1 : 1
    return sortDirection === 'asc' ? diff : -diff
  }
  const onSortChange: SortChangeHandler = (column) => {
    setSortDirection(sortBy === column && sortDirection === 'asc' ? 'desc' : 'asc')
    setSortrBy(column)
  }
  const [hiddenRows, setHiddenRows] = useState<Row[]>([])
  const onRowVisibilityChange = (row: Row) => {
    const updatedHiddenRows = hiddenRows.indexOf(row) > -1
      ? hiddenRows.filter((r) => r !== row)
      : [...hiddenRows, row]
    setHiddenRows(updatedHiddenRows)
  }
  const getCell: CellGetter = (row, column) => {
    const cellKey = Object.keys(row.cells)
      .find((key) => (
        String(key) === String(column.field)
      ))

    return cellKey ? row.cells[cellKey] : {} as Cell
  }
  const debouncedDataUpdate = useMemo(() => debounce(onDataUpdate, 1000), [onDataUpdate])
  const handleDataUpdate = (updatedData: Data) => {
    debouncedDataUpdate && debouncedDataUpdate(updatedData)
    setData(updatedData)
  }
  const handdleCellValueChange: CellValueUpdateHandler = (cell, newValue, newRows) => {
    const values = newValue ? [newValue.split('\t')] : newRows
    const cellRow = data.rows.find((row) => Object.values(row.cells).includes(cell))
    const cellKey = cellRow && Object.keys(cellRow.cells).find((key) => cellRow.cells[key] === cell)
    const cellColumn = data.columns.find((column) => column.field === cellKey)
    const cellColumnIdx = cellColumn === undefined ? -1 : data.columns.indexOf(cellColumn)

    const cellRowIndex = data.rows.indexOf(cellRow!)
    let newValuesIndex = 0
    const updatedData = values && cellKey && cellColumnIdx > -1 && {
      ...data,
      rows: data.rows.map((row, i) => {
        if (i >= cellRowIndex && i <= (cellRowIndex + values.length - 1)) {
          const updatedRow = {
            ...row,
            cells: {
              ...row.cells,
              ...values[newValuesIndex].reduce((acc, value, idx) => {
                const column = data.columns[cellColumnIdx + idx]
                return column
                  ? ({
                    ...acc,
                    [column.field]: {
                      ...row.cells[column.field],
                      value: row.cells[column.field].isEditable ? value!.length > 0 ? value : row.cells[column.field].value : row.cells[column.field].value
                    }
                  })
                  : acc
              }, {})
            }
          }
          ++newValuesIndex
          return updatedRow
        }
        return row
      })
    }

    if (updatedData) {
      handleDataUpdate(updatedData)
    }
  }

  useEffect(() => {
    setData(initialData)
  }, [initialData])

  const value = {
    data,
    sortBy,
    sortDirection,
    rowsCompareFn,
    onSortChange,
    hiddenRows,
    onRowVisibilityChange,
    onDataUpdate: handleDataUpdate,
    getCell,
    handdleCellValueChange
  }

  return (
    <DataTableContext.Provider value={value}>{children}</DataTableContext.Provider>
  )
}

export function useDataTableContext () {
  const context = useContext(DataTableContext)

  if (!context) {
    throw new Error('Used outside of "DataTableContextProvider"')
  }

  return context
}
