import React from 'react'
import { Grid } from '@material-ui/core'
import { Page } from 'components/Page'
import { useTranslationContext } from 'components/TranslationContext'

export function DataManagementPage () {
  const { translation } = useTranslationContext()
  return (
    <Page title={translation['menu.company_data']}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <img
            src='/data-management.png'
            style={{ width: '100%' }}
            alt='data management preview'
          />
        </Grid>
      </Grid>
    </Page>
  )
}
