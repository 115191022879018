export interface Option {
  label: string
  value: string | number
}

export interface OptionGroup {
  label?: string
  options: Option[]
}

export interface Field<Value = string> {
  label?: string
  hint?: string
  value: Value
  optionGroups: OptionGroup[]
}

export function getFieldsValues<Values> (fields: Record<string, Field>) {
  return Object.entries(fields).reduce((acc, [key, field]) => {
    return {
      ...acc,
      [key]: Array.isArray(field.value) ? field.value.map(({ id }) => id.toString()) : field.value
    }
  }, {} as Values)
}
