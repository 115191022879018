import { request } from './request'

export function getCollection<Collection> (prefix: string = '', endpoints: string[], deps?: boolean, excludedItems?: string[], cutText: string = '') {
  const objects = endpoints.map((endpoint) => {
    if (endpoint.includes('comment') || endpoint.includes('filter')) {
      return endpoint
    }
    if (excludedItems && excludedItems.indexOf(endpoint) !== -1) {
      return endpoint
    }
    return `${endpoint}${deps ? '' : prefix}`
  })

  return request('collection', 'POST', { objects })
    .then((response: Collection) => (
      Object.entries(response)
        .reduce((acc, [key, value]) => ({
          ...acc,
          [key.replace(prefix, '').replace(cutText, '')]: value
        }), {}) as Collection
    ))
}
