import { action, observable } from 'mobx'
import { Country } from '../api/types'

export interface ICountriesStore {
  countriesList: Country[] | null
  setCountriesList: (countries: Country[]) => void
}

export const countriesStore = observable<ICountriesStore>({
  countriesList: null,
  setCountriesList: action((countries : Country[]) : void => {
    countriesStore.countriesList = countries
  })
})
