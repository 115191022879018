import React from 'react'
import { Card, CardContent, useTheme } from '@material-ui/core'
import {
  ArgumentAxis,
  BarSeries,
  Chart,
  ChartVariants,
  EventTracker,
  getColorByIdx,
  LineSeries,
  ModifyDomainFn,
  normalizeChartData,
  Stack,
  Tooltip,
  ValueAxis,
  ValuesBarSeries,
  ValueScale
} from 'components/Chart'
import { GraphVolCommon } from 'api/marketAndCompanyApi'
import { StyledTypographyHeader } from './StyledTypography'

export function MarketVolumeAndCustomerSalesChart (props: GraphVolCommon) {
  const { palette } = useTheme()
  const { title, legend, units, chart } = props
  const modifyMarketShareDomain: ModifyDomainFn = (domain) => [domain[0] / 1.1, domain[1] * 1.1]
  const recalculateForecast = (actual?: number, forecast?: number) => {
    const adjustedForecast = (actual && forecast) ? forecast - actual : forecast
    return adjustedForecast && (adjustedForecast > 0 ? adjustedForecast : 0)
  }
  const maxConsumptionValue = normalizeChartData(chart)?.reduce((prev: number, item: Record<string, number>) => {
    const values = [prev, item.marketActual, item.marketForecast].filter(isFinite)
    return Math.max(...values)
  }, 0)
  const recalculatedChart = normalizeChartData(chart)?.map((item: Record<string, number>) => ({
    ...item,
    marketForecast: recalculateForecast(item.marketActual, item.marketForecast),
    companyForecast: recalculateForecast(item.companyActual, item.companyForecast),
    marketValues: Number(maxConsumptionValue) * 0.1,
    companyValues: Number(maxConsumptionValue) * 0.1
  }))

  return (
    <Card>
      <CardContent>
        <StyledTypographyHeader>
          {title}
        </StyledTypographyHeader>
      </CardContent>

      <Chart
        height={300}
        leftAxisUnit={units.leftAxis}
        rightAxisUnit={units.rightAxis}
        data={recalculatedChart}
        legend={[
          {
            label: legend.marketActual,
            color: getColorByIdx(0, undefined, ChartVariants.MarketVolumeLegend)
          },
          {
            label: legend.companyActual,
            color: getColorByIdx(1, undefined, ChartVariants.MarketVolumeLegend)
          },
          {
            label: legend.marketShareActual,
            color: getColorByIdx(2, undefined, ChartVariants.MarketVolumeLegend)
          }
        ]}
      >
        <ValueScale name="consumption" />
        <ValueScale name="marketShare" modifyDomain={modifyMarketShareDomain} />
        <ArgumentAxis />
        <ValueAxis
          scaleName="consumption"
        />
        <ValueAxis
          scaleName="marketShare"
          position="right"
          showGrid={false}
        />
        <BarSeries
          name="marketActual"
          argumentField="argument"
          valueField="marketActual"
          scaleName="consumption"
          color={getColorByIdx(0, undefined, ChartVariants.MarketVolumeFact)}
        />
        <BarSeries
          name="marketForecast"
          argumentField="argument"
          valueField="marketForecast"
          scaleName="consumption"
          color={getColorByIdx(3, undefined, ChartVariants.MarketVolumeForecast)}
        />
        <ValuesBarSeries
          name="marketValues"
          argumentField="argument"
          valueField="marketValues"
          valueKeys={['marketActual', 'marketForecast']}
          valueColors={[palette.text.primary, palette.text.secondary]}
          chart={chart}
          scaleName="consumption"
        />
        <BarSeries
          name="companyActual"
          argumentField="argument"
          valueField="companyActual"
          scaleName="consumption"
          color={getColorByIdx(1, undefined, ChartVariants.MarketVolumeFact)}
        />
        <BarSeries
          name="companyForecast"
          argumentField="argument"
          valueField="companyForecast"
          scaleName="consumption"
          color={getColorByIdx(4, undefined, ChartVariants.MarketVolumeForecast)}
        />
        <ValuesBarSeries
          name="companyValues"
          argumentField="argument"
          valueField="companyValues"
          valueKeys={['companyActual', 'companyForecast']}
          valueColors={[palette.text.primary, palette.text.secondary]}
          chart={chart}
          scaleName="consumption"
        />
        <LineSeries
          name="marketShareForecast"
          argumentField="argument"
          valueField="marketShareForecast"
          scaleName="marketShare"
          color={getColorByIdx(2, undefined, ChartVariants.MarketVolumeForecast)}
          unit="%"
        />
        <LineSeries
          name="marketShareActual"
          argumentField="argument"
          valueField="marketShareActual"
          scaleName="marketShare"
          color={getColorByIdx(2, undefined, ChartVariants.MarketVolumeFact)}
          unit="%"
        />
        <Stack
          stacks={[
            { series: ['marketActual', 'marketForecast', 'marketValues'] },
            { series: ['companyActual', 'companyForecast', 'companyValues'] }
          ]}
        />
        <EventTracker />
        <Tooltip ignoreSeries={['marketValues', 'companyValues']} />
      </Chart>
    </Card>
  )
}
