import React, { Fragment, useMemo, useState } from 'react'
import cn from 'classnames'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import './styles.sass'
import { useAuthContext } from '../AuthContext'
import { useTranslationContext } from '../TranslationContext'
import { useCategoriesContext } from '../CategoriesContext'
import { Link, useLocation, NavLink } from 'react-router-dom'
import { IMenuItem, IMenuSection } from './common'
import { menuItems } from './menuItems'
import { AppLogoWhite, AppShortIcon, ExpandIcon, ShrinkIcon } from '../../icons/MenuIcons'

export default function ColumnMenuNavigation () {
  const { currentCompany, currentUser } = useAuthContext()

  const [firstColumn, setFirstColumn] = useState<IMenuSection | null>(null)
  const [secondColumn, setSecondColumn] = useState<IMenuSection | null>(null)
  const [thirdColumn, setThirdColumn] = useState<IMenuSection | null>(null)
  const [fourthColumn, setFourthColumn] = useState<IMenuSection | null>(null)

  const [menuExpanded, setMenuExpanded] = useState(true)

  const { translation } = useTranslationContext()
  const { categories } = useCategoriesContext()

  const { pathname } = useLocation()

  const renderMenuItems = useMemo(
    () => {
      return menuItems(currentCompany?.shortName, translation, currentUser?.role, categories, currentUser?.username)
    },
    [translation, currentUser, categories, currentCompany]
  )

  const getStateBySectionId = (id: number) => {
    switch (id) {
      case 0:
        return setSecondColumn
      case 1:
        return setThirdColumn
      case 2:
        return setFourthColumn
    }
  }

  const resetAllMenuColumns = () => {
    setFirstColumn(null)
    setSecondColumn(null)
    setThirdColumn(null)
    setFourthColumn(null)
  }

  const columnsArray = [firstColumn, secondColumn, thirdColumn, fourthColumn]

  const isPathnameMatch = (href: string) => {
    const regexpWithTabs = /\/strategical\/categories\/category\/(.*)\/(.*)/
    const regexpWithoutTabs = /\/strategical\/categories\/category\/(\d+)$/

    const parsePathnameWithTabs = pathname.match(regexpWithTabs)
    const parseHrefWithTabs = href.match(regexpWithTabs)

    const parseHrefWithoutTabs = href.match(regexpWithoutTabs)

    if (parsePathnameWithTabs && parseHrefWithoutTabs) {
      return parsePathnameWithTabs[1] === parseHrefWithoutTabs[1]
    }

    if (parsePathnameWithTabs && parseHrefWithTabs) {
      return !!parsePathnameWithTabs && parseHrefWithTabs[2] === parsePathnameWithTabs[2] && parseHrefWithTabs[1] === parsePathnameWithTabs[1]
    }

    return !!href && pathname.match(href)
  }

  // @ts-ignore
  const onClickHeadMenuItem = (el: IMenuItem) => (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    e.preventDefault()

    if (firstColumn !== null && firstColumn.items === el.children) {
      setFirstColumn(null)
    } else {
      setFirstColumn(el.children ? ({ title: el.title, items: el.children }) : null)
    }
    setSecondColumn(null)
    setThirdColumn(null)
    setFourthColumn(null)
  }

  const getStateForColumn = (sectionId: number, el: IMenuItem) => {
    const stateFunction = getStateBySectionId(sectionId)

    let nextStateItem

    switch (sectionId + 1) {
      case 0:
        nextStateItem = firstColumn
        break
      case 1:
        nextStateItem = secondColumn
        break
      case 2:
        nextStateItem = thirdColumn
        break
      case 3:
        nextStateItem = fourthColumn
    }

    const isItemSelected = (nextStateItem?.items !== undefined && nextStateItem?.items === el.children) || (el.href && isPathnameMatch(el.href))

    return {
      stateFunction,
      isItemSelected
    }
  }

  return (
    <Fragment>
      <div className={cn('wrapper')} onMouseLeave={resetAllMenuColumns}>
        <div className={cn('container', { container_shrank: !menuExpanded })}>
          <div className={cn('container__wrapper', { container__wrapper_shrank: !menuExpanded })}>
            <div className={cn('container__logo', 'logo')}>
              <Link to={process.env.REACT_APP_FRONT_TYPE === 'strela' ? '/dashboards/general' : '/home'}>
                { !menuExpanded ? <AppShortIcon/> : <AppLogoWhite /> }
              </Link>
            </div>
            <div className={cn('container__menuItems', 'menuList')}>
              {
                renderMenuItems && renderMenuItems.map((el : IMenuItem) => {
                  if (el.hidden) {
                    return null
                  }
                  const isItemSelected = (firstColumn?.items === el.children)
                  return <Fragment>
                    {el.widthSeparator && <div className={cn('separator')} />}
                    <NavLink className={cn('menuLink')} to={el.href ? el.href : ''} onClick={resetAllMenuColumns}>
                      <div onClick={onClickHeadMenuItem(el)} className={cn('menuListItem', { menuListItem_selected: isItemSelected, menuListItem_selected_fromPath: (el.href && isPathnameMatch(el.href)) })}>
                        <div className={cn('menuListItem__container')}>
                          <div className={cn('menuListItem__icon')}>
                            { el.icon && <el.icon className={cn('menuListItem__svg')}/>}
                          </div>
                          {el.children && <div className={cn('menuListItem__title')}>
                            <div className={cn('menuListItem__textContainer')}>
                              <span className={cn('menuListItem__text')}>{el.title}</span>
                            </div>
                          </div>}
                          {
                            !el.children && <Link className={cn('menuListItem__textContainer')} to={el.href!}>{el.title}</Link>
                          }
                        </div>
                        {
                          el.children && <div className={cn('menuListItem__svgContainer')}>
                            <ChevronRightIcon fill='#000!important' className={cn('menuListItem__svg', 'menuListItem__svg_white')} />
                          </div>
                        }
                      </div>
                    </NavLink>
                  </Fragment>
                })
              }
            </div>
            <div className={cn('container__menuItems', 'container__menuItems_bottom')}>
              <div className={cn('menuLink', 'menuLink_bottom')}>
                <div className={cn('menuListItem')} onClick={() => setMenuExpanded(!menuExpanded)}>
                  <div className={cn('menuListItem__container')}>
                    <div className={cn('menuListItem__icon')}>
                      { menuExpanded ? <ShrinkIcon className={cn('menuListItem__svg')} /> : <ExpandIcon className={cn('menuListItem__svg')} /> }
                    </div>
                    <div className={cn('menuListItem__title')}>
                      <div className={cn('menuListItem__textContainer')}>
                        <span className={cn('menuListItem__text')}>Свернуть</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={cn('container__columns', { container__columns_shrank: !menuExpanded })}>
          {
            columnsArray.map((section, sectionId) => {
              if (section === null) {
                return null
              }

              return <div className={cn('container', 'container_white')}>
                <div className={cn('container__wrapper', 'container__wrapper_white')} onMouseOver={() => {
                  if (sectionId === 0) {
                    setThirdColumn(null)
                  }
                }}>

                  {
                    section.title && <div className={cn('container__title')}>
                      <span className={cn('container__header')}>{section.title}</span>
                    </div>
                  }
                  <div className={cn('container__menuItems', 'menuList')}>
                    {
                      section.items.map((el : IMenuItem) => {
                        if (el.hidden) {
                          return null
                        }

                        const { stateFunction, isItemSelected } = getStateForColumn(sectionId, el)

                        return <Link className={cn('menuLink')} to={el.href ? el.href! : ''} onClick={(e) => {
                          if (el.children) {
                            e.preventDefault()
                            e.stopPropagation()
                          } else {
                            resetAllMenuColumns()
                          }
                        }}>
                          <div onMouseOver={() => {
                            stateFunction && stateFunction(el.children ? ({ title: el.title, items: el.children }) : null)
                          }} className={cn('menuListItem', 'menuListItem_black', { menuListItem_black_selected: isItemSelected })}>
                            <div className={cn('menuListItem__container')}>
                              <div className={cn('menuListItem__icon', 'menuListItem__icon_black')}>
                                { el.icon && <el.icon className={cn('menuListItem__svg', 'menuListItem__svg_black')}/>}
                              </div>
                              <div className={cn('menuListItem__title', 'menuListItem__title_black')}>
                                <div className={cn('menuListItem__textContainer')}>
                                  <span className={cn('menuListItem__text')}>{el.title}</span>
                                </div>
                              </div>
                            </div>
                            {
                              el.children && <div className={cn('menuListItem__svgContainer')}>
                                <ChevronRightIcon fill='#000!important' className={cn('menuListItem__svg', 'menuListItem__svg_black')} />
                              </div>
                            }
                          </div>
                        </Link>
                      })
                    }
                  </div>
                </div>
              </div>
            })
          }
        </div>

      </div>
    </Fragment>
  )
}
