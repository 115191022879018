import { request } from 'api/request'
import { Table, Comment } from 'api/types'

interface Params {
  countryId: number
  categoryId: number
}

export interface Step1Result {
  tab: Table
}

export interface Step2Result {
  tab: Table
  comment: Comment
}

export interface Step3Result {
  tab: Table
}

const prefix = 'operational_part/prices_for_new_markets'

const getCollection = async <Result extends Record<keyof Result, unknown>>(endpoints: Record<keyof Result, string>): Promise<Result> => {
  const result = {} as Record<keyof Result, any>
  const keys = Object.keys(endpoints) as (keyof Result)[]
  const objects = Object.values(endpoints)
  const filters = {}
  const response = await request('collection', 'POST', { objects, filters })

  for (const key of keys) {
    result[key] = response[endpoints[key]]
  }

  return result
}

export function getStep1 ({ countryId, categoryId }: Params) {
  const tab = `${prefix}/settings/tab/${countryId}/${categoryId}`

  return getCollection<Step1Result>({
    tab
  })
}

export function getStep2 ({ countryId, categoryId }: Params) {
  const tab = `${prefix}/competitors/tab/${countryId}/${categoryId}`
  const comment = `${prefix}/competitors/comment/${countryId}/${categoryId}`

  return getCollection<Step2Result>({
    tab,
    comment
  })
}

export function getStep3 ({ countryId, categoryId }: Params) {
  const tab = `${prefix}/company/tab/${countryId}/${categoryId}`

  return getCollection<Step3Result>({
    tab
  })
}

export function updateSettings ({ countryId, categoryId }: Params, grid: Table['grid']): Promise<Table> {
  return request(`${prefix}/settings/tab/${countryId}/${categoryId}`, 'PUT', { grid })
}

export function updateCompetitors ({ countryId, categoryId }: Params, grid: Table['grid']): Promise<Table> {
  return request(`${prefix}/competitors/tab/${countryId}/${categoryId}`, 'PUT', { grid })
}

export async function updateCompetitorsComment ({ countryId, categoryId }: Params, comment: string): Promise<void> {
  await request(`${prefix}/competitors/comment/${countryId}/${categoryId}`, 'PUT', { comment })
}

export function updateCompany ({ countryId, categoryId }: Params, grid: Table['grid']): Promise<Table> {
  return request(`${prefix}/company/tab/${countryId}/${categoryId}`, 'PUT', { grid })
}
