import { Box, Card, Grid, Tooltip } from '@material-ui/core'
import {
  ArgumentAxis,
  Chart,
  ChartVariants,
  ValueAxis
} from 'components/Chart'
import { ChartInterface } from 'api/pricingApi'

import {
  LineSeriesProps as DxLineSeriesProps,
  LineSeries as DxLineSeries,
  ScatterSeries
} from '@devexpress/dx-react-chart-material-ui'
import { StyledTypography } from 'components/StyledTypography'

interface LineSeriesProps extends DxLineSeriesProps {
  unit?: string,
  chart? : Record<string, string | number>[],
  index? : number,
  variant? : ChartVariants
}

export function DiagGraph ({ chart: chartRoot, crossPoints }: {chart: ChartInterface, crossPoints: ChartInterface}) {
  const { legend, units, chart } = chartRoot
  const series = [
    'tooCheap',
    'cheap',
    'no_expensive',

    'no_cheap',
    'expensive',
    'tooExpensive'
  ]
  const colors = [
    '#be4b48',
    '#7d5fa0',
    '#46aac4',

    '#f59240',
    '#98b855',
    '#4a7ebb'
  ]

  const [{ argument, ...crossPrices }] = crossPoints.chart

  enum Points {
    extremeCheapnessPoint = 'extremeCheapnessPoint',
    optimalPricePoint = 'optimalPricePoint',
    indifferencePoint = 'indifferencePoint',
    extremeHighCostPoint = 'extremeHighCostPoint'
  }

  const isPoint = (x: unknown): x is Points => typeof x === 'string' && Object.values(Points).includes(x as Points)

  const linePoints = {
    no_cheap: [Points.extremeCheapnessPoint],
    expensive: [Points.indifferencePoint],
    tooExpensive: [
      Points.optimalPricePoint,
      Points.extremeHighCostPoint
    ]
  } as const

  const plygonPoints = (x: number, y: number, radius: number, sides: number = 6) => {
    const angle = 2 * Math.PI / sides
    const points = []

    for (let i = 0; i < sides; i++) {
      points.push(x + radius * Math.sin(i * angle))
      points.push(y - radius * Math.cos(i * angle))
    }

    return points
  }

  const starPoints = (x: number, y: number, innerRadius: number, outerRadius: number) => {
    const numPoints = 5
    const center = Math.max(innerRadius, outerRadius)
    const angle = Math.PI / numPoints
    const points = []

    for (let i = 0; i < numPoints * 2; i++) {
      var radius = i & 1 ? innerRadius : outerRadius
      points.push(x - outerRadius + center + radius * Math.sin(i * angle))
      points.push(y - outerRadius + center - radius * Math.cos(i * angle))
    }

    return points
  }

  const picto = (type: Points, x: number, y: number) => ({
    [Points.extremeCheapnessPoint]: <>
      <polygon points={plygonPoints(x, y, 12).toString()} style={{ stroke: '#006600', fill: '#be4b48' }}/>
    </>,
    [Points.optimalPricePoint]: <>
      <polygon points={starPoints(x, y, 6, 15).toString()} style={{ stroke: '#006600', fill: 'yellow' }}/>
    </>,
    [Points.indifferencePoint]: <>
      <circle cx={x} cy={y} r="10" style={{ stroke: '#006600', fill: '#98b855' }}/>
    </>,
    [Points.extremeHighCostPoint]: <>
      <polygon points={plygonPoints(x, y, 12).toString()} style={{ stroke: '#006600', fill: '#46aac4' }}/>
    </>
  }[type])

  const isLinePointsKey = (x: unknown): x is keyof typeof linePoints => Object.keys(linePoints).includes(x as string)

  const crossPointsLegend: {
    label: string,
    picto: JSX.Element
  }[] = []

  for (const [key, label] of Object.entries(crossPoints.legend)) {
    if (isPoint(key)) {
      crossPointsLegend.push({
        label,
        picto: picto(key, 15, 15)
      })
    }
  }

  const LineSeries = (props: LineSeriesProps) => {
    const { color, valueField } = props
    const pointKeys = isLinePointsKey(valueField) ? linePoints[valueField] : []

    return (
      <DxLineSeries
        {...props}
        seriesComponent={(props) => (
          <>
            <DxLineSeries.Path {...props} style={{ strokeWidth: 4, stroke: color }} />

            <ScatterSeries.Path {...props} pointComponent={(props: ScatterSeries.PointProps) => {
              const { arg, val, argument } = props

              for (const key of pointKeys) {
                const crossPrice = crossPrices[key]

                if (typeof crossPrice === 'number' && Math.abs(crossPrice - argument) < 0.000001) {
                  return picto(key, arg, val)
                }
              }

              return <></>
            }} />

          </>
        )}
      />
    )
  }

  return (<>
    <Card>
      <Chart
        height={300}
        leftAxisUnit={units.leftAxis}
        data={chart}
        legend={series.map((key, idx) => ({
          label: legend[key],
          color: colors[idx % colors.length]
        }))}
      >
        <ArgumentAxis />
        <ValueAxis />
        {series.map((key, idx) =>
          <LineSeries
            name={key}
            argumentField="argument"
            valueField={key}
            color={ colors[idx % colors.length] }
          />
        )}
      </Chart>
      <Box p={2} display="flex" flexDirection="column" justifyContent="center">
        <Grid container direction="column">
          <Grid container={true} spacing={2} justify='center'>
            {crossPointsLegend.map((item, idx) => (
              <Grid item key={idx}>
                <Box maxWidth="325px">
                  <Tooltip title={item.label || ''}>
                    <StyledTypography noWrap>
                      <Box
                        display="inline-block"
                        component="span"
                        mr={1}
                        style={{ verticalAlign: 'middle' }}
                      >
                        <svg width={30} height={30} xmlns="http://www.w3.org/2000/svg">
                          {item.picto}
                        </svg>
                      </Box>
                      <Box
                        display="inline-block"
                        component="span"
                        mr={1}
                        style={{ verticalAlign: 'middle' }}
                      >
                        {item.label}
                      </Box>
                    </StyledTypography>
                  </Tooltip>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Box>
    </Card>
  </>)
}
