import React from 'react'
import { Field, FieldConfig } from 'formik'
import { TableCell } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { lightBlue } from '@material-ui/core/colors'
import { TextField, TextFieldProps } from 'formik-material-ui'

const TableCellStyled = withStyles(() => ({
  root: {
    position: 'relative',

    '& .MuiTextField-root': {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,

      '& .MuiOutlinedInput-root': {
        height: '100%',

        '& .MuiOutlinedInput-input': {
          height: '100%'
        }
      }
    },

    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent'
    },

    '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent'
    }
  }
}))(TableCell)

interface TableCellFieldProps {
  name: string
  multiline?: boolean
  autoFocus?: boolean
  autoComplete?: string
  highlight?: boolean
  validate?: FieldConfig['validate']
  inputProps?: TextFieldProps['inputProps']
  error?: boolean
  minLength?: number
  disabled?: boolean
}

export function TableCellField ({ highlight, inputProps, ...props }: TableCellFieldProps) {
  return (
    <TableCellStyled style={{
      background: highlight ? lightBlue[50] : 'none',
      overflow: 'hidden'
    }}>
      <Field
        component={TextField}
        variant="outlined"
        inputProps={inputProps}
        {...props}
      />
    </TableCellStyled>
  )
}
