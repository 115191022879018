import { request } from 'api/request'
import { Comment, Marker, Table } from 'api/types'

export interface ChartData {
  title: string
  chart: Record<string, string | number>[]
  legend: Record<string, string>
}

function getCollection<Collection> (prefix: string, endpoints: string[]) {
  const objects = endpoints.map((endpoint) => `${prefix}${endpoint}`)
  return request('collection', 'POST', { objects })
    .then((response: Collection) => (
      Object.entries(response)
        .reduce((acc, [key, value]) => ({
          ...acc,
          [key.replace(prefix, '')]: value
        }), {}) as Collection
    ))
}

enum CategoryComment {
  'COMMENT' = 'comment',
  'SUMMARY_COMMENT' = 'summary_comment'
}

enum CategoryCommonEndpoints {
  'RELATIVE_CHART' = 'percent_graph',
  'RELATIVE_TABLE' = 'percent_tab'
}

export enum InKindEndpoints {
  'MARKER_TOTAL' = 'marker_total',
  'MARKER_ASSORTIMENT' = 'marker_assortiment',
  'MARKER_DISTRIBUTION' = 'marker_distribution',
  'MARKER_ADVERTISING' = 'marker_advertising',
  'ABSOLUTE_CHART' = 'inkind_graph',
  'ABSOLUTE_TABLE' = 'inkind_tab',

}

export enum MonetaryEndpoints {
  'MARKER_MONETARY_TOTAL' = 'marker/total',
  'MARKER_MONETARY_ASSORTIMENT' = 'marker/assortiment',
  'MARKER_MONETARY_DISTRIBUTION' = 'marker/distribution',
  'MARKER_MONETARY_ADVERTISING' = 'marker/advertising',
  'MARKER_MONETARY_PRICE' = 'marker/price',
  'MONETARY_CHART' = 'increase_graph',
  'MONETARY_TABLE' = 'increase_tab',
}

export const CategoryMonetaryEndpoints = { ...MonetaryEndpoints, ...CategoryCommonEndpoints, ...CategoryComment }
export const CategoryInKindEndpoints = { ...InKindEndpoints, ...CategoryCommonEndpoints, ...CategoryComment }

export const CategoryEndpoints = { ...CategoryInKindEndpoints, ...CategoryMonetaryEndpoints, ...CategoryCommonEndpoints }

interface CommonCategoryCollection {
  [CategoryEndpoints.COMMENT]: Comment
  [CategoryEndpoints.SUMMARY_COMMENT]: Comment
  [CategoryEndpoints.RELATIVE_CHART]: ChartData
  [CategoryEndpoints.RELATIVE_TABLE]: Table
}

export interface CategoryInKindCollection extends CommonCategoryCollection {
  [CategoryEndpoints.MARKER_TOTAL]: Marker
  [CategoryEndpoints.MARKER_ASSORTIMENT]: Marker
  [CategoryEndpoints.MARKER_DISTRIBUTION]: Marker
  [CategoryEndpoints.MARKER_ADVERTISING]: Marker
  [CategoryEndpoints.ABSOLUTE_CHART]: ChartData
  [CategoryEndpoints.ABSOLUTE_TABLE]: Table
}

export interface CategoryMonetaryCollection extends CommonCategoryCollection {
  [CategoryEndpoints.MARKER_MONETARY_TOTAL]: Marker
  [CategoryEndpoints.MARKER_MONETARY_ASSORTIMENT]: Marker
  [CategoryEndpoints.MARKER_MONETARY_DISTRIBUTION]: Marker
  [CategoryEndpoints.MARKER_MONETARY_ADVERTISING]: Marker
  [CategoryEndpoints.MARKER_MONETARY_PRICE]: Marker
  [CategoryEndpoints.MONETARY_CHART]: ChartData
  [CategoryEndpoints.MONETARY_TABLE]: Table
}

export type CategoryCollection = CategoryInKindCollection & CategoryMonetaryCollection

function getCategoryEndpoint (category: string | number) {
  return `growth/categories/${category}/`
}

function getCategoryMonetaryEndpoint (category: string | number) {
  return `growth/categories/${category}/monetary/`
}

function getCategoryCollection (category: string | number, monetary: boolean) {
  const prefix = monetary ? getCategoryMonetaryEndpoint(category) : getCategoryEndpoint(category)
  const endpoints = Object.values(monetary ? CategoryMonetaryEndpoints : CategoryInKindEndpoints)
  return getCollection<CategoryCollection>(prefix, endpoints)
}

function updateCategoryComment (comment: string, categoryId: string | number, monetary?: boolean, isSummary?: boolean) {
  const endpoint = `growth/categories/${categoryId}/${monetary ? 'monetary/' : ''}${isSummary ? 'summary_' : ''}comment`
  return request('comment/' + endpoint, 'PUT', { comment })
}

export const growthSourcesApi = {
  getCategoryCollection,
  updateCategoryComment
}
