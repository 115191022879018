import { countriesApi } from 'api/countriesApi'
import { Country } from 'api/types'
import { useQueryParams } from '../hooks/queryParams'
import { useState, useEffect, useCallback } from 'react'
import { useLocalStorage } from '../helpers/useLocalStorage'
import { useAuthContext } from 'components/AuthContext'

export interface Options {
  storageKey: string,
  queryParam?: string,
  prepend?: Country[]
}

export const useCountry = ({ storageKey, queryParam = 'countryId', prepend = [] }: Options) => {
  const [state, setState] = useState<{
    list: Country[],
    id: number
  }>()
  const { queryParams, setQueryParams } = useQueryParams()
  const [storageCountryId, setStorageCountryId] = useLocalStorage(storageKey)
  const [pending, setPending] = useState<boolean>(false)
  const { handleResponseFailure } = useAuthContext()

  useEffect(() => {
    if (state !== undefined || pending) {
      return
    }

    setPending(true)

    ;(async () => {
      try {
        const fetchedList = await countriesApi.getCountriesList()
        const list = [...prepend, ...fetchedList]

        const queryCountryId = queryParams.get(queryParam)
        const queryCountry = queryCountryId === null ? undefined : list.find(c => `${c.id}` === queryParams.get(queryParam))
        const storageCountry = storageCountryId === null ? undefined : list.find(c => `${c.id}` === storageCountryId)
        const country =
          queryCountry !== undefined ? queryCountry
            : storageCountry !== undefined ? storageCountry
              : list[0]

        setQueryParams({ [queryParam]: `${country.id}` })
        setStorageCountryId(`${country.id}`)
        setState({ list, id: country.id })
      } catch (error) {
        handleResponseFailure(error as any)
      }
    })()
  }, [
    prepend,
    state,
    queryParams,
    queryParam,
    setQueryParams,
    storageCountryId,
    setStorageCountryId,
    pending,
    handleResponseFailure
  ])

  const setId = useCallback((id: number): void => {
    if (state !== undefined) {
      setQueryParams({ [queryParam]: `${id}` })
      setStorageCountryId(`${id}`)
      setState({ ...state, id })
    }
  }, [state, queryParam, setQueryParams, setStorageCountryId])

  return { state, setId }
}
