import { Page } from 'components/Page'
import { Box, Card, FormControl, Grid, MenuItem, Typography, Select, SelectProps, Button, IconButton } from '@material-ui/core'
import { useTranslationContext } from 'components/TranslationContext'
import { useEffect, useState } from 'react'
import { useAuthContext } from 'components/AuthContext'
import { useProductCategory } from 'hooks/productCategory'
import { useCountry } from 'hooks/country'
import { getStep1, updateSettings, Step1Result, Step2Result, getStep2, getStep3, Step3Result, updateCompetitors, updateCompetitorsComment, updateCompany } from 'api/foreignMarketApi'
import { Table } from 'api/types'
import { DataTable, Row } from 'components/DataTable'
import { LoadingButton } from 'components/LoadingButton'
import { CommentForm } from 'components/CommentForm'
import { Add, Delete } from '@material-ui/icons'

const COUNTRY_LOCAL_STORAGE_KEY = 'foreignMarket.country'
const CATEGORY_LOCAL_STORAGE_KEY = 'foreignMarket.category'

export function ForeignMarketsPage () {
  const { translation } = useTranslationContext()
  const { handleResponseFailure, handleResponseSuccess } = useAuthContext()
  const [step1, setStep1] = useState<Step1Result>()
  const [step2, setStep2] = useState<Step2Result>()
  const [step3, setStep3] = useState<Step3Result>()
  const [isStep2, setIsStep2] = useState<boolean>(false)
  const [isStep3, setIsStep3] = useState<boolean>(false)

  const { state: country, setId: setCountryId } = useCountry({ storageKey: COUNTRY_LOCAL_STORAGE_KEY })
  const { state: productCategory, setId: setCategoryId } = useProductCategory({ storageKey: CATEGORY_LOCAL_STORAGE_KEY })

  const isEmptySettings = (tab: Table): boolean => {
    for (const row of tab.grid.rows) {
      if (row.cells.index === undefined || row.cells.index.value !== '0') {
        return false
      }
    }

    return true
  }

  const isEmptyCompetitors = (tab: Table): boolean => {
    const { grid } = tab

    if (grid.rows.length === 0) {
      return true
    }

    if (grid.rows.length > 1) {
      return false
    }

    const row = grid.rows[0]

    for (const column of grid.columns) {
      if (row.cells[column.field].value !== '0' && row.cells[column.field].value !== '') {
        return false
      }
    }

    return true
  }

  useEffect(() => {
    if (country === undefined || productCategory === undefined) {
      return
    }

    (async () => {
      try {
        const result = await getStep1({
          countryId: country.id,
          categoryId: productCategory.id
        })
        setIsStep2(!isEmptySettings(result.tab))
        setStep1(result)
      } catch (error) {
        handleResponseFailure(error as any)
      }
    })()
  }, [handleResponseFailure, country, productCategory, setStep1])

  useEffect(() => {
    if (country === undefined || productCategory === undefined || !isStep2) {
      return
    }

    (async () => {
      try {
        const result = await getStep2({
          countryId: country.id,
          categoryId: productCategory.id
        })
        setIsStep3(!isEmptyCompetitors(result.tab))
        setStep2(result)
      } catch (error) {
        handleResponseFailure(error as any)
      }
    })()
  }, [handleResponseFailure, country, productCategory, isStep2, setStep2])

  useEffect(() => {
    if (country === undefined || productCategory === undefined || !isStep3) {
      return
    }

    (async () => {
      try {
        const result = await getStep3({
          countryId: country.id,
          categoryId: productCategory.id
        })
        setStep3(result)
      } catch (error) {
        handleResponseFailure(error as any)
      }
    })()
  }, [handleResponseFailure, country, productCategory, isStep3, setStep3])

  if (!country || !productCategory) {
    return null
  }

  const handleCountryFilterChange: SelectProps['onChange'] = async (e) => {
    const { value } = e.target
    setCountryId(value as number)
  }

  const handleCategoryFilterChange: SelectProps['onChange'] = async (e) => {
    const { value } = e.target
    setCategoryId(value as number)
  }

  const handleSubmitSettings = async () => {
    if (step1 === undefined || country === undefined || productCategory === undefined) {
      return
    }

    try {
      const result = await updateSettings({
        countryId: country.id,
        categoryId: productCategory.id
      }, step1.tab.grid)
      setIsStep2(!isEmptySettings(result))
      handleResponseSuccess()
    } catch (err) {
      handleResponseFailure(err as any)
    }
  }

  const addCompetitorsRow = () => {
    if (step2 === undefined) {
      return
    }

    const { tab } = step2

    const row: Row = {
      cells: {},
      rowId: ''
    }

    for (const column of tab.grid.columns) {
      const isString = ['brand', 'product'].includes(column.field)
      row.cells[column.field] = {
        type: isString ? 'string' : 'number',
        value: isString ? '' : '0',
        isEditable: true,
        checked: null
      }
    }

    tab.grid.rows.push(row)

    setStep2({ ...step2, tab })
  }

  const deleteCompetitorsRow = (row: Row, idx: number) => {
    if (step2 === undefined) {
      return
    }

    const { tab } = step2
    tab.grid.rows.splice(idx, 1)

    setStep2({ ...step2, tab })
  }

  const handleSubmitCompetitors = async () => {
    if (step2 === undefined || country === undefined || productCategory === undefined) {
      return
    }

    try {
      const result = await updateCompetitors({
        countryId: country.id,
        categoryId: productCategory.id
      }, step2.tab.grid)
      setIsStep3(!isEmptyCompetitors(result))
      handleResponseSuccess()
    } catch (err) {
      handleResponseFailure(err as any)
    }
  }

  const handleCommentChange = async (comment: string): Promise<void> => {
    if (step2 === undefined || country === undefined || productCategory === undefined) {
      return
    }

    try {
      await updateCompetitorsComment({
        countryId: country.id,
        categoryId: productCategory.id
      }, comment)
      handleResponseSuccess()
    } catch (error) {
      handleResponseFailure(error as any)
    }
  }

  const addCompanyRow = () => {
    if (step3 === undefined) {
      return
    }

    const { tab } = step3

    const row: Row = {
      cells: {},
      rowId: ''
    }

    for (const column of tab.grid.columns) {
      const isString = ['brand', 'product'].includes(column.field)
      const isEditable = [
        'actualRetailPrice',
        'basePrice',
        'competitiveRetailPrice',
        'brand',
        'product'
      ].includes(column.field)
      row.cells[column.field] = {
        type: isString ? 'string' : 'number',
        value: isEditable ? (isString ? '' : '0') : '',
        isEditable,
        checked: null
      }
    }

    tab.grid.rows.push(row)

    setStep3({ ...step3, tab })
  }

  const deleteCompanyRow = (row: Row, idx: number) => {
    if (step3 === undefined) {
      return
    }

    const { tab } = step3
    tab.grid.rows.splice(idx, 1)

    setStep3({ ...step3, tab })
  }

  const handleSubmitCompany = async () => {
    if (step3 === undefined || country === undefined || productCategory === undefined) {
      return
    }

    try {
      const result = await updateCompany({
        countryId: country.id,
        categoryId: productCategory.id
      }, step3.tab.grid)
      setStep3({ ...step3, tab: result })
      handleResponseSuccess()
    } catch (err) {
      handleResponseFailure(err as any)
    }
  }

  return (
    <Page title={translation['menu.prices_for_foreign_markets']}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" paragraph>
            {step1?.tab.title}
          </Typography>
          <Card>
            <Grid container spacing={0}>
              <Grid item xs={12} md={6}>
                <FormControl variant="outlined" fullWidth style={{ padding: '1em' }}>
                  <Select
                    value={country.id}
                    onChange={handleCountryFilterChange}
                    MenuProps={{
                      MenuListProps: { disablePadding: true }
                    }}
                  >
                    { country.list.map(({ id, name: countryName }) => (
                      <MenuItem key={id} value={id}>
                        <Typography variant="inherit" noWrap>
                          {countryName}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl variant="outlined" fullWidth style={{ padding: '1em' }}>
                  <Select
                    value={productCategory.id}
                    onChange={handleCategoryFilterChange}
                    MenuProps={{
                      MenuListProps: { disablePadding: true }
                    }}
                  >
                    { productCategory.list.map(({ id, categoryName }) => (
                      <MenuItem key={id} value={id}>
                        <Typography variant="inherit" noWrap>
                          {categoryName}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              { step1 &&
              <Grid item xs={12}>
                <DataTable
                  description={step1.tab.description}
                  data={step1.tab.grid}
                  onDataUpdate={ grid => setStep1({ ...step1, tab: { ...step1.tab, grid } }) }
                  footer={() =>
                    <Box textAlign="right">
                      <Grid container spacing={1} justify="flex-end">
                        <Grid item>
                          <LoadingButton
                            type="submit"
                            variant="contained"
                            color="primary"
                            disableElevation
                            isLoading={false}
                            onClick={() => handleSubmitSettings()}
                          >
                            {translation['form.save']}
                          </LoadingButton>
                        </Grid>
                      </Grid>
                    </Box>
                  }
                />
              </Grid>
              }

            </Grid>
          </Card>
        </Grid>
        {isStep2 &&
          <Grid item xs={12}>
            <Typography variant="h6" paragraph>
              {step2?.tab.title}
            </Typography>
            <Card>
              <Grid container spacing={0}>
                { step2 && <>
                  <Grid item xs={12}>
                    <DataTable
                      description={step2.tab.description}
                      data={step2.tab.grid}
                      onDataUpdate={ grid => setStep2({ ...step2, tab: { ...step2.tab, grid } }) }
                      customColumns={[{
                        title: '',
                        render: (row, idx) => <Box textAlign="center">
                          <IconButton
                            size="small"
                            onClick={() => deleteCompetitorsRow(row, idx)}
                            disabled={false}
                          >
                            <Delete/>
                          </IconButton>
                        </Box>
                      }]}
                      footer={() =>
                        <Box textAlign="right">
                          <Grid container spacing={1} justify="flex-end">
                            <Grid item>
                              <Button
                                color="primary"
                                startIcon={<Add/>}
                                onClick={() => addCompetitorsRow()}
                              >
                                {translation['form.add']}
                              </Button>
                            </Grid>
                            <Grid item>
                              <LoadingButton
                                type="submit"
                                variant="contained"
                                color="primary"
                                disableElevation
                                isLoading={false}
                                onClick={() => handleSubmitCompetitors()}
                              >
                                {translation['form.save']}
                              </LoadingButton>
                            </Grid>
                          </Grid>
                        </Box>
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CommentForm
                      key={`${country.id}:${productCategory.id}`}
                      label={step2.comment.title}
                      value={step2.comment.comment}
                      onChange={handleCommentChange}
                      style={{ height: '100%' }}
                    />
                  </Grid>
                </>}

              </Grid>
            </Card>
          </Grid>
        }
        {isStep3 &&
          <Grid item xs={12}>
            <Typography variant="h6" paragraph>
              {step3?.tab.title}
            </Typography>
            <Card>
              { step3 &&
                <DataTable
                  description={step3.tab.description}
                  data={step3.tab.grid}
                  onDataUpdate={ grid => setStep3({ ...step3, tab: { ...step3.tab, grid } }) }
                  customColumns={[{
                    title: '',
                    render: (row, idx) => <Box textAlign="center">
                      <IconButton
                        size="small"
                        onClick={() => deleteCompanyRow(row, idx)}
                        disabled={false}
                      >
                        <Delete/>
                      </IconButton>
                    </Box>
                  }]}
                  footer={() =>
                    <Box textAlign="right">
                      <Grid container spacing={1} justify="flex-end">
                        <Grid item>
                          <Button
                            color="primary"
                            startIcon={<Add/>}
                            onClick={() => addCompanyRow()}
                          >
                            {translation['form.add']}
                          </Button>
                        </Grid>
                        <Grid item>
                          <LoadingButton
                            type="submit"
                            variant="contained"
                            color="primary"
                            disableElevation
                            isLoading={false}
                            onClick={() => handleSubmitCompany()}
                          >
                            {translation['form.save']}
                          </LoadingButton>
                        </Grid>
                      </Grid>
                    </Box>
                  }
                />
              }
            </Card>
          </Grid>
        }

      </Grid>
    </Page>
  )
}
