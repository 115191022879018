import React, { ChangeEvent } from 'react'
import { Box, Card, CardContent, TextField } from '@material-ui/core'
import { debounce } from '@material-ui/core/utils'
import { StyledTypographyHeader } from './StyledTypography'
import { CSSProperties } from '@material-ui/core/styles/withStyles'

interface CommentFormProps {
  label: string
  value: string
  style?: CSSProperties
  onChange: (comment: string) => void
}

export function CommentForm ({ label, value, onChange, style }: CommentFormProps) {
  const handleChangeDebounced = debounce(onChange, 1000)
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    handleChangeDebounced(e.currentTarget.value)
  }

  return (
    <Card style={style}>
      <CardContent>
        <Box pb={1}>
          <StyledTypographyHeader>
            {label}
          </StyledTypographyHeader>
        </Box>

        <TextField
          multiline
          fullWidth
          variant="outlined"
          defaultValue={value}
          onChange={handleChange}
          rows={10}
        />
      </CardContent>
    </Card>
  )
}
