import React, { useEffect, useState } from 'react'
import {
  Toolbar,
  Typography,
  LinearProgress,
  Box,
  AppBar
} from '@material-ui/core'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'
import { LayoutLanguage } from 'components/LayoutLanguage'
import { LayoutUserMenu } from 'components/LayoutUserMenu'
import { useLayoutContext } from 'components/LayoutContext'
import { NotificationSwitcher } from './NotificationSwitcher'
import { useAuthContext } from './AuthContext'
import { useTranslationContext } from 'components/TranslationContext'
import { TitleEditField } from './TitleEditField'
import { userPagesTitlesApi } from '../api/userPagesTitlesApi'
import { CountrySelect } from './CountrySelect'
import { BasicMarketUnitsSelect } from './BasicMarketUnitsSelect'
import ColumnMenuNavigation from './ColumnMenuNavigation'
import '../styles/global.sass'

const drawerWidth = 280

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      height: '100vh',
      overflow: 'auto'
    },
    appBar: {
      '@media print': {
        display: 'none'
      },
      backgroundColor: '#f2f2f2'
    },
    appBarTitle: {
      flexGrow: 1,
      color: '#000',
      fontSize: 20,
      marginLeft: 10
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,

      '@media print': {
        display: 'none'
      }
    },
    drawerPaper: {
      width: drawerWidth,
      backgroundColor: theme.palette.backgrounds.drawer
    },
    content: {
      position: 'relative',
      width: `calc(100% - ${drawerWidth}px)`,
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'auto',
      backgroundColor: '#f2f2f2'
    },
    linearProgress: {
      position: 'absolute',
      zIndex: theme.zIndex.appBar + 1,
      top: 0,
      left: 0,
      right: 0
    },
    snackbar: {
      backgroundColor: theme.palette.backgrounds.snackbar
    }
  })
)

export function Layout ({ children }: React.PropsWithChildren<{}>) {
  const classes = useStyles()
  const { title, isContentLoading, editableTitle, setTitle, pageKey } = useLayoutContext()
  const [isTitleEditing, setIsTitleEditing] = useState<boolean>(false)
  const { companyLogo, currentUser, handleResponseFailure, handleResponseSuccess } = useAuthContext()
  const { translation } = useTranslationContext()

  useEffect(() => {
    if (!editableTitle) {
      setIsTitleEditing(false)
    }
  }, [setIsTitleEditing, editableTitle])

  const handleTitleChange = (title : string) => {
    currentUser && userPagesTitlesApi.setPageTitle(pageKey as string, currentUser.id, title)
      .then(() => {
        setTitle(title)
        handleResponseSuccess()
      })
      .catch(handleResponseFailure)
  }

  return (
    <div className={classes.root}>
      <ColumnMenuNavigation />

      <div className={classes.content}>
        <AppBar elevation={0} position="static" className={classes.appBar}>
          <Toolbar>

            <TitleEditField isTitleEditing={isTitleEditing} setIsTitleEditing={setIsTitleEditing} onChange={handleTitleChange} />

            { !isTitleEditing && <Typography variant="h6" noWrap className={classes.appBarTitle}>
              {title}
            </Typography>}

            <Box px={5}>
              { companyLogo && <img style={{
                maxWidth: 100
              }} src={String(companyLogo)} alt={translation['components.CompaniesPage.logoImgAlt']} /> }
            </Box>

            <BasicMarketUnitsSelect />

            <NotificationSwitcher />

            <CountrySelect />

            <LayoutLanguage />

            <LayoutUserMenu />
          </Toolbar>
        </AppBar>

        {isContentLoading && (
          <LinearProgress
            className={classes.linearProgress}
            variant="indeterminate"
          />
        )}

        {children}
      </div>
    </div>
  )
}
