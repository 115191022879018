import React from 'react'
import { Page } from 'components/Page'
import { useTranslationContext } from 'components/TranslationContext'
import { StandardsPage } from './StandardsPage'
import { TargetsPage } from './TargetsPage'

export function AdvertisingPage () {
  const { translation } = useTranslationContext()

  const tabs = [
    {
      label: translation['pages.AdvertisingPage.tabs.standards'],
      url: 'standards',
      component: StandardsPage
    },
    {
      label: translation['pages.AdvertisingPage.tabs.targets'],
      url: 'targets',
      component: TargetsPage
    }
  ]

  return (
    <Page title={translation['menu.market_standards_adv']} tabs={tabs} />
  )
}
