import { action, observable } from 'mobx'
import { Units } from '../api/marketCharacteristicsApi'

export interface IMarketUnitsStore {
  units: Units | null
  setUnits: (units: Units | null) => void
}

export const marketUnitsStore = observable<IMarketUnitsStore>({
  units: null,
  setUnits: action((units: Units | null) => {
    marketUnitsStore.units = units
  })
})
