import React from 'react'
import { PeriodsForCalculationsPage } from './PeriodsForCalculationsPage'
import { MonitoringPeriodsPage } from './MonitoringPeriodsPage'
import { Page } from 'components/Page'
import { useTranslationContext } from 'components/TranslationContext'

export function PeriodsPage () {
  const { translation } = useTranslationContext()

  const tabs = [
    {
      label: translation['menu.units_periods_main'],
      url: 'calculation-periods',
      component: PeriodsForCalculationsPage
    },
    {
      label: translation['menu.units_control_periods'],
      url: 'monitoring-periods',
      component: MonitoringPeriodsPage
    }
  ]

  return (
    <Page title={translation['menu.units_periods']} tabs={tabs} />
  )
}
