import React from 'react'
import { Page } from 'components/Page'
import { StatusPage } from './StatusPage'
import { ForecastPage } from './ForecastPage'
import { useTranslationContext } from 'components/TranslationContext'

export function SalesSummaryPage () {
  const { translation } = useTranslationContext()
  const tabs = [
    {
      label: translation['pages.SalesSummaryPage.tabs.status'],
      url: 'status',
      component: StatusPage,
      hasInfoOnly: true
    },
    {
      label: translation['pages.SalesSummaryPage.tabs.forecast'],
      url: 'forecasr',
      component: ForecastPage,
      hasInfoOnly: true
    }
  ]
  return (
    <Page title={translation['pages.SalesSummaryPage.title']} tabs={tabs} />
  )
}
