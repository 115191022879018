import React, { Fragment } from 'react'
import { Box, Card, Tooltip, Typography } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import { useTranslationContext } from 'components/TranslationContext'
import { calculatePercentage } from 'helpers/calculatePercentage'
import { formatNumber } from 'helpers/formatNumber'
import { TripleMarkerProps } from '../api/types'
import { StyledTypography } from './StyledTypography'

interface MarkerTitleRowProps {
  row: string
}

function MarkerTitleRow ({ row }: MarkerTitleRowProps) {
  return (
    <Tooltip title={<h2>{`${row}`}</h2>} enterDelay={500}>
      <Box overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
        <Typography variant="h6" display="inline" style={{
          fontSize: 20
        }}>
          {row}
        </Typography>
      </Box>
    </Tooltip>
  )
}

interface MarkerTitleProps {
  title: string
}

function MarkerTitle ({ title }: MarkerTitleProps) {
  return (
    <Box mb={1}>
      {title.split('\n').map((row, idx, arr) => (
        <MarkerTitleRow
          key={idx}
          row={row}
        />
      ))}
    </Box>
  )
}

interface MarkerNumberProps {
  value?: string
  unit?: string
  colored: boolean
}

function MarkerNumber ({ value, unit, colored }: MarkerNumberProps) {
  const theme = useTheme()
  const color = colored
    ? value && Number(value) < 0
      ? theme.palette.error.main
      : theme.palette.success.main
    : theme.palette.text.secondary
  return (
    <Box
      width='100%'
      padding='20px'
    >
      <Box color={color}>
        <Typography variant="h4" style={{ fontWeight: 'bold' }} align="center">
          {colored && Number(value) > 0 && '+'}
          {formatNumber(value, { maximumFractionDigits: 2 }) ?? ''}
          {unit === '%' && '%'}
        </Typography>
      </Box>

      {unit !== '%'
        ? (
          <Typography variant="caption" align="center" display="block">
            {unit}
          </Typography>
        )
        : (
          <Box height="19px" />
        )}
    </Box>
  )
}

interface MarkerSectionProps {
  title: string
  value?: string
  secondary?: boolean
  unit?: string
}

function MarkerSection ({ title, value, secondary, unit }: MarkerSectionProps) {
  const formattedValue = `${formatNumber(value, { maximumFractionDigits: 2 })}${unit === '%' ? '%' : ''}`

  return (
    <Box width={{ xs: '100%', sm: 'auto' }}>
      <StyledTypography variant="body2" color={secondary ? 'textSecondary' : 'textPrimary'}>
        {title}
      </StyledTypography>

      <Tooltip title={formattedValue} enterDelay={500}>
        <Typography color={secondary ? 'textSecondary' : 'textPrimary'} component="div">
          <Box
            fontSize={20}
            fontWeight={secondary ? '400' : '500'}
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            {formattedValue}
          </Box>
        </Typography>
      </Tooltip>

      {unit !== '%' && !secondary
        ? (
          <Typography style={{
            fontSize: 12
          }} variant="caption">
            {unit}
          </Typography>
        )
        : (
          <Box height="19px" />
        )}
    </Box>
  )
}

interface MarkerActualForecastProps {
  unit?: string
  actualTitle?: string
  actualValue?: string
  forecastTitle?: string
  forecastValue?: string
}

function MarkerActualForecast (props: MarkerActualForecastProps) {
  const { translation } = useTranslationContext()
  const { actualTitle, actualValue, forecastTitle, forecastValue, unit } = props

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      flexWrap="wrap"
    >
      <MarkerSection
        unit={unit}
        title={actualTitle || translation['marker.actual']}
        value={actualValue}
      />

      <MarkerSection
        unit={unit}
        title={forecastTitle || translation['marker.forecast']}
        value={forecastValue}
        secondary
      />
    </Box>
  )
}

interface MarkerProgressProps {
  actualValue?: string
  forecastValue?: string
}

function MakerProgress ({ actualValue, forecastValue }: MarkerProgressProps) {
  const theme = useTheme()
  const percentage = calculatePercentage(actualValue, forecastValue)

  const getPercentageColor = (percentage?: number) => {
    if (!percentage || percentage <= 74) {
      return theme.palette.marker.low
    }

    if (percentage >= 98) {
      return theme.palette.marker.high
    }

    if (percentage >= 75 && percentage <= 97) {
      return theme.palette.marker.middle
    }
  }
  const color = getPercentageColor(percentage)
  const borderWidth = 5
  const borderStyle = `${borderWidth}px solid ${color}`

  const progressStyles = { background: percentage ? `linear-gradient(to right, ${color} ${Number(percentage)}%, transparent ${Number(percentage)}%)` : 'none' }

  return (
    <>
      <Box
        mb="4px"
        height={borderWidth * 2 + 2}
        border={`1px solid ${color}`}
        borderRadius={theme.shape.borderRadius}
        style={progressStyles}
      >
        {/* Print version removes background by default */}
        <Box
          borderTop={borderStyle}
          borderBottom={borderStyle}
          width={percentage ? `${percentage <= 100 ? percentage : 100}%` : 0}
        />
      </Box>

      <Typography style={{
        fontSize: 12
      }} variant="caption" display="block" align="right">
        {`${formatNumber(percentage, { maximumFractionDigits: 2 })}%`}
      </Typography>
    </>
  )
}

interface MarkerProps {
  title: string
  actualTitle?: string
  actualValue?: string
  forecastTitle?: string
  companyTitle?: string
  avgTitle?: string
  leadTitle?: string
  forecastValue?: string
  value?: string
  unit?: string
  companyValue?: number
  avgValue?: number
  leadValue?: number
  type: 'progress' | 'number' | 'combined' | 'scale'
}

export function TripleMarker ({ center, left, right, type }: TripleMarkerProps) {
  return (
    <Card>
      <Box p={2}>
        <MarkerTitle
          title={center.title}
        />
        <MarkerNumber
          value={center.value}
          unit={center.unit}
          colored={true}
        />
        <Box
          display="flex"
          justifyContent="space-between"
          flexWrap="wrap"
        >
          <MarkerSection
            unit={left.unit}
            title={left.title}
            value={left.value}
          />

          <MarkerSection
            unit={right.unit}
            title={right.title}
            value={right.value}
          />
        </Box>
      </Box>
    </Card>
  )
}

const boxSizingContainer : 'border-box' = 'border-box'
const positionMark : 'absolute' = 'absolute'
const positionMarks : 'relative' = 'relative'
const valueFontWeight : 'bold' = 'bold'

const markerScaleStyles = {
  container: {
    height: 100,
    width: '95%',
    boxSizing: boxSizingContainer,
    position: positionMarks
  },
  bar: {
    width: '104%',
    height: 3,
    backgroundColor: '#b2b2b2',
    position: positionMarks,
    bottom: 0
  },
  endLine: {
    width: 12,
    height: 3,
    backgroundColor: '#b2b2b2',
    alignSelf: 'flex-end'
  },
  marks: {
    display: 'flex',
    alignItems: 'flex-end',
    width: '100%',
    position: positionMarks
  },
  mark: {
    // width: 10,
    position: positionMark,
    paddingLeft: 6,
    paddingRight: 6
  },
  mark__bg: {
    backgroundColor: '#b2b2b2',
    height: 3
  },
  mark__company: {
    backgroundColor: '#7a7ae1',
    height: 20
  },
  mark__avg: {
    backgroundColor: 'green',
    height: 20
  },
  mark__lead: {
    backgroundColor: 'red',
    height: 20
  },
  markValue: {
    color: '#fff',
    fontSize: 12,
    fontWeight: valueFontWeight
  },
  legend: {
    marginTop: 15,
    width: '100%'
  },
  legend__item: {
    display: 'flex',
    alignItems: 'center'
  },
  legend__itemColor_company: {
    width: 15,
    height: 15,
    marginRight: 3,
    backgroundColor: '#7a7ae1'
  },
  legend__itemColor_avg: {
    width: 15,
    height: 15,
    marginRight: 3,
    backgroundColor: 'green'
  },
  legend__itemColor_lead: {
    width: 15,
    height: 15,
    marginRight: 3,
    backgroundColor: 'red'
  },
  legend__itemText: {
    valueFontWeight,
    fontSize: 15
  }
}

const MarkerScale = (
  {
    companyValue,
    avgValue,
    leadValue,
    companyTitle,
    avgTitle,
    leadTitle
  }: {
    companyValue?: number,
    avgValue?: number,
    leadValue?: number | null,
    companyTitle?: string,
    avgTitle?: string,
    leadTitle?: string | null
  }) => {
  if (!companyValue || !avgValue) {
    return null
  }

  const dataArr = [companyValue, avgValue]

  if (leadValue) {
    dataArr.push(leadValue)
  }

  const MarkerMark = ({ type, value }: {type: 'mark__company' | 'mark__lead' | 'mark__avg', value: number}) => {
    const max = Math.max.apply(null, dataArr)

    const cf = max / value
    const percents = 100 / cf

    return (
      <Fragment>
        <div style={{ ...markerScaleStyles.mark, ...markerScaleStyles[type], ...{ left: `${percents}%` } }} />
        <div style={{ ...markerScaleStyles.mark, ...markerScaleStyles.mark__bg, ...{ left: `${percents}%`, bottom: -3 } }} />
      </Fragment>
    )
  }

  const format = (value: number) => formatNumber(value, { maximumFractionDigits: 2 })

  return (
    <div style={markerScaleStyles.container}>
      <div style={markerScaleStyles.marks}>
        {
          leadValue && <MarkerMark type="mark__lead" value={leadValue} />
        }
        <MarkerMark type='mark__avg' value={avgValue} />
        <MarkerMark type='mark__company' value={companyValue} />
      </div>
      <div style={{ display: 'flex' }}>
        <div style={{ ...markerScaleStyles.bar }} />
        <div style={{ ...markerScaleStyles.endLine }} />
      </div>
      <div style={markerScaleStyles.legend}>
        <div style={markerScaleStyles.legend__item}>
          <div style={markerScaleStyles.legend__itemColor_company} />
          <div style={markerScaleStyles.legend__itemText}>
            <span>{companyTitle} - {format(companyValue)}</span>
          </div>
        </div>
        <div style={markerScaleStyles.legend__item}>
          <div style={markerScaleStyles.legend__itemColor_avg} />
          <div style={markerScaleStyles.legend__itemText}>
            <span>{avgTitle} = {format(avgValue)}</span>
          </div>
        </div>
        {
          leadValue && <div style={markerScaleStyles.legend__item}>
            <div style={markerScaleStyles.legend__itemColor_lead} />
            <div style={markerScaleStyles.legend__itemText}>
              <span>{leadTitle} - {format(leadValue)}</span>
            </div>
          </div>
        }
      </div>
    </div>
  )
}

export function Marker (props: MarkerProps) {
  const {
    title,
    actualTitle,
    actualValue,
    forecastTitle,
    forecastValue,
    unit,
    type,
    value
  } = props

  return (
    <Card>
      <Box p={2}>
        <MarkerTitle
          title={title}
        />
        {(type === 'number' || type === 'combined') && (
          <MarkerNumber
            value={value}
            unit={type === 'number' ? unit : '%'}
            colored={type === 'combined'}
          />
        )}

        {(type === 'progress' || type === 'combined') && (
          <MarkerActualForecast
            unit={unit}
            actualTitle={actualTitle}
            actualValue={actualValue}
            forecastTitle={forecastTitle}
            forecastValue={forecastValue}
          />
        )}

        {(type === 'progress') && (
          <MakerProgress
            actualValue={actualValue}
            forecastValue={forecastValue}
          />
        )}

        {type === 'number' && (
          <Box height="7px" />
        )}

        {type === 'scale' && (
          <Box pt={6} px={5}>
            { <MarkerScale {...props}/>}
          </Box>
        )}
      </Box>
    </Card>
  )
}
