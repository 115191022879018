import { request } from 'api/request'

interface SalesStructureElement {
  id: number
  structureName: string
}
export interface SalesStructure {
  description: string
  elements: SalesStructureElement[]
}

function getSalesStructure () {
  return request('companies/sales_structure')
}

function updateSalesStructure (salesStructure: SalesStructure) {
  return request('companies/sales_structure', 'PUT', salesStructure)
}

interface MarketingBudgetItem {
  name: string
  enabled: boolean
  desc: string
  type: string
  typeName: string
}

export interface MarketingBudget {
  enabledTitle: string
  nameTitle: string
  typeDescTitle: string
  typeNameTitle: string
  rows: MarketingBudgetItem[]
}

function getMarketingBudget () {
  return request('companies/marketing_budget')
}

function updateMarketingBudget (marketingBudget: MarketingBudget) {
  return request('companies/marketing_budget', 'PUT', marketingBudget)
}

export const companyCharacteristicsApi = {
  getSalesStructure,
  updateSalesStructure,
  getMarketingBudget,
  updateMarketingBudget
}
