import React, { Fragment } from 'react'

interface TextWithBreaksProps {
  text: string
}

export function TextWithBreaks ({ text }: TextWithBreaksProps) {
  return (
    <>
      {text.split('\n').map((item, key) => (
        <Fragment key={key}>
          {item}
          <br />
        </Fragment>
      ))}
    </>
  )
}
