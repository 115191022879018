import React, { useState, useEffect } from 'react'
import {
  Card,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Button,
  Box,
  IconButton,
  Dialog,
  DialogContent,
  TableBody,
  Grid,
  Tooltip
} from '@material-ui/core'
import { Add, Delete, Edit, LockOpen } from '@material-ui/icons'
import { useAuthContext } from 'components/AuthContext'
import { ChangePasswordForm } from 'components/ChangePasswordForm'
import { UserForm, UserFormProps } from 'components/UserForm'
import { Confirmation } from 'components/Cofirmation'
import { userRoles } from 'constants/userRoles'
import { useLayoutContext } from 'components/LayoutContext'
import { useTranslationContext } from 'components/TranslationContext'
import { User, usersApi } from 'api/usersApi'
import { companiesApi } from 'api/companiesApi'

interface UserManagementPageProps {
  companyId: number
}

export function UsersList ({ companyId }: UserManagementPageProps) {
  const { startContentLoading, finishContentLoading, isContentLoading } = useLayoutContext()
  const { currentUser, handleResponseSuccess, handleResponseFailure } = useAuthContext()
  const { translation } = useTranslationContext()
  const [users, setUsers] = useState<User[]>([])
  const [isUserFormOpen, setIsUserFormOpen] = useState(false)
  const [userFormInitialValue, setUserFormInitialValues] = useState<Partial<User>>()
  const [changingPasswordUser, setChangingPasswordUser] = useState<User | null>(null)

  const showUserForm = (user?: User) => () => {
    setUserFormInitialValues(user)
    setIsUserFormOpen(true)
  }
  const hideUserForm = () => {
    setUserFormInitialValues({})
    setIsUserFormOpen(false)
  }
  const handleUserFormSubmit: UserFormProps['onSubmit'] = (values, { setErrors }) => {
    if (values.id) {
      return usersApi.update(values)
        .then(() => {
          const updatedUsers = users.map((user) => user.id === values.id
            ? { ...user, ...values }
            : user
          )
          setUsers(updatedUsers)
          handleResponseSuccess()
          hideUserForm()
        })
        .catch((error) => {
          handleResponseFailure(error, setErrors)
        })
    }

    return usersApi.create(values, companyId)
      .then((createdUser) => {
        setUsers([...users, createdUser])
        handleResponseSuccess()
        hideUserForm()
      })
      .catch((error) => {
        handleResponseFailure(error, setErrors)
      })
  }
  const showChangePasswordForm = (user: User) => () => {
    setChangingPasswordUser(user)
  }
  const hideChangePasswordForm = () => {
    setChangingPasswordUser(null)
  }
  const handleChangePasswordFormSubmit = (newPassword: string) => {
    if (changingPasswordUser) {
      return usersApi.changePasswordForUser(changingPasswordUser.id, newPassword)
        .then(() => {
          handleResponseSuccess()
          hideChangePasswordForm()
        })
        .catch(handleResponseFailure)
    }
  }
  const [isDeletingUser, setIsDeletingUser] = useState(false)
  const deleteUser = (userForDelete: User) => () => {
    setIsDeletingUser(true)

    return usersApi.remove(userForDelete)
      .then(() => {
        handleResponseSuccess()
        setUsers(users.filter((user) => user !== userForDelete))
      })
      .catch(handleResponseFailure)
      .finally(() => {
        setIsDeletingUser(false)
      })
  }

  useEffect(() => {
    startContentLoading()

    companiesApi.getCompany(companyId)
      .then(({ users }) => setUsers(users))
      .catch(handleResponseFailure)
      .finally(finishContentLoading)
  }, [companyId, finishContentLoading, startContentLoading, handleResponseFailure])

  if (isContentLoading) {
    return null
  }

  return (
    <>
      <Card>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width="40%">{translation['menu.profile.name']}</TableCell>
              <TableCell width="30%">{translation['menu.profile.email']}</TableCell>
              <TableCell width="15%">{translation['menu.profile.position']}</TableCell>
              <TableCell width="15%">{translation['menu.profile.role']}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>

          <TableBody>
            {users.map((user) => (
              <TableRow key={user.id}>
                <TableCell>{user.username}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.position}</TableCell>
                <TableCell>{user.role && translation[`roles.${userRoles[user.role]}`]}</TableCell>
                <TableCell>
                  <Grid container spacing={1} wrap="nowrap">
                    <Grid item>
                      <Tooltip title={<h2 style={{ color: 'white' }}>{translation['form.edit']}</h2>}>
                        <IconButton size="small" onClick={showUserForm(user)}>
                          <Edit />
                        </IconButton>
                      </Tooltip>
                    </Grid>

                    <Grid item>
                      <Tooltip title={<h2 style={{ color: 'white' }}>{translation['menu.profile.change_password']}</h2>}>
                        <IconButton size="small" onClick={showChangePasswordForm(user)}>
                          <LockOpen />
                        </IconButton>
                      </Tooltip>
                    </Grid>

                    <Grid item>
                      <Confirmation
                        title={translation['menu.profile.confirm_delete_user']}
                        processText={translation['menu.profile.confirm_delete_user_agree']}
                        cancelText={translation['menu.profile.confirm_delete_user_cancel']}
                        onProcess={deleteUser(user)}
                        isProcessing={isDeletingUser}
                        trigger={({ showConfirmation }) => (
                          <Tooltip title={<h2 style={{ color: 'white' }}>{translation['form.delete']}</h2>}>
                            <span>
                              <IconButton
                                size="small"
                                onClick={showConfirmation}
                                disabled={user.id === currentUser?.id}
                              >
                                <Delete />
                              </IconButton>
                            </span>
                          </Tooltip>
                        )}
                      />
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <Box p={1} textAlign="right">
          <Button
            color="primary"
            startIcon={<Add />}
            onClick={showUserForm()}
          >
            {translation['form.add']}
          </Button>
        </Box>
      </Card>

      <Dialog open={isUserFormOpen} onClose={hideUserForm}>
        <DialogContent dividers>
          <UserForm initialValues={userFormInitialValue} onSubmit={handleUserFormSubmit} />
        </DialogContent>
      </Dialog>

      <Dialog open={Boolean(changingPasswordUser)} onClose={hideChangePasswordForm}>
        <DialogContent dividers>
          <ChangePasswordForm onSubmit={handleChangePasswordFormSubmit} />
        </DialogContent>
      </Dialog>
    </>
  )
}
