import React from 'react'
import { Typography } from '@material-ui/core'
import { Page } from 'components/Page'
import { useTranslationContext } from 'components/TranslationContext'

export function UserManagementInfoPage () {
  const { translation } = useTranslationContext()
  return (
    <Page title={translation['menu.company_users']}>
      <Typography variant="caption">
        {translation['pages.UserManagementInfoPage.content']}
      </Typography>
    </Page>
  )
}
