import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import { ArrowUpward } from '@material-ui/icons'

const useStyles = makeStyles({
  root: {
    position: 'fixed',
    right: 25,
    bottom: 25,
    backgroundColor: '#0000ff',
    border: '5px solid white',
    zIndex: 1000,
    '& svg path': {
      color: 'white'
    },
    '&:hover': {
      backgroundColor: '#0000ff',
      opacity: 0.8
    }
  }
})

interface GoTopButtonProps {
  contentRef: any
}

export const GoTopButton = ({ contentRef }: GoTopButtonProps) => {
  const classes = useStyles()

  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    if (window.innerHeight <= contentRef.current?.clientHeight) {
      setIsVisible(true)
    } else {
      setIsVisible(false)
    }
    // eslint-disable-next-line
  }, [contentRef.current?.clientHeight])

  const onClick = () => {
    contentRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    })
  }

  return isVisible ? (
    <IconButton
      aria-label='up'
      className={classes.root}
      onClick={onClick}
    >
      <ArrowUpward />
    </IconButton>
  ) : null
}
