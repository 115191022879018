import { Box, IconButton, TextField } from '@material-ui/core'
import { Check, Edit } from '@material-ui/icons'
import React, { ChangeEvent, Fragment } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { useLayoutContext } from './LayoutContext'
import { debounce } from '@material-ui/core/utils'

const useStyles = makeStyles(() =>
  createStyles({
    titleEditFieldContainer: {
      flexGrow: 1
    },
    titleEditField: {
      width: 400,
      background: '#fff'
    }
  })
)

interface TitleEditFieldProps {
  setIsTitleEditing: (isTitleEditing : boolean) => void
  isTitleEditing: boolean
  onChange: (title: string) => void
}

export function TitleEditField ({ setIsTitleEditing, isTitleEditing, onChange } : TitleEditFieldProps) {
  const classes = useStyles()
  const { title, editableTitle } = useLayoutContext()
  const handleChangeDebounced = debounce(onChange, 1000)
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    handleChangeDebounced(e.currentTarget.value)
  }

  return <Fragment>
    {editableTitle && <IconButton onClick={() => {
      setIsTitleEditing(!isTitleEditing)
    }} edge="start">
      {isTitleEditing ? <Check color="primary"/> : <Edit color="primary"/>}
    </IconButton>}

    {editableTitle && isTitleEditing && <Box className={classes.titleEditFieldContainer}>
      <TextField onChange={handleChange} className={classes.titleEditField} defaultValue={title} variant="outlined" />
    </Box>}
  </Fragment>
}
