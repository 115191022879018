import React from 'react'
import { Box, Button, DialogContent, DialogTitle } from '@material-ui/core'
import { useTranslationContext } from 'components/TranslationContext'

interface Properties {
  yes: () => void
  no: () => void
  waiting: boolean
  description?: string
}

export function MarketingBudgetEditableDialog ({ yes, no, waiting, description }: Properties) {
  const { translation } = useTranslationContext()

  return (
    <>
      <DialogTitle>{translation['components.MarketingBudgetEnableDialog.title']}</DialogTitle>
      <DialogContent>
        <Box pb={2} justifyContent="space-around" flexDirection="row" alignItems="center" display="flex">
          <Button
            variant="contained"
            color="secondary"
            onClick={yes}
            disabled={waiting}
          >{translation['components.MarketingBudgetEnableDialog.yes']}</Button>
          <Button
            variant="contained"
            onClick={no}
          >{translation['components.MarketingBudgetEnableDialog.no']}</Button>
        </Box>
      </DialogContent>
      <DialogContent dividers style={{ whiteSpace: 'pre' }}>{description}</DialogContent>
    </>
  )
}
