import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { useTranslationContext } from 'components/TranslationContext'

export function RawMaterialsPage () {
  const { translation } = useTranslationContext()

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography paragraph>
          {translation['pages.InfoPage.content']}
        </Typography>
      </Grid>
    </Grid>
  )
}
