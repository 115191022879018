import React, { useState } from 'react'
import { Box, Button, Grid, Popover, Typography } from '@material-ui/core'
import { PopoverProps } from '@material-ui/core/Popover'
import { LoadingButton } from 'components/LoadingButton'

interface TriggerProps {
  showConfirmation: (event: React.MouseEvent<HTMLElement>) => void;
}

interface ConfirmationProps {
  title: React.ReactNode;
  cancelText?: string;
  processText?: string;
  onProcess: (hideConfirmation: () => void) => void;
  isProcessing?: boolean;
  anchorOrigin?: PopoverProps['anchorOrigin'];
  transformOrigin?: PopoverProps['transformOrigin'];
  trigger: ({ showConfirmation }: TriggerProps) => React.ReactNode;
}

export function Confirmation (props: ConfirmationProps) {
  const {
    title,
    cancelText,
    processText,
    onProcess,
    isProcessing,
    anchorOrigin,
    transformOrigin,
    trigger
  } = props
  const [
    confirmationAnchor,
    setConfirmationAnchor
  ] = useState<HTMLElement | null>(null)
  const showConfirmation = (event: React.MouseEvent<HTMLElement>) => {
    setConfirmationAnchor(event.currentTarget)
  }
  const hideConfirmation = () => {
    setConfirmationAnchor(null)
  }

  return (
    <>
      {trigger({ showConfirmation })}

      <Popover
        open={!!confirmationAnchor}
        anchorEl={confirmationAnchor}
        onClose={hideConfirmation}
        anchorOrigin={
          anchorOrigin || {
            vertical: 'bottom',
            horizontal: 'right'
          }
        }
        transformOrigin={
          transformOrigin || {
            vertical: 'top',
            horizontal: 'right'
          }
        }
      >
        <Box p={2}>
          <Box mb={2}>
            {typeof title === 'string' ? (
              <Typography variant="body1">{title}</Typography>
            ) : (
              title
            )}
          </Box>

          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Button
                variant="outlined"
                fullWidth
                disabled={isProcessing}
                onClick={hideConfirmation}
              >
                {cancelText || 'No'}
              </Button>
            </Grid>

            <Grid item xs={6}>
              <LoadingButton
                variant="contained"
                color="primary"
                fullWidth
                disableElevation
                isLoading={Boolean(isProcessing)}
                onClick={() => onProcess(hideConfirmation)}
                autoFocus
              >
                {processText || 'Yes'}
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      </Popover>
    </>
  )
}
