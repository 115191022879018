import React, { useEffect, useState } from 'react'
import { Page } from 'components/Page'
import { useTranslationContext } from 'components/TranslationContext'
import { CompetitorsPage } from './CompetitorsPage'
import { useAuthContext } from '../../components/AuthContext'
import { CommonInfo, commonInfoApi } from '../../api/commonInfoApi'
import { CompanyBySegmentPage } from './CompanyBySegmentPage'
import { MarketBySegmentPage } from './MarketBySegmentPage'

export function MarketSegmentsPage () {
  const { translation } = useTranslationContext()
  const { currentCompany, handleResponseFailure } = useAuthContext()
  const [commonInfo, setCommonInfo] = useState<CommonInfo>()
  useEffect(() => {
    commonInfoApi.getCommonInfo()
      .then(setCommonInfo)
      .catch(handleResponseFailure)
  }, [handleResponseFailure])

  if (!commonInfo || !currentCompany) {
    return null
  }

  const { shortName } = commonInfo.fields

  const tabs = [
    {
      label: translation['pages.MarketAndCompanyCategoriesPage.tabs.market_by_segments'],
      url: 'market-by-segment',
      component: MarketBySegmentPage
    },
    {
      label: `${shortName.value}${translation['pages.MarketAndCompanyCategoriesPage.tabs.by_segments']}`,
      url: 'company-by-segment',
      component: CompanyBySegmentPage
    },
    {
      label: translation['menu.market_and_company_competiotors'],
      url: 'competitors',
      component: CompetitorsPage
    }
  ]

  return (
    <Page title={translation['menu.segments']} tabs={tabs} />
  )
}
