import React, { CSSProperties, FocusEvent, useState } from 'react'
import { Box, TableCell, Typography, Checkbox, Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useDataTableContext, Column, Row, Cell, updateDataRowCellProp } from 'components/DataTableContext'
import { DataTableBodyCellTrend } from 'components/DataTableBodyCellTrend'
import { DataTableBodyCellEdit } from 'components/DataTableBodyCellEdit'
import { lightBlue } from '@material-ui/core/colors'
import { formatNumber } from 'helpers/formatNumber'
import { Error } from '@material-ui/icons'
import { observer } from 'mobx-react-lite'
import { userRichNotificationsStore, IUserRichNotificationsStore } from '../stores/userRichNotificationsStore'

function renderCellValue ({ type, value }: Partial<Cell>, idx?: number) {
  const styles : CSSProperties = {
    maxWidth: '30em',
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    hyphens: 'auto'
  }

  if (idx === 0) {
    styles.whiteSpace = 'pre'
    styles.overflow = 'hidden'
    styles.textOverflow = 'ellipsis'
  }

  switch (type) {
    case 'number':
      return formatNumber(value, { maximumFractionDigits: 2 })
    case 'percent':
      return `${formatNumber(value, { maximumFractionDigits: 2 })}%`
    default:
      return (
        <Tooltip title={<h2>{value}</h2>}>
          <Box
            style={styles}
          >
            {value}
          </Box>
        </Tooltip>
      )
  }
}

const useStyles = makeStyles({
  root: {
    position: 'relative',
    outline: 'none'
  },
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  value: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center'
  }
})

interface DataTableBodyCellProps {
  column: Column
  row: Row
  idx: number
  style?: CSSProperties
  onClick?: () => void
}

interface DataTableBodyCellObserverProps extends DataTableBodyCellProps {
  userRichNotificationsStore: IUserRichNotificationsStore
}

const DataTableBodyCellObserver = observer(({ column, row, userRichNotificationsStore, idx, style = {}, onClick }: DataTableBodyCellObserverProps) => {
  const [isEditing, setIsEditing] = useState(false)
  const { data, onDataUpdate, getCell } = useDataTableContext()
  const cell = getCell(row, column)
  const classes = useStyles()

  const {
    type,
    delta,
    showDelta,
    description,
    isEditable,
    checked,
    error
  } = cell
  const { showRichNotifications } = userRichNotificationsStore
  const showEditForm = (e: FocusEvent<HTMLTableHeaderCellElement>) => {
    if (isEditable && e.target.getAttribute('type') !== 'checkbox') {
      setIsEditing(true)
    }
  }
  const hideEditForm = () => {
    setIsEditing(false)
  }
  const handleCheckboxChange = () => {
    const updatedData = updateDataRowCellProp<'checked'>(
      data, row, column.field, 'checked', !checked
    )
    onDataUpdate && onDataUpdate(updatedData)
  }

  const isntEditableStyles : CSSProperties = { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }

  return (
    <TableCell
      className={classes.root}
      style={{
        background: cell.isEditable ? lightBlue[50] : 'none',
        cursor: cell.isEditable ? 'pointer' : 'auto'
      }}
      tabIndex={isEditable ? 0 : -1}
      onFocus={showEditForm}
      onClick={onClick}
    >
      <div
        className={classes.container}
        style={{ justifyContent: column.align, ...style }}
      >
        <div
          className={classes.value}
          style={isEditable ? { borderBottom: isEditable ? '1px dotted #ccc' : 'none' } : isntEditableStyles}
        >
          <div>
            {renderCellValue(cell, idx)}
          </div>

          {description && (
            <Typography variant="caption" color="textSecondary">
              {description}
            </Typography>
          )}

          {isEditing && (
            <DataTableBodyCellEdit
              cell={cell}
              onClose={hideEditForm}
            />
          )}

          {(checked !== null) && (
            <Checkbox
              color="default"
              checked={checked}
              onChange={handleCheckboxChange}
              disableRipple
              size="small"
              style={{ marginLeft: '5px' }}
              hidden
            />
          )}
        </div>

        {showDelta && type === 'number' && (
          <DataTableBodyCellTrend value={delta} />
        )}

        {error && showRichNotifications && (
          <Box ml={1} clone>
            <Tooltip title={<h2 style={{ color: 'white' }}>{error}</h2>}>
              <Error fontSize="small" color="error" />
            </Tooltip>
          </Box>
        )}
      </div>
    </TableCell>
  )
})

export const DataTableBodyCell = ({ column, row, idx, style = {}, onClick } : DataTableBodyCellProps) =>
  <DataTableBodyCellObserver
    idx={idx}
    column={column}
    row={row}
    style={style}
    onClick={onClick}
    userRichNotificationsStore={userRichNotificationsStore}
  />
