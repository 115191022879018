import React, { SyntheticEvent, useEffect, useState } from 'react'
import {
  Box, Dialog, DialogContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem, Paper,
  Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  TableSortLabel,
  Typography
} from '@material-ui/core'
import { Page } from 'components/Page'
import { marketCharacteristicsApi, ProductCategories } from '../../api/marketCharacteristicsApi'
import { geographyApi } from '../../api/geographyApi'
import { countriesApi } from '../../api/countriesApi'
import { Region, RegionId, Regions, Subject } from '../../api/types'
import { useAuthContext } from '../../components/AuthContext'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import { companiesApi, Company } from '../../api/companiesApi'
import { CommentForm } from '../../components/CommentForm'
import { useTranslationContext } from '../../components/TranslationContext'
import { formatNumber } from '../../helpers/formatNumber'
import { staticalManagementApi } from '../../api/staticalManagementApi'
import { useLocalStorage } from '../../helpers/useLocalStorage'
import { commonInfoApi } from '../../api/commonInfoApi'
import { ISalesGeographyAnswers } from './ISalesGeographyAnswers'

interface Indicator {
  id: number
  name: string
}

interface ProductCategoryIndicator {
  category: number
  indicator: number | null
}

enum OrderColumn {
  subject = 'subject',
  volume = 'volume'
}

enum OrderDirection {
  asc = 'asc',
  desc = 'desc'
}

interface OrderBy {
  column: OrderColumn,
  direction: OrderDirection
}

interface Row {
  subject: Subject,
  volume: number
}

const GEOGRAPHY_CONSUMPTION_USER_YEAR = 'geographyConsumption.year'
const GEOGRAPHY_CONSUMPTION_USER_CATEGORY = 'geographyConsumption.category'
const GEOGRAPHY_CONSUMPTION_USER_REGION_IDS = 'geographyConsumption.regionIds'
const GEOGRAPHY_CONSUMPTION_USER_ORDER_BY = 'geographyConsumption.orderBy'

export function GeographyConsumptionPage () {
  const { translation } = useTranslationContext()
  const [userYear, setUserYear] = useLocalStorage(GEOGRAPHY_CONSUMPTION_USER_YEAR)
  const [userCategory, setUserCategory] = useLocalStorage(GEOGRAPHY_CONSUMPTION_USER_CATEGORY)
  const [userRegionIds, setUserRegionIds] = useLocalStorage(GEOGRAPHY_CONSUMPTION_USER_REGION_IDS)
  const [userOrderBy, setStorageUserOrderBy] = useLocalStorage(GEOGRAPHY_CONSUMPTION_USER_ORDER_BY)
  const [productCategories, setProductCategories] = useState<ProductCategories>()
  const [productCategoryId, setProductCategoryId] = useState<number | null>((userCategory as unknown) as number || null)
  const [year, setYear] = useState<number | null>((userYear as unknown) as number || null)
  const [factYears, setFactYears] = useState<number[]>()
  const [userRegions, setUserRegions] = useState<Regions | null>(null)
  const [standardRussiaRegions, setStandardRussiaRegions] = useState<Regions | null>(null)
  const [consumptionGeography, setConsumptionGeography] = useState<{subjects: Row[], marketUnitText : string, volumeSum: number}>()
  const [regionIds, setRegionIds] = useState<RegionId[]>(userRegionIds ? JSON.parse(userRegionIds) as RegionId[] : [])
  const [orderBy, setOrderBy] = useState<OrderBy>(userOrderBy ? JSON.parse(userOrderBy) as OrderBy : {
    column: OrderColumn.subject,
    direction: OrderDirection.asc
  })
  const [customRegionIds, setCustomRegionIds] = useState<RegionId[]>(userRegionIds ? JSON.parse(userRegionIds) as RegionId[] : [])
  const { handleResponseFailure, handleResponseSuccess, selectedCountry } = useAuthContext()
  const [currentCompany, setCurrentCompany] = useState<Company>()
  const [comment, setComment] = useState<string | null>(null)
  const [changingProductCategoryIndicator, setChangingProductCategoryIndicator] = useState<boolean>(false)
  const [indicators, setIndicators] = useState<Indicator[]>()
  const [productCategoryIndicator, setProductCategoryIndicator] = useState<number>()
  const [personalClassification, setPersonalClassification] = useState<boolean>()

  useEffect(() => {
    if (!indicators) {
      staticalManagementApi.getAllIndicators()
        .then((indicators) => {
          setIndicators(indicators)
        })
    }
  }, [indicators])

  useEffect(() => {
    if (!comment) {
      geographyApi.getConsumptionGeographyComment()
        .then((response) => {
          setComment(response.comment)
        })
        .catch(handleResponseFailure)
    }
  }, [comment, handleResponseFailure])

  useEffect(() => {
    if (!currentCompany) {
      companiesApi.getCurrentCompany()
        .then(setCurrentCompany)
        .catch(handleResponseFailure)
    }
  }, [currentCompany, handleResponseFailure])

  useEffect(() => {
    if (!factYears) {
      countriesApi.getYears()
        .then(response => setFactYears(response.yearsArr))
    }
  }, [factYears])
  useEffect(() => {
    if (year && productCategoryId && (customRegionIds || regionIds)) {
      geographyApi.getConsumptionGeography(year, productCategoryId, personalClassification ? 'custom' : 'standard', personalClassification ? customRegionIds : regionIds)
        .then(setConsumptionGeography)
        .catch(handleResponseFailure)
    }
  }, [setConsumptionGeography, productCategoryId, year, customRegionIds, handleResponseFailure, personalClassification, regionIds])

  useEffect(() => {
    if (!productCategories) {
      marketCharacteristicsApi.getProductCategories()
        .then(setProductCategories)
        .catch(handleResponseFailure)
    }
  }, [productCategories, handleResponseFailure])

  useEffect(() => {
    if (!standardRussiaRegions) {
      countriesApi.getRegionList()
        .then((items : Region[]) => {
          setStandardRussiaRegions({ items })
        })
    }
  }, [standardRussiaRegions])

  useEffect(() => {
    if (!currentCompany) {
      return
    }

    (async () => {
      const { personalClassification }: ISalesGeographyAnswers = await commonInfoApi.getSalesGeographyAnswers()
      setPersonalClassification(personalClassification)
      if (personalClassification) {
        const items = await countriesApi.getCustomRegions()
        setUserRegions({ items })
      } else {
        setUserRegions(standardRussiaRegions)
      }
    })()
  }, [currentCompany, standardRussiaRegions])

  if (!productCategories || !currentCompany || !userRegions || !standardRussiaRegions || !factYears || !indicators) {
    return null
  }

  const hideChangingProductCategoryIndicatorForm = () => {
    setChangingProductCategoryIndicator(false)
  }

  const showChangingProductCategoryIndicatorForm = (event: SyntheticEvent) => {
    event.preventDefault()
    event.stopPropagation()

    if (productCategoryId) {
      staticalManagementApi.getProductCategoryIndicator(productCategoryId)
        .then(({ indicator } : ProductCategoryIndicator) => {
          setProductCategoryIndicator(indicator as number)
          setChangingProductCategoryIndicator(true)
        })
    }
  }

  const category = productCategories.productCategoryArr.find(el => el.id === Number(productCategoryId))

  const categoryNameModal = category ? category.categoryName : null

  const onSort = (column: OrderColumn) => {
    const state = {
      column,
      direction: orderBy.column === column && orderBy.direction === OrderDirection.asc ? OrderDirection.desc : OrderDirection.asc
    }

    setStorageUserOrderBy(JSON.stringify(state))
    setOrderBy(state)
  }

  const sortFn = (a: Row, b: Row) => {
    if (orderBy.column === OrderColumn.volume) {
      if (a.volume < b.volume) {
        return orderBy.direction === OrderDirection.asc ? -1 : 1
      }

      if (a.volume > b.volume) {
        return orderBy.direction === OrderDirection.asc ? 1 : -1
      }
    } else if (orderBy.column === OrderColumn.subject) {
      return orderBy.direction === OrderDirection.asc ? a.subject.name.localeCompare(b.subject.name) : b.subject.name.localeCompare(a.subject.name)
    }

    return 0
  }

  return (
    <Page title={translation['components.GeographyPage.title']}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <Grid container spacing={3}>
            <Grid item>
              <Box>
                <FormControl style={{ minWidth: 240 }}>
                  <InputLabel id="demo-simple-select-label">{translation['components.GeographyConsumptionPage.year']}</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={year}
                    onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                      setUserYear(event.target.value as string)
                      setYear(event.target.value as number)
                    }}
                  >
                    {
                      factYears.map((el, idx) => <MenuItem key={idx.toString()} value={el.toString()}>{el}</MenuItem>)
                    }
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item>
              <Box>
                <FormControl style={{ minWidth: 240 }}>
                  <InputLabel id="demo-simple-select-label">{translation['components.GeographyConsumptionPage.category']}</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={productCategoryId}
                    onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                      setUserCategory(event.target.value as string)
                      setProductCategoryId(event.target.value as number)
                    }}
                  >
                    {
                      productCategories.productCategoryArr.map((el, idx) => <MenuItem key={idx.toString()} value={el.id}>{el.categoryName}</MenuItem>)
                    }
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box pt={5}>
                <Typography variant='h6'>{translation['components.GeographyConsumptionPage.regions']} {personalClassification ? currentCompany.name : selectedCountry!.shortName!}</Typography>
                <ToggleButtonGroup
                  value={personalClassification ? customRegionIds : regionIds}
                  onChange={(event: React.MouseEvent<HTMLElement>, regionIds: RegionId[]) => {
                    setUserRegionIds(JSON.stringify(regionIds))
                    personalClassification ? setCustomRegionIds(regionIds) : setRegionIds(regionIds)
                  }}
                >
                  { (personalClassification ? userRegions : standardRussiaRegions).items.map((el, idx) => <ToggleButton key={idx.toString()} value={el.id}>{el.name}</ToggleButton>) }
                  <ToggleButton value={'others'}>{translation['components.GeographyConsumptionPage.others']}</ToggleButton>
                </ToggleButtonGroup>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12} md={6}>
          <img
            src='/geography_consumption_map.png'
            style={{ width: '100%' }}
            alt='geography consumption'
          />
        </Grid>

        <Grid container>
          <Grid item xs={12}>
            <Box pt={5}>
              {consumptionGeography && <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        key={`column_${OrderColumn.subject}`}
                        align='left'
                        sortDirection={orderBy.column === OrderColumn.subject ? orderBy.direction : false}
                        onClick={() => onSort(OrderColumn.subject)}
                      >
                        <TableSortLabel
                          active={orderBy.column === OrderColumn.subject}
                          direction={orderBy.column === OrderColumn.subject ? orderBy.direction : undefined}
                        >
                          {translation['components.GeographyConsumptionPage.subject']}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell
                        key={`column_${OrderColumn.volume}`}
                        align='left'
                        sortDirection={orderBy.column === OrderColumn.volume ? orderBy.direction : false}
                        onClick={() => onSort(OrderColumn.volume)}
                      >
                        <TableSortLabel
                          active={orderBy.column === OrderColumn.volume}
                          direction={orderBy.column === OrderColumn.volume ? orderBy.direction : undefined}
                        >
                          {translation['components.GeographyConsumptionPage.volume_by']}&nbsp;<a href="#1" onClick={showChangingProductCategoryIndicatorForm}>{translation['components.GeographyConsumptionPage.indicator']}</a>&nbsp;{consumptionGeography.marketUnitText}
                        </TableSortLabel>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    { consumptionGeography.subjects.sort(sortFn).map((el, idx) => (
                      <TableRow key={idx.toString()}>
                        { /* <TableCell align="left">{el.subject.region.name}</TableCell> */ }
                        <TableCell align="left">{el.subject.name}</TableCell>
                        <TableCell align="left">{formatNumber(el.volume, { maximumFractionDigits: 2 })}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableHead>
                    <TableRow>
                      { /* <TableCell align="left"></TableCell> */ }
                      <TableCell align="left">{translation['components.GeographyConsumptionPage.sum']}{consumptionGeography.marketUnitText}</TableCell>
                      <TableCell align="left">{formatNumber(consumptionGeography.volumeSum, { maximumFractionDigits: 2 })}</TableCell>
                    </TableRow>
                  </TableHead>
                </Table>
              </TableContainer>}
            </Box>
            <Box pt={5}>
              <CommentForm
                label={translation['components.GeographyConsumptionPage.comment']}
                value={comment || ''}
                onChange={(comment) => geographyApi.setConsumptionGeographyComment(comment)
                  .then(() => {
                    handleResponseSuccess()
                  })
                  .catch(handleResponseFailure)}
              />
            </Box>
            {productCategoryId && categoryNameModal && <Dialog open={Boolean(changingProductCategoryIndicator)} onClose={hideChangingProductCategoryIndicatorForm}>
              <DialogContent dividers>
                <Box pb={1}>
                  <Typography variant='caption'>{translation['components.GeographyConsumptionPage.category']}</Typography>
                  <Typography>{categoryNameModal}</Typography>
                </Box>
                {indicators && <Box>
                  <FormControl style={{ minWidth: 250 }}>
                    <InputLabel id="demo-simple-select-label">{translation['components.ProductCategoriesPage.indicator']}</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={productCategoryIndicator}
                      onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                        if (productCategoryId) {
                          staticalManagementApi.setProductCategoryIndicator(productCategoryId, event.target.value as number)
                            .then(() => {
                              setProductCategoryIndicator(event.target.value as number)
                              if (year && productCategoryId && (customRegionIds || regionIds)) {
                                geographyApi.getConsumptionGeography(year, productCategoryId, personalClassification ? 'custom' : 'standard', personalClassification ? customRegionIds : regionIds)
                                  .then(setConsumptionGeography)
                                  .catch(handleResponseFailure)
                              }
                            })
                        }
                      }}
                    >
                      {
                        indicators.map((el, idx) => <MenuItem key={idx.toString()} value={el.id}>{el.name}</MenuItem>)
                      }
                    </Select>
                  </FormControl>
                </Box>}
              </DialogContent>
            </Dialog>}
          </Grid>
        </Grid>
      </Grid>
    </Page>
  )
}
