import React, { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import { useAuthContext } from 'components/AuthContext'
import { useLayoutContext } from 'components/LayoutContext'
import { Marker } from 'components/Marker'
import { DataTable } from 'components/DataTable'
import { CommentForm } from 'components/CommentForm'
import { categorySummaryApi, SummaryCollection, SummaryEndpoints } from 'api/categorySummaryApi'

export function SummaryPage () {
  const [collection, setCollection] = useState<SummaryCollection>()
  const { startContentLoading, finishContentLoading } = useLayoutContext()
  const { handleResponseFailure, handleResponseSuccess } = useAuthContext()

  useEffect(() => {
    startContentLoading()

    categorySummaryApi.getSummaryCollection()
      .then(setCollection)
      .catch(handleResponseFailure)
      .finally(finishContentLoading)
  }, [finishContentLoading, startContentLoading, handleResponseFailure])

  if (!collection) {
    return null
  }
  const handleCommentChange = (comment: string) => {
    categorySummaryApi.updateSummaryComment(comment)
      .then(handleResponseSuccess)
      .catch(handleResponseFailure)
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={3} sm={6}>
        <Marker {...collection[SummaryEndpoints.MARKER_SALES]} />
      </Grid>

      <Grid item xs={12} md={3} sm={6}>
        <Marker {...collection[SummaryEndpoints.MARKER_PROFIT]} />
      </Grid>

      <Grid item xs={12} md={3} sm={6}>
        <Marker {...collection[SummaryEndpoints.MARKER_ASP]} />
      </Grid>

      <Grid item xs={12}>
        <DataTable
          title={collection[SummaryEndpoints.SALES_TABLE].title}
          description={collection[SummaryEndpoints.SALES_TABLE].description}
          data={collection[SummaryEndpoints.SALES_TABLE].grid}
        />
      </Grid>

      <Grid item xs={12}>
        <DataTable
          title={collection[SummaryEndpoints.GROWTH_TABLE].title}
          description={collection[SummaryEndpoints.GROWTH_TABLE].description}
          data={collection[SummaryEndpoints.GROWTH_TABLE].grid}
        />
      </Grid>

      <Grid item xs={12}>
        <DataTable
          title={collection[SummaryEndpoints.IN_KIND_TABLE].title}
          description={collection[SummaryEndpoints.IN_KIND_TABLE].description}
          data={collection[SummaryEndpoints.IN_KIND_TABLE].grid}
        />
      </Grid>

      <Grid item xs={12}>
        <DataTable
          title={collection[SummaryEndpoints.MONETARY_TABLE].title}
          description={collection[SummaryEndpoints.MONETARY_TABLE].description}
          data={collection[SummaryEndpoints.MONETARY_TABLE].grid}
        />
      </Grid>

      <Grid item xs={12}>
        <CommentForm
          label={collection[SummaryEndpoints.COMMENT].title}
          value={collection[SummaryEndpoints.COMMENT].comment}
          onChange={handleCommentChange}
        />
      </Grid>
    </Grid>
  )
}
