import React, { Fragment, useState } from 'react'
import {
  TableCell,
  TableSortLabel,
  IconButton,
  Box,
  Menu,
  MenuItem,
  Checkbox,
  ListItemText
} from '@material-ui/core'
import { FilterList } from '@material-ui/icons'
import { useDataTableContext, Column, Row } from 'components/DataTableContext'
import { DataTableHeadCellTitle } from 'components/DataTableHeadCellTitle'

interface DataTableHeadCellProps {
  column: Column
  width?: string
}

export function DataTableHeadCell ({ column, width }: DataTableHeadCellProps) {
  const {
    data,
    sortBy,
    sortDirection,
    onSortChange,
    hiddenRows,
    onRowVisibilityChange
  } = useDataTableContext()
  const handleSortChange = () => onSortChange(column)
  const [rowsManagerAnchorEl, setRowsManagerAnchorEl] = useState<HTMLButtonElement | null>(null)
  const openRowsManager = (e: React.MouseEvent<HTMLButtonElement>) => (
    setRowsManagerAnchorEl(e.currentTarget)
  )
  const closeRowsManager = () => setRowsManagerAnchorEl(null)
  const handleRowVisibilityChange = (row: Row) => () => {
    onRowVisibilityChange(row)
  }

  return (
    <TableCell width={width}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent={column.align}
        textAlign={column.align}
      >
        {column.isSortable
          ? (
            <TableSortLabel
              active={sortBy === column}
              direction={sortDirection}
              onClick={handleSortChange}
            >
              <DataTableHeadCellTitle title={column.title} subtitle={column.subtitle} />
            </TableSortLabel>
          )
          : (
            <DataTableHeadCellTitle title={column.title} subtitle={column.subtitle} />
          )}

        {column.hasRowsManager && (
          <Fragment>
            <IconButton edge="end" onClick={openRowsManager}>
              <FilterList />
            </IconButton>

            <Menu
              open={Boolean(rowsManagerAnchorEl)}
              anchorEl={rowsManagerAnchorEl}
              onClose={closeRowsManager}
            >
              {data.rows.map((row, idx) => (
                <MenuItem
                  key={idx}
                  dense
                  onClick={handleRowVisibilityChange(row)}
                >
                  <Checkbox
                    edge="start"
                    color="default"
                    checked={hiddenRows.indexOf(row) === -1}
                    disableRipple
                    size="small"
                  />

                  <ListItemText primary={row.cells[column.field].value} />
                </MenuItem>
              ))}
            </Menu>
          </Fragment>
        )}
      </Box>
    </TableCell>
  )
}
