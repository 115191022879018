import React from 'react'
import { IconButton, Tooltip } from '@material-ui/core'
import { Error } from '@material-ui/icons'
import { useAuthContext } from 'components/AuthContext'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { useSnackbar } from 'notistack'
import { usersApi } from '../api/usersApi'
import { useTranslationContext } from './TranslationContext'
import { observer } from 'mobx-react-lite'
import { IUserRichNotificationsStore, userRichNotificationsStore } from '../stores/userRichNotificationsStore'

const useStyles = makeStyles(() =>
  createStyles({
    iconFillEnabled: { fill: '#f44336' },
    iconFillDisabled: { fill: '#787878' }
  })
)

interface NotificationSwitcherObserverProps {
  userRichNotificationsStore: IUserRichNotificationsStore
}

const NotificationSwitcherObserver = observer(({ userRichNotificationsStore } : NotificationSwitcherObserverProps) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const { translation } = useTranslationContext()
  const {
    currentUser,
    handleResponseFailure
  } = useAuthContext()
  const isShowingNotifications = currentUser?.showRichNotifications
  const { setShowRichNotifications, showRichNotifications } = userRichNotificationsStore

  if (isShowingNotifications !== undefined && showRichNotifications === null) {
    setShowRichNotifications(isShowingNotifications)
    return null
  }

  const switchNotifications = () => {
    return usersApi.update({ id: currentUser?.id, showRichNotifications: !showRichNotifications })
      .then(() => {
        setShowRichNotifications(!showRichNotifications)
        enqueueSnackbar(!showRichNotifications ? translation['categories.sales.notifications.turnOn'] : translation['categories.sales.notifications.turnOff'], { variant: 'success' })
      })
      .catch(handleResponseFailure)
  }

  return (
    <Tooltip title={<h2 style={{ color: 'white' }}>{translation['categories.sales.notifications.tooltip']}</h2>}>
      <IconButton onClick={switchNotifications} color='primary' aria-label="switch notifications" component="div">
        <Error className={showRichNotifications ? classes.iconFillEnabled : classes.iconFillDisabled} />
      </IconButton>
    </Tooltip>
  )
})

export const NotificationSwitcher = () => <NotificationSwitcherObserver userRichNotificationsStore={userRichNotificationsStore} />
