import { Country } from 'api/types'

export function getLocalStatLogo (country: Country | undefined) {
  const logoForCountry = {
    defaults: {
      logo: '/defaults-stat-icon.png',
      translation: 'localStatName.defaults'
    },
    russia: {
      logo: '/rosstat-icon.jpeg',
      translation: 'localStatName.rosstat'
    },
    ukraine: {
      logo: '/ukrstat-icon.png',
      translation: 'localStatName.ukrstat'
    }
  }

  for (const [key, value] of Object.entries(logoForCountry)) {
    const { name_en: name } = country as any
    if (key === name.toLowerCase()) {
      return value
    }
  }
  return logoForCountry.defaults
}
