import { createStyles, withStyles } from '@material-ui/core/styles'
import { Typography, TypographyProps } from '@material-ui/core'
import React from 'react'

export const StyledTypography = withStyles(() => {
  return createStyles({
    root: {
      fontSize: 16
    }
  })
})((props: TypographyProps) => <Typography {...props} />)

export const StyledTypographyHeader = withStyles(() => {
  return createStyles({
    root: {
      fontSize: 20,
      fontWeight: 500
    }
  })
})((props: TypographyProps) => <Typography {...props} />)
