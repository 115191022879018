import React from 'react'
import { Grid } from '@material-ui/core'

export function CurrencyRatesPage () {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <img
          src='/currency-rates.png'
          style={{ width: '100%' }}
          alt='currency rates preview'
        />
      </Grid>
    </Grid>
  )
}
