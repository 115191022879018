import React, { useEffect, useState } from 'react'
import { Pagination as MuiPagination } from '@material-ui/lab'
import { makeStyles } from '@material-ui/styles'

const approximatePages = (count: number, perPage: number) =>
  Math.ceil(count / perPage)

const useStyles = makeStyles({
  root: {
    marginTop: '16px'
  }
})

interface PaginationProps {
  loader: (page: number, limit: number) => void
  deps: any[]
  total: number
  limit: number
}

export const Pagination = ({
  loader,
  deps,
  total,
  limit
}: PaginationProps) => {
  const classes = useStyles()
  const [page, setPage] = useState(1)

  const totalPages = approximatePages(total, limit)

  useEffect(() => {
    loader(page, limit)
  }, [...deps, page]) // eslint-disable-line react-hooks/exhaustive-deps

  if (totalPages < 2) return null

  return (
    <MuiPagination
      classes={classes}
      color='primary'
      page={page}
      onChange={(e, page: number) => setPage(page)}
      count={totalPages}
    />
  )
}
