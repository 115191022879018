import React, { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import { DataTable } from 'components/DataTable'
import { useAuthContext } from 'components/AuthContext'
import { useLayoutContext } from 'components/LayoutContext'
import {
  marketAndCompanyApi,
  AverageCollection,
  AverageEndpoints,
  AverageTab
} from 'api/marketAndCompanyApi'
import { AverageSellPriceChart } from 'components/AverageSellPriceChart'
import { Marker } from 'components/Marker'
import { CommentForm } from 'components/CommentForm'

export function AverageSellingPricePage () {
  const { startContentLoading, finishContentLoading } = useLayoutContext()
  const { handleResponseFailure, handleResponseSuccess } = useAuthContext()
  const [collection, setCollection] = useState<AverageCollection>()

  useEffect(() => {
    startContentLoading()

    marketAndCompanyApi.getAverageCollection()
      .then(setCollection)
      .catch(handleResponseFailure)
      .finally(finishContentLoading)
  }, [finishContentLoading, startContentLoading, handleResponseFailure])

  if (!collection) {
    return null
  }

  const updateCollection = () => {
    marketAndCompanyApi.getAverageCollection()
      .then((updatedCollection) => {
        setCollection(updatedCollection)
        handleResponseSuccess()
      })
      .catch(handleResponseFailure)
  }
  const handleTab1Update = (grid: AverageTab['grid']) => {
    marketAndCompanyApi.updateAverageTab1(grid)
      .then(updateCollection)
      .catch(handleResponseFailure)
  }
  const handleTab2Update = (grid: AverageTab['grid']) => {
    marketAndCompanyApi.updateAverageTab2(grid)
      .then(updateCollection)
      .catch(handleResponseFailure)
  }
  const handleCommentChange = (comment: string) => {
    marketAndCompanyApi.updateAverageComment(comment)
      .then(handleResponseSuccess)
      .catch(handleResponseFailure)
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6} xl={4}>
        <Marker {...collection[AverageEndpoints.MARKER_MARKET]} />
      </Grid>

      <Grid item xs={12} md={6} xl={4}>
        <Marker {...collection[AverageEndpoints.MARKER_COMPANY_PRICE_1]} />
      </Grid>

      <Grid item xs={12} md={6} xl={4}>
        <Marker {...collection[AverageEndpoints.MARKER_COMPANY_PRICE_2]} />
      </Grid>

      <Grid item xs={12} xl={6}>
        <AverageSellPriceChart {...collection[AverageEndpoints.GRAPH_COMPANY]} />
      </Grid>

      <Grid item xs={12}>
        <DataTable
          title={collection[AverageEndpoints.TAB1].title}
          description={collection[AverageEndpoints.TAB1].description}
          data={collection[AverageEndpoints.TAB1].grid}
          onDataUpdate={handleTab1Update}
        />
      </Grid>

      <Grid item xs={12} md={8}>
        <DataTable
          title={collection[AverageEndpoints.TAB2].title}
          description={collection[AverageEndpoints.TAB2].description}
          data={collection[AverageEndpoints.TAB2].grid}
          onDataUpdate={handleTab2Update}
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <CommentForm
          label={collection[AverageEndpoints.COMMENT].title}
          value={collection[AverageEndpoints.COMMENT].comment}
          onChange={handleCommentChange}
        />
      </Grid>
    </Grid>
  )
}
