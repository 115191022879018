import { Comment, Marker, Table } from './types'
import {
  ChartData,
  getCollection,
  ProductCategory
} from './categoriesApi'
import { getCategoryEndpoint } from './common'
import { request } from './request'

export enum GeoDistributionEndpoints {
  'SALES_GRAPH' = 'sales_graph',
  'PERCENTS_GRAPH' = 'percents_graph',
  'CURRENT_YEAR_TABLE' = 'current_year',
  'PERCENTS_TABLE' = 'percents',
  'VALUES_TABLE' = 'values',
  'COMMENT' = 'comment',
  'MARKERS' = 'markers'
}

export interface GeoDistributionCollection {
  [GeoDistributionEndpoints.SALES_GRAPH]: ChartData
  [GeoDistributionEndpoints.PERCENTS_GRAPH]: ChartData
  [GeoDistributionEndpoints.CURRENT_YEAR_TABLE]: Table
  [GeoDistributionEndpoints.PERCENTS_TABLE]: Table
  [GeoDistributionEndpoints.VALUES_TABLE]: Table
  [GeoDistributionEndpoints.COMMENT]: Comment
  [GeoDistributionEndpoints.MARKERS]: Marker[]
}

function getGeoDistributionProfitEndpoint (categoryId: ProductCategory['id'] | any) {
  return getCategoryEndpoint(categoryId, 'geo_distribution/monetary/')
}

function getGeoDistributionEndpoint (categoryId: ProductCategory['id'] | any) {
  return getCategoryEndpoint(categoryId, 'geo_distribution/natural/')
}

function getGeoDistributionCollection (categoryId: ProductCategory['id']) {
  const prefix = getGeoDistributionEndpoint(categoryId)
  const endpoints = Object.values(GeoDistributionEndpoints)
  return getCollection<GeoDistributionCollection>(prefix, endpoints)
}

function getGeoDistributionProfitCollection (categoryId: ProductCategory['id']) {
  const prefix = getGeoDistributionProfitEndpoint(categoryId)
  const endpoints = Object.values(GeoDistributionEndpoints)
  return getCollection<GeoDistributionCollection>(prefix, endpoints)
}

function updateGeoDistributionTable (entityName: string, monetary: boolean, categoryId: ProductCategory['id'], grid: Table['grid']) {
  const getEndpoint = monetary ? getGeoDistributionProfitEndpoint : getGeoDistributionEndpoint
  return request(`${getEndpoint(categoryId)}${entityName}`, 'PUT', { grid })
}

function updateComment (comment: string, monetary: boolean, categoryId: ProductCategory['id']) {
  const getEndpoint = monetary ? getGeoDistributionProfitEndpoint : getGeoDistributionEndpoint
  return request(`comment/${getEndpoint(categoryId)}comment`, 'PUT', { comment })
}

export const geoDistributionApi = {
  getGeoDistributionProfitEndpoint,
  getGeoDistributionEndpoint,
  getGeoDistributionCollection,
  getGeoDistributionProfitCollection,
  updateGeoDistributionTable,
  updateComment
}
