import { request } from 'api/request'
import { Field } from 'helpers/fields'
import { Region } from './types'

export interface CommonInfo {
  fields: {
    name: Field<string>
    shortName: Field<string>
    market: Field<string>
    subscriptionExpires: Field<string>
    licenseUsersTotal: Field<string>
    countries: Field<string>
    divisionBySubject: Field<string>
    personalClassification: Field<string>
  }
}

export interface CommonInfoValues {
  name: string
  shortName: Field<string>
  market: Field<string>
  subscriptionExpires: Field<string>
  licenseUsersTotal: Field<string>
  countries: number[]
  divisionBySubject: boolean
  personalClassification: boolean
  customRegions: Region[]
}

export function getCommonInfo () {
  return request('common_info')
}

export function updateCommonInfo (values: Partial<CommonInfoValues>) {
  return request('common_info', 'PUT', values)
}

export function updateSalesGeographyAnswers (values: Partial<CommonInfoValues>) {
  return request('sales_geography_answers', 'PUT', values)
}

export function getSalesGeographyAnswers () {
  return request('sales_geography_answers')
}

export const commonInfoApi = {
  getCommonInfo,
  updateCommonInfo,
  getSalesGeographyAnswers,
  updateSalesGeographyAnswers
}
