import React from 'react'
import { Page } from 'components/Page'
import { QuartersPage } from './QuartersPage'
import { MonthsPage } from './MonthsPage'
import { PartnersPage } from './PartnersPage'
import { useTranslationContext } from 'components/TranslationContext'

export function SalesDirectionPage () {
  const { translation } = useTranslationContext()
  const tabs = [
    {
      label: translation['pages.SalesDirectionPage.tabs.quarters'],
      url: 'quarters',
      component: QuartersPage,
      hasInfoOnly: true
    },
    {
      label: translation['pages.SalesDirectionPage.tabs.months'],
      url: 'months',
      component: MonthsPage,
      hasInfoOnly: true
    },
    {
      label: translation['pages.SalesDirectionPage.tabs.partners'],
      url: 'partners',
      component: PartnersPage,
      hasInfoOnly: true
    }
  ]
  return (
    <Page title={translation['pages.SalesDirectionPage.title']} tabs={tabs} />
  )
}
