interface Config extends Intl.NumberFormatOptions {
  locale?: string
}

export function formatNumber (value?: string | number, config?: Config) {
  const {
    maximumFractionDigits = 20,
    locale = 'ru',
    ...options
  } = config ?? {}

  return Number(value).toLocaleString(locale, { maximumFractionDigits, ...options })
}
