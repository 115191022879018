import React from 'react'
import { Page } from 'components/Page'
import { IndicatorsPage } from './IndicatorsPage'
import { PopulationPage } from './PopulationPage'
import { OutletsCountPage } from './OutletsCountPage'
import { AdvertisingPage } from './AdvertisingPage'
import { useTranslationContext } from '../../components/TranslationContext'

export function StatisticalDataManagementPage () {
  const { translation } = useTranslationContext()
  const tabs = [
    {
      label: translation['components.IndicatorsPage.title'],
      url: 'indicators',
      component: IndicatorsPage
    },
    {
      label: translation['components.PopulationPage.title'],
      url: 'population',
      component: PopulationPage
    },
    {
      label: translation['components.OutletsCountPage.title'],
      url: 'outlets-count',
      component: OutletsCountPage
    },
    {
      label: translation['components.AdvertisingPage.title'],
      url: 'advertising-efficiency',
      component: AdvertisingPage
    }
  ]

  return (
    <Page title={translation['components.StatisticalDataManagementPage.title']} tabs={tabs} />
  )
}
