import React from 'react'
import { Page } from 'components/Page'
import { StandardsPage } from './StandardsPage'
import { TargetsPage } from './TargetsPage'
import { useTranslationContext } from 'components/TranslationContext'

export function SkuPage () {
  const { translation } = useTranslationContext()
  const tabs = [
    {
      label: translation['pages.SkuPage.tabs.standards'],
      url: 'standards',
      component: StandardsPage
    },
    {
      label: translation['pages.SkuPage.tabs.targets'],
      url: 'targets',
      component: TargetsPage
    }
  ]

  return (
    <Page title={translation['menu.sku']} tabs={tabs} />
  )
}
