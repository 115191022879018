import React, { Fragment } from 'react'
import { ArrayHelpers, FieldArray, Form, Formik } from 'formik'
import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core'
import { Add, Delete } from '@material-ui/icons'
import { useTranslationContext } from 'components/TranslationContext'
import { LoadingButton } from 'components/LoadingButton'
import { TableCellField } from 'components/TableCellField'
import { Regions } from '../api/types'
import { required } from '../helpers/validators'

interface CustomRegionsTableProps {
  regions: Regions | null
  withoutInputs?: boolean
  handleSubmit: (regions: Regions) => void
}

export function CustomRegionsTable ({ regions, withoutInputs = false, handleSubmit } : CustomRegionsTableProps) {
  const { translation } = useTranslationContext()

  if (!regions) {
    return null
  }

  const addRegion = (arrayHelpers: ArrayHelpers) => () => {
    arrayHelpers.push('')
  }
  const deleteRegion = (arrayHelpers: ArrayHelpers, idx: number) => () => {
    arrayHelpers.remove(idx)
  }

  const requiredField = required(translation['validation.required_field'])

  return (
    <Paper>
      {!withoutInputs && <Formik
        initialValues={regions}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({ values, isSubmitting }) => (
          <Form>
            <FieldArray
              name="items"
              render={(arrayHelpers) => (
                <>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell />

                        <TableCell width=">100%">{translation['components.CustomRegionsTable.region_name']}</TableCell>

                        <TableCell />
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {values.items.map((name, idx) => (
                        <TableRow key={idx}>
                          <TableCell>{idx + 1}</TableCell>

                          <TableCellField
                            validate={requiredField}
                            error={Boolean(!values.items[idx].name)}
                            autoFocus
                            autoComplete={'off'}
                            name={`items[${idx}].name`}
                          />

                          <TableCell padding="none">
                            <IconButton
                              size="small"
                              onClick={deleteRegion(arrayHelpers, idx)}
                              disabled={isSubmitting}
                            >
                              <Delete/>
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>

                  <Box p={1} textAlign="right">
                    <Grid container spacing={1} justify="flex-end">
                      <Grid item>
                        <Button
                          color="primary"
                          startIcon={<Add/>}
                          onClick={addRegion(arrayHelpers)}
                          disabled={isSubmitting}
                        >
                          {translation['form.add']}
                        </Button>
                      </Grid>

                      <Grid item>
                        <LoadingButton
                          type="submit"
                          variant="contained"
                          color="primary"
                          disableElevation
                          disabled={isSubmitting}
                          isLoading={isSubmitting}
                        >
                          {translation['form.save']}
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  </Box>
                </>
              )}
            />
          </Form>
        )}
      </Formik>}

      { withoutInputs && <Fragment>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />

              <TableCell width=">100%">{translation['components.CustomRegionsTable.region_name']}</TableCell>

              <TableCell />
            </TableRow>
          </TableHead>

          <TableBody>
            {regions.items.map(({ name }, idx) => (
              <TableRow key={idx}>
                <TableCell>{idx + 1}</TableCell>

                <TableCell>{name}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Fragment> }
    </Paper>
  )
}
