import React from 'react'
import { Field, FieldConfig } from 'formik'
import { TextField as FMuiTextField } from 'formik-material-ui'
import { Box, Tooltip, StandardTextFieldProps } from '@material-ui/core'
import { HelpOutline } from '@material-ui/icons'
interface TextFieldProps extends StandardTextFieldProps {
  hint?: string
  max?: number
  validate?: FieldConfig['validate']
}

export function TextField ({ hint, ...props }: React.PropsWithChildren<TextFieldProps>) {
  return (
    <Box display="inline-flex" width="100%" alignItems="center">
      <Field
        component={FMuiTextField}
        variant="outlined"
        fullWidth
        margin="normal"
        {...props}
      />

      {hint && (
        <Box pt={1} pl={2}>
          <Tooltip
            placement="right-start"
            title={<h2 style={{ color: 'white' }}>{hint}</h2>}
          >
            <HelpOutline color="action" />
          </Tooltip>
        </Box>
      )}
    </Box>
  )
}
