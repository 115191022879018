import React, { useCallback, useEffect, useState } from 'react'
import { Card, CardContent, Grid, Typography } from '@material-ui/core'
import { useLayoutContext } from 'components/LayoutContext'
import { useAuthContext } from 'components/AuthContext'
import { useCategoriesContext } from 'components/CategoriesContext'
import { DataTable } from 'components/DataTable'
import { Marker } from 'components/Marker'
import { SalesAndRevenueChart } from 'components/SalesAndRevenueChart'
import { CommentForm } from 'components/CommentForm'
import { categoriesApi, SalesCollection, SalesEndpoints } from 'api/categoriesApi'
import { Table } from 'api/types'
import {
  ArgumentAxis,
  Chart,
  ChartVariants,
  EventTracker,
  getColorByIdx,
  LineSeries,
  normalizeChartData,
  Tooltip,
  ValueAxis
} from 'components/Chart'
import calculateColumnsWidth from '../../utils/calculateColumnsWidth'

export function SalesAndRevenuePage () {
  const [collection, setCollection] = useState<SalesCollection>()
  const { currentCategory } = useCategoriesContext()
  const { startContentLoading, finishContentLoading } = useLayoutContext()
  const { handleResponseFailure, handleResponseSuccess } = useAuthContext()
  const getCollection = useCallback((endpoints?: string[]) => {
    if (currentCategory) {
      startContentLoading()

      categoriesApi.getSalesCollection(currentCategory.id, endpoints)
        .then((collection) => setCollection((prevCollection) => prevCollection
          ? { ...prevCollection, ...collection }
          : collection
        ))
        .catch(handleResponseFailure)
        .finally(finishContentLoading)
    }
  }, [currentCategory, startContentLoading, finishContentLoading, handleResponseFailure])

  useEffect(() => {
    getCollection()
  }, [getCollection])

  if (!currentCategory || !collection) {
    return null
  }
  const handleTableDependenciesUpdate = (endpoint: SalesEndpoints) => (table: Table) => {
    setCollection({ ...collection, [endpoint]: table })
    handleResponseSuccess()
    return getCollection(table.dependencies)
  }
  const handleMarketTableUpdate = (grid: Table['grid']) => {
    categoriesApi.updateMarketTable(currentCategory.id, grid)
      .then(handleTableDependenciesUpdate(SalesEndpoints.MARKET_TABLE))
      .catch(handleResponseFailure)
  }
  const handleSalesTableUpdate = (grid: Table['grid']) => {
    categoriesApi.updateSalesTable(currentCategory.id, grid)
      .then(handleTableDependenciesUpdate(SalesEndpoints.SALES_TABLE))
      .catch(handleResponseFailure)
  }
  const handleKpiTableUpdate = (grid: Table['grid']) => {
    categoriesApi.updateSalesKpiTable(currentCategory.id, grid)
      .then(handleTableDependenciesUpdate(SalesEndpoints.KPI_TABLE))
      .catch(handleResponseFailure)
  }
  const handleGrowthTableUpdate = (grid: Table['grid']) => {
    categoriesApi.updateGrowthTable(currentCategory.id, grid)
      .then(handleTableDependenciesUpdate(SalesEndpoints.GROWTH_TABLE))
      .catch(handleResponseFailure)
  }
  const handleCommentChange = (comment: string) => {
    categoriesApi.updateSalesComment(currentCategory.id, comment)
      .then(handleResponseSuccess)
      .catch(handleResponseFailure)
  }

  const { columnsWidth } = calculateColumnsWidth(collection[SalesEndpoints.MARKET_TABLE].grid.columns.length)

  const avgChartData = collection[SalesEndpoints.CHART_AVG].chart.map(({
    isForecast,
    argument,
    company,
    market
  }) => {
    const result = {
      isForecast,
      argument,
      marketForecast: market,
      companyForecast: company
    }

    return isForecast ? result : Object.assign(result, { company, market })
  })

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={3} sm={6}>
        <Marker {...collection[SalesEndpoints.MARKER_SALES]} />
      </Grid>

      <Grid item xs={12} md={3} sm={6}>
        <Marker {...collection[SalesEndpoints.MARKER_PROFIT]} />
      </Grid>

      <Grid item xs={12} md={3} sm={6}>
        <Marker {...collection[SalesEndpoints.MARKER_AVERAGE]} />
      </Grid>

      <Grid item xs={12} md={3} sm={6}>
        <Marker {...collection[SalesEndpoints.MARKER_SKU]} />
      </Grid>

      <Grid item xs={12} md={6}>
        <SalesAndRevenueChart {...collection[SalesEndpoints.CHART]} />
      </Grid>

      <Grid item xs={12} md={6}>
        <Card>
          <CardContent>
            <Typography variant="h6" paragraph>
              {collection[SalesEndpoints.CHART_AVG].title}
            </Typography>
          </CardContent>

          <Chart
            height={320}
            leftAxisUnit={collection[SalesEndpoints.CHART_AVG].units.leftAxis}
            data={normalizeChartData(avgChartData)}
            legend={[
              {
                label: collection[SalesEndpoints.CHART_AVG].legend.market,
                color: getColorByIdx(0, undefined, ChartVariants.MarketVolumeLegend)
              },
              {
                label: collection[SalesEndpoints.CHART_AVG].legend.company,
                color: getColorByIdx(1, undefined, ChartVariants.MarketVolumeLegend)
              }
            ]}
          >
            <ArgumentAxis />
            <ValueAxis />
            <LineSeries
              name="marketForecast"
              argumentField="argument"
              valueField="marketForecast"
              color={getColorByIdx(0, undefined, ChartVariants.MarketVolumeForecast)}
            />
            <LineSeries
              name="market"
              argumentField="argument"
              valueField="market"
              color={getColorByIdx(0, undefined, ChartVariants.MarketVolumeFact)}
            />
            <LineSeries
              name="companyForecast"
              argumentField="argument"
              valueField="companyForecast"
              color={getColorByIdx(1, undefined, ChartVariants.MarketVolumeForecast)}
            />
            <LineSeries
              name="company"
              argumentField="argument"
              valueField="company"
              color={getColorByIdx(1, undefined, ChartVariants.MarketVolumeFact)}
            />
            <EventTracker />
            <Tooltip />
          </Chart>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <DataTable
          title={collection[SalesEndpoints.MARKET_TABLE].title}
          description={collection[SalesEndpoints.MARKET_TABLE].description}
          data={collection[SalesEndpoints.MARKET_TABLE].grid}
          onDataUpdate={handleMarketTableUpdate}
          columnsWidth={columnsWidth}
        />
      </Grid>

      <Grid item xs={12}>
        <DataTable
          title={collection[SalesEndpoints.SALES_TABLE].title}
          description={collection[SalesEndpoints.SALES_TABLE].description}
          data={collection[SalesEndpoints.SALES_TABLE].grid}
          onDataUpdate={handleSalesTableUpdate}
          columnsWidth={columnsWidth}
        />
      </Grid>

      <Grid item xs={12}>
        <DataTable
          title={collection[SalesEndpoints.KPI_TABLE].title}
          description={collection[SalesEndpoints.KPI_TABLE].description}
          data={collection[SalesEndpoints.KPI_TABLE].grid}
          onDataUpdate={handleKpiTableUpdate}
        />
      </Grid>

      <Grid item xs={12}>
        <DataTable
          title={collection[SalesEndpoints.GROWTH_TABLE].title}
          description={collection[SalesEndpoints.GROWTH_TABLE].description}
          data={collection[SalesEndpoints.GROWTH_TABLE].grid}
          onDataUpdate={handleGrowthTableUpdate}
          columnsWidth={columnsWidth}
        />
      </Grid>

      <Grid item xs={12}>
        <CommentForm
          label={collection[SalesEndpoints.COMMENT].title}
          value={collection[SalesEndpoints.COMMENT].comment}
          onChange={handleCommentChange}
        />
      </Grid>
    </Grid>
  )
}
