import React, { useEffect, useState } from 'react'
import { FormControl, Grid, MenuItem, SelectProps, Typography, Select } from '@material-ui/core'
import { useAuthContext } from 'components/AuthContext'
import { useLayoutContext } from 'components/LayoutContext'
import { Marker } from 'components/Marker'
import { DataTable } from 'components/DataTable'
import { CommentForm } from 'components/CommentForm'
import { AdvBudgetChart } from 'components/AdvBudgetChart'
import {
  categorySummaryApi,
  MarketingBudgetCollection,
  MarketingBudgetEndpoints
} from 'api/categorySummaryApi'
import calculateColumnsWidth from '../../utils/calculateColumnsWidth'
import { useQueryParams } from '../../hooks/queryParams'
import { TargetsByMarketingBudgetQueryParams } from '../../types/tabsCommon'
import { useCategoriesContext } from '../../components/CategoriesContext'
import { useTranslationContext } from '../../components/TranslationContext'

export function TargetsByMarketingBudgetPage () {
  const [collection, setCollection] = useState<MarketingBudgetCollection>()
  const { startContentLoading, finishContentLoading } = useLayoutContext()
  const { handleResponseFailure, handleResponseSuccess } = useAuthContext()
  const { queryParams, setQueryParams } = useQueryParams()
  const [category, setCategory] = useState(queryParams.get('categoryId') || 'all')
  const { categories } = useCategoriesContext()
  const { translation } = useTranslationContext()

  useEffect(() => {
    startContentLoading()

    categorySummaryApi.getMarketingBudgetCollection(category)
      .then(collection => {
        setCollection(collection)
      })
      .catch(handleResponseFailure)
      .finally(finishContentLoading)
  }, [finishContentLoading, startContentLoading, handleResponseFailure, category])

  if (!collection) {
    return null
  }

  const handleFilterChange: SelectProps['onChange'] = (e) => {
    const { value } = e.target
    setCategory(value as string)
    setQueryParams<TargetsByMarketingBudgetQueryParams>({ categoryId: value })
  }

  const handleCommentChange = (comment: string) => {
    categorySummaryApi.updateMarketingComment(comment)
      .then(handleResponseSuccess)
      .catch(handleResponseFailure)
  }

  const { columnsWidth } = calculateColumnsWidth(collection[MarketingBudgetEndpoints.ABSOLUTE_TABLE].grid.columns.length)
  const childIndicatorStyle = {
    indicator: {
      justifyContent: 'right',
      textAlign: 'right',
      fontStyle: 'italic',
      fontSize: '0.9em'
    }
  } as const

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
        <Grid md={4}>
          <FormControl variant="outlined" fullWidth>
            <Select
              value={category}
              onChange={handleFilterChange}
              MenuProps={{ MenuListProps: { disablePadding: true } }}
            >
              <MenuItem key="all" value="all">
                {translation['pages.CategoriesContextPage.filter.all']}
              </MenuItem>
              {categories.map(({ id, categoryName }) => (
                <MenuItem key={id} value={id}>
                  <Typography variant="inherit" noWrap>
                    {categoryName}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Grid item xs={12} md={3} sm={6}>
        <Marker {...collection[MarketingBudgetEndpoints.MARKER_ADVERTISING]} />
      </Grid>

      <Grid item xs={12} md={3} sm={6}>
        <Marker {...collection[MarketingBudgetEndpoints.MARKER_MARGIN]} />
      </Grid>

      <Grid item xs={12}>
        <AdvBudgetChart
          absolute={collection[MarketingBudgetEndpoints.ABSOLUTE_CHART]}
          relative={collection[MarketingBudgetEndpoints.RELATIVE_CHART]}
        />
      </Grid>

      <Grid item xs={12}>
        <DataTable
          title={collection[MarketingBudgetEndpoints.ABSOLUTE_TABLE].title}
          description={collection[MarketingBudgetEndpoints.ABSOLUTE_TABLE].description}
          data={collection[MarketingBudgetEndpoints.ABSOLUTE_TABLE].grid}
          columnsWidth={columnsWidth}
          cellStyle={{
            type_01_online: childIndicatorStyle,
            type_01_offline: childIndicatorStyle
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <DataTable
          title={collection[MarketingBudgetEndpoints.RELATIVE_TABLE].title}
          description={collection[MarketingBudgetEndpoints.RELATIVE_TABLE].description}
          data={collection[MarketingBudgetEndpoints.RELATIVE_TABLE].grid}
          columnsWidth={columnsWidth}
          cellStyle={{
            type_01_online: childIndicatorStyle,
            type_01_offline: childIndicatorStyle
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <CommentForm
          label={collection[MarketingBudgetEndpoints.COMMENT].title}
          value={collection[MarketingBudgetEndpoints.COMMENT].comment}
          onChange={handleCommentChange}
        />
      </Grid>
    </Grid>
  )
}
