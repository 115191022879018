import React from 'react'
import { UnitsPage } from './UnitsPage'
import { PriceCoefficientsPage } from './PriceCoefficientsPage'
import { ProductCategoriesPage } from './ProductCategoriesPage'
import { KeyPlayersPage } from './KeyPlayersPage'
import { CurrencyRatesPage } from './CurrencyRatesPage'
import { SubstitutesPage } from './SubstitutesPage'
import { RawMaterialsPage } from './RawMaterialsPage'
import { Page } from 'components/Page'
import { useTranslationContext } from 'components/TranslationContext'

export function MarketCharacteristicsPage () {
  const { translation } = useTranslationContext()

  const tabs = [
    {
      label: translation['menu.units_market_units'],
      url: 'units',
      component: UnitsPage
    },
    {
      label: translation['menu.units_market_price_coefficients'],
      url: 'price-coefficients',
      component: PriceCoefficientsPage
    },
    {
      label: translation['menu.units_market_categories'],
      url: 'product-categories',
      component: ProductCategoriesPage
    },
    {
      label: translation['menu.units_market_curreny'],
      url: 'currency-rates',
      component: CurrencyRatesPage,
      hasInfoOnly: true
    },
    {
      label: translation['menu.units_market_key_players'],
      url: 'key-players',
      component: KeyPlayersPage
    },
    {
      label: translation['menu.units_market_substitutes'],
      url: 'substitutes',
      component: SubstitutesPage
    },
    {
      label: translation['menu.units_market_raw_materials'],
      url: 'raw-materials',
      component: RawMaterialsPage
    }
  ]

  return (
    <Page title={translation['menu.units_market']} tabs={tabs} />
  )
}
