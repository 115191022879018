import React from 'react'
import { createMuiTheme } from '@material-ui/core/styles'
import green from '@material-ui/core/colors/green'

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    backgrounds: {
      corporateColor: React.CSSProperties['color'],
      contentRoot: React.CSSProperties['color']
      drawer: React.CSSProperties['color']
      snackbar: React.CSSProperties['color']
    },
    marker: {
      high: React.CSSProperties['color'],
      middle: React.CSSProperties['color'],
      low: React.CSSProperties['color'],
    }
  }
  interface PaletteOptions {
    backgrounds: {
      corporateColor: React.CSSProperties['color'],
      contentRoot: React.CSSProperties['color']
      drawer: React.CSSProperties['color']
      snackbar: React.CSSProperties['color']
    },
    marker: {
      high: React.CSSProperties['color'],
      middle: React.CSSProperties['color'],
      low: React.CSSProperties['color'],
    }
  }
}

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#0000ff'
    },
    backgrounds: {
      corporateColor: '#0000ff',
      contentRoot: '#f2f2f2',
      drawer: '#787878',
      snackbar: '#ff7300!important'
    },
    marker: {
      high: green.A700,
      middle: '#FFCC00',
      low: '#Ff7300'
    }
  },
  overrides: {
    MuiButton: {
      root: {
        '@media print': {
          display: 'none'
        }
      }
    },
    MuiGrid: {
      container: {
        '@media print': {
          display: 'block'
        }
      },
      item: {
        '@media print': {
          display: 'inline-block',
          verticalAlign: 'top',
          width: '100%',
          breakInside: 'avoid'
        }
      }
    }
  }
})
