import React from 'react'
import { Page } from 'components/Page'
import { SummaryPage } from './SummaryPage'
import { TargetsByCategoryPage } from './TargetsByCategoryPage'
import { TargetsByMarketingBudgetPage } from './TargetsByMarketingBudgetPage'
import { useTranslationContext } from 'components/TranslationContext'
import { CategoriesContextPage } from './CategoriesContextPage'
import { useCategoriesContext } from '../../components/CategoriesContext'
import { InfoPage } from '../InfoPage'

export function CategorySummaryPage () {
  const { translation } = useTranslationContext()
  const { setCurrentCategory } = useCategoriesContext()

  setCurrentCategory(undefined)

  const tabs = [
    {
      label: translation['menu.categories_summary'],
      url: 'summary',
      component: SummaryPage
    },
    {
      label: translation['menu.categories_summary_targets'],
      url: 'targets-by-category',
      component: TargetsByCategoryPage
    },
    {
      label: translation['menu.categories_summary_targets_geo'],
      url: 'targets-by-geo',
      component: InfoPage,
      hasInfoOnly: true
    },
    {
      label: translation['menu.categories_summary_targets_brands'],
      url: 'targets-by-brand',
      component: InfoPage,
      hasInfoOnly: true
    },
    {
      label: translation['menu.growth_categories'],
      url: 'categories-context',
      component: CategoriesContextPage
    },
    {
      label: translation['menu.categories_summary_targets_market_budget'],
      url: 'targets-by-marketing-budget',
      component: TargetsByMarketingBudgetPage
    }
  ]

  return (
    <Page title={translation['menu.categories_summary']} tabs={tabs} />
  )
}
