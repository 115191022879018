import { request } from 'api/request'
import { User } from 'api/usersApi'

export interface Profile {
  name: string
  position: string
  email: string
}

function get () {
  return request('users/current')
}

function update (user: User) {
  return request(`users/${user.id}`, 'PATCH', user)
}

function changePassword (userId: User['id'], newPassword: string) {
  return request(`users/change_password/${userId}`, 'PATCH', { password: newPassword })
}

function changeSelectedCountry (userId: User['id'], selectedCountry: number) {
  return request(`users/update_selected_country/${userId}`, 'PATCH', { selectedCountry })
}

export const profileApi = {
  get,
  update,
  changePassword,
  changeSelectedCountry
}
