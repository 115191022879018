import React from 'react'
import { Grid } from '@material-ui/core'

export function ContractProductionPage () {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <img
          src='/contract-production-1.jpg'
          style={{ width: '100%' }}
          alt='contract production preview'
        />
      </Grid>

      <Grid item xs={12}>
        <img
          src='/contract-production-2.jpg'
          style={{ width: '100%' }}
          alt='contract production preview'
        />
      </Grid>
    </Grid>
  )
}
