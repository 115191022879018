import React from 'react'
import { Grid } from '@material-ui/core'
import { Page } from 'components/Page'
import { useTranslationContext } from 'components/TranslationContext'

export function MonitoringPeriodsPage () {
  const { translation } = useTranslationContext()
  return (
    <Page title={translation['menu.units_control_periods']}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <img
            src='/inspection-frequency.png'
            style={{ width: '100%' }}
            alt='Monitoring periods'
          />
        </Grid>
      </Grid>
    </Page>
  )
}
