import React, { useEffect, useState } from 'react'
import { ArrayHelpers, FieldArray, Form, Formik } from 'formik'
import {
  Box,
  Button,
  Grid,
  IconButton, Menu, MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow, Tooltip
} from '@material-ui/core'
import {
  Add,
  Delete,
  HelpOutline
} from '@material-ui/icons'
import { useAuthContext } from 'components/AuthContext'
import { useTranslationContext } from 'components/TranslationContext'
import { useLayoutContext } from 'components/LayoutContext'
import { LoadingButton } from 'components/LoadingButton'
import { TableCellField } from 'components/TableCellField'
import { marketCharacteristicsApi, ProductCategories } from 'api/marketCharacteristicsApi'
import { required } from 'helpers/validators'
import { ProductCategory } from 'api/categoriesApi'
import { TableCellSelect } from '../../components/TableCellSelect'
import { staticalManagementApi } from '../../api/staticalManagementApi'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'

export function ProductCategoriesPage () {
  const { handleResponseSuccess, handleResponseFailure } = useAuthContext()
  const { startContentLoading, finishContentLoading } = useLayoutContext()
  const { translation } = useTranslationContext()
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
  const [someAnchorEl, setSomeAnchorEl] = React.useState<HTMLElement | null>(null)
  const [productCategories, setProductCategories] = useState<ProductCategories>()
  const [indicators, setIndicators] = useState()
  const [branches, setBranches] = useState()
  const addProductCategory = (arrayHelpers: ArrayHelpers) => () => {
    arrayHelpers.push({ categoryName: '', indicator: null, branch: null })
  }
  const deleteProductCategory = (arrayHelpers: ArrayHelpers, idx: number) => () => {
    arrayHelpers.remove(idx)
  }
  const handleSubmit = (values: ProductCategories) => {
    return marketCharacteristicsApi.updateProductCategories(values)
      .then(() => {
        handleResponseSuccess()
      })
      .catch(handleResponseFailure)
  }

  const checkValues = (values: ProductCategory[]) => {
    if (!validate(values)) {
      handleResponseFailure(translation['pages.ProductCategoriesPage.error.empty_fields'])
    }
  }

  const validate = (values: ProductCategory[]) =>
    values.findIndex(item => !item.categoryName || !item.categoryName.trim()) === -1

  useEffect(() => {
    startContentLoading()

    marketCharacteristicsApi.getProductCategories()
      .then(setProductCategories)
      .catch(handleResponseFailure)
      .finally(finishContentLoading)
  }, [finishContentLoading, startContentLoading, handleResponseFailure])

  useEffect(() => {
    if (!indicators) {
      staticalManagementApi.getAllIndicators()
        .then((indicators) => {
          setIndicators(indicators)
        })
    }
  }, [indicators])

  useEffect(() => {
    if (!branches) {
      staticalManagementApi.getBranches()
        .then((branches: any) => {
          setBranches(branches)
        })
    }
  }, [branches])

  if (!productCategories || !indicators) {
    return null
  }

  // @ts-ignore
  const indicatorsSelect = indicators ? indicators.map(el => ({ value: el.id, name: el.name })) : []

  // @ts-ignore
  const branchesSelect = branches ? branches.map(el => ({ value: el.id, name: el.name })) : []

  const requiredField = required(translation['validation.required_field'])

  const handleButtonClick = (type: 'branch' | 'indicator') => (event: React.MouseEvent<HTMLButtonElement>) => {
    if (type === 'indicator') {
      setAnchorEl(event.currentTarget)
    } else {
      setSomeAnchorEl(event.currentTarget)
    }
  }

  const handleItemClick = (value: number, values: typeof productCategories, type: 'branch' | 'indicator') => () => {
    for (let i = 0; i < values.productCategoryArr.length; i++) {
      values.productCategoryArr[i][type] = value
    }
    handleClose()
  }

  const handleClose = () => {
    setAnchorEl(null)
    setSomeAnchorEl(null)
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper>
          <Formik
            initialValues={productCategories}
            onSubmit={handleSubmit}
          >
            {({ values, isSubmitting }) => (
              <Form style={{ overflow: 'auto' }}>
                <FieldArray
                  name="productCategoryArr"
                  render={(arrayHelpers) => (
                    <>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell></TableCell>

                            <TableCell width="33%">
                              {translation['pages.ProductCategoriesPage.grid.col.product_categories.title']}
                            </TableCell>
                            <TableCell width="34%">
                              <Button style={{ textTransform: 'none' }} color="default" onClick={handleButtonClick('indicator')}>
                                {translation['pages.ProductCategoriesPage.grid.col.indicator.title']}
                                <ArrowDropDownIcon />
                                <Box pt={1} pl={2}>
                                  <Tooltip
                                    placement="right-start"
                                    title={<h2 style={{ color: 'white' }}>{translation['pages.ProductCategoriesPage.grid.col.indicator.description']}</h2>}
                                  >
                                    <HelpOutline color="action" />
                                  </Tooltip>
                                </Box>
                              </Button>
                              <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                              >
                                <MenuItem value="" disabled>
                                  {translation['pages.ProductCategoriesPage.grid.col.indicator.choose']}
                                </MenuItem>
                                {
                                  indicatorsSelect.map(({ name, value }: { name: string, value: number }, idx: number) => <MenuItem onClick={handleItemClick(value, values, 'indicator')} key={idx} value={value}>{name}</MenuItem>)
                                }
                              </Menu>
                            </TableCell>
                            {/* TODO: need to add a translation */}
                            <TableCell width="33%">
                              <Button style={{ textTransform: 'none' }} color="default" onClick={handleButtonClick('branch')}>
                                {translation['pages.ProductCategoriesPage.grid.col.branch.title']}
                                <ArrowDropDownIcon />
                                <Box pt={1} pl={2}>
                                  <Tooltip
                                    placement="right-start"
                                    title={<h2 style={{ color: 'white' }}>{translation['pages.ProductCategoriesPage.grid.col.branch.description']}</h2>}
                                  >
                                    <HelpOutline color="action" />
                                  </Tooltip>
                                </Box>
                              </Button>
                              <Menu
                                anchorEl={someAnchorEl}
                                open={Boolean(someAnchorEl)}
                                onClose={handleClose}
                              >
                                <MenuItem value="" disabled>
                                  {translation['pages.ProductCategoriesPage.grid.col.branch.choose']}
                                </MenuItem>
                                {
                                  branchesSelect.map(({ name, value }: { name: string, value: number }, idx: number) => <MenuItem onClick={handleItemClick(value, values, 'branch')} key={idx} value={value}>{name}</MenuItem>)
                                }
                              </Menu>
                            </TableCell>

                            <TableCell></TableCell>
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {values.productCategoryArr.map((category, idx) => (
                            <TableRow key={idx}>
                              <TableCell>{idx + 1}</TableCell>

                              <TableCellField
                                autoFocus
                                name={`productCategoryArr[${idx}].categoryName`}
                                validate={requiredField}
                                error={Boolean(!values.productCategoryArr[idx].categoryName)}
                              />

                              <TableCellSelect value={values.productCategoryArr[idx].indicator || ''} name={`productCategoryArr[${idx}].indicator`} items={indicatorsSelect} />
                              <TableCellSelect value={values.productCategoryArr[idx].branch || ''} name={`productCategoryArr[${idx}].branch`} items={branchesSelect} />

                              <TableCell padding="none">
                                <IconButton
                                  size="small"
                                  onClick={deleteProductCategory(arrayHelpers, idx)}
                                  disabled={isSubmitting}
                                >
                                  <Delete />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>

                      <Box p={1} textAlign="right">
                        <Grid container spacing={1} justify="flex-end">
                          <Grid item>
                            <Button
                              color="primary"
                              startIcon={<Add />}
                              onClick={addProductCategory(arrayHelpers)}
                              disabled={isSubmitting}
                            >
                              {translation['form.add']}
                            </Button>
                          </Grid>

                          <Grid item>
                            <LoadingButton
                              type="submit"
                              variant="contained"
                              color="primary"
                              disableElevation
                              disabled={isSubmitting}
                              isLoading={isSubmitting}
                              onClick={() => checkValues(values.productCategoryArr)}
                            >
                              {translation['form.save']}
                            </LoadingButton>
                          </Grid>
                        </Grid>
                      </Box>
                    </>
                  )}
                />
              </Form>
            )}
          </Formik>
        </Paper>
      </Grid>

    </Grid>
  )
}
